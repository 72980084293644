import { ComponentProps, FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import cn from 'classnames'

import { logout } from '@/api/user'
import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { useDynamicLogout } from '@/hooks/useDynamicLogout'
import { ButtonIcon } from '@/libs/common/button-icon'
import { Button } from '@/libs/common/button/button'
import { Popover, type TOnChangeVisible } from '@/libs/common/popover'
import { Typography } from '@/libs/common/typography'
import { IconName } from '@/libs/enums'
import { useAppDispatch, useAppSelector } from '@/store'
import { removeUser } from '@/store/slices/user.slice.ts'

import styles from './styles.module.scss'

type TProperty = {
  variant?: undefined | 'onboarding'
  buttonClassName?: string
}

const SignOutButton: FC<ComponentProps<'button'> & TProperty> = ({
  variant,
  buttonClassName,
  ...props
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useCustomNavigate()

  const { dynamicLogout } = useDynamicLogout()

  const userData = useAppSelector((state) => state.user.userData)
  const logoutAndClearSession = async () => {
    if (!userData) return
    navigate({ isDashboard: true })
    await logout(userData.user_id)
    dispatch(removeUser())
    localStorage.clear()
    location.reload()
  }
  const [signOutPopup, setSignOutDeletePopup] = useState<TOnChangeVisible>({
    visible: false,
    anchorE: undefined,
  })

  return (
    <div className={styles.container}>
      <ButtonIcon
        className={cn(
          styles.trigger,
          {
            [styles[variant ?? '']]: variant,
            [styles.activeButton]: signOutPopup.visible,
          },
          buttonClassName,
        )}
        icon={IconName.POWER}
        tooltipTitle={!variant ? t('menu.sign_out') : undefined}
        onClick={(e) => setSignOutDeletePopup({ visible: true, anchorE: e.currentTarget })}
        {...props}
      >
        {variant &&
          {
            onboarding: <Typography variant="body1">{t('menu.sign_out')}</Typography>,
          }[variant]}
      </ButtonIcon>

      <Popover
        className={styles.popover}
        popoverState={signOutPopup}
        onChangeVisible={setSignOutDeletePopup}
      >
        <Typography variant="body2" className={styles.title}></Typography>
        <Typography variant="body2">{t('menu.sign_out_confirm')}</Typography>
        <div className={styles.buttons}>
          <ButtonIcon
            onClick={() => {
              setSignOutDeletePopup({ visible: false, anchorE: signOutPopup.anchorE })
            }}
          >
            <Typography variant="body2" className={styles.cancel}>
              {t('cancel')}
            </Typography>
          </ButtonIcon>
          <Button
            className={styles.confirm}
            styleVariant="red"
            onClick={() => {
              logoutAndClearSession()
              setSignOutDeletePopup({ visible: false, anchorE: signOutPopup.anchorE })
              dynamicLogout()
            }}
          >
            {t('menu.sign_out')}
          </Button>
        </div>
      </Popover>
    </div>
  )
}

export { SignOutButton }
