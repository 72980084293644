import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { signIn } from '@/api/user'
import { AuthCard } from '@/components/auth-card'
import { CustomToast } from '@/components/custom-toast'
import { SixDigitForm } from '@/components/six-digit-form'
import { Spinner } from '@/components/spinner'
import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { useSelectNewToken } from '@/hooks/useSelectNewToken.hook'
import { Icon, Typography } from '@/libs/common'
import { DEFAULT_TOKEN } from '@/libs/configs/default-token.config.ts'
import { AppMode, AppRoute, IconName, SpinnerSize } from '@/libs/enums'
import { useAppDispatch, useAppSelector } from '@/store'
import { fetchUserById } from '@/store/slices/user.slice.ts'

import styles from './styles.module.scss'

export const EnterCode: React.FC = () => {
  const [code, setCode] = useState<string[]>(new Array(6).fill(''))
  const navigate = useCustomNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const handleCodeChange = (newCode: string[]) => {
    setCode(newCode)
  }
  const [searchParams] = useSearchParams()
  const dispatch = useAppDispatch()
  const networkId = useAppSelector((state) => state.chain.currentChain.indexerChainId)
  const { t } = useTranslation()
  const chain = useAppSelector((state) => state.chain.currentChain)

  const handleNewTokenSelection = useSelectNewToken()

  useEffect(() => {
    const codeString = code.join('')
    if (codeString.length === 6) {
      try {
        const data = JSON.parse(localStorage.signData)
        setLoading(true)
        signIn({
          blockchain: networkId,
          signatory: data.signatory,
          signature: data.signature,
          nonce: data.nonce,
          code: codeString,
          message: data.message,
        })
          .then(async (res) => {
            localStorage.token = res.data.data.token
            localStorage.refreshToken = res.data.data.refresh_token
            await dispatch(fetchUserById(res.data.data.id)).unwrap()

            localStorage.removeItem('signData')
            localStorage.removeItem('signUpData')

            if (localStorage.code) {
              navigate({
                path: AppRoute.ADD_DEVICE,
                searchParams: {
                  code: localStorage.code,
                },
              })
              return
            }

            if (searchParams.get('wallet')) {
              navigate({
                searchParams: {
                  wallet: searchParams.get('wallet'),
                  token: chain.defaultToken,
                },
                newChain: chain.description.toLowerCase(),
                isDashboard: true,
              })
            } else {
              // TODO: Check if this is needed
              if (localStorage.cachedPath) {
                navigate({ isDashboard: true, path: localStorage.cachedPath })
              }
              await handleNewTokenSelection(DEFAULT_TOKEN, {
                skipTokenSetToUrl: !localStorage.cachedPath,
                isForceNavigate: !localStorage.cachedPath,
                skipRedirect: !!localStorage.cachedPath || !!localStorage.code,
              })
            }
          })
          .catch(() => {
            setLoading(false)
            CustomToast('error', 'login.error_2fa')
          })
      } catch {
        setLoading(false)
      }
    }
  }, [code])

  return (
    <>
      <AuthCard
        className={styles.card}
        footerLink={{
          title: t('login.cancel_login'),
          link: `${AppRoute.DASHBOARD}/${AppMode.PRO}?token=${DEFAULT_TOKEN}`,
        }}
      >
        <div className={styles.content}>
          <div className={styles.titleContainer}>
            <div className={styles.icon} onClick={() => navigate({ delta: -1 })}>
              <Icon name={IconName.ARROW_RIGHT} />
            </div>
            <Typography variant="h3" textColor="light-grey" align="center">
              {t('login.enter_code')}
            </Typography>
            <div />
          </div>
          {loading && <Spinner size={SpinnerSize.BIG} centered />}
          {!loading && <SixDigitForm onCodeChange={handleCodeChange} />}
        </div>
      </AuthCard>
    </>
  )
}
