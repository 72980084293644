import { FC } from 'react'

import { Grid, InputAdornment } from '@mui/material'
import cls from 'classnames'

import { Icon } from '@/libs/common/icon'
import { Typography } from '@/libs/common/typography'
import { IconName } from '@/libs/enums'
import { ValueOf } from '@/libs/types'

import styles from './styles.module.scss'

type TProperty = {
  label?: string
  icon?: ValueOf<typeof IconName>
  imageUrl?: string | null
  isDisabled?: boolean
  children?: React.ReactNode
}

const EndAdornment: FC<TProperty> = ({ label, icon, imageUrl, children, isDisabled }) => {
  return (
    <InputAdornment position="end" className={styles.inputAdornment}>
      <Grid></Grid>
      {(icon || imageUrl) && (
        <div className={cls(styles.icon, { [styles.iconDisabled]: isDisabled })}>
          {icon && <Icon name={icon as any} />}
          {imageUrl && <img src={imageUrl} alt={label} />}
        </div>
      )}
      {label && (
        <Typography
          variant="caption"
          isUpperCase
          className={cls(styles.label, { [styles.textDisabled]: isDisabled })}
        >
          {label}
        </Typography>
      )}
      {children}
    </InputAdornment>
  )
}

export { EndAdornment }
