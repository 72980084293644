import { type PropsWithChildren, useEffect, useState } from 'react'

import { EthereumWalletConnectors } from '@dynamic-labs/ethereum'
import { DynamicContextProvider } from '@dynamic-labs/sdk-react-core'
import { SolanaWalletConnectors } from '@dynamic-labs/solana'

import { getNonce } from '@/api/user'
import { useAppDispatch } from '@/store'
import { setAuth } from '@/store/slices/auth.slice'

const CustomDynamicProvider = ({ children }: PropsWithChildren) => {
  const dispatch = useAppDispatch()

  const [nonce, setNonce] = useState<string | null>(null)

  useEffect(() => {
    async function fetchNonce() {
      try {
        const { data } = await getNonce()
        setNonce(data.data.nonce)
      } catch (error) {
        console.error(error)
      }
    }

    fetchNonce()
  }, [])

  if (!nonce) return null

  return (
    <DynamicContextProvider
      settings={{
        environmentId: import.meta.env.VITE_DYNAMIC_LABS_ENVIRONMENT_ID,
        walletConnectors: [EthereumWalletConnectors, SolanaWalletConnectors],
        initialAuthenticationMode: 'connect-and-sign',
        events: {
          onSignedMessage: (data) => {
            dispatch(
              setAuth({
                messageToSign: data.messageToSign,
                signedMessage: data.signedMessage,
              }),
            )
          },
          onLogout: () => {
            dispatch(setAuth(null))
          },
        },
        siweStatement: `By Signing, You Agree To Our Terms Of Use And Privacy Policy: ${nonce}\n\nhttps://blazingbot.com/terms\n\nhttps://blazingbot.com/privacy`,
        mobileExperience: 'redirect',
        termsOfServiceUrl: 'https://blazingbot.com/terms',
        privacyPolicyUrl: 'https://blazingbot.com/privacy',
      }}
    >
      {children}
    </DynamicContextProvider>
  )
}

export { CustomDynamicProvider }
