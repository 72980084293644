import { FC, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useSearchParams } from 'react-router-dom'

import { Grid } from '@mui/material'

import { AppRoute } from '@/libs/enums'
import { ESidebarBtnType } from '@/libs/enums/sidebar-btn-type'
import { useModal } from '@/pages/modal-page/modal-page'
import { useAppSelector } from '@/store'

import { WalletItem } from './libs/components/wallet-item'
import styles from './styles.module.scss'

const SelectWalletToDeposit: FC = () => {
  const { t } = useTranslation()
  const wallets = useAppSelector((state) => state.user.userWallets)
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const typeFromQuery = searchParams.get('type')
  const type = useMemo(() => {
    const locationPathname = location.pathname
    if (locationPathname.includes(AppRoute.TRANSFER_WALLETS)) {
      if (typeFromQuery === ESidebarBtnType.TRANSFER) {
        return ESidebarBtnType.TRANSFER
      }
      if (typeFromQuery === ESidebarBtnType.DISPERSE_COLLECT) {
        return ESidebarBtnType.DISPERSE_COLLECT
      }
      return null
    }
    return ESidebarBtnType.DEPOSIT
  }, [location])

  const { setModalProps } = useModal()

  useEffect(() => {
    let modalTitle = ''
    switch (type) {
      case ESidebarBtnType.TRANSFER:
        modalTitle = t('wallet.select_wallet_to_transfer_from')
        break
      case ESidebarBtnType.DISPERSE_COLLECT:
        modalTitle = t('wallet.select_wallet_to_collect_from')
        break
      case ESidebarBtnType.DEPOSIT:
        modalTitle = t('wallet.select_wallet_to_deposit_to')
        break
      default:
        modalTitle = t('wallet.select_wallet_to_transfer_from')
    }
    setModalProps({
      title: modalTitle,
    })
  }, [type])

  return (
    <div>
      <Grid rowGap={2} display="grid" className={styles.walletsList}>
        {wallets?.map((wallet) => (
          <WalletItem wallet={wallet} key={wallet.address} type={type as ESidebarBtnType} />
        ))}
      </Grid>
    </div>
  )
}

export { SelectWalletToDeposit }
