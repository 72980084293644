import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@/libs/common'
import { ModalComponent } from '@/libs/common/modal-component'
import { useAppSelector } from '@/store'

const LOCAL_STORAGE_KEY = 'brave-warning-submitted'

const BraveWarningModal = () => {
  const browserInfo = useAppSelector((state) => state.app.browserInfo)

  const { t } = useTranslation()

  const [open, setOpen] = useState(false)

  const handleSubmit = () => {
    setOpen(false)
    localStorage[LOCAL_STORAGE_KEY] = 'true'
  }

  useEffect(() => {
    if (browserInfo.isBrave && localStorage[LOCAL_STORAGE_KEY] !== 'true') {
      setOpen(true)
    }
  }, [browserInfo.isBrave])

  return (
    <ModalComponent
      open={open}
      name="brave-warning"
      title={t('browser.brave_title')}
      message={t('browser.brave_message')}
    >
      <Button onClick={handleSubmit}>{t('browser.understood')}</Button>
    </ModalComponent>
  )
}

export { BraveWarningModal }
