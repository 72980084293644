import { CustomToast } from '@/components/custom-toast'
import { TransactionResponseType } from '@/libs/enums'
import { TDataError, TTransactionResponse, ValueOf } from '@/libs/types'
import { ANALYTICS_EVENTS, sendAnalyticsEvent } from '@/utils/analytics'

type TResponseType = ValueOf<typeof TransactionResponseType>

export const processTransactionResponse = (response: TTransactionResponse, type: TResponseType) => {
  if (response.status === 'success' && Array.isArray(response.data.transactions)) {
    response.data.transactions.map((el) => {
      if (el.status === 'success') {
        switch (type) {
          case TransactionResponseType.TRANSFER: {
            CustomToast('info', `toast.info.transfer_is_pending`, {
              interpolation: { walletName: el.name },
            })
            break
          }
          case TransactionResponseType.COLLECT: {
            CustomToast('info', 'toast.info.collect_is_pending')
            break
          }
          case TransactionResponseType.BUY: {
            CustomToast('info', 'toast.info.transaction_is_pending')
            sendAnalyticsEvent(ANALYTICS_EVENTS.BUY_TOKENS)
            break
          }
          case TransactionResponseType.SELL: {
            CustomToast('info', 'toast.info.transaction_is_pending')
            sendAnalyticsEvent(ANALYTICS_EVENTS.SELL_TOKENS)
            break
          }
        }
      }
      if (el.status === 'error') {
        CustomToast('error', 'toast.error.transaction_error_description', {
          interpolation: { walletName: el.name, error: (el.data as TDataError).description },
        })
      }
    })
  }
  if (response.status === 'error') {
    CustomToast('error', `${(response.data as any).description}`)
  }
}
