import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import { DynamicEmbeddedWidget, useDynamicContext } from '@dynamic-labs/sdk-react-core'
import { isSolanaWallet } from '@dynamic-labs/solana'
import bs58 from 'bs58'

import { getStateOfUser, signIn } from '@/api/user'
import { CustomToast } from '@/components/custom-toast/custom-toast'
import { useCustomNavigate } from '@/hooks/useCustomNavigate'
import { useDynamicLogout } from '@/hooks/useDynamicLogout'
import { useSelectNewToken } from '@/hooks/useSelectNewToken.hook'
import { chainsConfig } from '@/libs/configs/chains.config'
import { AppRoute } from '@/libs/enums'
import { TChainConfig } from '@/libs/types/chain.type'
import { useAppDispatch, useAppSelector } from '@/store'
import { setCurrentChain } from '@/store/slices/chain.slice'
import { fetchUserBuyTemplates, fetchUserById } from '@/store/slices/user.slice'
import { getNonceFromMessage } from '@/utils/get-nonce-from-message'

type Props = {
  isAuth?: boolean
}

const ConnectWallet = ({ isAuth }: Props) => {
  const navigate = useCustomNavigate()
  const [searchParams] = useSearchParams()

  const dispatch = useAppDispatch()
  const authData = useAppSelector((state) => state.auth.authData)

  const handleNewTokenSelection = useSelectNewToken()

  const { primaryWallet } = useDynamicContext()
  const { dynamicLogout } = useDynamicLogout()

  type AuthData = typeof authData
  type PrimaryWallet = typeof primaryWallet

  async function handleSignIn(
    networkId: number,
    address: string,
    signature: string,
    nonce: string,
    messageToSign: string,
    chain: any,
    searchParams: URLSearchParams,
  ) {
    const res = await signIn({
      blockchain: networkId,
      signatory: address,
      signature,
      nonce,
      message: messageToSign,
    })
    const { token, refresh_token, id } = res.data.data

    localStorage.setItem('token', token)
    localStorage.setItem('refreshToken', refresh_token)

    localStorage.setItem(
      'signData',
      JSON.stringify({
        blockchain: networkId,
        signatory: address,
        signature,
        message: messageToSign,
        nonce,
      }),
    )

    await dispatch(fetchUserById(id)).unwrap()
    dispatch(fetchUserBuyTemplates())

    navigate({
      path: isAuth
        ? AppRoute.DASHBOARD
        : localStorage.code
          ? AppRoute.ADD_DEVICE
          : localStorage.cachedPath,
      isDashboard: !localStorage.code,
      newChain: chain.description.toLowerCase(),
      searchParams: localStorage.code
        ? { code: localStorage.code }
        : { token: searchParams.get('token') ?? chain.defaultToken },
    })
  }

  /**
   * This function handles the sign-in process with the following logic:
   * 1. Attempt to sign in using the provided signature.
   * 2. If the first attempt fails due to an "Invalid Signature" error, decode the signature and try again.
   * 3. If either the original or decoded signature results in an "Invalid 2FA Code" error, redirect the user to the 2FA code entry page.
   * 4. For all other errors, display an appropriate error message.
   */

  const signInProcess = async ({
    networkId,
    address,
    signature,
    nonce,
    messageToSign,
    chain,
  }: {
    networkId: number
    address: string
    signature: string
    nonce: string
    messageToSign: string
    chain: TChainConfig
  }) => {
    const handleInvalid2FACode = (currentSignature: string) => {
      localStorage.setItem(
        'signData',
        JSON.stringify({
          blockchain: networkId,
          signatory: address,
          signature: currentSignature,
          message: messageToSign,
          nonce,
        }),
      )
      navigate({
        path: `/${AppRoute.ENTER_CODE}`,
        searchParams: { wallet: address },
      })
    }

    try {
      await handleSignIn(networkId, address, signature, nonce, messageToSign, chain, searchParams)
    } catch (e: any) {
      const errCode = e?.response?.data?.data?.code
      const errCodeTitle = e?.response?.data?.data?.title

      if (errCodeTitle === 'Invalid Signature') {
        signature = bs58.encode(Buffer.from(signature, 'base64'))

        try {
          await handleSignIn(
            networkId,
            address,
            signature,
            nonce,
            messageToSign,
            chain,
            searchParams,
          )
        } catch (e: any) {
          const retryErrorCode = e?.response?.data?.data?.code
          // Invalid 2FA Code
          if (retryErrorCode === 'Q9S7WRQT81') {
            handleInvalid2FACode(signature)
          } else {
            CustomToast('error', e?.response?.data?.data.title)
          }
        }
      } else if (errCode === 'Q9S7WRQT81') {
        handleInvalid2FACode(signature)
      } else {
        CustomToast('error', e?.response?.data?.data.title)
      }
    }
  }
  const signInOrSignUp = async (
    { signedMessage, messageToSign }: NonNullable<AuthData>,
    primaryWallet: NonNullable<PrimaryWallet>,
  ) => {
    const nonce = getNonceFromMessage(messageToSign)

    const address = primaryWallet.address

    const signature = signedMessage

    let networkId = 1

    if (isSolanaWallet(primaryWallet)) {
      networkId = 8
    }

    const chain = chainsConfig.find((chain) => chain.id === networkId)!

    dispatch(setCurrentChain(chain))
    await handleNewTokenSelection(chain.defaultToken, {
      customChain: chain.description.toLowerCase(),
      skipRedirect: true,
    })

    const userState = await getStateOfUser(address)

    if (userState.data.data.exists) {
      await signInProcess({ networkId, address, signature, nonce, messageToSign, chain })
    } else {
      localStorage.setItem(
        'signData',
        JSON.stringify({
          blockchain: networkId,
          signatory: address,
          signature,
          message: messageToSign,
          nonce,
        }),
      )

      if (!isAuth) {
        localStorage.setItem(
          'account',
          JSON.stringify({
            signatory: address,
            signature,
            nonce,
          }),
        )
        navigate({ path: `/${AppRoute.ALREADY_SIGN}` })
      } else {
        await signInProcess({ networkId, address, signature, nonce, messageToSign, chain })
      }
    }
  }

  useEffect(() => {
    window.twq?.('event', 'tw-ojn9a-otvrm', {})
  }, [])

  useEffect(() => {
    async function main() {
      if (authData && primaryWallet) {
        await signInOrSignUp(authData, primaryWallet)
        dynamicLogout()
      }
    }

    main()
  }, [authData, primaryWallet])

  return <DynamicEmbeddedWidget />
}

export { ConnectWallet }
