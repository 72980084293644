enum ETokenInfo {
  PRICE = 'PRICE',
  MCAP = 'MCAP',
  GAIN = 'GAIN',
  LIQUIDITY = 'LIQUIDITY',
  TRADE_OPEN = 'TRADE_OPEN',
  MAX_BUY_PER_WALLET = 'MAX_BUY_PER_WALLET',
  TAXES = 'TAXES',
  HONEYPOT = 'HONEYPOT',
  RENOUNCED = 'RENOUNCED',
  BURN_LP = 'BURN_LP',
  CLOG = 'CLOG',
  MAX_BUY = 'MAX_BUY',
  MAX_SELL = 'MAX_SELL',
  MAX_HOLD = 'MAX_HOLD',
  MINT_AUTH = 'MINT_AUTH',
  FREEZE_AUTH = 'FREEZE_AUTH',
  TOKEN_DATA_MUTABLE = 'TOKEN_DATA_MUTABLE',
  UPDATE_AUTH = 'UPDATE_AUTH',
  DEV_SOLD = 'DEV_SOLD',
  HOLDERS = 'HOLDERS',
  TOP_10_HOLDERS = 'TOP_10_HOLDERS',
}

export { ETokenInfo }
