import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import cn from 'classnames'

import { deleteDevice, getDevicesList } from '@/api/device'
import { TDevice } from '@/api/device/types'
import DeleteConfirmationDevice from '@/components/user-settings/libs/components/active-devices-table/delete-confirmation-device.tsx'
import useCheckUser from '@/hooks/useCheckUser.ts'
import { Typography } from '@/libs/common'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

interface Column {
  id: 'type' | 'name' | 'id' | 'actionButtons'
  label: string
  minWidth?: number
  align?: 'right' | 'left'
  format?: (value: number) => string
}

const ActiveDevicesTable = () => {
  const [rows, setRows] = useState<TDevice[]>([])
  const checkUserAndExecute = useCheckUser()
  const userData = useAppSelector((state) => state.user.userData)
  const { t } = useTranslation()

  const columns: readonly Column[] = [
    { id: 'type', label: t('settings.device_type'), minWidth: 230 },
    { id: 'name', label: t('settings.device_name'), minWidth: 230 },
    { id: 'id', label: t('settings.device_id'), minWidth: 430 },
    {
      id: 'actionButtons',
      label: '',
      minWidth: 40,
      format: (value: number) => value.toLocaleString('en-US'),
    },
  ]

  useEffect(() => {
    checkUserAndExecute(async () => {
      await getInfo()
    })
  }, [userData])
  const getInfo = async () => {
    const { data } = await getDevicesList()
    setRows(data.data)
  }

  const cancelConnection = async (id: string) => {
    await deleteDevice({ id: id })
    await getInfo()
  }

  const renderCell = (column: Column, row: TDevice) => {
    const value = row[column.id]
    if (column.id === 'actionButtons') {
      return (
        <DeleteConfirmationDevice
          deviceName={row.name}
          onConfirm={() => cancelConnection(row.id)}
        />
      )
    } else {
      return value
    }
  }

  return (
    <TableContainer className={styles.container}>
      <Table stickyHeader aria-label="sticky table" classes={styles}>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <>
                <TableCell
                  key={column.id}
                  align={column.align}
                  className={cn(styles.tableHeadCell, {
                    [styles.tableHeadCellAction]: column.id === 'actionButtons',
                  })}
                >
                  {column.label}
                </TableCell>
              </>
            ))}
          </TableRow>
        </TableHead>

        <TableBody className={styles.tableBody}>
          {rows.map((row, indx) => {
            return (
              <TableRow
                role="checkbox"
                tabIndex={-1}
                key={row.id + indx}
                className={cn(styles.bodyRow, { [styles.background]: indx % 2 !== 0 })}
              >
                {columns.map((column) => (
                  <TableCell key={column.id} align={column.align} className={cn(styles.cell)}>
                    {renderCell(column, row)}
                  </TableCell>
                ))}
              </TableRow>
            )
          })}
          {!rows.length && (
            <TableRow className={styles.headRow}>
              <TableCell />
              <TableCell>
                <Typography variant="body2" textColor="color-grey-3">
                  {t('settings.no_devices_yet')}
                </Typography>
              </TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export { ActiveDevicesTable }
