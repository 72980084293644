import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@/libs/common'
import { useAppSelector } from '@/store'

import { WalletItem } from './libs/components/wallet-item'
import styles from './styles.module.scss'

const WalletsTab: FC = () => {
  const { t } = useTranslation()
  const wallets = useAppSelector((state) => state.user.userWallets)

  return (
    <div className={styles.container}>
      {wallets?.length ? (
        wallets.map((wallet) => <WalletItem wallet={wallet} key={wallet.address} />)
      ) : (
        <Typography
          variant="body1"
          component="span"
          textColor="grey"
          align="center"
          marginBottom={1}
        >
          {t('wallet.no_wallets')}
        </Typography>
      )}
    </div>
  )
}

export { WalletsTab }
