import { ChainName } from '@/libs/enums'

const chainPriorityCurrencies = {
  [ChainName.ETHEREUM]: 'Gwei',
  [ChainName.BNB]: 'BNB',
  [ChainName.ARBITRUM]: '',
  [ChainName.BASE]: '',
  [ChainName.AVAX]: '',
  [ChainName.SOLANA]: 'Gwei',
}

export { chainPriorityCurrencies }
