enum IconName {
  POWER = 'power',
  LOGO = 'logo',
  MAIL = 'mail',
  TOAST_SUCCESS = 'toast-success',
  TOAST_INFO = 'toast-info',
  TOAST_ERROR = 'toast-error',
  TOAST_WARNING = 'toast-warning',
  VERIFIED_MAIL = 'verified-mail',
  DOWNLOAD = 'download',
  FLIP = 'flip',
  PHONE = 'phone',
  ARROW_RIGHT = 'arrow-right',
  COPY = 'copy',
  LIST_POINT = 'list-point',
  DEPOSIT = 'deposit-funds',
  TRANSFER = 'transfer-funds',
  MANUFACTURING = 'manufacturing',
  KEY = 'key',
  LIST_ALT_ADD = 'list-alt-add',
  INFO_ICON = 'info-icon',
  EDIT_SMALL = 'edit-small',
  X_SQUARE = 'x-square',
  SUCCESS = 'success',
  GAS = 'gas',
  DIAMOND = 'diamond',
  BLOCK = 'block',
  BNB = 'bnb',
  BNB_V2 = 'bnb-v2',
  ARBITRUM = 'arbitrum',
  ETHEREUM = 'ethereum',
  EXCHANGE = 'exchange',
  CLOSE = 'close',
  SNIPE = 'snipe',
  COIN_ETH = 'coin-eth',
  METAMASK_LOGO = 'metamask-logo',
  LOCK = 'lock',
  CROSS = 'cross',
  WITHDRAW = 'withdraw',
  SETTING = 'setting',
  BURGER = 'burger',
  CROSSED_STAR = 'crossed-star',
  STAR = 'star',
  ARROW_DROP_DOWN = 'arrow-drop-down',
  SELL = 'sell',
  FILTER = 'filter',
  SHARE = 'share',
  EDIT = 'edit',
  DELETE = 'delete',
  ADD_CIRCLE = 'add-circle',
  ARROW_RIGHT2 = 'arrow-right2',
  CHEVRON_RIGHT = 'chevron-right',
  EXPAND_MORE = 'expand-more',
  SHOPPING_CART = 'shopping-cart',
  SELL2 = 'sell2',
  SHOW = 'show',
  HIDE = 'hide',
  CHECK = 'check',
  CHECK_SMALL = 'check-small',
  CLOSE_SMALL = 'close-small',
  ETHERSCAN = 'etherscan',
  SEARCH = 'search',
  PANCAKESWAP = 'pancakeswap',
  LIST_ALT = 'list-alt',
  ARROW_DOWN_GREY = 'arrow-down-grey',
  SNIPE_WHITE = 'snipe-white',
  UPDATE_PLAN = 'update-plan',
  CLOSE_MIDDLE = 'close-middle',
  TOLL = 'toll',
  RADAR = 'radar',
  CUSTOMIZE = 'customize',
  PEPE = 'pepe',
  SMALL_SUCCESS = 'small-success',
  PENDING = 'pending',
  WALLET = 'wallet',
  GOLD_WALLET = 'gold-wallet',
  ARROW_RIGHT_GREY = 'arrow-right-grey',
  VISIBILITY = 'visibility',
  PROFIT_SELL = 'profit-sell',
  DELETE_RED = 'delete-red',
  FAVORITE_ETH = 'favorite-eth',
  TELEGRAM_LINK = 'telegram-link',
  TELEGRAM_UNLINK = 'telegram-unlink',
  CLOSE_GREY = 'close-grey',
  PENDING_ORANGE = 'pending-orange',
  PRIVATE_TX = 'private-tx',
  MIDDLE_SUCCESS = 'middle-success',
  COPY_LARGE = 'copy-large',
  SHOPPING_CART_LARGE = 'shopping-cart-large',
  SNIPE_LARGE = 'snipe-large',
  SCRAPE = 'scrape',
  BASE_CHAIN = 'base-chain',
  AVAX_CHAIN = 'avax-chain',
  REFRESH = 'refresh',
  CONNECT_WALLET = 'connect-wallet',
  ERROR_TX = 'error-tx',
  TRIANGLE = 'triangle',
  ETH_CURRENCY = 'eth-currency',
  LANGUAGE = 'language',
  LANGUAGE_GOLD = 'language-gold',
  EXCLUDE = 'exclude',
  SORT_ASC = 'sort-asc',
  SORT_DESC = 'sort-desc',
  TWITTER = 'twitter',
  SETTING_2 = 'setting-2',
  CHEVRON_LEFT = 'chevron-left',
  FIRE = 'fire',
  BOLT = 'bolt',
  MORE = 'more',
  ETHER_LOGO = 'ether-logo',
  ETHERSCAN_GOLD = 'etherscan-gold',
  METAMASK = 'MetaMask',
  RABBY = 'Rabby Wallet',
  WALLETCONNECT = 'WalletConnect',
  BUY = 'buy',
  SWITCH = 'switch',
  BOTTOM_TABS_BUY = 'bottom-tabs-buy',
  BOTTOM_TABS_SELL = 'bottom-tabs-sell',
  BOTTOM_TABS_PROFIT = 'bottom-tabs-profit',
  SIDEBAR_COLLAPSED_BUTTON = 'sidebar-collapsed-button',
  LOCK_WITH_REFLECTION = 'lock-with-reflection',
  AFFILIATE = 'affiliate',
  DOUBLE_ARROW_LEFT = 'double-arrow-left',
  TEMPLATES_GREY = 'templates-grey',
  STAR_GREY_BOLD = 'star-grey-bold',
  HOLDINGS_GREY = 'holdings-grey',
  WALLET_GREY = 'wallet-grey',
  APPROVE = 'approve',
  TOKEN_BADGE = 'token-badge',
  SCHEDULE = 'schedule',
  COPY_GREY = 'copy-grey',
  TRADING_STATUS = 'trading-status',
  LOCK_GREEN = 'lock-green',
  SETTINGS_3 = 'quick-settings',
  INFO_ERROR = 'info-error',
  APPROVE_BIG = 'approve-big',
  BUBBLE = 'bubble',
  LOGO_TEXT_BETA = 'logo-text-beta',
  ALARM = 'alarm',
  FEES_COLLECTED = 'fees-collected',
  GROUP_OF_PEOPLE = 'group-of-people',
  CANDLESTICK = 'candlestick',
  TROPHY = 'trophy',
  PRICE_CHECK = 'price-check',
  BSCSCAN = 'bscscan',
  SOLANA = 'solana',
  SOLSCAN = 'solscan',
  PHANTOM = 'phantom',
  BRIDGE = 'bridge',
  DROPDOWN_ARROWS = 'dropdown-arrows',
  ARROW_DOWN_MINIMALISTIC = 'arrow-down-minimalistic',
  ARROW_RIGHT3 = 'arrow_right_3',
  TELEGRAM = 'telegram',
  /**
   * Universal icons were added because the old icons were not customizable with hardcoded sizes and colors
   * I added postfix `_` to avoid conflicts
   */
  ARROW_DOWN_ = 'arrow-down_',
  ARTICLE_ = 'article_',
  CHECK_ = 'check_',
  CLOSE_ = 'close_',
  ELECTRIC_BOLT_OUTLINED_ = 'electric-bolt-outlined_',
  ELECTRIC_BOLT_ = 'electric-bolt_',
  GEAR_ = 'gear_',
  PERSON_ = 'person_',
  PUMP_FUN_ = 'pump-fun_',
  RESTART_ = 'restart_',
  SETTINGS_ = 'settings_',
  SOLANA_CIRCLE_ = 'solana-circle_',
  SOLANA_ = 'solana_',
  BASE_NETWORK = 'BASE_NETWORK',
  CANCEL = 'cancel',
}

export { IconName }
