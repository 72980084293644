import { TPanelHistoryOrder } from '@/api/favorites/types'
import { useSniperWebsocket } from '@/hooks/useSniperWebsocket'
import { createQueryString } from '@/libs/helper/createQueryString'
import { TSocketResponse } from '@/libs/types/backend-response.type'
import { SniperSockerService } from '@/socket'
import { useAppDispatch, useAppSelector } from '@/store'
import { setPanelHistory } from '@/store/slices/favorites-tokens.slice'

const searchHistorySniperSocket = new SniperSockerService(
  import.meta.env.VITE_SNIPER_INDEXER_SOCKET_URL,
)

const useWatchPanelHistory = () => {
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  const dispatch = useAppDispatch()

  useSniperWebsocket({
    socket: searchHistorySniperSocket,
    connectionProps: {
      endpoint: 'order/stream/panels/history',
      query: createQueryString({ b: currentChain.indexerChainId, l: 'en' }),
    },
    onMessage: (jsonData) => {
      const data = JSON.parse(jsonData) as TSocketResponse<TPanelHistoryOrder[] | string>
      if (typeof data.data === 'string') {
        return
      }
      dispatch(setPanelHistory(data.data))
    },
  })
}

export { useWatchPanelHistory }
