import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'

import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import cn from 'classnames'

import { getNewTokensList } from '@/api/tokens'
import { TNewToken } from '@/api/tokens/types'
import { RowLoadingPlaceholder } from '@/components/row-loading-placeholder'
import { RefreshButton } from '@/libs/common'
import { useAppSelector } from '@/store'

import { Row } from './libs/row/row'
import styles from './styles.module.scss'

type TProperty = {
  resolution: string
}

const NewTokensTable: FC<TProperty> = ({ resolution }) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(true)
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const [tokensList, setTokenList] = useState<TNewToken[] | null>(null)
  const [hasMore, setHasMore] = useState(true)
  const [filteredTokenList, setFilteredTokenList] = useState<TNewToken[] | null>(null)
  const handleNewTokensList = async (isHardUpdate = false) => {
    try {
      setIsLoading(true)
      const { data } = await getNewTokensList(currentChain.id)
      if (!data.data.length) {
        setHasMore(false)
      } else {
        if (isHardUpdate) {
          setTokenList(data.data)
          setIsLoading(false)
          setHasMore(!!data.data.length)
          return
        }
        setTokenList((tokensList) => {
          if (tokensList) {
            const updatedList = [...tokensList, ...data.data]
            const ids = updatedList.map(({ address }) => address)
            const filtered = updatedList.filter(
              ({ address }, index) => !ids.includes(address, index + 1),
            )
            return filtered
          }
          return data.data
        })
      }
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    if (resolution === 'ALL') {
      setFilteredTokenList(tokensList)
      return
    }
    const updatedList = tokensList?.filter((token) => token.status === resolution)
    setFilteredTokenList(updatedList || null)
  }, [tokensList, resolution])

  useEffect(() => {
    handleNewTokensList()
  }, [])
  return (
    <Paper className={styles.root}>
      <InfiniteScroll
        hasMore={hasMore}
        dataLength={filteredTokenList?.length || 0}
        next={handleNewTokensList}
        scrollableTarget="new-token-table"
        loader={null}
      >
        <TableContainer
          className={cn(styles.container, styles.containerSmall)}
          id="new-token-table"
        >
          <Table stickyHeader aria-label="sticky table" classes={styles}>
            <TableHead>
              <TableRow className={styles.headRow}>
                <TableCell className={cn(styles.tableHeadCell, styles.token)}>
                  <div className={styles.refreshWrapper}>
                    {t('token_list.token')}
                    <RefreshButton
                      onClick={() => handleNewTokensList(true)}
                      isLoading={isLoading}
                    ></RefreshButton>
                  </div>
                </TableCell>
                <TableCell className={cn(styles.tableHeadCell, styles.actions)}>
                  {t('token_info.actions')}
                </TableCell>
                <TableCell className={cn(styles.tableHeadCell, styles.status)}>
                  {t('token_info.status')}
                </TableCell>
                <TableCell className={cn(styles.tableHeadCell, styles.listed)}>
                  {t('token_info.listed')}
                </TableCell>
                <TableCell className={cn(styles.tableHeadCell, styles.tax)}>
                  {t('token_info.tax')}
                </TableCell>
                <TableCell className={cn(styles.tableHeadCell, styles.liquidity)}>
                  {t('token_info.liquidity')}
                </TableCell>
                <TableCell className={cn(styles.tableHeadCell, styles.volume)}>
                  {t('token_info.volume')}
                </TableCell>
                <TableCell className={cn(styles.tableHeadCell, styles.marketCap)}>
                  {t('token_info.marketcap')}
                </TableCell>
                <TableCell className={cn(styles.tableHeadCell, styles.price)}>
                  {t('token_list.price')}
                </TableCell>
                <TableCell className={cn(styles.tableHeadCell, styles.traders)}>
                  {t('token_info.traders')}
                </TableCell>
                <TableCell className={cn(styles.tableHeadCell, styles.supply)}>
                  {t('token_info.supply')}
                </TableCell>
                <TableCell className={cn(styles.tableHeadCell, styles.deployed)}>
                  {t('token_info.deployed')}
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {filteredTokenList &&
                filteredTokenList?.map((row) => <Row key={row.address} row={row} />)}
              {isLoading && <RowLoadingPlaceholder colSpan={12} />}
            </TableBody>
          </Table>
        </TableContainer>
      </InfiniteScroll>
    </Paper>
  )
}

export { NewTokensTable }
