import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import cls from 'classnames'

import styles from '@/components/dashboard-component-lite/styles.module.scss'
import { Tabs } from '@/libs/common'
import { LocalStorageItem, TabName, TemplateGroup } from '@/libs/enums'

import { BuyTab } from './libs/buy-tab'
import { SellTab } from './libs/sell-tab'

const BuySellBlock = () => {
  const { t } = useTranslation()

  const [isExpanded, setIsExpanded] = useState(false)
  const handleExpand = (value: boolean) => setIsExpanded(value)
  const tabs = [
    {
      label: t('buy'),
      content: <BuyTab onExpand={handleExpand} />,
    },
    {
      label: t('sell'),
      content: <SellTab onExpand={handleExpand} />,
    },
  ]
  const activePanelTab = localStorage.getItem(LocalStorageItem.ACTIVE_PANEL_TAB)
  const activeTabIndex = tabs.findIndex((tab) => tab.label == activePanelTab)
  const defaultValue = activeTabIndex !== -1 ? activeTabIndex : 0
  return (
    <div className={cls(styles.block, styles.buySellBock, isExpanded && styles.expanded)}>
      <Tabs
        tabs={tabs}
        defaultValue={defaultValue}
        name={TabName.BUY_SELL_LITE}
        onChange={(_, value) => {
          localStorage.setItem(
            LocalStorageItem.ACTIVE_PANEL_TAB,
            value < tabs.length ? tabs[value].label : TemplateGroup.BUY,
          )
          setIsExpanded(false)
        }}
      />
    </div>
  )
}

export { BuySellBlock }
