import { Grid } from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import cn from 'classnames'
import { t } from 'i18next'

import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { ButtonIcon, Icon, Switch, Typography } from '@/libs/common'
import { AppRoute, IconName } from '@/libs/enums'

import styles from './styles.module.scss'

interface Column {
  id:
    | 'trader'
    | 'totalInvested'
    | 'pnl'
    | 'realizedProfit'
    | 'unrealizedProfit'
    | 'positions'
    | 'actionButtons'
  label: string
  minWidth?: number
  align?: 'right' | 'left'
  format?: (value: number) => string
}

const columns: readonly Column[] = [
  { id: 'trader', label: t('token_info.trader') },
  {
    id: 'totalInvested',
    label: 'Total Invested',
  },
  { id: 'pnl', label: 'PnL', align: 'right' },
  {
    id: 'realizedProfit',
    label: 'Realized Profit',
    align: 'right',
    format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    id: 'unrealizedProfit',
    label: 'Unrealized Profit',
    align: 'right',
    format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    id: 'positions',
    label: 'Positions',
    align: 'right',
    format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    id: 'actionButtons',
    label: '',
    align: 'right',
    format: (value: number) => value.toLocaleString('en-US'),
  },
]

interface Data {
  trader: string
  totalInvested: string
  pnl: number
  realizedProfit: string
  unrealizedProfit: string
  positions: string
  actionButtons: string
}

function createData(
  trader: string,
  totalInvested: string,
  pnl: number,
  realizedProfit: string,
  unrealizedProfit: string,
  positions: string,
  actionButtons: string,
): Data {
  return { trader, totalInvested, pnl, realizedProfit, unrealizedProfit, positions, actionButtons }
}

const rows = [
  createData('Copycat', '12.324565 ETH', 44.56, '$4.85', '$12,456.70', '9', ''),
  createData('Marketer', '122.324565 ETH', 14.56, '$1,456.70', '$54.85', '2', ''),
  createData('Copycat', '1.324565 ETH', -44.56, '$546.85', '$1,456.70', '7', ''),
  createData('Copycat', '12.324565 ETH', 44.56, '$54.85', '$12,456.70', '9', ''),
  createData('Marketer', '122.324565 ETH', 14.56, '$1,456.70', '$54.85', '2', ''),
  createData('Copycat', '1.324565 ETH', -44.56, '$546.85', '$1,456.70', '7', ''),
  createData('Copycat', '12.324565 ETH', 44.56, '$54.85', '$12,456.70', '9', ''),
  createData('Marketer', '122.324565 ETH', 14.56, '$1,456.70', '$54.85', '2', ''),
  createData('Copycat', '1.324565 ETH', -44.56, '$546.85', '$1,456.70', '7', ''),
  createData('Copycat', '12.324565 ETH', 44.56, '$54.85', '$12,456.70', '9', ''),
  createData('Marketer', '122.324565 ETH', 14.56, '$1,456.70', '$54.85', '2', ''),
  createData('Copycat', '1.324565 ETH', -44.56, '$546.85', '$1,456.70', '7', ''),
  createData('Copycat', '12.324565 ETH', 44.56, '$54.85', '$12,456.70', '9', ''),
  createData('Marketer', '122.324565 ETH', 14.56, '$1,456.70', '$54.85', '2', ''),
  createData('Copycat', '1.324565 ETH', -44.56, '$546.85', '$1,456.70', '7', ''),
]

const ActiveCopyTradesTable = () => {
  const navigate = useCustomNavigate()

  const handleOpenModal = () => {
    navigate({
      isDashboard: true,
      path: `${AppRoute.MODAL}/${AppRoute.COPY_TRADE}`,
    })
  }

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer className={styles.container}>
        <Table stickyHeader aria-label="sticky table" classes={styles}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} className={styles.tableHeadCell}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody className={styles.tableBody}>
            {rows.map((row, indx) => {
              return (
                <TableRow
                  role="checkbox"
                  tabIndex={-1}
                  key={row.realizedProfit + indx}
                  className={cn(styles.bodyRow, { [styles.background]: indx % 2 !== 0 })}
                >
                  {columns.map((column) => {
                    const value = row[column.id]
                    if (column.id === 'pnl') {
                      return (
                        <TableCell key={column.id} align={column.align} className={styles.cell}>
                          <Typography
                            variant="body2"
                            className={cn(row.pnl > 0 ? styles.profit : styles.loss)}
                          >
                            {Math.abs(row.pnl)}%
                          </Typography>
                        </TableCell>
                      )
                    } else {
                      return (
                        <TableCell key={column.id} align={column.align} className={cn(styles.cell)}>
                          {column.id === 'actionButtons' ? (
                            <Grid
                              container
                              alignItems="center"
                              gap={1.5}
                              justifyContent="center"
                              flexWrap="nowrap"
                            >
                              <Switch value={false} />

                              <ButtonIcon tooltipTitle="Settings" onClick={handleOpenModal}>
                                <Icon name={IconName.SETTING} />
                              </ButtonIcon>

                              <ButtonIcon tooltipTitle="Visibility">
                                <Icon name={IconName.VISIBILITY} />
                              </ButtonIcon>

                              <ButtonIcon tooltipTitle={t('delete')}>
                                <Icon name={IconName.DELETE_RED} />
                              </ButtonIcon>
                            </Grid>
                          ) : (
                            value
                          )}
                        </TableCell>
                      )
                    }
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export { ActiveCopyTradesTable }
