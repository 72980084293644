import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid } from '@mui/material'
import cls from 'classnames'

import { ButtonGroupRadio, ButtonGroupRadioButton, Icon, Input, Typography } from '@/libs/common'
import { IconName } from '@/libs/enums'
import { getChainSensitiveLabel } from '@/libs/helper/getChainSensitiveLabel'
import { TFees } from '@/libs/types'
import { TChainConfig } from '@/libs/types/chain.type'
import { useAppSelector } from '@/store'

import { PriorityCurrency } from '../priority-currency'
import styles from './styles.module.scss'

type TProperty = {
  fees?: TFees | null
  onChange: (value: TFees) => void
  customChain?: TChainConfig
}

const SelectFees: FC<TProperty> = ({ fees, onChange, customChain }) => {
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  const { t } = useTranslation()

  const chainToUse = customChain || currentChain

  const [customMinerTip, setCustomMinerTip] = useState<string>(fees ? `${fees.minerTip}` : '')

  useEffect(() => {
    if (fees?.type === 'advanced') onChange({ type: 'advanced', minerTip: customMinerTip })
  }, [customMinerTip])

  useEffect(() => {
    if (fees?.type === 'advanced') {
      setCustomMinerTip(fees.minerTip)
    }
  }, [fees])

  const handleChange = (_: React.BaseSyntheticEvent, value: TFees) => {
    onChange(value)
  }

  return (
    <div className={styles.container}>
      {!chainToUse.features?.noPriorityGas && (
        <div className={styles.fees}>
          <ButtonGroupRadio
            exclusive
            label={
              <Typography variant="body2" textColor="light-grey">
                {t('orders.fees')}
              </Typography>
            }
            className={styles.radioGroup}
            onChange={handleChange}
            value={fees}
          >
            <Grid container gap={2} flexDirection="row" flexWrap="nowrap">
              <ButtonGroupRadioButton
                value={{ type: 'normal', minerTip: 1 }}
                name="fees"
                className={cls(styles.customButton, { [styles.active]: fees?.type === 'normal' })}
              >
                <Typography variant="body2" className={styles.lightBold}>
                  {t('orders.normal_speed')}
                </Typography>
                {/* <div className={styles.cost}>
                <Typography variant="body2" className={styles.eth}>
                  0,000032 ETH
                </Typography>
                <Typography variant="body2" className={styles.money}>
                  (~$1.32)
                </Typography>
              </div> */}
              </ButtonGroupRadioButton>
              <ButtonGroupRadioButton
                value={{ type: 'fast', minerTip: 7 }}
                name="fees"
                className={cls(styles.customButton, { [styles.active]: fees?.type === 'fast' })}
              >
                <Typography variant="body2" className={styles.lightBold}>
                  {t('orders.fast_speed')}
                </Typography>
                {/* <div className={styles.cost}>
                <Typography variant="body2">0,000032 ETH</Typography>
                <Typography variant="body2">(~$1.32)</Typography>
              </div> */}
              </ButtonGroupRadioButton>
              <ButtonGroupRadioButton
                value={{ type: 'advanced', minerTip: +customMinerTip }}
                name="fees"
                className={cls(styles.customButton, styles.advanced, {
                  [styles.active]: fees?.type === 'advanced',
                  [styles.activeLabel]: fees?.type === 'advanced',
                })}
              >
                <Icon name={IconName.ADD_CIRCLE} />
                <Typography
                  variant="body2"
                  className={cls(styles.text, { [styles.activeLabel]: fees?.type === 'advanced' })}
                >
                  {t('orders.advanced')}
                </Typography>
              </ButtonGroupRadioButton>
            </Grid>
          </ButtonGroupRadio>
        </div>
      )}
      {(fees?.type === 'advanced' || chainToUse.features?.noPriorityGas) && (
        <div className={styles.advanced}>
          <Input
            className={styles.input}
            label={getChainSensitiveLabel('gas')}
            placeholder={t('template.enter_gwei_amount', {
              currency: currentChain.priorityCurrency.toLowerCase(),
            })}
            isNumeric
            endAdornment={<PriorityCurrency />}
            onChange={(e) => setCustomMinerTip(e.target.value)}
            value={customMinerTip}
          />
          {/* <Input
            className={styles.input}
            label="Max fee"
            placeholder="Enter gwei amount"
            isNumeric
            endAdornment={<EndAdornment label="ETH" icon={IconName.ETHEREUM} />}
            onChange={(e) => setCustomMaxFee(e.target.value)}
            value={customMaxFee}
          /> */}
          {/* <div className={styles.advancedInfo}>
            <Typography variant="body2" className={styles.light}>
              Estimated fees
            </Typography>
            <div className={styles.cost}>
              <Typography variant="body2" className={styles.light}>
                0,000032 ETH
              </Typography>
              <Typography variant="body2" className={styles.light}>
                (~$1.32)
              </Typography>
            </div>
          </div> */}
        </div>
      )}
    </div>
  )
}

export { SelectFees }
