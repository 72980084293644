import { useEffect } from 'react'

import { useModal } from '@/pages/modal-page/modal-page.tsx'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

const BubbleMap = () => {
  const tokenAddress = useAppSelector((state) => state.chain.selectedTokenAddress)
  const { setModalProps } = useModal()
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  useEffect(() => {
    setModalProps({ title: 'Bubblemaps' })
  }, [])

  return (
    <div className={styles.wrapper}>
      <iframe
        title="Bubblemaps"
        src={`https://app.insightx.network/bubblemaps/${currentChain.bubblemapsChainId}/${tokenAddress}?link=0&proxybutton=true`}
        width="100%" //(Whatever you use, this is just an example)
        height="100%" //(Whatever you use, this is just an example)
      ></iframe>
    </div>
  )
}

export { BubbleMap }
