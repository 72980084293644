import { Button, Checkbox, Icon, Input, Typography } from '@/libs/common'
import { IconName } from '@/libs/enums'

import styles from './styles.module.scss'

const Filter = () => {
  return (
    <div className={styles.root}>
      <div className={styles.checkboxes}>
        <Checkbox
          label="Top 10 Holders"
          labelProps={{ variant: 'h2', textColor: 'color-grey-2', fontWeight: 500 }}
        />
        <Checkbox
          label="With at least 1 social"
          labelProps={{ variant: 'h2', textColor: 'color-grey-2', fontWeight: 500 }}
        />
      </div>
      <div className={styles.divider} />
      <div className={styles.inputs}>
        <div className={styles.row}>
          <Typography variant="body1" textColor="color-grey-3" variantWeight="bold">
            B.Curve %
          </Typography>
          <div className={styles.fromTo}>
            <Input
              spacing={0}
              placeholder="Min"
              endAdornment={<div className={styles.percent}>%</div>}
            />
            <Typography textColor="color-grey-3" variantWeight="regular">
              to
            </Typography>
            <Input
              spacing={0}
              placeholder="Max"
              endAdornment={<div className={styles.percent}>%</div>}
            />
          </div>
        </div>
        <div className={styles.row}>
          <Typography variant="body1" textColor="color-grey-3" variantWeight="bold">
            Holders
          </Typography>
          <div className={styles.fromTo}>
            <Input spacing={0} placeholder="Min" />
            <Typography textColor="color-grey-3" variantWeight="regular">
              to
            </Typography>
            <Input spacing={0} placeholder="Max" />
          </div>
        </div>
        <div className={styles.row}>
          <Typography variant="body1" textColor="color-grey-3" variantWeight="bold">
            Liquidity
          </Typography>
          <div className={styles.fromTo}>
            <Input spacing={0} placeholder="Min" />
            <Typography textColor="color-grey-3" variantWeight="regular">
              to
            </Typography>
            <Input spacing={0} placeholder="Max" />
          </div>
        </div>
      </div>
      <div className={styles.buttons}>
        <Button
          className={styles.reset}
          iconStart={<Icon width={20} height={20} name={IconName.RESTART_} />}
        >
          Reset
        </Button>
        <Button>Apply</Button>
      </div>
    </div>
  )
}

export { Filter }
