import { FC, useState } from 'react'

import { Grid, InputLabel } from '@mui/material'
import { InputProps as MUIInputProps } from '@mui/material/Input'

import { ButtonGroupRadio, ButtonGroupRadioButton, Input, Typography } from '@/libs/common'
import { SwitchInline } from '@/libs/common/switch-inline'
import { TooltipIcon } from '@/libs/common/tooltip-icon'
import { TSelectOption } from '@/libs/types/select-option.type'

import styles from './styles.module.scss'

type TProps = MUIInputProps & {
  label: string
  value: number | string
  onOptionSelect: (value: number) => void
  tooltipInfo?: string
  withAuto?: boolean
  min?: number
}

const options: TSelectOption<number>[] = [
  {
    value: 25,
    label: '25%',
  },
  {
    value: 50,
    label: '50%',
  },
  {
    value: 75,
    label: '75%',
  },
  {
    value: 100,
    label: '100%',
  },
]

const PercentsInput: FC<TProps> = ({
  label,
  value,
  tooltipInfo,
  onOptionSelect,
  withAuto,
  ...rest
}) => {
  const [auto, setAuto] = useState(false)
  // TODO: Handle auto switch for parent
  const handleChangeAuto = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setAuto(checked)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'e' || event.key === 'E') {
      event.preventDefault()
    }
  }

  return (
    <Grid container>
      <Grid container flexDirection="row" flexWrap="nowrap" alignItems="center">
        <InputLabel className={styles.label}>{label}</InputLabel>
        {tooltipInfo && <TooltipIcon info={tooltipInfo} marginLeft={5} marginRight={5} />}
        {!auto && (
          <>
            <div className={styles.inputWrapper}>
              <Input
                className={styles.input}
                value={value}
                placeholder="X"
                autoComplete="off"
                type="number"
                onKeyDown={handleKeyDown}
                inputProps={{ min: 0, max: 100 }}
                {...rest}
              />
            </div>
            <Typography variant="body2">/ 100%</Typography>
          </>
        )}
        {withAuto && <SwitchInline switchLabel="Auto" onChange={handleChangeAuto} />}
      </Grid>

      {!auto && (
        <Grid container className={styles.radioGroup}>
          <ButtonGroupRadio
            exclusive
            onChange={(_, value) => onOptionSelect(value)}
            value={(value && +value) || value}
          >
            {options.map((option) => (
              <ButtonGroupRadioButton
                value={option.value}
                name="amount"
                className={styles.radioButton}
                key={option.value}
              >
                <Typography variant="caption" className={styles.buttonContent}>
                  {option.label}
                </Typography>
              </ButtonGroupRadioButton>
            ))}
          </ButtonGroupRadio>
        </Grid>
      )}
    </Grid>
  )
}

export { PercentsInput }
