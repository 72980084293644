import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import cn from 'classnames'

import {
  ButtonGroupRadio,
  ButtonGroupRadioButton,
  ButtonIcon,
  Input,
  Typography,
} from '@/libs/common'
import { TooltipIcon } from '@/libs/common/tooltip-icon'
import { formatNumber } from '@/libs/helper'
import { TUserWallet } from '@/libs/types/user.type'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

// type TWallet = {
//   name: string
//   public_key: string
//   balance?: string | number
//   balanceFormatted?: string | number
//   holdingData?: {
//     b: string
//     a: string
//     vc: string
//     vu: string
//     balanceFormatted?: number
//   }
// }

// const mockData: TWallet[] = [
//   {
//     name: 'W1',
//     balance: '12.580000 ETH',
//   },
//   {
//     name: 'W2',
//     balance: '12.580000 ETH',
//   },
//   {
//     name: 'W3',
//     balance: '12.580000 ETH',
//   },
//   {
//     name: 'W4',
//     balance: '12.580000 ETH',
//   },
//   {
//     name: 'W5',
//     balance: '12.580000 ETH',
//   },
//   {
//     name: 'W6',
//     balance: '12.580000 ETH',
//   },
// ]

type TProperty = {
  isMulti?: boolean
  tokenSymbol?: string
  wallets?: TUserWallet[] | null
  onChange: (value: string[]) => void
  wallet?: string[]
  label?: string
  tooltipInfo?: string
  isSelectDefaultWallet?: boolean
  showOnlyWallets?: boolean
  labelClassName?: string
  disabled?: boolean
}

const SelectWallet: FC<TProperty> = ({
  label,
  isMulti,
  tokenSymbol,
  wallet,
  wallets,
  tooltipInfo,
  isSelectDefaultWallet = true,
  onChange,
  showOnlyWallets,
  labelClassName,
  disabled,
}) => {
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  const { t } = useTranslation()

  const [inputValue, setInputValue] = useState('')
  const [currentValue, setCurrentValue] = useState<string[]>([])

  useEffect(() => {
    const newValue = currentValue.filter((el) => wallets?.find((wallet) => wallet.address === el))
    handleInputOnSelect(newValue)
    newValue.length && setCurrentValue(newValue)
  }, [wallets])

  useEffect(() => {
    if (
      wallet &&
      (wallet.find((address) => !currentValue.includes(address)) ||
        currentValue.find((address) => !wallet.includes(address)))
    )
      handleRadioWallet(null, wallet)
  }, [wallet])

  useEffect(() => {
    if (!isSelectDefaultWallet) {
      if (wallet) {
        handleInputOnSelect(wallet)
        setCurrentValue(wallet)
      }
      return
    }

    const defaultWallet = wallets?.find((wallet) => wallet.is_default)
    if (defaultWallet) {
      onChange([defaultWallet.address])
      handleInputOnSelect([defaultWallet.address])
      setCurrentValue([defaultWallet.address])
    }
  }, [])

  const handleInputOnSelect = (newValue: string | string[]) => {
    if (!newValue?.length) {
      setInputValue('')
      return
    }

    const positions: number[] = []
    ;(newValue as string[]).forEach((address) => {
      const index = wallets?.findIndex((item) => item.address === address)

      if (index !== undefined && index !== -1) {
        positions.push(index + 1)
      }
    })
    setInputValue(positions.sort((a, b) => a - b).join(','))
  }

  const handleRadioWallet = (_: React.BaseSyntheticEvent | null, newValue: string | string[]) => {
    if (disabled) return
    const newValues = Array.isArray(newValue) ? newValue : [newValue]
    onChange(newValues)
    setCurrentValue(newValues.filter(Boolean))
    handleInputOnSelect(newValues)
  }

  const handleSelectAll = () => {
    if (!wallets) return
    const newValues = wallets.map((item) => item.address)
    onChange(newValues)
    setCurrentValue(newValues.filter(Boolean))
    handleInputOnSelect(newValues)
  }

  const handleUnselectAll = () => {
    onChange([])
    setCurrentValue([])
    setInputValue('')
  }

  const parseIndexes = (indexesInput: string) => {
    if (!wallets?.length || !indexesInput) {
      setInputValue('')
      onChange([])
      setCurrentValue([])
      return []
    }

    let newSymbol = ''
    for (let i = 0; i <= indexesInput.length; i++) {
      if (indexesInput[i] !== inputValue[i]) {
        newSymbol = indexesInput[i]
        break
      }
    }
    if ([',', '-'].includes(newSymbol)) {
      setInputValue(indexesInput)
      return
    }

    const maxNumber = wallets.length
    const takenNumbers: Record<number, boolean> = {}
    let inputString = ''

    const pushNumber = (value: number) => {
      if (value <= maxNumber && !takenNumbers[value] && value !== 0) {
        takenNumbers[value] = true
      }
    }

    indexesInput.split(',').forEach((part) => {
      if (part.includes('-')) {
        const range = part.split('-')
        const start = +range[0]
        const end = +range[1]
        if (!start || !end || start > maxNumber || end > maxNumber) {
          return
        }
        for (let i = start; i <= end; i++) {
          pushNumber(i)
        }
        inputString += `,${start}-${end || ''}`
      } else {
        pushNumber(+part)
        inputString += `,${part}`
      }
    })

    setInputValue(inputString[0] === ',' ? inputString.slice(1, inputString.length) : inputString)

    const publicKeys: string[] = []
    Object.keys(takenNumbers).forEach((key) => {
      const publicKey = wallets[+key - 1]?.address
      if (publicKey) {
        publicKeys.push(publicKey)
      }
    })
    onChange(publicKeys)
    setCurrentValue(publicKeys)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.label}>
        <div className={styles.labelInfo}>
          <Typography variant="body2" className={cn(styles.text, labelClassName)}>
            {label ? (
              label
            ) : (
              <>{isMulti ? t('wallet.select_wallets') : t('wallet.select_wallet')}</>
            )}
          </Typography>

          {isMulti && !showOnlyWallets && (
            <>
              {tooltipInfo && <TooltipIcon info={tooltipInfo} />}
              <Typography variant="body2" className={styles.text}>
                : {currentValue?.length || 0}/{wallets?.length || 0}
              </Typography>
            </>
          )}
        </div>

        {!showOnlyWallets && <div className={styles.divider} />}
        {isMulti &&
          !showOnlyWallets &&
          wallets &&
          (wallet && wallet.length < wallets?.length ? (
            <ButtonIcon onClick={handleSelectAll} type="button">
              <Typography variant="body2" className={styles.button}>
                {t('select_all')}
              </Typography>
            </ButtonIcon>
          ) : (
            <ButtonIcon onClick={handleUnselectAll} type="button">
              <Typography variant="body2" className={styles.button}>
                {t('unselect_all')}
              </Typography>
            </ButtonIcon>
          ))}
      </div>

      {isMulti && !showOnlyWallets && (
        <Input
          placeholder={t('wallet.enter_wallet_sequence')}
          className={styles.input}
          value={inputValue}
          disabled={disabled}
          onChange={(e) => {
            const value = e.target.value.replace(/[^0-9,-]/g, '')
            parseIndexes(value)
          }}
        />
      )}

      <ButtonGroupRadio
        separated
        exclusive={!isMulti}
        className={styles.radioGroup}
        groupClassName={styles.radioGroupContent}
        onChange={handleRadioWallet}
        value={wallet}
      >
        {wallets?.map((el, index) => (
          <ButtonGroupRadioButton
            value={el.address}
            name="wallet"
            className={styles.customButton}
            key={index}
            disabled={disabled}
          >
            <Typography
              className={styles.walletName}
              variant="body2"
              resetLineHeight
              noWrap
              maxWidth="100%"
            >
              {el.name}
            </Typography>

            {tokenSymbol ? (
              <Typography variant="body2" className={styles.lightBold} resetLineHeight>
                {formatNumber(el.holdingData?.balanceFormatted || '0').formatted} {tokenSymbol}
              </Typography>
            ) : (
              <Typography variant="body2" className={styles.lightBold} resetLineHeight>
                {(+(el.balanceFormatted || 0)).toLocaleString('en-US', {
                  maximumFractionDigits: 5,
                })}{' '}
                {currentChain.chainSymbol}
              </Typography>
            )}
          </ButtonGroupRadioButton>
        ))}
      </ButtonGroupRadio>
    </div>
  )
}

export { SelectWallet }
