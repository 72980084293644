import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import cn from 'classnames'

import { Spinner } from '@/components/spinner'
import { useWindowDimensions } from '@/hooks/useWindowDimensions'
import { Typography } from '@/libs/common'
import { SpinnerSize } from '@/libs/enums'
import { formatNumber } from '@/libs/helper'
import { useAppSelector } from '@/store'

import { TokenDataItem } from './libs/components/token-data-item'
import styles from './styles.module.scss'

// @TODO delete mock data
// const mockedData = {
//   preLaunchActive: 11256,
//   profitSellPanelActive: Math.floor(Math.random() * 5000),
//   activeTemplatesPreLaunch: 11256,
//   totalWalletsTryingToSnipe: Math.floor(Math.random() * 1000),
//   freshWallets: Math.floor(Math.random() * 100),
//   alphaWallets: Math.floor(Math.random() * 50),
// }

type TProperty = {
  isDefaultToken: boolean
}

const TokenData: FC<TProperty> = ({ isDefaultToken }) => {
  const { t } = useTranslation()
  const windowDimension = useWindowDimensions()
  const useSlider = windowDimension.width <= 630
  const isResponsive = !useSlider && windowDimension.width <= 1500
  const isMobile = !useSlider && windowDimension.width <= 980
  const currentToken = useAppSelector((state) => state.chain.currentToken)
  const simulation = useAppSelector(
    (state) => state.chain.adaptedCurrentTokenSimulationWebsocket?.data,
  )

  return (
    <div className={styles.container}>
      <div
        className={cn(styles.mobileGradient, { [styles.visible]: windowDimension.width <= 630 })}
      />
      <div className={styles.tokenDataWrapper}>
        {!simulation || (isDefaultToken && !currentToken) ? (
          <div className={styles.spinnerWrapper}>
            <Spinner size={SpinnerSize.SMALL} centered />
          </div>
        ) : simulation ? (
          <>
            <TokenDataItem isUpgradePlan title="Dex:" value={simulation.liquidity.dex_name} />
            <TokenDataItem
              isUpgradePlan
              title={`${t('token_info.total_supply')}:`}
              value={
                <>
                  {simulation?.total_supply === '-' || !+simulation.total_supply
                    ? '-'
                    : `${formatNumber(simulation?.total_supply).raw} ${simulation.symbol}`}
                </>
              }
            />
            {/*<TokenDataItem*/}
            {/*  isUpgradePlan*/}
            {/*  title="Vol 24h:"*/}
            {/*  value={<>${formatNumber(+(simulation?.additional_data?.volume || '0')).formatted}</>}*/}
            {/*/>*/}
            <TokenDataItem
              isUpgradePlan
              title={t('token_info.contract_verified')}
              value={simulation?.tradeable ? 'Yes' : 'No'}
            />
            {/* {!isMobile && (
              <TokenDataItem isUpgradePlan title="Top 10 holders:" value={simulation?.t.ho} />
            )} */}
            {!isMobile && (
              <TokenDataItem
                isUpgradePlan
                title={`${t('token_info.holder_count')}:`}
                value={simulation?.holders_count}
              />
            )}
            {/* {!isMobile && (
              <TokenDataItem isUpgradePlan title="Owner Supply:" value={`${simulation?.oh}%`} />
            )} */}

            {!isResponsive && (
              <TokenDataItem
                isUpgradePlan
                title={t('token_info.burned_tokens')}
                value={`${simulation?.burned.p}%`}
              />
            )}

            {/* <TokenDataItem
      title="Pre-launch Active: "
      value={mockedData.preLaunchActive}
      isUpgradePlan={true}
    />

    <TokenDataItem
      title="Profit/Sell Panel Active: "
      value={mockedData.profitSellPanelActive}
      isUpgradePlan={true}
    />

    <TokenDataItem
      title="Active Templates Prelaunch: "
      value={mockedData.activeTemplatesPreLaunch}
      isUpgradePlan={true}
    />

    <TokenDataItem
      title="Total wallets trying to snipe this: "
      value={mockedData.totalWalletsTryingToSnipe}
    />

    <TokenDataItem title="Fresh Wallets: " value={mockedData.freshWallets} />

    <TokenDataItem title="Alpha Wallets: " value={mockedData.alphaWallets} /> */}
          </>
        ) : (
          <div className={styles.spinnerWrapper}>
            <Typography variant="body1" align="center">
              {t('token_info.no_simulation')}
            </Typography>
          </div>
        )}
      </div>
    </div>
  )
}

export { TokenData }
