import { FC, useEffect, useMemo, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { renameWallet } from '@/api/wallet'
import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { Button, Input } from '@/libs/common'
import { AppRoute } from '@/libs/enums'
import { extractErrorDescription } from '@/libs/helper/extractErrorDescription'
import { useModal } from '@/pages/modal-page/modal-page'
import { useAppDispatch, useAppSelector } from '@/store'
import { setUserWallets } from '@/store/slices/user.slice'

import styles from './styles.module.scss'

type WalletNameType = {
  walletName: string
}

const walletNameSchema = yup.object({
  walletName: yup
    .string()
    .min(5, 'wallet.enter_wallet_name_validation')
    .max(30)
    .required('wallet.enter_wallet_name'),
})

const defaultValues: WalletNameType = {
  walletName: 'Wallet1121133',
}

const EditWalletName: FC = () => {
  const { t } = useTranslation()
  const user = useAppSelector((state) => state.user.userData)
  const wallets = useAppSelector((state) => state.user.userWallets)

  const dispatch = useAppDispatch()

  const navigate = useCustomNavigate()
  const { walletAddress } = useParams()

  const [isLoading, setIsLoading] = useState(false)

  const { setModalProps } = useModal()

  useEffect(() => {
    setModalProps({ title: t('modal.edit_wallet_name.title'), withBackButton: true })
  }, [])

  const currentWallet = useMemo(
    () => wallets?.find((item) => item.address === walletAddress),
    [walletAddress, wallets],
  )

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { walletName: currentWallet?.name || '' },
    resolver: yupResolver(walletNameSchema),
  })

  const onSubmit: SubmitHandler<typeof defaultValues> = async (data) => {
    if (!user || !currentWallet) return
    try {
      setIsLoading(true)
      const { data: res } = await renameWallet({
        name: data.walletName.trim(),
        address: currentWallet.address,
      })
      dispatch(setUserWallets(res.data))
      navigate({
        isDashboard: true,
        path: `${AppRoute.MODAL}/${AppRoute.WALLET_MANAGEMENT}`,
      })
    } catch (err) {
      extractErrorDescription(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="walletName"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <Input
              error={Boolean(errors.walletName)}
              errorMessage={errors.walletName?.message && t(errors.walletName.message)}
              maxLength={30}
              isTransparent
              label={t('wallet.wallet_name')}
              placeholder={t('wallet.enter_wallet_name')}
              {...field}
            />
          )}
        />

        <Button type="submit" isLoading={isLoading} checkOnAccountLock>
          {t('save')}
        </Button>
      </form>
    </div>
  )
}

export { EditWalletName }
