import { t } from 'i18next'

import { EImportModalType } from '@/pages/modal-page/libs/components/import-wallets/libs/enum'

const getWalletsTitle = (query: string | null) => {
  switch (query) {
    case EImportModalType.PUBLIC_AND_PRIVATE_KEYS:
      return t('setup_wallets.import_wallets')
    case 'private-keys':
      return 'Import private key(s)'
    case 'wallet-file':
      return t('setup_wallets.import_wallet_file')
    default:
      return 'Unknown type'
  }
}

export { getWalletsTitle }
