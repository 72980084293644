import { FC, useEffect, useMemo, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import { Box } from '@mui/material'
import Grid from '@mui/material/Grid'
import cn from 'classnames'
import * as yup from 'yup'

// import { mockPositionsData } from '@/components/buy-sell-snipe-profit-sell/libs/components/profit-sell-tab/mockdata/mock-positions-data'
import { PositionCard } from '@/components/position-card/position-card'
import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { useSorting } from '@/hooks/useSorting'
import { Button, HiddenInputs, Icon, Input, InputWithRadioGroup, Typography } from '@/libs/common'
import { EndAdornment } from '@/libs/common/input/components/end-adornment'
import { OptionalInput } from '@/libs/common/optional-input'
import { PercentsInput } from '@/libs/common/percents-input'
import { SwitchInline } from '@/libs/common/switch-inline'
import { MAX_TRX_DECIMALS } from '@/libs/configs/transactions.config'
import { AppRoute, IconName, Sorting, TabName } from '@/libs/enums'
import { createSellPayload, handleSell } from '@/libs/helper'
import { convertScientificNotationNumber } from '@/libs/helper/convertScientificNotationNumber'
import { getChainSensitiveLabel } from '@/libs/helper/getChainSensitiveLabel'
import { validatePercentInput } from '@/libs/helper/validatePercentInput'
import { TSellForm } from '@/libs/types'
import {
  TActivePanelItem,
  TPanelPositionItemSocket,
} from '@/libs/types/panels-data-socket-response.type'
import { TSelectOption } from '@/libs/types/select-option.type'
import { stringOfNumbersValidation } from '@/libs/validations/common'
import { useAppDispatch, useAppSelector } from '@/store'
import { fetchPriorities } from '@/store/slices/user.slice'

import { PriorityCurrency } from '../priority-currency'
import { PositionCardMessage } from './libs/position-card-message'
import styles from './styles.module.scss'

// const limitOptions: TSelectOption<number>[] = [
//   {
//     value: -10,
//     label: '-10%',
//   },
//   {
//     value: -20,
//     label: '-20%',
//   },
//   {
//     value: -30,
//     label: '-30%',
//   },
//   {
//     value: -40,
//     label: '-40%',
//   },
// ]

type TProps = {
  usedInModal?: boolean
}

enum ELastUsedAmountInput {
  SELL = 'sell',
  RECEIVE = 'receive',
}

const spendOptions: TSelectOption<number>[] = [
  {
    value: 50,
    label: '50%',
  },
  {
    value: 75,
    label: '75%',
  },
  {
    value: 100,
    label: '100%',
  },
]

const SellTab: FC<TProps> = ({ usedInModal = false }) => {
  const userData = useAppSelector((state) => state.user.userData)
  const userWallets = useAppSelector((state) => state.user.userWallets)
  const currentToken = useAppSelector((state) => state.chain.currentToken)
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const selectedTokenAddress = useAppSelector((state) => state.chain.selectedTokenAddress)
  const simulation = useAppSelector(
    (state) => state.chain.adaptedCurrentTokenSimulationWebsocket?.data,
  )
  const sellPanels = useAppSelector((state) => state.profitSell.profitSell?.sp)
  const profitSellPositions = useAppSelector(
    (state) => state.profitSell.profitSell?.ap,
  ) as TActivePanelItem[]
  const priorities = useAppSelector((state) => state.user.defaultPriorities)
  const userConfirmationRequired = useAppSelector((state) => state.user.userData?.sell_confirmation)
  const navigate = useCustomNavigate()
  const { t } = useTranslation()

  const defaultValues: TSellForm = useMemo(
    () => ({
      privateTransaction: !currentChain.features?.noPrivateTx,
      slippage: currentChain.defaultValues.slippage,
      sellPriority: '',
      // dip: '',
      // marketcap: '',
      // price: '',
      // triggerPricePercent: '',
      // expiration: '',
      sell: '',
      receive: '',
      bribeAmount: '',
    }),
    [currentChain],
  )

  // const [footerTab, setFooterTab] = useState<string>('market')
  const [searchParams, setSearchParams] = useSearchParams()
  const [isDisabled, setIsDisable] = useState(true)
  const [positionsChecked, setPositionsChecked] = useState<Record<string, boolean>>({
    checkedAtLeastOne: false,
    checkedAll: false,
  })
  const [selectedWallets, setSelectedWallets] = useState<TPanelPositionItemSocket[]>([])
  const [tokensAvailable, setTokensAvailable] = useState(0)
  const [isLoadingAtLeastOne, setIsLoadingAtLeastOne] = useState(false)

  const [lastUsedAmountInput, setLastUsedAmountInput] = useState<ELastUsedAmountInput | null>(null)
  const [sellPercentage, setSellPercentage] = useState<number>(0)

  const { SortIcon, sorting } = useSorting()
  const dispatch = useAppDispatch()

  const availableWallets = useMemo(
    () =>
      sellPanels
        ? [...sellPanels]?.sort((a, b) => {
            // Need to create new ref due to readable array
            return sorting === Sorting.ASC ? +a.w.u - +b.w.u : +b.w.u - +a.w.u
          })
        : [],
    [sellPanels, sorting],
  )

  const sellCardId = searchParams.get('sellCardId')

  const schema = yup.object({
    privateTransaction: yup.boolean().default(!currentChain.features?.noPrivateTx),
    slippage: stringOfNumbersValidation.required().test((value) => +value <= 100),
    sellPriority: stringOfNumbersValidation,
    bribeAmount: stringOfNumbersValidation,
    sell:
      import.meta.env.VITE_IS_TEST_INSTANCE === 'true'
        ? stringOfNumbersValidation.required()
        : stringOfNumbersValidation.required().test((value) => +value <= tokensAvailable),
    receive: stringOfNumbersValidation.required(),
  })

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm({
    defaultValues: {
      ...defaultValues,
      sellPriority: priorities?.sell_priority || '',
      bribeAmount: priorities?.bribe_amount || '',
    },
    resolver: yupResolver(schema) as any,
  })

  const fieldsRequiredForSellActions = watch(['slippage'])

  useEffect(() => {
    if (!userData) return

    const setPriorities = async () => {
      const data = await dispatch(fetchPriorities(null)).unwrap()
      setValue('sellPriority', data.sell_priority)
      setValue('bribeAmount', data.bribe_amount)
    }
    setPriorities()

    reset({
      ...getValues(),
      slippage: currentChain.defaultValues.slippage,
      privateTransaction: !currentChain.features?.noPrivateTx,
    })
  }, [currentChain.id])

  useEffect(() => {
    clearChecked()
  }, [selectedTokenAddress])

  useEffect(() => {
    if (searchParams.has(TabName.BUY_SELL)) {
      searchParams.delete(TabName.BUY_SELL)
      setSearchParams(searchParams)
    }
  }, [searchParams])

  useEffect(() => {
    let newDisabled = tokensAvailable <= 0
    if (!newDisabled) {
      for (const field of fieldsRequiredForSellActions) {
        if ((Array.isArray(field) && !field.length) || !field) {
          newDisabled = true
          break
        }
      }
    }
    setIsDisable(newDisabled)
  }, [fieldsRequiredForSellActions, tokensAvailable])

  useEffect(() => {
    let newTokensAvailable = 0
    const updatedWallets = selectedWallets.reduce((wallets, currentWallet) => {
      const availableWallet = availableWallets?.find(
        (available) => available.wa === currentWallet.wa,
      )
      if (availableWallet) {
        wallets.push(availableWallet)
        newTokensAvailable += +(availableWallet.ba || 0)
      }
      return wallets
    }, [] as TPanelPositionItemSocket[])

    setSelectedWallets(updatedWallets)
    setTokensAvailable(newTokensAvailable)
  }, [availableWallets])

  // const handleFooterTabChange = (_: React.BaseSyntheticEvent, newValue: string) => {
  //   setFooterTab(newValue)
  // }

  const handlePositionChecking = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const updatedState = {
      ...positionsChecked,
      [event.target.name]: checked,
    }

    let newTokensAvailable = 0

    const newSelectedWallets = Object.keys(updatedState)
      .map((key) => {
        if (['checkedAtLeastOne', 'checkedAll'].includes(key) || !updatedState[key]) return null
        const selectedWallet = availableWallets?.find((wallet) => wallet.wa === key) || null
        if (!selectedWallet) return null
        newTokensAvailable += +(selectedWallet.ba || 0)
        return selectedWallet
      })
      .filter(Boolean) as TPanelPositionItemSocket[]

    setSelectedWallets(newSelectedWallets)
    setTokensAvailable(newTokensAvailable)

    const { checkedAtLeastOne, checkedAll, ...onlyPositions } = updatedState
    const positionsValues = Object.values(onlyPositions)
    const chackedPositions = positionsValues.filter((checked) => checked)
    const atLeastOneChecked = !!chackedPositions.length
    const checkedAllPositions = positionsValues.length === chackedPositions.length

    setPositionsChecked({
      ...updatedState,
      checkedAtLeastOne: atLeastOneChecked,
      checkedAll: checkedAllPositions,
    })
  }

  const clearChecked = () => {
    reset()
    setPositionsChecked({
      checkedAtLeastOne: false,
      checkedAll: false,
    })
  }

  const setAllChecked = () => {
    if (!availableWallets) return

    let newTokensAvailable = 0

    const allPositionsChecked = availableWallets.reduce((sum, item) => {
      return { ...sum, [item.wa]: !positionsChecked.checkedAll }
    }, {})

    if (!positionsChecked.checkedAll) {
      newTokensAvailable = availableWallets.reduce((sum, next) => sum + +(next.ba || 0), 0)
    }

    setSelectedWallets(!positionsChecked.checkedAll ? availableWallets : [])
    setTokensAvailable(newTokensAvailable)

    setPositionsChecked({
      ...positionsChecked,
      ...allPositionsChecked,
      checkedAtLeastOne: !positionsChecked.checkedAll,
      checkedAll: !positionsChecked.checkedAll,
    })
  }

  const handleMarketSellAll = async (wallet: TPanelPositionItemSocket) => {
    if (!userData || !currentToken || !simulation || !wallet) return null

    setIsLoadingAtLeastOne(true)

    const wallets = [
      {
        name: wallet.wn,
        public_key: wallet.wa,
      },
    ]
    const payload = await createSellPayload({
      wallets,
      sellPercentage: 100,
      dex: wallet.sd,
      data: {
        receive: '0',
        sell: '0',
        sellPriority: priorities?.sell_priority,
        bribeAmount: priorities?.bribe_amount,
        slippage: currentChain.defaultValues.slippage,
        privateTransaction: !currentChain.features?.noPrivateTx,
      },
    })

    if (payload) {
      if (userConfirmationRequired) {
        navigate({
          isDashboard: true,
          path: `${AppRoute.MODAL}/${AppRoute.APPROVE_SELL}`,
          state: {
            payload,
            data: {
              receive: wallet.w.t,
              sell: wallet.ba,
            },
          },
        })
      } else {
        await handleSell(payload)
      }
    }

    setIsLoadingAtLeastOne(false)
    return null
  }

  const onSubmit: SubmitHandler<typeof defaultValues> = async (data) => {
    if (!userData || !currentToken || !userWallets) return

    selectedWallets.length && setIsLoadingAtLeastOne(true)

    const wallets = selectedWallets.map((item) => {
      return {
        name: item.wn,
        public_key: item.wa,
      }
    })

    const payload = await createSellPayload({
      wallets,
      sellPercentage,
      data,
      dex: selectedWallets[0].sd,
    })

    if (payload) {
      if (userConfirmationRequired) {
        navigate({
          isDashboard: true,
          path: `${AppRoute.MODAL}/${AppRoute.APPROVE_SELL}`,
          state: { payload, data },
        })
      } else {
        await handleSell(payload)
      }
    }

    if (sellPercentage) setSellPercentage(0)
    selectedWallets.length && setIsLoadingAtLeastOne(false)
  }

  const totalPriceImpact = useMemo(() => {
    const walletAddresses = Object.keys(positionsChecked)
    return (
      availableWallets?.reduce(
        (acc, item) => (walletAddresses.includes(item.wa) ? acc + +item.pi || 0 : 0),
        0,
      ) || 0
    )
  }, [selectedWallets, positionsChecked])

  const calculateReceive = (value: string) => {
    if (!simulation || !currentChain.nativeTokenPriceInUsd) return
    setValue('sell', value, {
      shouldValidate: !!value,
    })

    if (!value) {
      setValue('sell', '')
      clearErrors(['sell', 'receive'])
      return
    }

    const coeff = +simulation.price.usd / currentChain.nativeTokenPriceInUsd
    const receiveValue = +value * coeff

    setValue(
      'receive',
      +value
        ? convertScientificNotationNumber(
            totalPriceImpact
              ? receiveValue - (totalPriceImpact / 100) * receiveValue
              : receiveValue,
            MAX_TRX_DECIMALS,
          )
        : '',
      { shouldValidate: true },
    )
  }

  const calculateSell = (value: string) => {
    if (!simulation || !currentChain.nativeTokenPriceInUsd) return
    setValue('receive', value, {
      shouldValidate: !!value,
    })

    if (!value) {
      setValue('receive', '')
      clearErrors(['sell', 'receive'])
      return
    }

    const coeff = currentChain.nativeTokenPriceInUsd / +simulation.price.usd
    setValue(
      'sell',
      +value ? convertScientificNotationNumber(+value * coeff, MAX_TRX_DECIMALS) : '',
      {
        shouldValidate: true,
      },
    )
  }
  // TODO: Move this logic with 2 inputs to a separate component
  useEffect(() => {
    if (!currentChain.nativeTokenPriceInUsd || !lastUsedAmountInput) return

    if (lastUsedAmountInput === ELastUsedAmountInput.SELL) {
      const sellValue = getValues().sell
      if (sellValue) {
        calculateReceive(sellValue)
      }
    } else if (lastUsedAmountInput === ELastUsedAmountInput.RECEIVE) {
      const receiveValue = getValues().receive
      if (receiveValue) {
        calculateSell(receiveValue)
      }
    }
  }, [currentChain])

  const { checkedAtLeastOne, checkedAll, ...walletsWithToken } = positionsChecked
  const countOfSelectedWallets = Object.values(walletsWithToken).filter(Boolean).length

  return (
    <div>
      <Grid className={cn(styles.content, { [styles.usedInModal]: usedInModal })}>
        {!availableWallets?.length && (
          <Typography variant="body1" textColor="grey" align="center">
            {t('wallet.no_wallets_available')}
          </Typography>
        )}
        {!!availableWallets?.length && (
          <Grid container wrap="nowrap" alignItems="center" justifyContent="space-between">
            <Grid container alignItems="center" gap={1} className={styles.rowContainerItem}>
              <Typography variant="body2" component="span" textColor="light-grey">
                {t('wallet.select_wallets')}
              </Typography>

              <Typography variant="body2" component="span" textColor="light-grey">
                <Box display="flex" gap="12px" color="#d1d1d1">
                  {`${countOfSelectedWallets}/${availableWallets?.length}`}
                </Box>
              </Typography>

              <SortIcon />
            </Grid>
            <Grid
              container
              wrap="nowrap"
              justifyContent="space-between"
              alignItems="center"
              gap={2}
              className={styles.buttonGroup}
            >
              <Button
                styleVariant="borderless-gold"
                transparent
                className={styles.button}
                onClick={setAllChecked}
              >
                {positionsChecked.checkedAll ? t('unselect_all') : t('select_all')}
              </Button>
            </Grid>
          </Grid>
        )}
        <Grid
          container
          direction="column"
          wrap="nowrap"
          gap={1.5}
          className={styles.cardsContainer}
        >
          {availableWallets?.map((wallet, index) => {
            const tokenName = profitSellPositions?.find((token) =>
              token.po.find((position) => position.wa == wallet.wa && position.ts === wallet.ts),
            )?.tn
            return (
              <PositionCard
                className={styles.card}
                variant="sell"
                key={index}
                message={<PositionCardMessage />}
                onMarketSellAll={() => handleMarketSellAll(wallet)}
                positionData={{
                  id: wallet.wa,
                  isActive: true,
                  coin: wallet.ts || '',
                  coinCompairedWith: currentChain.chainSymbol,
                  totalBalance: +wallet.ba || 0,
                  totalWallets: userWallets?.length || 0,
                  cost: { tokens: wallet.c.t, usd: wallet.c.u },
                  sold: { tokens: wallet.so.t, usd: wallet.so.u },
                  worth: { tokens: wallet.w.t, usd: wallet.w.u },
                  afterTax: { tokens: wallet.at.t || 0, usd: wallet.at.u || 0 },
                  priceImpact: wallet.pi,
                  profitOrLoss: +wallet.pl,
                  supply: wallet.ps,
                  tokenUsdPrice: +(simulation?.price.usd || 0),
                }}
                position={{
                  ...wallet,
                  id: wallet.wa,
                  tn: tokenName || '',
                  tos: wallet.ts,
                  tw: wallet.w,
                  tc: wallet.c,
                  tpl: wallet.pl,
                }}
                isSold={Boolean(sellCardId && sellCardId === wallet.wa)}
                checkboxProps={{
                  name: wallet.wa,
                  checked: positionsChecked[wallet.wa] ?? false,
                  onChange: handlePositionChecking,
                }}
              />
            )
          })}
        </Grid>
      </Grid>
      <HiddenInputs expanded={positionsChecked.checkedAtLeastOne && !!availableWallets?.length}>
        <Grid container rowGap={3} marginTop={3}>
          <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <Grid className={styles.formContainer}>
              {!currentChain.features?.noPrivateTx && (
                <Controller
                  name="privateTransaction"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <SwitchInline
                      label={t('template.anti_mev')}
                      tooltipInfo={t('template.anti_mev_tooltip')}
                      {...field}
                    />
                  )}
                />
              )}

              <Controller
                name="slippage"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <PercentsInput
                    label={t('template.slippage')}
                    onOptionSelect={(value) => field.onChange(value)}
                    tooltipInfo={t('template.slippage_tooltip')}
                    placeholder="X"
                    error={!!errors.slippage?.message}
                    {...field}
                  />
                )}
              />

              {!!currentChain.features?.sellPriority && (
                <Controller
                  name="sellPriority"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <OptionalInput
                      label={getChainSensitiveLabel('sellPriority')}
                      placeholder={t('template.enter_gwei_amount', {
                        currency: currentChain.priorityCurrency.toLowerCase(),
                      })}
                      isNumeric
                      tooltipInfo={`${t('template.enter_gwei_amount_tooltip')} ${currentChain.chainSymbol}.`}
                      endAdornment={<PriorityCurrency />}
                      error={!!errors.sellPriority?.message}
                      {...field}
                    />
                  )}
                />
              )}

              {!!currentChain.features?.bribeAmount && (
                <Controller
                  name="bribeAmount"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <OptionalInput
                      label={getChainSensitiveLabel('bribeAmount')}
                      tooltipInfo={t('token_info.bribe_amount_tooltip')}
                      placeholder={t('token_info.bribe_amount_placeholder')}
                      isNumeric
                      endAdornment={<PriorityCurrency />}
                      error={!!errors.bribeAmount?.message}
                      {...field}
                    />
                  )}
                />
              )}

              {/* <Grid container rowGap={2} flexDirection="column">
                <ButtonGroupRadio
                  onChange={handleFooterTabChange}
                  value={footerTab}
                  exclusive
                  className={cn(styles.group, styles.bgGrey)}
                >
                  <ButtonGroupRadioButton value="market">Market</ButtonGroupRadioButton>
                  <ButtonGroupRadioButton value="limit">Limit</ButtonGroupRadioButton>
                </ButtonGroupRadio>
                {footerTab === 'limit' && (
                  <Grid container rowGap={2} flexDirection="column" className={styles.limitBlock}>
                    <div>
                      <Typography variant="body2" textColor="light-grey">
                        Trigger price
                      </Typography>
                      <div className={styles.inputWrapper}>
                        <Controller
                          name="dip"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <Input
                              className={cn(styles.input, styles.first)}
                              placeholder="Enter dip %"
                              endAdornment={<EndAdornment label="%" />}
                              {...field}
                            />
                          )}
                        />

                        <Controller
                          name="marketcap"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <Input
                              className={styles.input}
                              placeholder="Enter marketcap"
                              endAdornment={<EndAdornment label="MC" />}
                              {...field}
                            />
                          )}
                        />

                        <Controller
                          name="price"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <Input
                              className={cn(styles.input, styles.last)}
                              placeholder="Enter price"
                              endAdornment={<EndAdornment label="$" />}
                              {...field}
                            />
                          )}
                        />
                      </div>

                      <Controller
                        name="triggerPricePercent"
                        control={control}
                        render={({ field: { ref, onChange, ...field } }) => (
                          <ButtonGroupRadio
                            className={styles.radioGroup}
                            onChange={(_, value: string) => onChange(value)}
                            exclusive
                            {...field}
                          >
                            {limitOptions.map((option) => (
                              <ButtonGroupRadioButton
                                value={option.value}
                                className={styles.radioButton}
                                key={option.value}
                              >
                                {option.label}
                              </ButtonGroupRadioButton>
                            ))}
                          </ButtonGroupRadio>
                        )}
                      />
                    </div>

                    <Controller
                      name="expiration"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <OptionalInput
                        tooltipInfo='Enter the number of hours the limit order is valid. After this time expires, it will be removed.'
                          label="Expiration"
                          className={styles.input}
                          placeholder="Enter the position expiration time in hours"
                          adornmentText="Hours"
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                )}
              </Grid> */}
            </Grid>

            <Grid className={styles.footer}>
              <div className={styles.arrow}>
                <Icon name={IconName.ARROW_DOWN_GREY} />
              </div>

              <div className={styles.container}>
                <div className={styles.spend}>
                  <Controller
                    name="sell"
                    control={control}
                    render={({ field: { ref, onChange, ...field } }) => (
                      <InputWithRadioGroup
                        label={t('sell')}
                        placeholder={t('amount_values.amount_to_sell')}
                        isNumeric
                        className={styles.input}
                        onOptionSelect={(value) => {
                          const newValue = convertScientificNotationNumber(
                            tokensAvailable * (+value / 100),
                            MAX_TRX_DECIMALS,
                          )
                          setLastUsedAmountInput(ELastUsedAmountInput.SELL)
                          calculateReceive(newValue)
                          setSellPercentage(+value)
                        }}
                        endAdornment={
                          <EndAdornment
                            label={currentToken?.token.symbol}
                            imageUrl={currentToken?.token.images.small}
                            isDisabled={isDisabled}
                          />
                        }
                        radioGroupName="amount"
                        disabled={isDisabled}
                        options={spendOptions}
                        onChange={(e) => {
                          if (!currentToken || !currentChain.nativeTokenPriceInUsd) return
                          if (sellPercentage) setSellPercentage(0)
                          const newValue = convertScientificNotationNumber(
                            e.target.value,
                            MAX_TRX_DECIMALS,
                            true,
                          )
                          if (e.target.value == '') {
                            calculateReceive(e.target.value)
                            return
                          }
                          setLastUsedAmountInput(ELastUsedAmountInput.SELL)
                          calculateReceive(newValue)
                        }}
                        error={!!errors.sell?.message}
                        withCustomInput
                        validateCustomInput={validatePercentInput}
                        {...field}
                      />
                    )}
                  />

                  <div className={styles.available}>
                    <Typography variant="body2" className={styles.param}>
                      {t('available')}:
                    </Typography>
                    <Typography variant="body2" className={styles.value}>
                      {tokensAvailable} {currentToken?.token.symbol}
                    </Typography>
                  </div>
                </div>
                <div className={styles.union}>
                  <Controller
                    name="receive"
                    control={control}
                    render={({ field: { ref, onChange, ...field } }) => (
                      <Input
                        label={t('receive')}
                        placeholder={t('amount_values.amount_to_receive')}
                        isNumeric
                        className={styles.input}
                        endAdornment={
                          <EndAdornment
                            label={currentChain.chainSymbol}
                            icon={(IconName as any)[currentChain.iconName]}
                            isDisabled={isDisabled}
                          />
                        }
                        disabled={isDisabled}
                        onChange={(e) => {
                          if (!currentToken || !currentChain.nativeTokenPriceInUsd) return
                          if (sellPercentage) setSellPercentage(0)
                          if (e.target.value == '') {
                            calculateSell(e.target.value)
                            return
                          }
                          const newValue = convertScientificNotationNumber(
                            e.target.value,
                            MAX_TRX_DECIMALS,
                            true,
                          )
                          setLastUsedAmountInput(ELastUsedAmountInput.RECEIVE)
                          calculateSell(newValue)
                        }}
                        error={!!errors.receive?.message}
                        {...field}
                      />
                    )}
                  />
                  {!!simulation?.liquidity.dex_name && (
                    <div className={cn(styles.infoContainer, { [styles.disabled]: isDisabled })}>
                      <div className={styles.info}>
                        <Typography variant="body2" className={styles.param}>
                          DEX:
                        </Typography>
                        <Typography variant="body2" className={styles.value}>
                          {simulation.liquidity.dex_name}
                        </Typography>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <Button
                type="submit"
                disabled={isDisabled}
                isLoading={isLoadingAtLeastOne}
                checkOnAccountLock
              >
                {t('sell')}
              </Button>
            </Grid>
          </form>
        </Grid>
      </HiddenInputs>
    </div>
  )
}

export { SellTab }
