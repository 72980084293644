const isInTelegramBrowser = () => {
  return (
    // @ts-expect-error Telegram browser properties are only defined in Telegram in-app browser
    typeof window.TelegramWebviewProxy !== 'undefined' ||
    // @ts-expect-error iOS specific property
    typeof window.TelegramWebviewProxyProto !== 'undefined' ||
    // @ts-expect-error Android specific property
    typeof window.TelegramWebview !== 'undefined'
  )
}

export { isInTelegramBrowser }
