import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type TInitialState = {
  authData: {
    messageToSign: string
    signedMessage: string
  } | null
}

// We need to sync state with local storage to keep auth data in mobile auth flow
const loadFromLocalStorage = (): TInitialState => {
  try {
    const serializedState = localStorage.getItem('authData')
    if (serializedState === null) {
      return { authData: null }
    }
    return { authData: JSON.parse(serializedState) }
  } catch (error) {
    return { authData: null }
  }
}

const saveToLocalStorage = (state: TInitialState) => {
  try {
    const serializedState = JSON.stringify(state.authData)
    localStorage.setItem('authData', serializedState)
  } catch (error) {
    console.error('Error saving auth data to localStorage', error)
  }
}

const initialState = loadFromLocalStorage()

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (
      state,
      { payload }: PayloadAction<{ messageToSign: string; signedMessage: string } | null>,
    ) => {
      if (payload) {
        state.authData = {
          ...state.authData,
          ...payload,
        }
      } else {
        state.authData = payload
      }

      saveToLocalStorage(state)
    },
  },
})

const { setAuth } = authSlice.actions

export { authSlice, setAuth }
