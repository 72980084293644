import { FC, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

type TProps = PropsWithChildren & {
  interpolation?: Record<string, string | number>
}

const WithTranslation: FC<TProps> = ({ interpolation, children }) => {
  const { t } = useTranslation()

  if (typeof children !== 'string') {
    return <></>
  }

  return <>{t(children, interpolation)}</>
}

export { WithTranslation }
