import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box } from '@mui/material'
import Grid from '@mui/material/Grid'
import cn from 'classnames'

import { deleteProfitPanel } from '@/api/profit-loss'
import { VerticalGroup } from '@/components/buy-sell-snipe-profit-sell/index'
import { Currency } from '@/components/currency'
import { CustomToast } from '@/components/custom-toast'
import styles from '@/components/profit-sell-tab/libs/components/profit-sell-tab-card/styles.module.scss'
import { SuspiciousTokenWarningLabel } from '@/components/suspicious-token-warning-label'
import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { useSelectedPanel } from '@/hooks/useSelectedPanel'
import {
  Button,
  ButtonIcon,
  Popover,
  TOnChangeVisible,
  TokenPlaceholder,
  TokenRedirect,
  Typography,
} from '@/libs/common'
import stylesPopoverDelete from '@/libs/common/popover/styles-popover-delete.module.scss'
import { AppRoute, IconName } from '@/libs/enums'
import { createSellPayload, getPriceImpactColor, handleSell } from '@/libs/helper'
import { formatTokenPrice } from '@/libs/helper/formatTokenPrice'
import { handleError } from '@/libs/helper/handleError'
import { TActivePanelItem } from '@/libs/types/panels-data-socket-response.type'
import { useAppDispatch, useAppSelector } from '@/store'
import { setCurrentPositionId } from '@/store/slices/profit-sell.slice'

type TProps = {
  position: TActivePanelItem
  className?: string
}

const defaultLoadingState = {
  initial: false,
  all: false,
}

const ProfitSellTabCard: FC<TProps> = ({ className, position }) => {
  const defaultPriorities = useAppSelector((state) => state.user.defaultPriorities)
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const [loadingState, setLoadingState] = useState(defaultLoadingState)
  const currentPosition = useSelectedPanel()
  const userConfirmationRequired = useAppSelector((state) => state.user.userData?.sell_confirmation)
  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const navigate = useCustomNavigate()

  // const [isActiveLoading, setIsActiveLoading] = useState(false)
  // const [isActive, setIsActive] = useState(position.panel.isActive)

  // const handleActive = async (_: BaseSyntheticEvent, newValue: boolean) => {
  //   try {
  //     setIsActiveLoading(true)
  //     setIsActive(newValue)
  //     await setProfitPanelActivity(position.panel._id, newValue)
  //   } catch (err) {
  //     CustomToast('error', (err as any).message)
  //     setIsActive(!newValue)
  //   } finally {
  //     setIsActiveLoading(false)
  //   }
  // }

  const handleDelete = async () => {
    try {
      await deleteProfitPanel(position)
      CustomToast('success', t('token_info.position_deleted'))
    } catch (err) {
      handleError(err)
    }
  }

  const handleOpenShareProfitModal = () => {
    navigate({
      isDashboard: true,
      path: `${AppRoute.MODAL}/${AppRoute.SHARE_PROFIT}`,
      state: { positionsSummary: position },
    })
  }

  // const handleOpenEditPosition = () => {
  //   navigate(`${AppRoute.MODAL}/${AppRoute.EDIT_POSITION}/${position.panel.token_address}`)
  // }

  const switchToViewPage = () => {
    if (currentPosition?.ta !== position.ta) {
      dispatch(setCurrentPositionId(position.id))
    }
  }

  const [deletePopup, setDeletePopup] = useState<TOnChangeVisible>({
    visible: false,
    anchorE: undefined,
  })

  const onSell = async (type: 'all' | 'initial') => {
    if (!position.po.length) return

    setLoadingState({ ...defaultLoadingState, [type]: true })
    const wallets = position.po.map((item) => ({
      name: item.wn,
      public_key: item.wa,
    }))

    const payload = await createSellPayload({
      data: {
        receive: '0',
        sell: '0',
        sellPriority: defaultPriorities?.sell_priority || '',
        bribeAmount: defaultPriorities?.bribe_amount,
        slippage: currentChain.defaultValues.slippage,
        privateTransaction: !currentChain.features?.noPrivateTx,
      },
      token: position.ta,
      wallets,
      mode: type === 'all' ? 0 : 2,
      sellPercentage: type === 'all' ? 100 : 0,
      dex: position.sd,
    })

    if (payload) {
      if (userConfirmationRequired) {
        navigate({
          path: `${AppRoute.MODAL}/${AppRoute.APPROVE_SELL}`,
          isDashboard: true,
          state: {
            payload,
            data: {
              receive: position.tw.t || 0,
              sell: position.tb || 0,
              tokenName: position.tos,
            },
          },
        })
      } else {
        await handleSell(payload)
      }
    }

    setLoadingState({ ...defaultLoadingState, [type]: false })
  }

  const isHoneypotWithLiquidity = position.h && (position.l ?? 0) > 0

  return (
    <Grid
      className={cn(
        styles.card,
        //  { [styles.inactiveCard]: !isActive }
        className,
      )}
    >
      <div className={styles.headInfo}>
        {/* header row */}
        <Grid
          container
          wrap="nowrap"
          alignItems="center"
          justifyContent="space-between"
          className={styles.rowContainer}
        >
          <TokenRedirect className={styles.rowContainerItem} address={position.ta}>
            <Grid container className={styles.coinIcon}>
              {position.i && position.i.s ? (
                <img
                  className={styles.tokenLogo}
                  src={position.i.s}
                  alt={position.i.s + ' token'}
                />
              ) : (
                <TokenPlaceholder />
              )}
            </Grid>
            {position.tn && (
              <Typography variant="body2" className={styles.tokenName}>
                {position.tn}
              </Typography>
            )}
            {position.tps && (
              <Typography variant="body2" marginBottom={-0.3}>
                {formatTokenPrice(`${position.tps}`).formatted}%
              </Typography>
            )}
          </TokenRedirect>
          {/* <Grid container className={styles.rowContainerItem}>
          <Typography variant="body2">P/S Panel active</Typography>
          <Switch checked={isActive} onChange={handleActive} disabled={isActiveLoading} />
        </Grid> */}
          <Grid container className={styles.headerButtonsGroup}>
            {
              <Button
                className={styles.shareBtn}
                styleVariant="borderless-gold"
                iconStartName={IconName.SHARE}
                transparent
                onClick={handleOpenShareProfitModal}
              >
                {t('share')}
              </Button>
              /*  <Button
                className={styles.headerButton}
                styleVariant="borderless-gold"
                iconStartName={IconName.EDIT}
                transparent
                onClick={handleOpenEditPosition}
                disabled
              >
                Edit position
              </Button> */
            }
            <Button
              tooltipTitle={t('delete')}
              className={cn(stylesPopoverDelete.deleteButton, styles.deleteButton)}
              styleVariant="borderless-red"
              iconStartName={IconName.DELETE}
              transparent
              onClick={(e) => setDeletePopup({ visible: true, anchorE: e.currentTarget })}
            />

            <Popover
              className={cn(stylesPopoverDelete.popover, styles.popover)}
              popoverState={deletePopup}
              onChangeVisible={setDeletePopup}
            >
              <Typography variant="body2" className={stylesPopoverDelete.title}>
                {t('confirm_delete')}
              </Typography>
              <Typography variant="body2">{t('sell_tab.delete_this_position')}</Typography>
              <div className={stylesPopoverDelete.popoverButtons}>
                <ButtonIcon
                  onClick={() => {
                    setDeletePopup({ visible: false, anchorE: deletePopup.anchorE })
                  }}
                >
                  <Typography variant="body2" className={stylesPopoverDelete.cancel}>
                    {t('cancel')}
                  </Typography>
                </ButtonIcon>
                <Button
                  className={stylesPopoverDelete.confirm}
                  styleVariant="red"
                  onClick={() => {
                    handleDelete()
                    setDeletePopup({ visible: false, anchorE: deletePopup.anchorE })
                  }}
                >
                  {t('delete')}
                </Button>
              </div>
            </Popover>
          </Grid>
        </Grid>

        <SuspiciousTokenWarningLabel tokenPosition={position} marginTop={-0.8} marginBottom={1} />

        {/* middle rows */}
        <Grid
          container
          wrap="nowrap"
          alignItems="center"
          justifyContent="space-between"
          direction="column"
          className={styles.rowContainer}
          gap={1}
        >
          <Grid
            container
            wrap="nowrap"
            alignItems="center"
            justifyContent="space-between"
            className={styles.rowContainer}
          >
            <Box display="flex" className={styles.middleRow}>
              <VerticalGroup
                //TO DO get wallets count with positive balance from back
                // title={`Total balance (${position.panel_data.userWallets.length} wallets)`}
                title={t('sell_tab.total_balance')}
                description={<>{formatTokenPrice(position.tb).formatted}</>}
              />
              <VerticalGroup
                title={t('profit_and_loss')}
                description={
                  <span
                    className={cn(
                      +position.tpl > 0 && !isHoneypotWithLiquidity
                        ? styles.isProfit
                        : styles.isLoss,
                    )}
                  >
                    {'%'}
                    {Math.abs(!isHoneypotWithLiquidity ? +position.tpl : 100).toFixed(2)}
                  </span>
                }
              />
            </Box>
            <Grid container className={styles.middleButtonsGroup}>
              <Button
                styleVariant="black"
                className={cn(styles.middleRowButton, styles.buttonSellAll)}
                transparent
                onClick={() => onSell('all')}
                isLoading={loadingState.all}
              >
                {t('sell_tab.sell_all')}
              </Button>

              <Button
                styleVariant="black"
                className={cn(styles.middleRowButton, styles.buttonSellInitial)}
                transparent
                onClick={() => onSell('initial')}
                isLoading={loadingState.initial}
              >
                {t('sell_tab.sell_initial')}
              </Button>

              <Button
                styleVariant="black"
                iconEndName={IconName.ARROW_RIGHT2}
                className={styles.middleRowButton}
                onClick={switchToViewPage}
                transparent
              >
                {t('view')}
              </Button>
            </Grid>
          </Grid>
          {/* <Grid container>
          <Button
            className={styles.transferButton}
            styleVariant="borderless-gold"
            iconStartName={IconName.WITHDRAW}
            transparent
            disabled
          >
            Transfer to backup wallets
          </Button>
        </Grid> */}
        </Grid>
      </div>

      <Grid
        container
        wrap="nowrap"
        alignItems="center"
        justifyContent="space-between"
        className={styles.rowContainer}
      >
        <VerticalGroup
          title={t('token_info.cost')}
          description={
            <>
              {formatTokenPrice(`${position.tc.t}`, 2).formatted} <Currency />
              {/* {currentChain.description} */}
            </>
          }
        />
        <VerticalGroup
          title={t('token_info.worth')}
          descClassName={position.tw.u > position.tc.u ? 'greenText' : ''}
          description={
            <>
              {!isHoneypotWithLiquidity ? formatTokenPrice(`${position.tw.t}`, 2).formatted : 0}{' '}
              <Currency />
              {/* {currentChain.description} */}
            </>
          }
        />
        <VerticalGroup
          title={t('token_info.after_tax')}
          descClassName={+position.tat.u > +position.tb * +position.p ? 'greenText' : ''}
          description={
            <>
              {!isHoneypotWithLiquidity ? formatTokenPrice(`${position.tat.t}`, 2).formatted : 0}{' '}
              <Currency />
              {/* {currentChain.description} */}
            </>
          }
        />
        <VerticalGroup
          title={t('token_info.price_impact')}
          descClassName={getPriceImpactColor(+position.tpi)}
          description={
            <>{!isHoneypotWithLiquidity ? formatTokenPrice(position.tpi, 2).formatted : 0}%</>
          }
        />
      </Grid>

      {/* footer row */}
      {/* <Grid
        container
        wrap="nowrap"
        alignItems="center"
        justifyContent="center"
        className={cn(styles.rowContainer, styles.footerRow)}
        gap={3}
      >
        <Grid
          container
          gap={0.5}
          justifyContent="center"
          wrap="nowrap"
          alignItems="center"
          className={styles.footerItem}
        >
          <Typography variant="body2" resetLineHeight noWrap>
            Order type:
          </Typography>
          <OrderType type="snipe" />
        </Grid>
        <Grid
          container
          gap={0.5}
          justifyContent="center"
          wrap="nowrap"
          alignItems="center"
          className={styles.footerItem}
        >
          <Typography variant="body2" resetLineHeight noWrap>
            Date:
          </Typography>
          <Typography variant="body2" textColor="light-grey" resetLineHeight noWrap>
            2024-01-25
          </Typography>
        </Grid>
      </Grid> */}
    </Grid>
  )
}

export { ProfitSellTabCard }
