import { FC } from 'react'

import { Webhooks } from '@/components/user-settings/libs/components'
import { useAppDispatch, useAppSelector } from '@/store'
import { fetchUserById } from '@/store/slices/user.slice'

import { UserNameForm } from '../user-name-form/user-name-form'
import styles from './styles.module.scss'

const Account: FC = () => {
  const userData = useAppSelector((state) => state.user.userData)

  const dispatch = useAppDispatch()

  const handleSuccess = async () => {
    if (!userData) return
    await dispatch(fetchUserById(userData.user_id)).unwrap()
  }

  return (
    <>
      <UserNameForm
        username={userData?.username}
        usernameSet={userData?.username_set}
        onSuccess={handleSuccess}
        className={styles.form}
      />
      <Webhooks />
    </>
  )
}

export { Account }
