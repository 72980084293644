import { ChainName } from '@/libs/enums'
import { TCopyTradeTransaction } from '@/libs/types/copy-trade-transaction'
import { TEntityTemplate } from '@/libs/types/template'
import { TDefaultBuySellPriorities, TUserWallet } from '@/libs/types/user.type'
import { store } from '@/store'

const getDefaultTemplate = (
  buySellPriorities?: TDefaultBuySellPriorities,
  mainWallet?: TUserWallet,
) => {
  const currentChain = store.getState().chain.currentChain

  const { buy_priority, approve_priority } = buySellPriorities || {
    buy_priority: '',
    approve_priority: '',
  }

  if (!mainWallet) {
    return null
  }

  return {
    isPredefined: true,
    id: 'Fast Buy default',
    name: 'Fast Buy',
    setup: {
      token: '',
      network: {
        blockchain: currentChain.indexerChainId,
        dex: 0,
        // dex: null,
      },
      wallets: [
        {
          name: mainWallet.name,
          public_key: mainWallet.address,
        },
      ],
      operation: {
        private_tx: !currentChain.features?.noPrivateTx,
        tx_type: 1,
        bribe_amount: 0,
        contract: {
          separate_txs: true,
        },
        gas: {
          approve: {
            gas: currentChain.features?.noPriorityGas ? approve_priority : 10,
            miner_tip: currentChain.features?.noPriorityGas ? 0 : (approve_priority ?? 7),
            auto_calculate: true,
            gas_limit: 1000000,
          },
          buy: {
            gas: currentChain.features?.noPriorityGas ? buy_priority : 10,
            miner_tip: currentChain.features?.noPriorityGas ? 0 : (buy_priority ?? 7),
            auto_calculate: true,
            gas_limit: 1000000,
          },
        },
        pair: {
          routing: {
            mode: 2,
            path: [],
          },
          fee: {
            mode: 1,
            path_fees: [],
          },
        },
        setup: {
          buy_mode: 0,
          buy_amount: 0,
          buy_tokens: 0,
          supply_percentage: 0,
          rounds_to_buy: 1,
          slippage: currentChain.defaultValues.slippage,
          auto_approve: true,
          force_buy: true,
        },
        shields: {
          anti_fake_liquidity: false,
          anti_tax: true,
          anti_honeypot: true,
          anti_market_cap: false,
          buy_tax: 50,
          sell_tax: 70,
          maximum_liquidity: 0,
          minimum_liquidity: 0,
          maximum_market_cap: 0,
          minimum_market_cap: 0,
          anti_honeypot_mode: 0,
          anti_fake_liquidity_mode: 0,
          anti_tax_mode: 0,
          anti_duplicate: false,
          anti_price_impact: false,
          price_impact: 0,
          anti_buy_sell_ratio: false,
          buy_sell_ratio: 0,
          buy_simulation: false,
          buy_simulation_mode: 0,
        },
      },
    },
  }
}

const getNewDefaultTemplate = ({
  mainWallet,
  chainName = ChainName.ETHEREUM,
  defaultPriorities,
}: {
  mainWallet: TUserWallet | undefined
  chainName?: ChainName
  defaultPriorities?: TDefaultBuySellPriorities
}) => {
  if (!mainWallet) {
    return null
  }

  switch (chainName) {
    case ChainName.ETHEREUM:
      return getFastBuyTemplateForETH(mainWallet, defaultPriorities)
    case ChainName.BNB:
      return getFastBuyTemplateForBNB(mainWallet, defaultPriorities)
    default:
      return undefined
  }
}

const getFastBuyTemplateForETH = (
  mainWallet: TUserWallet,
  buySellPriorities?: TDefaultBuySellPriorities,
) => {
  const currentChain = store.getState().chain.currentChain

  const { buy_priority, approve_priority } = buySellPriorities || {
    buy_priority: '',
    approve_priority: '',
  }
  return {
    isPredefined: true,
    id: 'Fast Buy default',
    name: 'Fast Buy',
    setup: {
      token: '',
      network: {
        blockchain: currentChain.indexerChainId,
        dex: 0,
        // dex: null,
      },
      wallets: [
        {
          name: mainWallet.name,
          public_key: mainWallet.address,
        },
      ],
      operation: {
        private_tx: !currentChain.features?.noPrivateTx,
        tx_type: 1,
        bribe_amount: 0,
        contract: {
          separate_txs: true,
        },
        gas: {
          approve: {
            gas: currentChain.features?.noPriorityGas ? approve_priority : 10,
            miner_tip: currentChain.features?.noPriorityGas ? 0 : (approve_priority ?? 7),
            auto_calculate: true,
            gas_limit: 1000000,
          },
          buy: {
            gas: currentChain.features?.noPriorityGas ? buy_priority : 10,
            miner_tip: currentChain.features?.noPriorityGas ? 0 : (buy_priority ?? 7),
            auto_calculate: true,
            gas_limit: 1000000,
          },
        },
        pair: {
          routing: {
            mode: 2,
            path: [],
          },
          fee: {
            mode: 1,
            path_fees: [],
          },
        },
        setup: {
          buy_mode: 0,
          buy_amount: 0,
          buy_tokens: 0,
          supply_percentage: 0,
          rounds_to_buy: 1,
          slippage: currentChain.defaultValues.slippage,
          auto_approve: true,
          force_buy: true,
        },
        shields: {
          anti_fake_liquidity: false,
          anti_tax: true,
          anti_honeypot: true,
          anti_market_cap: false,
          buy_tax: 50,
          sell_tax: 70,
          maximum_liquidity: 0,
          minimum_liquidity: 0,
          maximum_market_cap: 0,
          minimum_market_cap: 0,
          anti_honeypot_mode: 0,
          anti_fake_liquidity_mode: 0,
          anti_tax_mode: 0,
          anti_price_impact: false,
          anti_duplicate: false,
          price_impact: 0,
          anti_buy_sell_ratio: false,
          buy_sell_ratio: 0,
          buy_simulation: false,
          buy_simulation_mode: 0,
        },
      },
    },
  }
}

const getFastBuyTemplateForBNB = (
  mainWallet: TUserWallet,
  buySellPriorities?: TDefaultBuySellPriorities,
): TEntityTemplate<TCopyTradeTransaction> => {
  const currentChain = store.getState().chain.currentChain

  const { sell_priority, buy_priority, approve_priority } = buySellPriorities ?? {
    buy_priority: '',
    approve_priority: '',
    sell_priority: '',
  }
  return {
    isPredefined: true,
    id: 'Fast Buy default',
    name: 'Fast Buy',
    order: 0,
    created_at: '',
    setup: {
      token: '',
      network: {
        blockchain: currentChain.indexerChainId,
        dex: 0,
        // dex: null,
      },
      wallets: [
        {
          name: mainWallet.name,
          public_key: mainWallet.address,
        },
      ],
      operation: {
        private_tx: !currentChain.features?.noPrivateTx,
        tx_type: 0,
        bribe_amount: 0,
        contract: {
          separate_txs: true,
        },
        gas: {
          approve: {
            gas: currentChain.features?.noPriorityGas ? +(approve_priority ?? 20) : 10,
            miner_tip: currentChain.features?.noPriorityGas ? 0 : +(approve_priority ?? 20),
            auto_calculate: true,
            gas_limit: 1000000,
          },
          buy: {
            gas: currentChain.features?.noPriorityGas ? +(buy_priority ?? 5) : 10,
            miner_tip: currentChain.features?.noPriorityGas ? 0 : +(buy_priority ?? 5),
            auto_calculate: true,
            gas_limit: 1000000,
          },
          sell: {
            gas: currentChain.features?.noPriorityGas ? +(sell_priority ?? 20) : 10,
            miner_tip: +(sell_priority ?? 20),
            auto_calculate: true,
            gas_limit: 1000000,
          },
        },
        pair: {
          routing: {
            mode: 2,
            path: [],
          },
          fee: {
            mode: 1,
            path_fees: [],
          },
        },
        setup: {
          buy_mode: 0,
          buy_amount: 0,
          buy_tokens: 0,
          supply_percentage: 0,
          rounds_to_buy: 1,
          slippage: currentChain.defaultValues.slippage,
          auto_approve: true,
          force_buy: true,
          front_run_buys: false,
          front_run_priority: 20,
          back_run_buys: false,
        },
        shields: {
          anti_fake_liquidity: false,
          anti_tax: true,
          anti_honeypot: true,
          anti_market_cap: false,
          buy_tax: 40,
          sell_tax: 40,
          buy_amount: 0,
          maximum_liquidity: 3000,
          minimum_liquidity: 1000,
          maximum_market_cap: 30000,
          minimum_market_cap: 0,
          anti_honeypot_mode: 0,
          anti_fake_liquidity_mode: 0,
          anti_tax_mode: 0,
          anti_price_impact: false,
          anti_duplicate: false,
          price_impact: 0,
          anti_buy_sell_ratio: false,
          buy_sell_ratio: 0,
          buy_simulation: false,
          buy_simulation_mode: 0,
        },
      },
    },
  }
}

export { getDefaultTemplate, getNewDefaultTemplate }
