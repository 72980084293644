import { useDynamicContext, useReinitialize } from '@dynamic-labs/sdk-react-core'

import { useAppDispatch } from '@/store'
import { setAuth } from '@/store/slices/auth.slice'

import { useIsMobile } from './useIsMobile'

const useDynamicLogout = () => {
  const dispatch = useAppDispatch()

  const isMobile = useIsMobile()
  const reinitialize = useReinitialize()
  const { handleLogOut } = useDynamicContext()

  /**
   * We have to use this hack because mobile logout triggers opening the wallet app
   * to disconnect it. In general, we don't need it, we need just logout from dynamic account
   * because we use dynamic only for connect wallet and sign message
   */
  const dynamicLogout = () => {
    if (isMobile) {
      localStorage.removeItem('dynamic_authentication_token')
      localStorage.removeItem('dynamic_min_authentication_token')
      dispatch(setAuth(null))
      reinitialize()
    } else {
      handleLogOut()
    }
  }

  return { dynamicLogout }
}

export { useDynamicLogout }
