import { useEffect, useState } from 'react'

import { DynamicEmbeddedWidget, useDynamicContext } from '@dynamic-labs/sdk-react-core'
import { isSolanaWallet } from '@dynamic-labs/solana'
import { type AxiosError } from 'axios'
import bs58 from 'bs58'

import { attachChainToAccount } from '@/api/user'
import { CustomToast } from '@/components/custom-toast'
import { useCustomNavigate } from '@/hooks/useCustomNavigate'
import { useDynamicLogout } from '@/hooks/useDynamicLogout'
import { AppRoute } from '@/libs/enums'
import { useAppDispatch, useAppSelector } from '@/store'
import { fetchUserById } from '@/store/slices/user.slice'
import { getNonceFromMessage } from '@/utils/get-nonce-from-message'
import { isBase64 } from '@/utils/is-base64'

const LinkWallet = () => {
  const navigate = useCustomNavigate()

  const dispatch = useAppDispatch()

  const userData = useAppSelector((state) => state.user.userData)
  const authData = useAppSelector((state) => state.auth.authData)
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  const { primaryWallet } = useDynamicContext()
  const { dynamicLogout } = useDynamicLogout()

  const [isInProcess, setIsInProcess] = useState(false)

  const linkWallet = async ({
    user,
    auth,
    wallet,
  }: {
    user: NonNullable<typeof userData>
    auth: NonNullable<typeof authData>
    wallet: NonNullable<typeof primaryWallet>
  }) => {
    setIsInProcess(true)
    try {
      const nonce = getNonceFromMessage(auth.messageToSign)

      let signature = auth.signedMessage

      // Solana signature is base64 encoded on desktop, but not on mobile
      if (isSolanaWallet(wallet) && isBase64(signature)) {
        signature = bs58.encode(Buffer.from(signature, 'base64'))
      }

      await attachChainToAccount(user.user_id, {
        blockchain: currentChain.id,
        message: auth.messageToSign,
        signatory: wallet.address,
        signature,
        nonce,
      })
      await dispatch(fetchUserById(user.user_id)).unwrap()

      navigate({
        path: AppRoute.SETUP_WALLETS,
      })
    } catch (e) {
      const error = e as AxiosError<{
        id: string
        status: string
        data: {
          code: string
          description: string
          title: string
        }
      }>

      if (!error.response) {
        CustomToast('error', 'Something went wrong')
        return
      }

      const { title, description, code } = error.response.data.data

      /**
       * In fact, this error indicates that the incorrect signature,
       * but in this case, it indicates that for example the user has switched to Solana but is trying to link EVM wallet.
       * Otherwords, for example, the user sends blockchain_id: 8 (Solana) with EVM signature.
       */
      if (code === 'UBFBULELQV') {
        CustomToast('error', `Choose another wallet to connect to ${currentChain.label}`)
        return
      }

      CustomToast('error', `${title}: ${description}`)
    } finally {
      setIsInProcess(false)
      dynamicLogout()
    }
  }

  useEffect(() => {
    if (userData && authData && primaryWallet && !isInProcess) {
      linkWallet({
        user: userData,
        auth: authData,
        wallet: primaryWallet,
      })
    }
  }, [userData, authData, primaryWallet, isInProcess])

  return <DynamicEmbeddedWidget />
}

export { LinkWallet }
