import { FC, useEffect, useState } from 'react'

import { ToggleButtonGroupProps } from '@mui/material'
import cls from 'classnames'

import { getSetup, setSetup } from '@/api/user'
import { ChainNameToNativeTokenIcon } from '@/components/header/libs/maps/chain-name-to-chain-element.tsx'
import {
  ButtonGroupRadio,
  ButtonGroupRadioButton,
  ButtonIcon,
  Input,
  Typography,
} from '@/libs/common'
import { IconName } from '@/libs/enums'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

type TProperty = ToggleButtonGroupProps & {
  label?: string | React.ReactNode
  separated?: boolean
  groupClassName?: string
  solid?: boolean
}

const BuyAmountGroup: FC<TProperty> = (props) => {
  const [options, setOptions] = useState([])
  const [customOptions, setCustomOptions] = useState([])
  const [isCustomizeMode, setIsCustomizeMode] = useState(false)
  const userData = useAppSelector((state) => state.user.userData)
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const networkId = useAppSelector((state) => state.chain.currentChain.indexerChainId)

  useEffect(() => {
    if (!userData) return
    const setSetupValues = async () => {
      const { data } = await getSetup(userData.user_id)
      return Object.entries(data.data)
        .filter(([key]) => key.startsWith('lite_'))
        .map(([, value]) => value?.toString())
    }
    setSetupValues().then((result: any) => {
      setCustomOptions(result)
      setOptions(result)
    })
  }, [networkId])
  const handleSubmitSetting = () => {
    if (!validateGroup()) return
    setOptions(customOptions)

    const keys = [
      'lite_buy_one',
      'lite_buy_two',
      'lite_buy_three',
      'lite_buy_four',
      'lite_buy_five',
      'lite_buy_six',
    ]

    const result = keys.reduce((acc: any, key: any, index) => {
      acc[key] = customOptions[index]
      return acc
    }, {})
    if (userData && userData.user_id)
      setSetup(userData.user_id, { ...result, blockchain: networkId })
    // localStorage.setItem(LocalStorageItem.LITE_BUY_AMOUNT, JSON.stringify(customOptions))
    setIsCustomizeMode(false)
  }

  const validateGroup = () => {
    let isValidValue = true
    customOptions.forEach((el) => {
      if (el === '' || el === '0') isValidValue = false
    })
    const isUnique = new Set(customOptions).size === customOptions.length
    return isUnique && isValidValue
  }

  return (
    <div className={styles.container}>
      <div className={styles.controlsWrapper}>
        {isCustomizeMode ? (
          <div className={styles.controls}>
            <ButtonIcon icon={IconName.CHECK_SMALL} type="button" onClick={handleSubmitSetting} />
            <ButtonIcon
              icon={IconName.CLOSE_MIDDLE}
              type="button"
              onClick={() => {
                setCustomOptions(options)
                setIsCustomizeMode(false)
              }}
            />
          </div>
        ) : (
          <ButtonIcon
            className={styles.control}
            icon={IconName.EDIT}
            type="button"
            onClick={() => setIsCustomizeMode(true)}
          />
        )}
      </div>
      <ButtonGroupRadio {...props}>
        {options?.map((el, index) => {
          return isCustomizeMode
            ? customOptions && (
                <Input
                  isNumeric
                  placeholder=""
                  value={customOptions[index]}
                  className={cls(
                    cls(styles.customButton, styles.input, {
                      [styles.firstInput]: index === 0,
                      [styles.thirdInput]: index === 2,
                      [styles.forthInput]: index === 3,
                      [styles.lastInput]: index === options.length - 1,
                    }),
                  )}
                  onChange={(e) => {
                    const newValue = e.target.value
                    setCustomOptions((state) => {
                      const updatedState = [...state] as any
                      updatedState[index] = newValue
                      return updatedState
                    })
                  }}
                />
              )
            : customOptions && (
                <ButtonGroupRadioButton
                  value={el}
                  name="wallet"
                  className={cls(styles.customButton, styles.blockButton)}
                  key={index}
                >
                  {ChainNameToNativeTokenIcon[currentChain.chainName]}

                  <Typography variant="body2" textColor="light-grey">
                    {el}
                  </Typography>
                </ButtonGroupRadioButton>
              )
        })}
      </ButtonGroupRadio>
    </div>
  )
}

export { BuyAmountGroup }
