import { ComponentProps, FC } from 'react'

import cls from 'classnames'

import { Icon } from '@/libs/common/icon'
import { Tooltip } from '@/libs/common/tooltip/index'
import { IconName } from '@/libs/enums'
import { ValueOf } from '@/libs/types'

import styles from './styles.module.scss'

type TProperty = {
  icon?: ValueOf<typeof IconName>
  children?: React.ReactNode
  tooltipTitle?: string
  large?: boolean
  containerClassName?: string
}

type TButtonIconProps = ComponentProps<'button'> & TProperty

const ButtonIcon: FC<TButtonIconProps> = ({
  icon,
  children,
  className,
  containerClassName,
  tooltipTitle,
  large,
  ...props
}) => {
  return (
    <Tooltip title={tooltipTitle} withIcon={false} className={containerClassName}>
      <button className={cls(styles.button, className)} {...props}>
        {icon && (
          <div className={styles.icon}>
            <Icon name={icon as any} />
          </div>
        )}
        {children}
      </button>
    </Tooltip>
  )
}

export { ButtonIcon, type TButtonIconProps }
