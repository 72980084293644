import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { TextareaAutosize, TextareaAutosizeProps } from '@mui/material'
import cn from 'classnames'

import { Typography } from '@/libs/common'

import styles from './styles.module.scss'

type TProps = TextareaAutosizeProps & {
  value: string
  maxWallets?: number
  onChange: (value: string) => void
}

const ImportPrivateKeysComponent: FC<TProps> = ({ className, maxWallets, ...props }) => {
  const { t } = useTranslation()

  const privateKeyLabel = t('settings.private_key').replace(/ /g, '_')

  return (
    <div>
      <Typography variant="h1" align="center" className={styles.header}>
        {t('setup_wallets.import_wallets')}
      </Typography>

      <Typography variant="body1" className={styles.typographyColor}>
        {t('wallet.enter_wallet_here')}
        {!!maxWallets && ` (max ${maxWallets})`}:
      </Typography>

      <Typography variant="body1" className={styles.walletFormatWrapper}>
        {privateKeyLabel}
        <br />
        {privateKeyLabel}
        <br />
        ...
      </Typography>

      <TextareaAutosize
        minRows={5.8}
        className={cn(styles.textarea, className)}
        {...props}
      ></TextareaAutosize>
    </div>
  )
}

export { ImportPrivateKeysComponent }
