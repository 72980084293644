import { CSSProperties } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'

type TProps<T> = {
  data: T[]
  totalItemsCount: number
  listHeight: number
  itemHeight: number
  renderRow: (item: T, style: CSSProperties) => JSX.Element | null
  noDataLabel?: JSX.Element | string
  withInfiniteScroll?: boolean
  loadMoreItems?: () => any
}

const VirtualizedList = <T,>({
  data,
  totalItemsCount,
  listHeight,
  itemHeight,
  renderRow,
  noDataLabel,
  withInfiniteScroll,
  loadMoreItems,
}: TProps<T>) => {
  const isItemLoaded = (index: number) => !!data[index]

  if (!data.length) {
    return noDataLabel || <></>
  }

  const renderList = ({
    width,
    onItemsRendered,
    ref,
  }: {
    width: number
    onItemsRendered?: any
    ref?: any
  }) => (
    <FixedSizeList
      itemCount={totalItemsCount}
      onItemsRendered={onItemsRendered}
      ref={ref}
      height={listHeight}
      itemSize={itemHeight}
      width={width}
    >
      {({ index, style }) => renderRow(data[index], style)}
    </FixedSizeList>
  )

  return (
    <div>
      <AutoSizer disableHeight style={{ width: '100%', height: listHeight }}>
        {({ width }) => {
          if (withInfiniteScroll && loadMoreItems) {
            return (
              <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={totalItemsCount}
                loadMoreItems={loadMoreItems}
              >
                {({ onItemsRendered, ref }) => <>{renderList({ width, onItemsRendered, ref })}</>}
              </InfiniteLoader>
            )
          }
          return renderList({ width })
        }}
      </AutoSizer>
    </div>
  )
}

export { VirtualizedList }
