import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import cn from 'classnames'

import { FavoriteHistoryList } from '@/components/favorite-history-list'
import { Accordion, Icon, Typography } from '@/libs/common'
import { IconName } from '@/libs/enums'

import styles from './styles.module.scss'

type TProps = {
  isOpen?: boolean
}

const FavoritesAccordion: FC<TProps> = ({ isOpen }) => {
  const { t } = useTranslation()
  const [isFavorites, setIsFavorites] = useState(true)
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    if (!isOpen && expanded) {
      setExpanded(false)
    }
  }, [isOpen])

  return (
    <Accordion
      title={
        <div className={styles.titleGroup}>
          <Typography
            className={cn(styles.titleTab, isFavorites && styles.activeTab)}
            onClick={(e) => {
              e.stopPropagation()
              setIsFavorites(true)
            }}
          >
            {t('menu.favorites')}
          </Typography>
          <span className={styles.titleTab}>/</span>
          <Typography
            className={cn(styles.titleTab, !isFavorites && styles.activeTab)}
            onClick={(e) => {
              e.stopPropagation()
              setIsFavorites(false)
            }}
          >
            {t('menu.history')}
          </Typography>
        </div>
      }
      headerIcon={<Icon name={IconName.STAR_GREY_BOLD} />}
      titleClassName={styles.title}
      contentWithScroll="favorites"
      expanded={expanded}
      onChange={setExpanded}
    >
      <FavoriteHistoryList isFavorites={isFavorites} />
    </Accordion>
  )
}

export { FavoritesAccordion }
