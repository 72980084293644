// import { FC, useEffect, useState } from 'react'
// import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import QRCode from 'react-qr-code'

// import { yupResolver } from '@hookform/resolvers/yup'
// import { useWeb3Modal } from '@web3modal/wagmi/react'
// import { parseEther } from 'ethers'
// import { useAccount, useSendTransaction, useWaitForTransactionReceipt } from 'wagmi'
// import * as yup from 'yup'
// import { CustomToast } from '@/components/custom-toast'
import { ButtonCopy, Typography } from '@/libs/common'
// import { EndAdornment } from '@/libs/common/input/components/end-adornment'
// import { IconName } from '@/libs/enums'
// import { formatTokenPrice } from '@/libs/helper/formatTokenPrice'
// import { TSelectOption } from '@/libs/types/select-option.type'
import { TUserWallet } from '@/libs/types/user.type'

// import { stringOfNumbersValidation } from '@/libs/validations/common'
// import { useAppSelector } from '@/store'
import styles from './styles.module.scss'

type TProps = {
  wallet?: TUserWallet
}

// const options: TSelectOption<string>[] = [
//   {
//     value: '0.05',
//     label: '0.05',
//   },
//   {
//     value: '0.1',
//     label: '0.1',
//   },
//   {
//     value: ' 0.25',
//     label: '0.25',
//   },
//   {
//     value: '0.5',
//     label: '0.5',
//   },
// ]

// const schema = yup.object({
//   amount: stringOfNumbersValidation.required(),
// })

// const defaultValues = {
//   amount: '',
// }

const DepositTab: FC<TProps> = ({ wallet }) => {
  const { t } = useTranslation()
  // const currentChain = useAppSelector((state) => state.chain.currentChain)

  // const [isLoading, setIsLoading] = useState(false)

  // const {
  //   control,
  //   handleSubmit,
  //   watch,
  //   formState: { errors },
  // } = useForm({
  //   defaultValues,
  //   resolver: yupResolver(schema),
  // })
  // const { open } = useWeb3Modal()
  // const { address: connectorAddress, isConnected } = useAccount()

  // const { data: hash, sendTransaction } = useSendTransaction()

  // const handleConnectWallet = () => {
  //   open()
  // }

  // const result = useWaitForTransactionReceipt({
  //   hash,
  // })

  // useEffect(() => {
  //   if (result.isSuccess) {
  //     CustomToast('success', 'Your wallet was successfully funded')
  //   }
  // }, [result.isSuccess])
  // const onSubmit: SubmitHandler<typeof defaultValues> = async ({ amount }) => {
  //   if (!wallet || !isConnected) return

  //   setIsLoading(true)

  //   try {
  //     const parsedAmount = parseEther(amount)
  //     sendTransaction({
  //       to: wallet.public_key as `0x${string}`,
  //       value: parsedAmount,
  //     })
  //   } catch (err) {
  //     CustomToast('error', (err as any).message)
  //   } finally {
  //     setIsLoading(false)
  //   }
  // }

  if (!wallet) {
    return <></>
  }

  // const connectWalletOrDeposit = connectorAddress ? (
  //   <Button
  //     type="submit"
  //     disabled={!watch('amount')}
  //     className={styles.button}
  //     isLoading={isLoading}
  //   >
  //     <span className={styles.buttonText}>Deposit</span>
  //   </Button>
  // ) : (
  //   <Button
  //     className={styles.button}
  //     onClick={handleConnectWallet}
  //     iconStartName={IconName.CONNECT_WALLET}
  //   >
  //     <span className={styles.buttonText}>Connect wallet</span>
  //   </Button>
  // )

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <div className={styles.divider}>
          <Typography variant="body2" className={styles.label}>
            {t('wallet.manual_deposit')}
          </Typography>
          <div className={styles.line}></div>
        </div>
        <div className={styles.codeWrapper}>
          <div className={styles.qrCode}>
            <QRCode size={100} value={wallet.address} />
          </div>
          <div className={styles.codeInfo}>
            <Typography variant="caption">{t('wallet.wallet_address')}</Typography>
            <div className={styles.copy}>
              <Typography variant="body2" className={styles.address}>
                {wallet.address}
              </Typography>
              <ButtonCopy value={wallet.address} />
            </div>
          </div>
        </div>
      </div>
      {/* <form onSubmit={handleSubmit(onSubmit)} className={styles.section}>
        <div className={styles.divider}>
          <Typography variant="body2" className={styles.label}>
            Wallet deposit
          </Typography>
          <div className={styles.line}></div>
        </div>
        <div>
          <Controller
            name="amount"
            control={control}
            render={({ field: { ref, onChange, ...field } }) => (
              <InputWithRadioGroup
                label="Amount"
                placeholder="Enter amount to deposit"
                onOptionSelect={(value) => {
                  onChange(value)
                }}
                endAdornment={
                  <EndAdornment
                    label={currentChain.description}
                    icon={(IconName as any)[currentChain.iconName]}
                  />
                }
                radioGroupName="amount"
                options={options}
                onChange={onChange}
                error={!!errors.amount?.message}
                disabled={isLoading}
                {...field}
              />
            )}
          />
          <div className={styles.available}>
            <Typography variant="body2">Available:</Typography>
            <Typography variant="body2">
              {formatTokenPrice(`${wallet?.balanceFormatted || 0}`).formatted}{' '}
              {currentChain.description}
            </Typography>
          </div>
        </div>

        {connectWalletOrDeposit}
      </form> */}
    </div>
  )
}

export { DepositTab }
