import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid } from '@mui/material'

import { Accordion, Typography } from '@/libs/common'

import { TemplateSettingsElement } from './libs/components/template-settings-element'
import styles from './styles.module.scss'

// TODO: Get all the values from the props
type TProps = {
  onChange?: (value: boolean) => void
}

const TemplateSettingsList: FC<TProps> = ({ onChange }) => {
  const { t } = useTranslation()

  return (
    <Accordion
      className={styles.accordion}
      titleClassName={styles.accordionTitle}
      title="Template Settings"
      withDivider
      mainColorArrow
      onChange={onChange}
    >
      <Grid container rowGap={2} flexDirection="column">
        <Grid container rowGap={1.5} flexDirection="column">
          <Typography variant="body2" textColor="white">
            {t('buy')}
          </Typography>
          <div className={styles.settingsBlock}>
            <TemplateSettingsElement label="Mempool Backup" value={true} />
            <TemplateSettingsElement label={t('template.max_tx_or_fail')} value={true} />
            <TemplateSettingsElement label="First Bundle Buy" value="20 ETH" />
            <TemplateSettingsElement label="Backup Bundle Buy" value="20.00 GWEI" />
            <TemplateSettingsElement label="Mempool Backup Buy" value="20.00 GWEI" />
            <TemplateSettingsElement label="First Bundle or fail" value="0.03 ETH TIP" />
            <TemplateSettingsElement label="Backup Bundle" value="0.03 ETH TIP" />
            <TemplateSettingsElement
              label={t('amount_values.exact_percentage_tokens')}
              value="20%"
            />
            <TemplateSettingsElement label={t('template.slippage')} value="50%" />
            <TemplateSettingsElement label={t('wallet.selected_wallet')} value="3/4" />
            <TemplateSettingsElement label={t('template.sell_extra_rug')} value="20 GWEI" />
            <TemplateSettingsElement label={t('token_info.max_marketcap')} value="$30,000" />
            <TemplateSettingsElement label={t('template.max_buy_template')} value="20%" />
            <TemplateSettingsElement label={t('template.max_sell_tax')} value="25%" />
            <TemplateSettingsElement label={t('token_info.min_liquidity')} value="$2,400" />
            <TemplateSettingsElement label={t('token_info.max_liquidity')} value="$3,400" />
          </div>
        </Grid>
        <Grid container rowGap={1.5} flexDirection="column">
          <Typography variant="body2" textColor="white">
            {t('template.safety')}
          </Typography>
          <div className={styles.settingsBlock}>
            <TemplateSettingsElement label={t('template.anti_rug')} value={true} />
            <TemplateSettingsElement label="Anti-Blacklist" value={true} />
            <TemplateSettingsElement label={t('template.anti_rug_threshold')} value="50%" />
            <TemplateSettingsElement label="Transfer On Blacklist" value={true} />
            <TemplateSettingsElement label={t('template.sell_extra_rug')} value="20 GWEI" />
            <TemplateSettingsElement label={t('token_info.max_marketcap')} value="$30,000" />
            <TemplateSettingsElement label={t('template.max_buy_template')} value="20%" />
            <TemplateSettingsElement label={t('template.max_sell_tax')} value="20%" />
            <TemplateSettingsElement label={t('token_info.min_liquidity')} value="$2,400" />
            <TemplateSettingsElement label={t('token_info.max_liquidity')} value="$3,400" />
          </div>
        </Grid>
        <Grid container rowGap={1.5} flexDirection="column">
          <Typography variant="body2" textColor="white">
            Sell
          </Typography>
          <div className={styles.settingsBlock}>
            <TemplateSettingsElement label={t('template.sell_priority')} value="20 GWEI" />
            <TemplateSettingsElement label={t('template.auto_sell')} value={true} />
            <TemplateSettingsElement label={t('sell_tab.sell_on_profit')} value="20%" />
            <TemplateSettingsElement label={t('sell_tab.sell_profit_amount_title')} value="400%" />
            <TemplateSettingsElement
              label={t('wallet.wallets_to_sell_on_profit')}
              value="1, 3, 4"
            />
            <TemplateSettingsElement label={t('sell_tab.sell_profit')} value="20%" />
            <TemplateSettingsElement label="Sell in steps">
              <Typography variant="body2" textColor="light-grey">
                W1 - x20
              </Typography>
              <Typography variant="body2" textColor="light-grey">
                W2 - x40
              </Typography>
            </TemplateSettingsElement>
            <TemplateSettingsElement label="Sell in steps" />
          </div>
        </Grid>
      </Grid>
    </Accordion>
  )
}

export { TemplateSettingsList }
