import { useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Grid } from '@mui/material'
import cls from 'classnames'

import { PriorityCurrency } from '@/components/priority-currency'
import { SelectWallet } from '@/components/select-wallet'
import { TemplatesControl } from '@/components/templates-control'
import {
  Accordion,
  Button,
  ButtonGroupRadio,
  ButtonGroupRadioButton,
  HiddenInputs,
  Icon,
  Input,
  InputWithRadioGroup,
  Typography,
} from '@/libs/common'
import { EndAdornment } from '@/libs/common/input/components/end-adornment'
import { OptionalInput } from '@/libs/common/optional-input'
import { PercentsInput } from '@/libs/common/percents-input'
import { SwitchInline } from '@/libs/common/switch-inline'
import { IconName } from '@/libs/enums'
import { TSelectOption } from '@/libs/types/select-option.type'
import { TSnipeTemplate } from '@/libs/types/snipe.type'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

const limitOptions: TSelectOption<number>[] = [
  {
    value: -10,
    label: '-10%',
  },
  {
    value: -20,
    label: '-20%',
  },
  {
    value: -30,
    label: '-30%',
  },
  {
    value: -40,
    label: '-40%',
  },
]

const defaultValues = {
  slippage: 0,
  buyPriority1: '',
  selectedWallets: [],
  //advanced settings
  buyPriority2: '',
  approvePriority: '',
  maxTxOrFail: false,
  transferOnBlacklist: false,
  antiBlacklist: false,
  antiRug: false,
  antiRugThreshold: 0,
  sellRugExtraTip: '12',
  maxBuyTax: '',
  maxSellTax: '',
  minLiquidity: '',
  maxLiquidity: '',
  maxMarketcap: '',
  dip: '',
  marketcap: '',
  price: '',
  triggerPricePercent: '',
  expiration: '',
  spend: '',
  receive: '',
}

const spendOptions: TSelectOption<number>[] = [
  {
    value: 0.25,
    label: '25%',
  },
  {
    value: 0.5,
    label: '50%',
  },
  {
    value: 0.75,
    label: '75%',
  },
  {
    value: 1,
    label: '100%',
  },
]
const mockTemplates: TSnipeTemplate[] = [
  {
    _id: '1',
    name: 'Manual',
  },
  {
    _id: '2',
    name: 'Fast Buy',
  },
  {
    _id: '3',
    name: 'Max profit',
  },
  {
    _id: '4',
    name: 'Quick trade',
  },
]

const EditLimitBuy = () => {
  const { t } = useTranslation()
  const { token } = useParams()
  const [templates] = useState<TSnipeTemplate[]>(mockTemplates)
  const [currentTemplate] = useState<TSnipeTemplate | null>(mockTemplates[0])
  const [isAntiRugSettings, setIsAntiRugSettings] = useState(defaultValues.antiRug)
  const [footerTab, setFooterTab] = useState<string>('market')
  const { control, handleSubmit } = useForm({ defaultValues })
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const { iconName, chainSymbol } = currentChain

  const customizeTemplate = () => {
    // Implement template customization here
  }

  const handleFooterTabChange = (_: React.BaseSyntheticEvent, newValue: string) => {
    setFooterTab(newValue)
  }

  const onSubmit: SubmitHandler<typeof defaultValues> = (data) => {
    // Send data to backend
    return data
  }
  return (
    <div className={styles.container}>
      <Typography variant="h1" textColor="light-grey" textAlign="center">
        Edit {token} Limit Buy settings
      </Typography>
      <Grid>
        <TemplatesControl
          templates={templates}
          currentTemplateId={currentTemplate?._id || mockTemplates[0]._id}
          onSelectTemplate={(id) => id}
          onCustomize={customizeTemplate}
          customizeDisabled={currentTemplate?._id === '1'}
          editDisabled={currentTemplate?._id === '1'}
          deleteDisabled={!!currentTemplate && ['1', '2'].includes(currentTemplate._id)}
        />
      </Grid>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <Grid container rowGap={3}>
          {currentTemplate === mockTemplates[0] && (
            <>
              <Accordion
                className={styles.accordion}
                titleClassName={styles.accordionTitle}
                title={t('template.advanced_manual_buy_settings')}
                withDivider
                mainColorArrow
              >
                <Grid container rowGap={3} flexDirection="column" marginTop={1} marginBottom={3}>
                  <Grid container rowGap={2}>
                    <Typography variant="body2" textColor="white">
                      {t('buy')}
                    </Typography>
                    <Controller
                      name="buyPriority2"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <OptionalInput
                          className={styles.input}
                          label={t('template.buy_priority')}
                          placeholder={t('template.enter_gwei_amount', {
                            currency: currentChain.priorityCurrency.toLowerCase(),
                          })}
                          isNumeric
                          tooltipInfo={`${t('template.buy_priority_tooltip')} ${currentChain.chainSymbol}.`}
                          onAutoChange={() => {
                            //TO DO: added auto switch
                          }}
                          endAdornment={<PriorityCurrency />}
                          {...field}
                        />
                      )}
                    />

                    <Controller
                      name="approvePriority"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <OptionalInput
                          className={styles.input}
                          label={t('template.approve_priority')}
                          placeholder={t('template.enter_gwei_amount', {
                            currency: currentChain.priorityCurrency.toLowerCase(),
                          })}
                          isNumeric
                          tooltipInfo={`${t('template.approve_priority_tooltip')} ${currentChain.chainSymbol}.`}
                          endAdornment={<PriorityCurrency />}
                          {...field}
                        />
                      )}
                    />

                    <Controller
                      name="maxTxOrFail"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <SwitchInline
                          label={t('template.max_tx_or_fail')}
                          tooltipInfo={t('template.max_tx_or_fail_tooltip')}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid container rowGap={2} flexDirection="column">
                    <Typography variant="body2" textColor="white">
                      {t('template.safety')}
                    </Typography>

                    <Grid container columnGap={3} flexWrap="nowrap">
                      <Controller
                        name="transferOnBlacklist"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <SwitchInline
                            label="Transfer On Blacklist"
                            tooltipInfo="Will transfer tokens to the backup wallet when the developer tries to blacklist your wallet."
                            {...field}
                          />
                        )}
                      />

                      <Controller
                        name="antiBlacklist"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <SwitchInline
                            label={t('template.anti_blacklist')}
                            tooltipInfo={t('template.anti_blacklist_tooltip')}
                            {...field}
                          />
                        )}
                      />
                    </Grid>

                    <HiddenInputs
                      expanded={isAntiRugSettings}
                      trigger={
                        <Controller
                          name="antiRug"
                          control={control}
                          render={({ field: { ref, onChange, ...field } }) => (
                            <SwitchInline
                              label={t('template.anti_rug')}
                              tooltipInfo={t('template.anti_rug_tooltip')}
                              onChange={(
                                _: React.ChangeEvent<HTMLInputElement>,
                                checked: boolean,
                              ) => {
                                setIsAntiRugSettings(checked)
                                onChange(checked)
                              }}
                              {...field}
                            />
                          )}
                        />
                      }
                    >
                      <Grid container rowGap={2}>
                        <Controller
                          name="antiRugThreshold"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <PercentsInput
                              label={t('template.anti_rug_threshold')}
                              onOptionSelect={(value) => field.onChange(value)}
                              tooltipInfo={t('template.anti_rug_tooltip')}
                              {...field}
                            />
                          )}
                        />

                        <Controller
                          name="sellRugExtraTip"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <OptionalInput
                              placeholder={t('token_info.amount')}
                              isNumeric
                              label={t('template.sell_extra_rug')}
                              tooltipInfo={t('template.sell_extra_rug_tooltip')}
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                    </HiddenInputs>

                    <Grid container flexWrap="nowrap" columnGap={3}>
                      <Controller
                        name="maxBuyTax"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <OptionalInput
                            placeholder={t('template.enter_percentage')}
                            isNumeric
                            label={t('template.max_buy_tax')}
                            adornmentText="%"
                            tooltipInfo={t('template.max_buy_tax_tooltip')}
                            {...field}
                          />
                        )}
                      />

                      <Controller
                        name="maxSellTax"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <OptionalInput
                            placeholder={t('template.enter_percentage')}
                            isNumeric
                            label={t('template.max_sell_tax')}
                            adornmentText="%"
                            tooltipInfo={t('template.max_sell_tax_tooltip')}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid container flexWrap="nowrap" columnGap={3}>
                      <Controller
                        name="minLiquidity"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <OptionalInput
                            placeholder={t('token_info.enter_min_liquidity')}
                            isNumeric
                            label={t('token_info.min_liquidity')}
                            adornmentText="$"
                            tooltipInfo={t('token_info.min_liquidity_tooltip')}
                            {...field}
                          />
                        )}
                      />

                      <Controller
                        name="maxLiquidity"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <OptionalInput
                            placeholder={t('token_info.enter_max_liquidity')}
                            isNumeric
                            label={t('token_info.max_liquidity')}
                            adornmentText="$"
                            tooltipInfo={t('token_info.max_liquidity_tooltip')}
                            {...field}
                          />
                        )}
                      />
                    </Grid>

                    <Controller
                      name="maxMarketcap"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <OptionalInput
                          placeholder={t('token_info.enter_max_marketcap')}
                          isNumeric
                          label={t('token_info.max_marketcap')}
                          adornmentText="$"
                          tooltipInfo={t('token_info.max_marketcap_tooltip')}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Accordion>

              <Controller
                name="slippage"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <PercentsInput
                    label={t('template.slippage')}
                    onOptionSelect={(value) => field.onChange(value)}
                    tooltipInfo={t('template.slippage_tooltip')}
                    placeholder="X"
                    {...field}
                  />
                )}
              />

              <Controller
                name="buyPriority1"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <OptionalInput
                    className={styles.input}
                    label={t('template.buy_priority')}
                    placeholder={t('template.enter_gwei_amount', {
                      currency: currentChain.priorityCurrency.toLowerCase(),
                    })}
                    isNumeric
                    tooltipInfo={`${t('template.buy_priority_tooltip')} ${currentChain.chainSymbol}.`}
                    endAdornment={<PriorityCurrency />}
                    {...field}
                  />
                )}
              />

              <Controller
                name="selectedWallets"
                control={control}
                render={({ field: { ref, value, ...field } }) => (
                  <SelectWallet tooltipInfo="tooltipInfo" isMulti wallet={value} {...field} />
                )}
              />
            </>
          )}

          <Grid container rowGap={2} flexDirection="column">
            <ButtonGroupRadio
              onChange={handleFooterTabChange}
              value={footerTab}
              exclusive
              className={cls(styles.group, styles.bgGrey)}
            >
              <ButtonGroupRadioButton value="market">Market</ButtonGroupRadioButton>
              <ButtonGroupRadioButton value="limit">Limit</ButtonGroupRadioButton>
            </ButtonGroupRadio>
            {footerTab === 'limit' && (
              <Grid container rowGap={2} flexDirection="column" className={styles.limitBlock}>
                <div>
                  <Typography variant="body2" textColor="light-grey">
                    Trigger price
                  </Typography>
                  <div className={styles.inputWrapper}>
                    <Controller
                      name="dip"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <Input
                          className={cls(styles.input, styles.first)}
                          placeholder="Enter dip %"
                          endAdornment={<EndAdornment label="%" />}
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="marketcap"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <Input
                          className={styles.input}
                          placeholder="Enter marketcap"
                          endAdornment={<EndAdornment label="MC" />}
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="price"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <Input
                          className={cls(styles.input, styles.last)}
                          placeholder="Enter price"
                          endAdornment={<EndAdornment label="$" />}
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <Controller
                    name="triggerPricePercent"
                    control={control}
                    render={({ field: { ref, onChange, ...field } }) => (
                      <ButtonGroupRadio
                        className={styles.radioGroup}
                        onChange={(_, value: string) => onChange(value)}
                        exclusive
                        {...field}
                      >
                        {limitOptions.map((option) => (
                          <ButtonGroupRadioButton
                            value={option.value}
                            className={styles.radioButton}
                            key={option.value}
                          >
                            {option.label}
                          </ButtonGroupRadioButton>
                        ))}
                      </ButtonGroupRadio>
                    )}
                  />
                </div>

                <Controller
                  name="expiration"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <OptionalInput
                      label="Expiration"
                      tooltipInfo="Enter the number of hours the limit order is valid. After this time expires, it will be removed."
                      className={styles.input}
                      placeholder="Enter the position expiration time in hours"
                      adornmentText="Hours"
                      {...field}
                    />
                  )}
                />
              </Grid>
            )}
          </Grid>
          <div className={styles.footer}>
            <div className={styles.arrow}>
              <Icon name={IconName.ARROW_DOWN_GREY} />
            </div>

            <div className={styles.container}>
              <div className={styles.spend}>
                <Controller
                  name="spend"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <InputWithRadioGroup
                      label={t('spend')}
                      placeholder={t('amount_values.amount_to_spend')}
                      isNumeric
                      className={styles.input}
                      onOptionSelect={(value) => field.onChange(value)}
                      endAdornment={
                        <EndAdornment label={chainSymbol} icon={(IconName as any)[iconName]} />
                      }
                      radioGroupName="amount"
                      options={spendOptions}
                      {...field}
                    />
                  )}
                />
                <div className={styles.available}>
                  <Typography variant="body2" className={styles.param}>
                    {t('available')}:
                  </Typography>
                  <Typography variant="body2" className={styles.value}>
                    13.003454 ETH
                  </Typography>
                </div>
              </div>

              <div className={styles.union}>
                <Controller
                  name="receive"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <Input
                      label={t('receive')}
                      placeholder={t('amount_values.amount_to_receive')}
                      isNumeric
                      className={styles.input}
                      endAdornment={<EndAdornment label="PEPE" icon={IconName.PEPE} />}
                      {...field}
                    />
                  )}
                />
                <div className={cls(styles.infoContainer)}>
                  <div className={styles.info}>
                    <Typography variant="body2" className={styles.param}>
                      DEX:
                    </Typography>
                    <Typography variant="body2" className={styles.value}>
                      UNI V2
                    </Typography>
                  </div>
                </div>
              </div>
            </div>

            <Button type="submit">{t('save')}</Button>
          </div>
        </Grid>
      </form>
    </div>
  )
}

export { EditLimitBuy }
