import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid, TableCell, TableRow } from '@mui/material'
import cn from 'classnames'

import styles from '@/components/holdings-table/styles.module.scss'
import { SellPopup } from '@/components/sell-popup'
import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { useSelectNewToken } from '@/hooks/useSelectNewToken.hook'
import {
  Button,
  ButtonIcon,
  Icon,
  TOnChangeVisible,
  TokenPlaceholder,
  Typography,
} from '@/libs/common'
import { AppRoute, IconName } from '@/libs/enums'
import { formatNumber } from '@/libs/helper'
import { TActivePanelItem } from '@/libs/types/panels-data-socket-response.type'

type TProperty = {
  row: TActivePanelItem
  handleMarketSellAll: (tokenAddress: string) => Promise<void>
}

const Row: FC<TProperty> = ({ row, handleMarketSellAll }) => {
  const [sellPopup, setSellPopup] = useState<TOnChangeVisible>({
    visible: false,
    anchorE: undefined,
  })
  const { t } = useTranslation()

  const navigate = useCustomNavigate()
  const handleNewTokenSelection = useSelectNewToken()

  const handlePopUp = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setSellPopup({ visible: true, anchorE: event.currentTarget })
  }

  const handleChangeCurrentToken = () => {
    handleNewTokenSelection(row.ta)
  }

  const handleOpenShareProfitModal = () => {
    navigate({
      isDashboard: true,
      path: `${AppRoute.MODAL}/${AppRoute.SHARE_PROFIT}`,
      state: { positionsSummary: row },
    })
  }

  return (
    <TableRow className={styles.row} onClick={handleChangeCurrentToken}>
      <TableCell className={styles.cell}>
        <div className={styles.token}>
          {row.i && row.i.s ? (
            <img className={styles.tokenLogo} src={row.i.s} alt={row.tn + ' token'} />
          ) : (
            <TokenPlaceholder />
          )}
          <div>
            <Typography variant="body2" className={cn(styles.cellText, styles.name)}>
              {row.tn || t('token_list.token')}
            </Typography>
            <Typography variant="body2" textColor="color-white-3" className={styles.cellText}>
              {row.tos}
            </Typography>
          </div>
        </div>
      </TableCell>
      <TableCell className={styles.cell}>
        <div className={styles.currencyWrapper}>
          {/* <Icon name={IconName.ETH_CURRENCY} /> */}
          <Typography variant="body2" textColor="color-white-3" className={styles.cellText}>
            ${formatNumber(row.tc.u, 2).formatted}
          </Typography>
        </div>
      </TableCell>
      <TableCell className={styles.cell}>
        <div className={styles.currencyWrapper}>
          {/* <Icon name={IconName.ETH_CURRENCY} /> */}
          <Typography variant="body2" textColor="color-white-3" className={styles.cellText}>
            ${formatNumber(+row.tw.u).formatted}
          </Typography>
        </div>
        <Typography variant="caption" className={styles.cellText}>
          {formatNumber(row.tb).formatted}
        </Typography>
      </TableCell>
      <TableCell className={styles.cell}>
        <div className={styles.currencyWrapper}>
          {/* <Icon name={IconName.ETH_CURRENCY} /> */}
          <Typography variant="body2" textColor="color-white-3" className={styles.cellText}>
            ${formatNumber(row.ts.u).formatted}
          </Typography>
        </div>
      </TableCell>
      <TableCell className={styles.cell}>
        <Typography
          variant="body2"
          textColor="color-white-3"
          className={cn(styles.cellText, {
            [styles.greenText]: +row.tpl > 0,
            [styles.redText]: +row.tpl < 0,
          })}
        >
          {+row.tpl > 0 && '+'}
          {formatNumber(row.tpl).formatted}%
        </Typography>
      </TableCell>
      <TableCell className={cn(styles.cell, styles.sell)}>
        <Grid display="flex" alignItems="center">
          <Button
            className={styles.shareBtn}
            styleVariant="borderless-gold"
            iconStartName={IconName.SHARE}
            transparent
            onClick={(e) => {
              e.stopPropagation()
              handleOpenShareProfitModal()
            }}
          >
            {t('share')}
          </Button>
          <div className={styles.popupContainer}>
            <ButtonIcon onClick={handlePopUp}>
              <Icon name={IconName.SELL} />
              <Typography variant="body2" textColor="gold">
                {t('sell')}
              </Typography>
            </ButtonIcon>
            <SellPopup
              light
              onChangeVisible={setSellPopup}
              popoverState={sellPopup}
              tokenAddress={row.ta}
              onMarketSellAll={() => handleMarketSellAll(row.ta)}
            />
          </div>
        </Grid>
      </TableCell>
    </TableRow>
  )
}

export { Row }
