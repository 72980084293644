import { CandlestickData, HistogramData, ISeriesApi, Time, UTCTimestamp } from 'lightweight-charts'

import { getBars } from '@/api/chart'
import { createQueryString } from '@/libs/helper/createQueryString'
import { SniperSockerService } from '@/socket'
import { store } from '@/store'

import { RESOLUTION_MAP } from '../../../configs'

type CandleWithVolume = {
  candle: CandlestickData<Time>
  volume: HistogramData<Time>
}

type UpdateCallback = (data: CandleWithVolume) => void

class Datafeed {
  private barsSniperSocket: SniperSockerService
  private isWebsocketConnected = false
  private currentBars: CandleWithVolume[] = []
  private updateCallback: UpdateCallback | null = null
  private lastTokenAddress: string | null = null

  constructor(onUpdate?: UpdateCallback) {
    this.updateCallback = onUpdate || null
    this.barsSniperSocket = new SniperSockerService()
  }

  async connectWebsocket(): Promise<void> {
    if (this.isWebsocketConnected) return

    return new Promise((resolve, reject) => {
      this.barsSniperSocket.connect({
        endpoint: 'token/stream/bars',
        query: createQueryString({
          b: store.getState().chain.currentChain.indexerChainId,
        }),
        isPublic: true,
        onOpen: () => {
          this.isWebsocketConnected = true
          resolve()
        },
      })

      this.barsSniperSocket.onError(() => {
        this.isWebsocketConnected = false
        reject()
      })
    })
  }

  disconnect(): void {
    this.barsSniperSocket.disconnect()
    this.isWebsocketConnected = false
  }

  async fetchHistoricalData(
    tokenAddress: string,
    resolution: keyof typeof RESOLUTION_MAP,
    from: number,
    to: number,
  ): Promise<CandleWithVolume[]> {
    if (this.lastTokenAddress !== tokenAddress) {
      this.currentBars = []
    }

    this.lastTokenAddress = tokenAddress

    try {
      const bars = await getBars({
        from,
        to,
        resolution,
        tokenAddress,
        blockchain: store.getState().chain.currentChain.id,
      })

      const formattedBars = bars.map((bar) => ({
        candle: {
          time: (bar.time / 1000) as UTCTimestamp,
          high: Number(bar.high),
          low: Number(bar.low),
          open: Number(bar.open),
          close: Number(bar.close),
        },
        volume: {
          time: (bar.time / 1000) as UTCTimestamp,
          value: Number(bar.volume),
          color: Number(bar.close) >= Number(bar.open) ? '#26a69a' : '#ef5350',
        },
      }))

      if (formattedBars.length) {
        this.currentBars = [...formattedBars, ...this.currentBars]
      }

      return formattedBars
    } catch (error) {
      console.error('Error fetching historical data:', error)
      return []
    }
  }

  subscribeToRealtimeData(
    resolution: keyof typeof RESOLUTION_MAP,
    candlestickSeries: ISeriesApi<'Candlestick'>,
    volumeSeries: ISeriesApi<'Histogram'>,
  ): void {
    const currentToken = store.getState().chain.currentToken
    if (!currentToken) return

    this.barsSniperSocket.onMessage((jsonData) => {
      const { data } = JSON.parse(jsonData)
      const resolutionKey = RESOLUTION_MAP[resolution as keyof typeof RESOLUTION_MAP]

      if (data[resolutionKey]) {
        const ohlcvt = data[resolutionKey].u
        if (ohlcvt) {
          const candleData: CandleWithVolume = {
            candle: {
              time: ohlcvt.t as UTCTimestamp,
              high: Number(ohlcvt.h),
              low: Number(ohlcvt.l),
              open: Number(ohlcvt.o),
              close: Number(ohlcvt.c),
            },
            volume: {
              time: ohlcvt.t as UTCTimestamp,
              value: Number(ohlcvt.v),
              color: Number(ohlcvt.c) >= Number(ohlcvt.o) ? '#26a69a' : '#ef5350',
            },
          }

          this.currentBars = [...this.currentBars, candleData]
          candlestickSeries.update(candleData.candle)
          volumeSeries.update(candleData.volume)
          this.updateCallback?.(candleData)
        }
      }
    })

    const payload = {
      s: currentToken.pair.address,
      q: currentToken.info?.quote_token || '',
    }

    this.barsSniperSocket.emit(JSON.stringify(payload))
  }
}

export { Datafeed }
