import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid, IconButton } from '@mui/material'
import cn from 'classnames'

import { ProgressBar } from '@/components/profit-sell-tab/libs/components'
import stylesCardCommon from '@/components/profit-sell-tab/libs/components/styles-cards.module.scss'
import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { Button, Icon, Typography } from '@/libs/common'
import { AppRoute, IconName } from '@/libs/enums'
import { PositionDTO } from '@/libs/types'

import styles from './styles.module.scss'

type TProps = {
  isSellAll: boolean
  isSellInitial: boolean
  soldTokenData: {
    initialValue: string
    soldValue: string
    percent: string
  }
  tokenInfo: PositionDTO
  handleCloseSellMessage: () => void
  setIsSellInitial: (value: boolean) => void
}

const PositionSoldCard: FC<TProps> = ({
  isSellAll,
  isSellInitial,
  soldTokenData,
  tokenInfo,
  handleCloseSellMessage,
  setIsSellInitial,
}) => {
  const { t } = useTranslation()
  const navigate = useCustomNavigate()

  const handleOpenShareProfitModal = () => {
    navigate({
      isDashboard: true,
      path: `${AppRoute.MODAL}/${AppRoute.SHARE_PROFIT}`,
      state: { positionsSummary: tokenInfo },
    })
  }

  return (
    <Grid className={styles.soldCardContainer}>
      <IconButton className={styles.closeButton} onClick={handleCloseSellMessage}>
        <Icon name={IconName.CLOSE} />
      </IconButton>

      <Grid container justifyContent="center" gap={1}>
        <Grid container flexDirection="column" gap={1}>
          <Typography variant="body1" className={cn(styles.successDescription, styles.colorGreen)}>
            <Icon name={IconName.CHECK} />
            {t('sell_tab.position')} {isSellAll ? t('sell_tab.market') : t('sell_tab.initials')}{' '}
            {t('sell_tab.sold_successfully')}!
          </Typography>

          <Typography variant="body2" className={cn(styles.successDescription, styles.colorGrey)}>
            {soldTokenData.initialValue}

            <div className={styles.iconWrapper}>
              <Icon name={IconName.ARROW_RIGHT_GREY} />
            </div>

            {soldTokenData.soldValue}
          </Typography>
        </Grid>

        <Grid container direction="column" alignItems="center" gap={1}>
          <Typography variant="body2">
            <span
              className={cn(
                Number(soldTokenData.percent) > 0
                  ? stylesCardCommon.isProfit
                  : stylesCardCommon.isLoss,
              )}
            >
              {'%'}
              {soldTokenData.percent}
            </span>
          </Typography>

          <Button
            className={stylesCardCommon.headerButton}
            styleVariant="borderless-gold"
            iconStartName={IconName.SHARE}
            transparent
            onClick={handleOpenShareProfitModal}
          >
            {t('share')}
          </Button>
        </Grid>
      </Grid>

      <ProgressBar isSellInitial={isSellInitial} setIsSellInitial={setIsSellInitial} />
    </Grid>
  )
}

export { PositionSoldCard }
