import { FC } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Grid } from '@mui/material'
import cls from 'classnames'
import { t } from 'i18next'

import { Button, Icon, Input, InputWithRadioGroup, Typography } from '@/libs/common'
import { EndAdornment } from '@/libs/common/input/components/end-adornment'
import { IconName } from '@/libs/enums'
import { TSelectOption } from '@/libs/types/select-option.type'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

type TProps = {
  isDisable?: boolean
  buttonTitle?: string
}

const spendOptions: TSelectOption<number>[] = [
  {
    value: 0.25,
    label: '25%',
  },
  {
    value: 0.5,
    label: '50%',
  },
  {
    value: 0.75,
    label: '75%',
  },
  {
    value: 1,
    label: '100%',
  },
]

const defaultValues = {
  spend: '',
  receive: '',
}

const SpendReceiveAmount: FC<TProps> = ({ isDisable = false, buttonTitle = t('buy') }) => {
  const { t } = useTranslation()
  const { control } = useForm({ defaultValues })
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  const { iconName, chainSymbol } = currentChain

  return (
    <div className={styles.wrapper}>
      <Grid container className={styles.dividerWithArrow}>
        <div className={styles.dividerLine} />
        <Icon name={IconName.ARROW_DOWN_GREY} />
        <div className={styles.dividerLine} />
      </Grid>
      <div className={styles.container}>
        <div className={styles.spend}>
          <Controller
            name="spend"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <InputWithRadioGroup
                label={t('spend')}
                placeholder={t('amount_values.amount_to_spend')}
                isNumeric
                className={styles.input}
                onOptionSelect={(value) => field.onChange(value)}
                endAdornment={
                  <EndAdornment
                    label={chainSymbol}
                    icon={(IconName as any)[iconName]}
                    isDisabled={isDisable}
                  />
                }
                radioGroupName="amount"
                disabled={isDisable}
                options={spendOptions}
                {...field}
              />
            )}
          />
          <div className={styles.available}>
            <Typography variant="body2" className={styles.param}>
              {t('available')}:
            </Typography>
            <Typography variant="body2" className={styles.value}>
              13.003454 ETH
            </Typography>
          </div>
        </div>
        <div className={styles.union}>
          <Controller
            name="receive"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <Input
                label={t('receive')}
                placeholder={t('amount_values.amount_to_receive')}
                isNumeric
                className={styles.input}
                endAdornment={
                  <EndAdornment label="PEPE" icon={IconName.PEPE} isDisabled={isDisable} />
                }
                disabled={isDisable}
                {...field}
              />
            )}
          />
          <div className={cls(styles.infoContainer, { [styles.disabled]: isDisable })}>
            <div className={styles.info}>
              <Typography variant="body2" className={styles.param}>
                DEX:
              </Typography>
              <Typography variant="body2" className={styles.value}>
                UNI V2
              </Typography>
            </div>
            <div className={styles.info}>
              <Typography variant="body2" className={styles.param}>
                {t('token_info.minimum_received')}:
              </Typography>
              <Typography variant="body2" className={styles.value}>
                -
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <Button type="submit" disabled={isDisable}>
        {buttonTitle}
      </Button>
    </div>
  )
}

export { SpendReceiveAmount }
