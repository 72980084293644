import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useSearchParams } from 'react-router-dom'

import { Grid } from '@mui/material'
import cn from 'classnames'
import moment from 'moment'

import { claimAffiliatesFees, getMyAffiliatesInfo } from '@/api/affiliate'
import { TAffilitesData } from '@/api/affiliate/types'
import { CustomToast } from '@/components/custom-toast'
import { Spinner } from '@/components/spinner'
import { Typography, ValueWithCopyAndTitle } from '@/libs/common'
import { AppRoute, IconName, SpinnerSize } from '@/libs/enums'
import { formatNumber } from '@/libs/helper'
import { extractErrorDescription } from '@/libs/helper/extractErrorDescription'
import { useAppDispatch, useAppSelector } from '@/store'
import { fetchUserById } from '@/store/slices/user.slice'

import { UserNameForm } from '../user-name-form/user-name-form'
import { AffiliateStep } from './libs/components/affiliate-step'
import { AffiliateUserData } from './libs/components/affiliate-user-data'
import { AffiliatesAccordion } from './libs/components/affiliates-accordion'
import { OverviewItem } from './libs/components/overview-item'
import styles from './styles.module.scss'

const Affiliates = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const userData = useAppSelector((state) => state.user.userData)
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  const nativeTokeSymbol = currentChain.chainSymbol
  const { t } = useTranslation()

  const [affiliatesData, setAffiliatesData] = useState<TAffilitesData | null>(null)
  const [loading, setLoading] = useState(false)
  const [claimLoading, setClaimLoading] = useState(false)

  const dispatch = useAppDispatch()

  const handleUsernameUpdate = async () => {
    if (!userData) return
    await dispatch(fetchUserById(userData.user_id)).unwrap()
  }

  useEffect(() => {
    return () => {
      searchParams.delete('settingNav')
      searchParams.delete('dashboardNav')

      setSearchParams(searchParams)
    }
  }, [])

  useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        const { data } = await getMyAffiliatesInfo()
        setAffiliatesData(data.data)
      } catch (err) {
        extractErrorDescription(err)
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  const claim = async () => {
    try {
      setClaimLoading(true)
      await claimAffiliatesFees()
      CustomToast('success', 'settings.claim_success')
    } catch (err) {
      extractErrorDescription(err)
    } finally {
      setClaimLoading(false)
    }
  }

  if (loading) {
    return <Spinner size={SpinnerSize.MEDIUM} centered />
  }

  if (!affiliatesData) {
    return <Typography textAlign="center">{t('settings.no_data')}</Typography>
  }

  return (
    <Grid className={styles.wrapper}>
      <div className={styles.howToEarnWrapper}>
        <div className={styles.howToEarn}>
          <div className={styles.howToEarnHeader}>
            <Typography textColor="light-grey" className={styles.howToEarnTitle}>
              {t('settings.affiliate_steps.title')}
            </Typography>
            <Link to={`${AppRoute.MODAL}/${AppRoute.AFFILIATE_IMAGE}`} className={styles.learnMore}>
              {t('learn_more')}
            </Link>
          </div>
          <div className={styles.steps}>
            <AffiliateStep
              iconName={IconName.ARROW_RIGHT3}
              title={t('settings.affiliate_steps.step1')}
              index={1}
              isLast={false}
            />
            <AffiliateStep
              title={t('settings.affiliate_steps.step2')}
              index={2}
              isLast={false}
              iconName={IconName.ARROW_RIGHT3}
            />
            <AffiliateStep
              title={t('settings.affiliate_steps.step3')}
              index={3}
              isLast
              iconName={IconName.ARROW_RIGHT3}
            />
          </div>
        </div>
      </div>
      <UserNameForm
        username={userData?.username}
        usernameSet={userData?.username_set}
        onSuccess={handleUsernameUpdate}
        className={styles.form}
      />
      <div>
        <div className={cn(styles.content, styles.linksContainer)}>
          <ValueWithCopyAndTitle
            title={t('settings.web_ref_code')}
            value={`${window.location.origin}?affiliateId=${userData?.username}`}
          />
          <ValueWithCopyAndTitle
            title={t('settings.tg_ref_code')}
            value={`${import.meta.env.VITE_TG_URL}?start=ref_${userData?.username}`}
          />
        </div>
        <Grid className={cn(styles.content, styles.infoWrapper)}>
          <div className={styles.overview}>
            <OverviewItem
              title={t('settings.total_fees_pending')}
              value={
                <>
                  {formatNumber(affiliatesData.total_fees_pending_to_collect.native).formatted}{' '}
                  {nativeTokeSymbol}
                </>
              }
              additionalValue={
                <>(≈${formatNumber(affiliatesData.total_fees_pending_to_collect.usd).formatted})</>
              }
              iconName={IconName.ALARM}
              buttonTitle={t('settings.claim')}
              onButtonClick={claim}
              buttonLoading={claimLoading}
            />
            <OverviewItem
              title={t('settings.total_fees_collected')}
              value={
                <>
                  {formatNumber(affiliatesData.total_fees_collected.native).formatted}{' '}
                  {nativeTokeSymbol}
                </>
              }
              additionalValue={
                <>(≈${formatNumber(affiliatesData.total_fees_collected.usd).formatted})</>
              }
              iconName={IconName.FEES_COLLECTED}
            />
            <div className={styles.overviewGroup}>
              <OverviewItem
                title={t('settings.no_of_signups')}
                value={affiliatesData.number_of_signups}
                iconName={IconName.GROUP_OF_PEOPLE}
              />
              <OverviewItem
                title={t('settings.ref_trading_volume')}
                value={
                  <>
                    {formatNumber(affiliatesData.referrals_trading_volume.native).formatted}{' '}
                    {nativeTokeSymbol}
                  </>
                }
                iconName={IconName.CANDLESTICK}
                additionalValue={
                  <>(≈${formatNumber(affiliatesData.referrals_trading_volume.usd).formatted})</>
                }
              />
            </div>
            <div className={styles.overviewGroup}>
              <OverviewItem
                title={t('settings.commission_tier')}
                value={affiliatesData.comission_tier.name}
                iconName={IconName.TROPHY}
              />
              <OverviewItem
                title={t('settings.total_fees_earned')}
                value={
                  <>
                    {formatNumber(affiliatesData.total_fees_earned.native).formatted}{' '}
                    {nativeTokeSymbol}
                  </>
                }
                iconName={IconName.PRICE_CHECK}
                additionalValue={
                  <>(≈${formatNumber(affiliatesData.total_fees_earned.usd).formatted})</>
                }
              />
            </div>
          </div>

          {affiliatesData.snapshots.map((snapshot, index) => (
            <AffiliatesAccordion
              title={`Snapshot ${moment(snapshot.running_date).format('D/M/YYYY')}`}
              earnings={snapshot.total_fees_to_collect}
              status={snapshot.status}
              key={index}
            >
              <>
                {snapshot.groups.map((group) => (
                  <AffiliatesAccordion
                    key={group.order}
                    title={
                      <Grid display="flex" gap={0.5} alignItems="center">
                        Group {group.order} <Typography>({group.users.length})</Typography>
                      </Grid>
                    }
                    earnings={group.total_fees_to_collect}
                  >
                    <>
                      {group.users.map((user) => (
                        <AffiliatesAccordion
                          key={user.name}
                          title={user.name}
                          earnings={user.total_fees_to_collect}
                        >
                          <AffiliateUserData transactions={user.transactions} />
                        </AffiliatesAccordion>
                      ))}
                      {!group.users.length && (
                        <Typography textAlign="center">{t('settings.no_users_data')}</Typography>
                      )}
                    </>
                  </AffiliatesAccordion>
                ))}
                {!snapshot.groups.length && (
                  <Typography textAlign="center">{t('settings.no_groups_data')}</Typography>
                )}
              </>
            </AffiliatesAccordion>
          ))}

          {!affiliatesData.snapshots.length && (
            <Typography textAlign="center">{t('settings.no_snapshots_data')}</Typography>
          )}
        </Grid>
      </div>
    </Grid>
  )
}

export { Affiliates }
