import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Stack } from '@mui/material'

import { SelectWallet } from '@/components/select-wallet'
import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { Button } from '@/libs/common'
import { AppRoute } from '@/libs/enums'
import { useAppSelector } from '@/store'

const ExportTab: FC = () => {
  const { t } = useTranslation()
  const wallets = useAppSelector((state) => state.user.userWallets)
  const defaultWallet = wallets?.find((wallet) => wallet.is_default)
  const [wallet, setWallet] = useState<string[]>(defaultWallet ? [defaultWallet?.address] : [])
  const navigate = useCustomNavigate()

  const handleWalletChange = useCallback((value: string[]) => {
    setWallet(value)
  }, [])

  const handleClick = () => {
    localStorage.exporWallets = JSON.stringify(wallet)
    navigate({ isDashboard: true, path: `${AppRoute.MODAL}/${AppRoute.MASTER_PASSWORD}` })
  }

  return (
    <Stack spacing={3} useFlexGap position="relative">
      <SelectWallet
        tooltipInfo={t('wallet.select_wallets_for_export_tooltip')}
        isMulti
        wallet={wallet}
        onChange={handleWalletChange}
        isSelectDefaultWallet={true}
        wallets={wallets}
        label={t('wallet.select_wallets_for_export')}
      />

      <Button disabled={wallet.length === 0} onClick={handleClick} checkOnAccountLock>
        {t('wallet.export_wallets')}
      </Button>
    </Stack>
  )
}

export { ExportTab }
