import {
  Control,
  Controller,
  FieldErrors,
  UseFormReset,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'

import cn from 'classnames'

import { useWindowDimensions } from '@/hooks/useWindowDimensions'
import { Icon, Input, Tooltip, Typography } from '@/libs/common'
import { MAX_TRX_DECIMALS } from '@/libs/configs/transactions.config'
import { IconName, LocalStorageItem } from '@/libs/enums'
import { formatNumber } from '@/libs/helper'
import { convertScientificNotationNumber } from '@/libs/helper/convertScientificNotationNumber'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

interface TProps {
  control: Control<any>
  errors: FieldErrors
  setValue: UseFormSetValue<any>
  handleSubmit: (e: React.FormEvent) => void
  isEdit: boolean
  setIsEdit: (value: boolean) => void
  watch: UseFormWatch<any>
  reset: UseFormReset<any>
}

const QuickBuy = ({
  control,
  errors,
  setValue,
  handleSubmit,
  isEdit,
  setIsEdit,
  watch,
  reset,
}: TProps) => {
  const mainWallet = useAppSelector((state) => state.user.mainWallet)
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const quickBuyAmount = watch('amount')

  const { width: windowWidth } = useWindowDimensions()

  const handleCancel = () => {
    reset({
      amount: localStorage.getItem(LocalStorageItem.QUICK_BUY_AMOUNT) ?? '0.0',
    })

    setIsEdit(false)
  }

  return (
    <form onSubmit={handleSubmit}>
      {windowWidth < 768 ? (
        <div className={styles.mobile}>
          {/* <div className={cn(styles.quickBuy, styles.templates)}>
            <div className={styles.quickBuyLabel}>
            <Icon
              className={styles.electricBoltIcon}
              width={20}
              height={20}
              name={IconName.ARTICLE_}
              color="#d1d1d1"
            />
            <Typography variant="body1" textColor="color-grey-3">
              Template
            </Typography>
            <div className={styles.divider} />
          </div>
          <TemplateSelect />
        </div> */}
          <div className={styles.quickBuyContainer}>
            <div className={cn(styles.quickBuy, styles.quickBuyInput)}>
              <div className={styles.quickBuyLabel}>
                <Icon
                  className={styles.electricBoltIcon}
                  width={20}
                  height={20}
                  name={IconName.ELECTRIC_BOLT_OUTLINED_}
                  color="#d1d1d1"
                />
                <Tooltip withIcon={true} title={'Quick buy will be done from main wallet'}>
                  <Typography variant="body1" textColor="color-grey-3">
                    Quick buy
                  </Typography>
                </Tooltip>
                {/* <div className={styles.divider} /> */}
              </div>
              <Controller
                name="amount"
                control={control}
                defaultValue={quickBuyAmount}
                render={({ field }) => (
                  <Input
                    {...field}
                    onFocus={() => setIsEdit(true)}
                    placeholder="2.3"
                    className={styles.input}
                    spacing={0}
                    error={!!errors.amount}
                    startAdornment={
                      <div className={styles.startAdornment}>
                        <Icon name={IconName.BASE_CHAIN} height={20} width={20} />{' '}
                      </div>
                    }
                    onChange={(e: any) => {
                      const value = convertScientificNotationNumber(
                        e.target.value,
                        MAX_TRX_DECIMALS,
                        true,
                      )
                      setValue('amount', `${value}`, {
                        shouldValidate: !!value,
                      })
                    }}
                  />
                )}
              />
            </div>

            <Typography variant="caption" textColor="color-grey-6" className={styles.balance}>
              Balance:{' '}
              {mainWallet?.balanceFormatted
                ? formatNumber(mainWallet?.balanceFormatted).formatted
                : 0}{' '}
              {currentChain.chainSymbol}
            </Typography>
          </div>
          {isEdit && (
            <div className={cn(styles.actions, styles.actionsMobile)}>
              <button
                type="button"
                aria-label="Close"
                className={styles.action}
                onClick={handleCancel}
              >
                <Icon width={20} height={20} name={IconName.CANCEL} color="#979797" />
              </button>
              <button type="submit" aria-label="Save" className={styles.action}>
                <Icon width={20} height={20} name={IconName.CHECK} />
              </button>
            </div>
          )}
        </div>
      ) : (
        <div className={styles.desktop}>
          <div>
            <div className={styles.field}>
              <div className={styles.label}>
                <Icon
                  name={IconName.ELECTRIC_BOLT_OUTLINED_}
                  width={20}
                  height={20}
                  color="#d1d1d1"
                />
                <Tooltip withIcon={true} title={'Quick buy will be done from main wallet'}>
                  <Typography textColor="light-grey">Quick buy</Typography>
                </Tooltip>
              </div>
              <Controller
                name="amount"
                control={control}
                defaultValue={quickBuyAmount}
                render={({ field }) => (
                  <Input
                    {...field}
                    isNumeric
                    onFocus={() => setIsEdit(true)}
                    placeholder="2.3"
                    className={styles.input}
                    spacing={0}
                    error={!!errors.amount}
                    startAdornment={
                      <div className={styles.startAdornment}>
                        <Icon name={IconName.BASE_CHAIN} width={20} height={20} />{' '}
                      </div>
                    }
                    onChange={(e: any) => {
                      const value = convertScientificNotationNumber(
                        e.target.value,
                        MAX_TRX_DECIMALS,
                        true,
                      )
                      setValue('amount', `${value}`, {
                        shouldValidate: !!value,
                      })
                    }}
                  />
                )}
              />
            </div>
            <Typography variant="caption" textColor="color-grey-6" className={styles.balance}>
              Balance:{' '}
              {mainWallet?.balanceFormatted
                ? formatNumber(mainWallet?.balanceFormatted).formatted
                : 0}{' '}
              {currentChain.chainSymbol}
            </Typography>
          </div>
          {isEdit && (
            <div className={styles.actions}>
              <button
                type="button"
                aria-label="Close"
                className={styles.action}
                onClick={handleCancel}
              >
                <Icon width={20} height={20} name={IconName.CANCEL} color="#979797" />
              </button>
              <button type="submit" aria-label="Save" className={styles.action}>
                <Icon width={20} height={20} name={IconName.CHECK} />
              </button>
            </div>
          )}
          <div className={styles.verticalDivider} />
          {/* <div className={cn(styles.field, styles.fieldTemplate)}>
          <div className={styles.label}>
            <Icon name={IconName.ARTICLE_} width={20} height={20} color="#d1d1d1" />
            <Typography textColor="white">Template</Typography>
          </div>
          <TemplateSelect />
        </div> */}
        </div>
      )}
    </form>
  )
}

export { QuickBuy }
