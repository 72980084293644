import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { getFilterTokens, getTokenExchangesAndSocial } from '@/api/tokens'
import { TTokenTransactionFiltered } from '@/api/tokens/types'
import { chainsConfig } from '@/libs/configs/chains.config'
import { ESorting } from '@/libs/enums/sorting.enum'
import { setLastVisitedToken } from '@/libs/helper/lastVisitedToken'
import { TChainConfig } from '@/libs/types/chain.type'
import { TSniperSimulationData } from '@/libs/types/sniper-simulation-socket-response.type'
import { TSniperBasicInfo } from '@/libs/types/sniper-socket-responses.type'
import { TTokenInfo } from '@/libs/types/token-info-response.type'
import { TRootState } from '@/store'
import { adaptSimulation } from '@/utils/adapt-simulation'
import { createAsyncThunkWithControll } from '@/utils/requestsController'

type TTransactionsObj = {
  sorting: ESorting
  token: string
  data: TTokenTransactionFiltered[]
  lastEntry?: TTokenTransactionFiltered[]
  cursor?: string
} | null

type TInitialState = {
  currentChain: TChainConfig & { nativeTokenPriceInUsd?: number }
  currentChainBlock: string | null
  currentGas: string | null
  currentToken: TTokenInfo | null
  adaptedCurrentTokenSimulationWebsocket: ReturnType<typeof adaptSimulation> | null
  selectedTokenAddress: string | null
  transactions: TTransactionsObj
  myTransactions: TTransactionsObj
}

const initialState: TInitialState = {
  currentChain: chainsConfig[0],
  currentChainBlock: null,
  currentGas: null,
  currentToken: null,
  adaptedCurrentTokenSimulationWebsocket: null, // From now on we only use this for simulation
  selectedTokenAddress: null,
  transactions: null,
  myTransactions: null,
}

const { thunk: fetchNewCurrentToken, applyExtraReducers: applyExtraReducersForNewCurrentToken } =
  createAsyncThunkWithControll(
    'chain/fetchNewCurrentToken',
    async (tokenAddress: string, { getState }) => {
      const {
        currentChain: { id: currentChainId },
      } = (getState() as TRootState).chain
      const [currentToken, { data }] = await Promise.all([
        getFilterTokens(tokenAddress, currentChainId),
        getTokenExchangesAndSocial(tokenAddress, currentChainId),
      ])
      const socialLinks = { ...data?.data.token.social }
      const statistics = { ...data?.data.statistics }
      const info = { ...data?.data.token }

      setLastVisitedToken(currentChainId, tokenAddress)

      return { ...currentToken.data.data[0], socialLinks, statistics, info }
    },
  )

const chainSlice = createSlice({
  name: 'chain',
  initialState,
  reducers: {
    setCurrentChain: (state, { payload }: PayloadAction<TChainConfig>) => {
      state.currentChain = payload
    },
    setCurrentToken: (state, { payload }: PayloadAction<TTokenInfo | null>) => {
      state.currentToken = payload
    },
    setSelectedTokenAddress: (state, { payload }: PayloadAction<string | null>) => {
      state.selectedTokenAddress = payload
    },
    setCurrentChainBasicInfo: (state, { payload }: PayloadAction<TSniperBasicInfo>) => {
      state.currentChainBlock = payload.cb.n
      state.currentGas = payload.cb.f
      const usdPrice = +payload.p
      state.currentChain.nativeTokenPriceInUsd =
        usdPrice >= 1
          ? +usdPrice
              .toLocaleString('en-US', {
                maximumFractionDigits: 2,
              })
              .replace(/,/g, '')
          : usdPrice
    },
    setCurrentTokenSimulationWebsocket: (
      state,
      { payload }: PayloadAction<TSniperSimulationData>,
    ) => {
      state.adaptedCurrentTokenSimulationWebsocket = adaptSimulation(payload)
    },
    setTransactions: (state, { payload }: PayloadAction<TTransactionsObj>) => {
      state.transactions = payload
    },
    setMyTransactions: (state, { payload }: PayloadAction<TTransactionsObj>) => {
      state.myTransactions = payload
    },
    setTransactionsFromWebSocket: (
      state,
      { payload }: PayloadAction<TTokenTransactionFiltered[]>,
    ) => {
      if (state.transactions?.sorting === ESorting.DESC) {
        state.transactions.data = [...payload, ...state.transactions.data]
        state.transactions.lastEntry = payload
      }
    },
    setMyTransactionsFromWebSocket: (
      state,
      { payload }: PayloadAction<TTokenTransactionFiltered[]>,
    ) => {
      if (state.myTransactions?.sorting === ESorting.DESC) {
        state.myTransactions.data = [...payload, ...state.myTransactions.data]
        state.myTransactions.lastEntry = payload
      }
    },
  },
  extraReducers: (builder) => {
    applyExtraReducersForNewCurrentToken(builder, {
      onFulfilled: (state, { payload }) => {
        if (payload) state.currentToken = payload
      },
    })
  },
})

const {
  setCurrentChain,
  setCurrentToken,
  setCurrentChainBasicInfo,
  setCurrentTokenSimulationWebsocket,
  setSelectedTokenAddress,
  setTransactions,
  setMyTransactions,
  setTransactionsFromWebSocket,
  setMyTransactionsFromWebSocket,
} = chainSlice.actions

export {
  chainSlice,
  // Thunks
  fetchNewCurrentToken,
  // Reducers
  setCurrentChain,
  setCurrentChainBasicInfo,
  setCurrentToken,
  setCurrentTokenSimulationWebsocket,
  setMyTransactions,
  setMyTransactionsFromWebSocket,
  setSelectedTokenAddress,
  setTransactions,
  setTransactionsFromWebSocket,
}
