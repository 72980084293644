import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import cn from 'classnames'

import tableStyles from '@/components/pending-orders/table-styles.module.scss'
import { Spinner } from '@/components/spinner'
import { ButtonIcon, Icon, Typography } from '@/libs/common'
import { IconName, Sorting, SpinnerSize } from '@/libs/enums'
import { ValueOf } from '@/libs/types'
import { TOrderHistory } from '@/libs/types/order.type'
import { useAppSelector } from '@/store'

import { Row } from './libs/components/row'
import styles from './styles.module.scss'

type TProperty = {
  rows: TOrderHistory[] | null
  loading: boolean
}

const OrderHistoryTable: FC<TProperty> = ({ rows, loading }) => {
  const { t } = useTranslation()
  const userData = useAppSelector((state) => state.user.userData)
  const [sorting, setSorting] = useState<ValueOf<typeof Sorting>>(Sorting.DESC)
  const [keySort, setKeySorting] = useState('d')
  const handleSort = (key: string) => {
    setKeySorting(key)
    setSorting((sorting) => {
      return sorting === Sorting.ASC && key === keySort ? Sorting.DESC : Sorting.ASC
    })
  }

  const sortedRows = useMemo(() => {
    return rows
      ?.filter((row) => +row.ba !== 0)
      .sort((a: any, b: any) => {
        const aValue = a[keySort]?.u ?? a[keySort]
        const bValue = b[keySort]?.u ?? b[keySort]

        const dateA = new Date(aValue)
        const dateB = new Date(bValue)

        if (!isNaN(dateA.getTime()) && !isNaN(dateB.getTime())) {
          return sorting === Sorting.ASC
            ? dateA.getTime() - dateB.getTime()
            : dateB.getTime() - dateA.getTime()
        }

        if (typeof aValue === 'string' && typeof bValue === 'string') {
          return sorting === Sorting.ASC
            ? aValue.localeCompare(bValue)
            : bValue.localeCompare(aValue)
        }

        const numA = parseFloat(aValue)
        const numB = parseFloat(bValue)

        if (!isNaN(numA) && !isNaN(numB)) {
          return sorting === Sorting.ASC ? numA - numB : numB - numA
        }

        return 0
      })
  }, [rows, sorting, keySort])

  return (
    <TableContainer className={styles.container}>
      {!loading ? (
        sortedRows?.length ? (
          <Table
            classes={tableStyles}
            aria-label="collapsible table"
            size="small"
            className={tableStyles.container}
          >
            <TableHead>
              <TableRow className={cn(tableStyles.tableHead, styles.headRow)}>
                <TableCell
                  className={cn(tableStyles.cell, tableStyles.headCell, styles.expandIcon)}
                />
                <TableCell
                  className={cn(
                    tableStyles.cell,
                    tableStyles.headCell,
                    tableStyles.icon,
                    styles.wallet,
                  )}
                  align="center"
                >
                  <Icon name={IconName.WALLET} />
                </TableCell>

                <TableCell
                  className={cn(tableStyles.cell, tableStyles.headCell, styles.type)}
                  align="center"
                >
                  <div>
                    {t('token_info.type')}
                    <ButtonIcon
                      icon={
                        keySort === 'ty' && sorting === Sorting.ASC
                          ? IconName.SORT_ASC
                          : IconName.SORT_DESC
                      }
                      onClick={() => handleSort('ty')}
                    />
                  </div>
                </TableCell>

                <TableCell className={cn(tableStyles.cell, tableStyles.headCell, styles.token)}>
                  <div>
                    {t('token_list.token')}
                    <ButtonIcon
                      icon={
                        keySort === 'tn' && sorting === Sorting.ASC
                          ? IconName.SORT_ASC
                          : IconName.SORT_DESC
                      }
                      onClick={() => handleSort('tn')}
                    />
                  </div>
                </TableCell>

                <TableCell className={cn(tableStyles.cell, tableStyles.headCell, styles.amount)}>
                  <div>
                    {t('token_info.amount')}
                    <ButtonIcon
                      icon={
                        keySort === 'tam' && sorting === Sorting.ASC
                          ? IconName.SORT_ASC
                          : IconName.SORT_DESC
                      }
                      onClick={() => handleSort('tam')}
                    />
                  </div>
                </TableCell>

                <TableCell className={cn(tableStyles.cell, tableStyles.headCell, styles.date)}>
                  <div>
                    {t('token_list.date')}
                    <ButtonIcon
                      icon={
                        keySort === 'd' && sorting === Sorting.ASC
                          ? IconName.SORT_ASC
                          : IconName.SORT_DESC
                      }
                      onClick={() => handleSort('d')}
                    />
                  </div>
                </TableCell>

                <TableCell className={cn(tableStyles.cell, tableStyles.headCell, styles.status)}>
                  <div>
                    {t('token_info.status')}
                    <ButtonIcon
                      icon={
                        keySort === 's' && sorting === Sorting.ASC
                          ? IconName.SORT_ASC
                          : IconName.SORT_DESC
                      }
                      onClick={() => handleSort('s')}
                    />
                  </div>
                </TableCell>

                <TableCell className={cn(tableStyles.cell, tableStyles.headCellWidth)}></TableCell>
              </TableRow>
            </TableHead>

            <TableBody className={tableStyles.tableBody}>
              {sortedRows.map((row, index) => (
                <Row key={row.id} row={row} index={index} />
              ))}
            </TableBody>
          </Table>
        ) : (
          <Typography variant="body1" textColor="color-grey-4" className={styles.message}>
            {t('token_info.no_orders')}
          </Typography>
        )
      ) : (
        <div className={styles.message}>
          {userData ? (
            <Spinner size={SpinnerSize.MEDIUM} centered />
          ) : (
            <Typography variant="body1" textColor="color-grey-4" className={styles.message}>
              {t('token_info.no_orders')}
            </Typography>
          )}
        </div>
      )}
    </TableContainer>
  )
}

export { OrderHistoryTable }
