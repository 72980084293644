import { FC, PropsWithChildren } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { Modal, ModalProps } from '@mui/material'

import { Icon, Typography } from '@/libs/common'
import { IconName } from '@/libs/enums'

import styles from './styles.module.scss'

type TProps = PropsWithChildren &
  ModalProps & {
    open: boolean
    name: string
    setOpen?: (value: boolean) => void
    title?: string
    message?: string
    hasCloseButton?: boolean
  }

const ModalComponent: FC<TProps> = ({
  name,
  title,
  message,
  open,
  setOpen,
  children,
  hasCloseButton = false,
  ...rest
}) => {
  return (
    <Modal open={open} disableEnforceFocus onClose={() => setOpen?.(false)} {...rest}>
      <div className={styles.wrapper}>
        <TransitionGroup exit={false}>
          <CSSTransition
            key={name}
            classNames={{
              enter: styles.animationEnter,
              enterActive: styles.animationEnterActive,
              appear: styles.animationAppear,
              appearActive: styles.animationAppearActive,
            }}
            timeout={300}
            in
            appear
          >
            <div id={name} className={styles.container}>
              {hasCloseButton ? (
                <button type="button" className={styles.close} onClick={() => setOpen?.(false)}>
                  <Icon name={IconName.CLOSE} />
                </button>
              ) : null}
              {!!title && (
                <Typography variant="h1" align="center" className={styles.title}>
                  {title}
                </Typography>
              )}
              {!!message && (
                <Typography align="center" variant="body1" className={styles.message}>
                  {message}
                </Typography>
              )}

              {children}
            </div>
          </CSSTransition>
        </TransitionGroup>
      </div>
    </Modal>
  )
}

export { ModalComponent }
