import { FC, useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@mui/material'
import * as yup from 'yup'

import { createBuyTemplate, updateBuyTemplate } from '@/api/templates'
import { CustomToast } from '@/components/custom-toast'
import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { Button, Input, Tabs } from '@/libs/common'
import { EManualSettingsSection, ETemplateType, ManualBuyTemplateAction } from '@/libs/enums'
import { EBuyMode } from '@/libs/enums/buy-mode.enum.ts'
import { createBuyFormStateFromTemplate } from '@/libs/helper/buy/createBuyFormStateFromTemplate'
import { createManualBuyPayload } from '@/libs/helper/buy/createManualBuyPayload'
import { extractErrorDescription } from '@/libs/helper/extractErrorDescription'
import { validateRequiredFields } from '@/libs/helper/validateRequiredFields'
import { TBuyTemplate } from '@/libs/types/template'
import { TWallet } from '@/libs/types/transaction'
import { getManualBuyValidationSchema } from '@/libs/validations/buy'
import { getTemplateActionTitle } from '@/pages/modal-page/libs/helpers'
import { useModal } from '@/pages/modal-page/modal-page'
import { useAppDispatch, useAppSelector } from '@/store'
import { setUserBuyTemplates } from '@/store/slices/user.slice'
import { ANALYTICS_EVENTS, sendAnalyticsEvent } from '@/utils/analytics'

import { ManualTemplateBuyTab } from './components/buy-tab'
import { ManualTemplateSafetyTab } from './components/safety-tab'
import { ManualTemplateSellTab } from './components/sell-tab/sell-tab'
import styles from './styles.module.scss'

const shieldsDefaultValues = {
  buy_tax: '',
  buy_amount: '',
  sell_tax: '',
  minimum_liquidity: '',
  maximum_liquidity: '',
  maximum_market_cap: '',
}

const ManualBuyTemplate: FC = () => {
  const { t } = useTranslation()
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const userData = useAppSelector((state) => state.user.userData)
  const buyTemplates = useAppSelector((state) => state.user.userTemplates.buyTemplates)
  const userWallets = useAppSelector((state) => state.user.userWallets)
  const defaultUserPriorities = useAppSelector((state) => state.user.defaultPriorities)

  const { chainName, iconName, chainSymbol } = currentChain

  const [isPercentageUnit, setIsPercentageUnit] = useState(false)

  const dispatch = useAppDispatch()

  const navigate = useCustomNavigate()

  const { id: templateId } = useParams()

  const { setModalProps } = useModal()

  const templateToEdit = useMemo(
    () => buyTemplates?.find((item) => item.id === templateId),
    [buyTemplates],
  )

  const [isLoading, setIsLoading] = useState(false)
  const [currentTab, setCurrentTab] = useState(0)
  const [isContinueDisabled, setIsContinueDisabled] = useState(true)
  const [isInit, setIsInit] = useState(true)

  const schema = yup.object({
    name: yup.string().required().min(4).max(64),
    template: getManualBuyValidationSchema(0, { excludeBuyFields: true }),
  })
  const advancedBuyDefaultValues = {
    buyPriority: defaultUserPriorities?.buy_priority ?? '',
    approvePriority: defaultUserPriorities?.approve_priority ?? '',
    bribeAmount: defaultUserPriorities?.bribe_amount ?? '',
    minPercentTokenOrFail: '',
    maxTxOrFail: false,
  }
  const defaultWallet = !userWallets ? null : userWallets.find((wallet) => wallet.is_default)
  const ordinaryBuyDefaultValues = useMemo(() => {
    return {
      privateTransaction: !currentChain.features?.noPrivateTx,
      degenChadMode: false,
      slippage: currentChain.defaultValues.slippage,
      selectedWallets: defaultWallet ? [defaultWallet.address] : [],
      // onLimit: {
      //   dip: '',
      //   marketcap: '',
      //   price: '',
      //   triggerPricePercent: '',
      //   expiration: '',
      // },
      buy_amount: '',
    }
  }, [userWallets])

  const defaultValues = {
    name: '',
    template: {
      ordinaryBuy: ordinaryBuyDefaultValues,
      advancedBuy: advancedBuyDefaultValues,
      shields: shieldsDefaultValues,
      transferOnBlacklist: false,
      antiBlacklist: false,
      antiRug: false,
      antiRugThreshold: 0,
      sellRugExtraTip: '',
      autoSell: false,
      sellPriority: '',
      walletsToSellOnProfit: [] as string[],
      walletsToSellOnLoss: [] as string[],
      walletsToSellOnStopLoss: [] as string[],
      stopLoss: '',
      stopLossAmount: '',
      sellOnProfit: '',
      sellOnProfitAmount: '',
      walletsToTrailingSell: [],
      trailingSell: '',
    },
  }
  const formMethods = useForm({
    defaultValues:
      !!templateId && !!templateToEdit
        ? createBuyFormStateFromTemplate(templateToEdit)
        : defaultValues,
    resolver: yupResolver(schema) as any,
  })
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = formMethods

  const fieldsRequiredForBuy = watch([
    'template.ordinaryBuy.slippage',
    'template.ordinaryBuy.selectedWallets',
  ])
  const selectedWalletAddresses = watch('template.ordinaryBuy.selectedWallets')
  const degenChadMode = watch('template.ordinaryBuy.degenChadMode')
  const templateTabs = useMemo(() => {
    const tabs = [
      {
        label: t(`buy_tab.${EManualSettingsSection.BUY}`),
        content: (
          <ManualTemplateBuyTab
            chainName={chainName}
            templateId={templateId}
            isInit={isInit}
            setIsPercentageUnit={setIsPercentageUnit}
            isPercentageUnit={isPercentageUnit}
          />
        ),
      },
      {
        isDisabled: isContinueDisabled,
        label: t(`buy_tab.${EManualSettingsSection.SAFETY}`),
        content: (
          <ManualTemplateSafetyTab
            chainName={chainName}
            iconName={iconName}
            chainSymbol={chainSymbol}
            templateId={templateId}
          />
        ),
      },
    ]

    if (currentChain.features?.blackListSell) {
      // TODO: Refactor this tab as its values are not being used
      tabs.push({
        isDisabled: isContinueDisabled,
        label: EManualSettingsSection.SELL,
        content: <ManualTemplateSellTab selectedWalletAddresses={selectedWalletAddresses} />,
      })
    }

    return tabs
  }, [
    currentChain,
    currentTab,
    templateId,
    isInit,
    isContinueDisabled,
    selectedWalletAddresses,
    setIsPercentageUnit,
    isPercentageUnit,
  ])

  const isLastMainTab = currentTab === templateTabs.length - 1

  useEffect(() => {
    const action = templateId ? ManualBuyTemplateAction.EDIT : ManualBuyTemplateAction.ADD
    setModalProps({
      title: t(getTemplateActionTitle(action)),
      titleProps: { className: styles.title },
      withBackButton: true,
    })
  }, [templateId])

  useEffect(() => {
    const buyMode = templateToEdit?.setup?.operation?.setup?.buy_mode
    setIsPercentageUnit(buyMode === EBuyMode.MIN_PERCENT)
  }, [templateId])

  useEffect(() => {
    setIsContinueDisabled(validateRequiredFields(fieldsRequiredForBuy))
  }, [fieldsRequiredForBuy])

  useEffect(() => {
    setIsInit(() => false)
  }, [])

  const handleChangeMainTab = (value: number) => {
    setCurrentTab(value)
  }

  const onSubmit: SubmitHandler<typeof defaultValues> = async (data) => {
    if (!userWallets || !userData) return

    try {
      setIsLoading(true)

      const wallets: TWallet[] = []
      data.template.ordinaryBuy.selectedWallets.forEach((publicKey) => {
        const wallet = userWallets.find((item) => item.address === publicKey)

        if (wallet) {
          wallets.push({
            name: wallet.name,
            public_key: wallet.address,
          })
        }
      })

      const templateSetup = createManualBuyPayload({
        data: data.template,
        wallets,
        isTemplate: true,
        percentMode: isPercentageUnit,
        degenMode: !!currentChain.features?.degenMode && degenChadMode,
      })

      if (!templateSetup) {
        throw new Error('Failed to create template')
      }

      const payload = {
        blockchain: templateSetup.network.blockchain,
        name: data.name,
        template: {
          type: ETemplateType.MANUAL_BUY,
          setup: templateSetup,
        },
      }

      let newTemplatesList: TBuyTemplate[] = []

      if (templateId) {
        if (payload.template.setup?.network && templateToEdit?.setup.network) {
          payload.template.setup.network.blockchain = templateToEdit.setup?.network.blockchain
          payload.blockchain = templateToEdit.setup.network.blockchain
        }
        const { data } = await updateBuyTemplate(templateId, payload as any)
        newTemplatesList = data.data
        sendAnalyticsEvent(ANALYTICS_EVENTS.EDIT_MANUAL_BUY_TEMPLATE)
      } else {
        const { data } = await createBuyTemplate(payload as any)
        sendAnalyticsEvent(ANALYTICS_EVENTS.CREATE_MANUAL_BUY_TEMPLATE)
        newTemplatesList = data.data
      }

      dispatch(setUserBuyTemplates(newTemplatesList))

      navigate({ isDashboard: true })

      CustomToast(
        'success',
        templateId ? 'toast.success.template_edited' : 'toast.success.template_created',
      )
    } catch (err) {
      extractErrorDescription(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <FormProvider {...formMethods}>
      <Grid className={styles.content}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container rowGap={3}>
            <Grid className={styles.settings} container rowGap={0.5}>
              <Controller
                name="name"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <Input
                    label={t('template.template_name')}
                    placeholder={t('template.enter_template_name')}
                    className={styles.input}
                    error={!!errors.name?.message}
                    maxLength={64}
                    {...field}
                  />
                )}
              />

              <Tabs value={currentTab} handleChangeTab={handleChangeMainTab} tabs={templateTabs} />
            </Grid>
            <Grid container columnGap={3} flexWrap="nowrap">
              <Button
                styleVariant="black"
                onClick={() => handleChangeMainTab(currentTab + 1)}
                disabled={isContinueDisabled || isLastMainTab}
              >
                {t('continue')}
              </Button>
              <Button
                type="submit"
                disabled={!isLastMainTab}
                isLoading={isLoading}
                checkOnAccountLock
              >
                {templateId ? t('save') : t('add')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </FormProvider>
  )
}

export { ManualBuyTemplate }
