import { type ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'

import cls from 'classnames'

import { ButtonIcon, Icon, Typography } from '@/libs/common'
import { ChainName, IconName } from '@/libs/enums'
import { useAppSelector } from '@/store'

import { NewTokens } from './libs/new-tokens'
import { PumpVision } from './libs/pump-vision'
import { Trending } from './libs/trending/trending'
import styles from './styles.module.scss'

type NavTab = {
  value: 'trending' | 'new' | 'pump_vision'
  label: string
  icon: IconName | null
}

const tabs = {
  trending: <Trending />,
  new: <NewTokens />,
  pump_vision: <PumpVision />,
} satisfies Record<NavTab['value'], ReactNode>

const TokenList = () => {
  const { t } = useTranslation()

  const navTabs = [
    {
      value: 'trending',
      label: t('token_list.trending'),
      icon: IconName.FIRE,
    },
    {
      value: 'new',
      label: t('token_list.new'),
      icon: IconName.BOLT,
    },
    {
      value: 'pump_vision',
      label: 'Virtual Vision',
      // icon: IconName.PUMP_FUN_,
      icon: null,
    },
  ] satisfies NavTab[]

  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const [currentTab, setCurrentTab] = useState(navTabs[0].value)

  return (
    <div className={styles.container}>
      <div className={styles.nav}>
        {navTabs.map(({ value, label, icon }) => {
          if (value === 'pump_vision' && currentChain.chainName !== ChainName.BASE) return null
          return (
            <ButtonIcon
              className={cls(styles.navElement, value === currentTab && styles.active)}
              key={value}
              onClick={() => setCurrentTab(value)}
            >
              {icon && <Icon width={20} height={20} name={icon} />}
              <Typography variant="body1" textColor="light-grey">
                {label}
              </Typography>
            </ButtonIcon>
          )
        })}
      </div>
      {tabs[currentTab as keyof typeof tabs]}
    </div>
  )
}

export { TokenList }
