import { FC } from 'react'

import cn from 'classnames'

import { Icon } from '@/libs/common'
import { Button, TButtonProps } from '@/libs/common/button/button'
import { IconName } from '@/libs/enums'
import { ValueOf } from '@/libs/types'

import styles from './styles.module.scss'

type TQuickBuyButtonProps = Omit<TButtonProps, 'styleVariant' | 'children'> & {
  amount?: string
  iconName: ValueOf<typeof IconName>
  borderColor: string
  isLoading: boolean
}

const QuickBuyButton: FC<TQuickBuyButtonProps> = ({
  amount,
  iconName,
  className,
  borderColor,
  isLoading,
  ...props
}) => {
  return (
    <Button
      styleVariant="black"
      disabled={isLoading}
      className={cn(styles.quickBuyButton, className)}
      style={borderColor ? { borderColor } : undefined}
      {...props}
    >
      <div className={styles.content}>
        <Icon height={20} width={20} name={IconName.BOLT} color="#FFC800" />
        {iconName && (
          <Icon height={20} width={20} name={IconName[iconName as keyof typeof IconName] as any} />
        )}
        <span className={styles.amount}>{amount}</span>
      </div>
    </Button>
  )
}

export { QuickBuyButton }
