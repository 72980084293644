import { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useParams, useSearchParams } from 'react-router-dom'

import { Grid, InputLabel } from '@mui/material'
import cls from 'classnames'
import { t } from 'i18next'

import { BuyWithSimulation } from '@/components/buy-with-simulation'
import { SelectWallet } from '@/components/select-wallet'
import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import {
  Button,
  ButtonGroupRadio,
  ButtonGroupRadioButton,
  HiddenInputs,
  Input,
  StepInput,
  Tabs,
  Typography,
} from '@/libs/common'
import { ControlledAccordion } from '@/libs/common/controlled-accordion'
import { OptionalInput } from '@/libs/common/optional-input'
import { PercentsInput } from '@/libs/common/percents-input'
import { SwitchInline } from '@/libs/common/switch-inline'
import { TooltipIcon } from '@/libs/common/tooltip-icon'
import { EManualSettingsSection, SnipeTemplateAction } from '@/libs/enums'
import { getTemplateActionTitle } from '@/pages/modal-page/libs/helpers'
import { useModal } from '@/pages/modal-page/modal-page'
import { useAppSelector } from '@/store'

import { SNIPE_TEMPLATE_CHAIN_SETTINGS } from './libs/constants'
import styles from './styles.module.scss'

const tabs = ['Buy', 'Safety', 'Sell']
const profitTabs = ['Sell in steps', 'Sell on profit', 'Trailing sell']

const SnipeTemplate = () => {
  const [mainTab, setMainTab] = useState(0)
  const userWallets = useAppSelector((state) => state.user.userWallets)
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const [profitTab, setProfitTab] = useState<string>(profitTabs[0])
  const chainName = useAppSelector((state) => state.chain.currentChain.chainName)
  const [isFirstBundle, setIsFirstBundle] = useState(false)
  const [isBackupBundle, setIsBackupBundle] = useState(false)

  const [isAutoSell, setIsAutoSell] = useState(false)

  const { id: templateId } = useParams()
  const navigate = useCustomNavigate()
  const { setModalProps } = useModal()
  const [searchParams] = useSearchParams()

  const defaultValues = useMemo(() => {
    const defaultWallet = !userWallets ? null : userWallets.find((wallet) => wallet.is_default)
    return {
      firstBundleOrFail: '',
      firstBundleBuy: '',
      backupBundle: '',
      buyWithSimulation: {
        amount: '',
        gas: '',
      },
      backupBundleBuy: '',
      mempoolBackupBuy: '',
      maxTxOrFail: '',
      slippage: 25,
      selectedWallets: defaultWallet ? [defaultWallet.address] : [],
      minPercentForFail: '',
      approvePriority: '',
      transferOnBlacklist: '',
      antiBlacklist: '',
      antiRug: false,
      antiRugThreshold: 50,
      sellRugExtraTip: '',
      maxBuyTax: '',
      maxSellTax: '',
      minLiquidity: '',
      maxLiquidity: '',
      maxMarketcap: '',
      autoSell: '',
      sellPriority: '',
      selectedWalletsForProfit: defaultWallet ? [defaultWallet.address] : [],
      selectedWalletsForLoss: defaultWallet ? [defaultWallet.address] : [],
      stopLoss: '',
      stopLossAmount: '',
      firstStep: '25',
      secondStep: '32',
      thirdStep: '40',
      sellOnProfit: '',
      sellOnProfitAmount: '',
      walletsToTrailingSell: [],
      trailingSell: '',
    }
  }, [userWallets?.length])

  const [walletsOnProfit, setWalletsOnProfit] = useState<string[]>(
    defaultValues.selectedWalletsForProfit,
  )
  const [isAntiRugSettings, setIsAntiRugSettings] = useState(defaultValues.antiRug)

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({ defaultValues })
  const action = searchParams.get('action')
  const isLastMainTab = mainTab === tabs.length - 1

  useEffect(() => {
    setModalProps({
      title: getTemplateActionTitle(SnipeTemplateAction.ADD_SNIPE),
      titleProps: { className: styles.title },
      withBackButton: true,
    })
  }, [templateId])

  const handleChangeMainTab = useCallback(() => {
    setMainTab((value) => (value < tabs.length ? value + 1 : value))
  }, [])

  const handleProfitTabChange = useCallback((_: React.BaseSyntheticEvent, newValue: string) => {
    setProfitTab(newValue)
  }, [])

  const onSubmit: SubmitHandler<typeof defaultValues> = (data) => {
    // Send data to backend
    navigate({ isDashboard: true })
    return data
  }

  const buySimulationFieldNames = ['buyWithSimulation.amount', 'buyWithSimulation.gas']
  const chainSettings = SNIPE_TEMPLATE_CHAIN_SETTINGS[chainName]
  return (
    <div>
      <Grid className={styles.content}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
          <Grid container rowGap={0.5}>
            <Input
              label={t('template.template_name')}
              placeholder={t('template.enter_template_name')}
              className={styles.input}
              maxLength={15}
            />

            <Tabs
              tabControl
              value={mainTab}
              tabs={[
                {
                  label: tabs[0],
                  content: (
                    <Grid container rowGap={2}>
                      <Grid container rowGap={isFirstBundle ? 2 : 0}>
                        {chainSettings[EManualSettingsSection.BUY]?.firstBundleOrFail ?? (
                          <Controller
                            name="firstBundleOrFail"
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <OptionalInput
                                placeholder={t('token_info.amount')}
                                isNumeric
                                label="First Bundle or fail"
                                isOptional
                                tooltipInfo="Be the first buyer or don't buy anything. If enabled, your transaction will only proceed if it was in the first block (bundle)."
                                onExpand={setIsFirstBundle}
                                {...field}
                              />
                            )}
                          />
                        )}
                        {chainSettings[EManualSettingsSection.BUY]?.firstBundleBuy ?? (
                          <Controller
                            name="firstBundleBuy"
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <ControlledAccordion expanded={isFirstBundle}>
                                <OptionalInput
                                  placeholder={t('token_info.amount')}
                                  isNumeric
                                  label="First Bundle Buy (Tip)"
                                  tooltipInfo="tooltipInfo"
                                  {...field}
                                />
                              </ControlledAccordion>
                            )}
                          />
                        )}
                      </Grid>
                      {chainSettings[EManualSettingsSection.BUY]?.backupBundle ?? (
                        <Grid container rowGap={isBackupBundle ? 2 : 0}>
                          <Controller
                            name="backupBundle"
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <OptionalInput
                                placeholder={t('token_info.amount')}
                                isNumeric
                                label="Backup Bundle"
                                isOptional
                                tooltipInfo="Sends a backup bundle together with main bundle or just backup bundle if only selected backup - It will be accepted anywhere in the block, not just 1st positions like first bundle or fail"
                                onExpand={setIsBackupBundle}
                                {...field}
                              />
                            )}
                          />
                          <Controller
                            name="backupBundleBuy"
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <ControlledAccordion expanded={isBackupBundle}>
                                <OptionalInput
                                  placeholder={t('token_info.amount')}
                                  isNumeric
                                  label="Backup Bundle Buy"
                                  tooltipInfo="The amount of ETH that will be spent to buy token at backup transaction."
                                  {...field}
                                />
                              </ControlledAccordion>
                            )}
                          />
                        </Grid>
                      )}
                      <Controller
                        name="mempoolBackupBuy"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <OptionalInput
                            placeholder={t('token_info.amount')}
                            isNumeric
                            label="Mempool Backup Buy"
                            isOptional
                            tooltipInfo="It will send mempool transaction with your other type of transactions, in case there is a bad block or any other situation that may arise it is very helpful to try with mempool too (This will send in same block along with bribe and backup bribe if selected)."
                            {...field}
                          />
                        )}
                      />

                      {chainSettings[EManualSettingsSection.BUY]?.buyWithSimulation ?? (
                        <Grid container>
                          <BuyWithSimulation
                            fieldsNames={buySimulationFieldNames}
                            control={control}
                            errors={errors?.buyWithSimulation}
                            isInitialExpanded={buySimulationFieldNames.some(
                              (fieldName: any) => +watch(fieldName),
                            )}
                          />
                        </Grid>
                      )}

                      <Controller
                        name="maxTxOrFail"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <SwitchInline
                            label={t('template.max_tx_or_fail')}
                            tooltipInfo={t('template.max_tx_or_fail_tooltip')}
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        name="slippage"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <PercentsInput
                            label={t('template.slippage')}
                            onOptionSelect={(value: any) => field.onChange(value)}
                            tooltipInfo={t('template.slippage_tooltip')}
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        name="selectedWallets"
                        control={control}
                        render={({ field: { ref, value, ...field } }) => (
                          <SelectWallet
                            isMulti
                            wallet={value}
                            wallets={userWallets}
                            tooltipInfo="tooltipInfo"
                            {...field}
                          />
                        )}
                      />
                      <Grid container flexWrap="nowrap" columnGap={3}>
                        <Controller
                          name="minPercentForFail"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <OptionalInput
                              placeholder={`${currentChain.chainName.toUpperCase()} amount`}
                              isNumeric
                              label="Min % / Tokens or fail"
                              tooltipInfo="Minimal percentage of total supply or exact amount in ETH equivalent of tokens that should be bought or the transaction will fail."
                              {...field}
                            />
                          )}
                        />
                        <Controller
                          name="approvePriority"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <OptionalInput
                              placeholder={t('template.enter_gwei_amount', {
                                currency: currentChain.priorityCurrency.toLowerCase(),
                              })}
                              isNumeric
                              label={t('template.approve_priority')}
                              tooltipInfo={`${t('template.approve_priority_tooltip')} ${currentChain.chainSymbol}.`}
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  ),
                },
                {
                  label: tabs[1],
                  content: (
                    <Grid container rowGap={2}>
                      <Grid container flexWrap="nowrap" columnGap={3}>
                        <Controller
                          name="transferOnBlacklist"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <SwitchInline
                              label="Transfer On Blacklist"
                              tooltipInfo="Will transfer tokens to the backup wallet when the developer tries to blacklist your wallet."
                              {...field}
                            />
                          )}
                        />
                        <Controller
                          name="antiBlacklist"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <SwitchInline
                              label={t('template.anti_blacklist')}
                              tooltipInfo={t('template.anti_blacklist_tooltip')}
                              {...field}
                            />
                          )}
                        />
                      </Grid>

                      <HiddenInputs
                        expanded={isAntiRugSettings}
                        trigger={
                          <Controller
                            name="antiRug"
                            control={control}
                            render={({ field: { ref, onChange, ...field } }) => (
                              <SwitchInline
                                label={t('template.anti_rug')}
                                tooltipInfo={t('template.anti_rug_tooltip')}
                                onChange={(
                                  _: React.ChangeEvent<HTMLInputElement>,
                                  checked: boolean,
                                ) => {
                                  setIsAntiRugSettings(checked)
                                  onChange(checked)
                                }}
                                {...field}
                              />
                            )}
                          />
                        }
                      >
                        <Grid container rowGap={2}>
                          <Controller
                            name="antiRugThreshold"
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <PercentsInput
                                label={t('template.anti_rug_threshold')}
                                onOptionSelect={(value: any) => field.onChange(value)}
                                tooltipInfo={t('template.anti_rug_tooltip')}
                                {...field}
                              />
                            )}
                          />
                          <Controller
                            name="sellRugExtraTip"
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <OptionalInput
                                placeholder={t('token_info.amount')}
                                isNumeric
                                label={t('template.sell_extra_rug')}
                                tooltipInfo={t('template.sell_extra_rug_tooltip')}
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                      </HiddenInputs>

                      <Grid container flexWrap="nowrap" columnGap={3}>
                        <Controller
                          name="maxBuyTax"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <OptionalInput
                              placeholder={t('template.enter_percentage')}
                              isNumeric
                              label={t('template.max_buy_template')}
                              adornmentText="%"
                              tooltipInfo={t('template.max_buy_tax_tooltip')}
                              {...field}
                            />
                          )}
                        />
                        <Controller
                          name="maxSellTax"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <OptionalInput
                              placeholder={t('template.enter_percentage')}
                              isNumeric
                              label={t('template.max_sell_tax')}
                              adornmentText="%"
                              tooltipInfo={t('template.max_sell_tax_tooltip')}
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                      <Grid container flexWrap="nowrap" columnGap={3}>
                        <Controller
                          name="minLiquidity"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <OptionalInput
                              placeholder={t('token_info.enter_min_liquidity')}
                              isNumeric
                              label={t('token_info.min_liquidity')}
                              adornmentText="$"
                              tooltipInfo={t('token_info.min_liquidity_tooltip')}
                              {...field}
                            />
                          )}
                        />
                        <Controller
                          name="maxLiquidity"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <OptionalInput
                              placeholder={t('token_info.enter_max_liquidity')}
                              isNumeric
                              label={t('token_info.max_liquidity')}
                              adornmentText="$"
                              tooltipInfo={t('token_info.max_liquidity_tooltip')}
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                      <Controller
                        name="maxMarketcap"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <OptionalInput
                            placeholder={t('token_info.enter_max_marketcap')}
                            isNumeric
                            label={t('token_info.max_marketcap')}
                            adornmentText="$"
                            tooltipInfo={t('token_info.max_marketcap_tooltip')}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                  ),
                },
                {
                  label: tabs[2],
                  content: (
                    <Grid container rowGap={2}>
                      <Controller
                        name="autoSell"
                        control={control}
                        render={({ field: { ref, onChange, ...field } }) => (
                          <SwitchInline
                            label={t('template.auto_sell')}
                            tooltipInfo={t('template.auto_sell_tooltip')}
                            onChange={(
                              _: React.ChangeEvent<HTMLInputElement>,
                              checked: boolean,
                            ) => {
                              setIsAutoSell(checked)
                              onChange(checked)
                            }}
                            {...field}
                          />
                        )}
                      />
                      <ControlledAccordion expanded={isAutoSell}>
                        <Grid container rowGap={2}>
                          <Controller
                            name="sellPriority"
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <OptionalInput
                                placeholder={t('template.enter_gwei_amount', {
                                  currency: currentChain.priorityCurrency.toLowerCase(),
                                })}
                                isNumeric
                                label={t('template.sell_priority')}
                                onAutoChange={() => {
                                  // Hendle auto toggle change
                                }}
                                tooltipInfo={`${t('template.enter_gwei_amount_tooltip')} ${currentChain.chainSymbol}.`}
                                // onExpand={setIsFirstBundle}
                                {...field}
                              />
                            )}
                          />
                          <Typography
                            variant="body1"
                            textColor="grey"
                            className={styles.labelWithDivider}
                          >
                            Profit
                          </Typography>
                          <Controller
                            name="selectedWalletsForProfit"
                            control={control}
                            render={({ field: { ref, value, onChange, ...field } }) => {
                              return (
                                <SelectWallet
                                  isMulti
                                  wallets={userWallets}
                                  wallet={value}
                                  label={t('wallet.wallets_to_sell_on_profit')}
                                  tooltipInfo={t('wallet.wallets_to_sell_on_profit_tooltip')}
                                  onChange={(value: any) => {
                                    setWalletsOnProfit(value)
                                    onChange(value)
                                  }}
                                  {...field}
                                />
                              )
                            }}
                          />
                          <ButtonGroupRadio
                            onChange={handleProfitTabChange}
                            value={profitTab}
                            exclusive
                          >
                            {profitTabs.map((tab) => (
                              <ButtonGroupRadioButton value={tab} key={tab}>
                                {tab}
                              </ButtonGroupRadioButton>
                            ))}
                          </ButtonGroupRadio>

                          {profitTab === profitTabs[0] && (
                            <div className={styles.tabWrapper}>
                              <div className={styles.labelContent}>
                                <InputLabel className={styles.label}>
                                  <Typography variant="body2" textColor="light-grey">
                                    Sell in steps
                                  </Typography>
                                </InputLabel>
                                <TooltipIcon
                                  info={t('sell_tab.sell_in_steps_tooltip')}
                                  marginLeft={5}
                                />
                              </div>
                              {walletsOnProfit.length !== 0 ? (
                                <div className={styles.stepWrapper}>
                                  {walletsOnProfit.map((wallet, index) => (
                                    <Controller
                                      key={wallet}
                                      name={`w${wallet}` as keyof typeof defaultValues}
                                      control={control}
                                      render={({ field: { ref, ...field } }) => (
                                        <StepInput
                                          wallet="#1"
                                          balance={`12.58 ${currentChain.chainName.toUpperCase()}`}
                                          className={cls({
                                            [styles.firstInput]: index === 0,
                                            [styles.lastInput]:
                                              index === walletsOnProfit.length - 1,
                                          })}
                                          {...field}
                                        />
                                      )}
                                    />
                                  ))}
                                </div>
                              ) : (
                                <Grid marginTop={1}>
                                  <Typography variant="body1">
                                    {t('wallet.no_wallets_selected')}
                                  </Typography>
                                </Grid>
                              )}
                            </div>
                          )}
                          {profitTab === profitTabs[1] && (
                            <Grid container flexWrap="nowrap" columnGap={3}>
                              <Controller
                                name="sellOnProfit"
                                control={control}
                                render={({ field: { ref, ...field } }) => (
                                  <OptionalInput
                                    placeholder={t('sell_tab.enter_sell_profit')}
                                    isNumeric
                                    label={t('sell_tab.sell_on_profit')}
                                    adornmentText="%"
                                    tooltipInfo={t('sell_tab.sell_on_profit_tooltip')}
                                    {...field}
                                  />
                                )}
                              />
                              <Controller
                                name="sellOnProfitAmount"
                                control={control}
                                render={({ field: { ref, ...field } }) => (
                                  <OptionalInput
                                    placeholder={t('sell_tab.sell_profit_amount')}
                                    isNumeric
                                    label={t('sell_tab.sell_profit_amount_title')}
                                    adornmentText="%"
                                    tooltipInfo={t('sell_tab.sell_profit_amount_tooltip')}
                                    {...field}
                                  />
                                )}
                              />
                            </Grid>
                          )}
                          {profitTab === profitTabs[2] ? (
                            <>
                              <Controller
                                name="walletsToTrailingSell"
                                control={control}
                                render={({ field: { ref, value, ...field } }) => (
                                  <SelectWallet
                                    isMulti
                                    wallet={value}
                                    label={t('sell_tab.wallets_to_sell')}
                                    tooltipInfo="tooltipInfo"
                                    {...field}
                                  />
                                )}
                              />
                              <Controller
                                name="trailingSell"
                                control={control}
                                render={({ field: { ref, ...field } }) => (
                                  <OptionalInput
                                    placeholder={t('sell_tab.enter_sell_trailing')}
                                    isNumeric
                                    label={t('sell_tab.sell_profit')}
                                    adornmentText="%"
                                    tooltipInfo="tooltipInfo"
                                    {...field}
                                  />
                                )}
                              />
                            </>
                          ) : (
                            <>
                              <Typography
                                variant="body1"
                                textColor="grey"
                                className={styles.labelWithDivider}
                              >
                                {t('loss')}
                              </Typography>
                              <Controller
                                name="selectedWalletsForLoss"
                                control={control}
                                render={({ field: { ref, value, ...field } }) => (
                                  <SelectWallet
                                    isMulti
                                    wallet={value}
                                    wallets={userWallets}
                                    label={t('wallet.wallets_to_sell_on_stop')}
                                    tooltipInfo={t('wallet.wallets_to_sell_on_stop_tooltip')}
                                    {...field}
                                  />
                                )}
                              />
                              <Grid container flexWrap="nowrap" columnGap={3}>
                                <Controller
                                  name="stopLoss"
                                  control={control}
                                  render={({ field: { ref, ...field } }) => (
                                    <OptionalInput
                                      placeholder={t('amount_values.percentage_sell_on_loss')}
                                      isNumeric
                                      label={t('template.stop_loss')}
                                      adornmentText="$"
                                      tooltipInfo={t('template.stop_loss_tooltip')}
                                      {...field}
                                    />
                                  )}
                                />
                                <Controller
                                  name="stopLossAmount"
                                  control={control}
                                  render={({ field: { ref, ...field } }) => (
                                    <OptionalInput
                                      placeholder={t('amount_values.amount_sell_on_loss')}
                                      isNumeric
                                      label={t('template.stop_loss_amount')}
                                      adornmentText="$"
                                      tooltipInfo={t('amount_values.amount_sell_on_loss_tooltip')}
                                      {...field}
                                    />
                                  )}
                                />
                              </Grid>
                            </>
                          )}
                        </Grid>
                      </ControlledAccordion>
                    </Grid>
                  ),
                },
              ]}
            />
            <Grid container columnGap={2} flexWrap="nowrap" marginTop={3}>
              <Button styleVariant="black" onClick={handleChangeMainTab} disabled={isLastMainTab}>
                {t('continue')}
              </Button>

              <Button disabled={!isLastMainTab} type="submit">
                {action === 'add-snipe' ? t('add') : t('save')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </div>
  )
}

export { SnipeTemplate }
