import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import cn from 'classnames'

import { AuthCard, TAuthCardProps } from '@/components/auth-card'
import { Button, Icon, Typography } from '@/libs/common/index'
import { IconName, VerificationStatus } from '@/libs/enums'

import styles from './styles.module.scss'

const SuccessPaidPlanSuccess: FC<TAuthCardProps> = ({ className, ...props }) => {
  const { t } = useTranslation()
  return (
    <AuthCard
      className={cn(styles.authCard, className)}
      verificationStatus={VerificationStatus.ENJOY_BLAZING_BOT}
      isHeaderButtons
      {...props}
    >
      <Stack spacing={3} useFlexGap>
        <Stack spacing={1} useFlexGap>
          <Grid container justifyContent="center">
            <Icon name={IconName.SUCCESS} />
          </Grid>
          <Typography variant="h1" align="center">
            {t('success')}
          </Typography>
          <Typography variant="body1" align="center">
            {t('login.setup_success')}
          </Typography>
        </Stack>
        <Link to="/" className={styles.link}>
          <Button>{t('login.continue_to_dashboard')}</Button>
        </Link>
      </Stack>
    </AuthCard>
  )
}

export { SuccessPaidPlanSuccess }
