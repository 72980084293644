import { ChainName } from '@/libs/enums'

// This config contains keys for the translations
const chainSensitiveLabels = {
  buyPriority: {
    default: 'template.buy_priority',
    [ChainName.BNB]: 'template.buy_gwei',
  },
  defaultBuyPriority: {
    default: 'template.default_buy_priority',
    [ChainName.BNB]: 'template.default_buy_gwei',
  },
  sellPriority: {
    default: 'template.sell_priority',
    [ChainName.BNB]: 'template.sell_gwei',
  },
  defaultSellPriority: {
    default: 'template.default_sell_priority',
    [ChainName.BNB]: 'template.default_sell_gwei',
  },
  approvePriority: {
    default: 'template.approve_priority',
    [ChainName.BNB]: 'template.approve_gwei',
  },
  defaultApprovePriority: {
    default: 'template.default_approve_priority',
    [ChainName.BNB]: 'template.default_approve_gwei',
  },
  bribeAmount: {
    default: 'token_info.bribe_amount',
  },
  defaultBribeAmount: {
    default: 'template.default_bribe_amount',
  },
  setupDefaultPriorities: {
    default: 'buy_tab.setup_default_priorities',
    [ChainName.BNB]: 'buy_tab.setup_default_gwei',
  },
  gas: {
    default: 'Miner Tip',
    [ChainName.BNB]: 'Gas',
    [ChainName.SOLANA]: 'Buy Priority',
  },
}

export { chainSensitiveLabels }
