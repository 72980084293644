import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import cn from 'classnames'

import { removeTokenFromHoneypot } from '@/api/tokens'
import { CustomToast } from '@/components/custom-toast'
import { Button, Typography } from '@/libs/common'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

type TProps = {
  token: string
  className?: string
}

const RemoveHoneypotBtn: FC<TProps> = ({ token, className }) => {
  const { t } = useTranslation()
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  const [honeypotRemovalLoading, setHoneypotRemovalLoading] = useState(false)

  const removeHoneypot = async () => {
    try {
      setHoneypotRemovalLoading(true)
      await removeTokenFromHoneypot(token, currentChain.indexerChainId)
      CustomToast('success', 'toast.success.honeypot_removal_requested', {
        interpolation: { token },
      })
    } catch (err) {
      CustomToast('error', (err as any).message)
    } finally {
      setHoneypotRemovalLoading(false)
    }
  }

  return (
    <Button
      className={cn(styles.removeHoneypotBtn, className)}
      isLoading={honeypotRemovalLoading}
      onClick={(e) => {
        e.stopPropagation()
        removeHoneypot()
      }}
    >
      <Typography className={styles.removeHoneypotLabel}>
        {t('token_info.remove_honeypot')}
      </Typography>
    </Button>
  )
}

export { RemoveHoneypotBtn }
