import { CSSProperties, type FC } from 'react'
import { cloneElement } from 'react'

import { IconNameToIcon } from './libs/maps/icon-name-to-icon'

type Props = {
  name: keyof typeof IconNameToIcon
  width?: CSSProperties['width']
  height?: CSSProperties['height']
  color?: CSSProperties['color']
  className?: string
}
const Icon: FC<Props> = ({ name, width, height, color, className }) => {
  const IconComponent = IconNameToIcon[name]

  /**
   * If width or height is not provided, we have to use values from svg.
   * We use ...() syntax to not override current props.
   */
  const props = {
    ...(width !== undefined && { width }),
    ...(height !== undefined && { height }),
    ...(color !== undefined && { style: { color } }),
    ...(className !== undefined && { className }),
  }

  return cloneElement(IconComponent, props)
}

export { Icon }
