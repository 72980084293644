import { useTranslation } from 'react-i18next'

import useCheckUser from '@/hooks/useCheckUser'
import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { Icon, Typography } from '@/libs/common'
import { AppRoute, IconName } from '@/libs/enums'

import styles from './styles.module.scss'

const BottomTabs = () => {
  const { t } = useTranslation()
  const navigate = useCustomNavigate()
  const checkUserAndExecute = useCheckUser()

  const tabs = [
    {
      label: t('buy'),
      icon: <Icon name={IconName.SHOPPING_CART_LARGE} />,
      navigate: `${AppRoute.MODAL}/${AppRoute.BUY_MODAL}`,
    },
    {
      label: t('sell'),
      icon: <Icon name={IconName.BOTTOM_TABS_SELL} />,
      navigate: `${AppRoute.MODAL}/${AppRoute.SELL_MODAL}`,
    },
    {
      label: t('profit_and_loss'),
      icon: <Icon name={IconName.BOTTOM_TABS_PROFIT} />,
      navigate: `${AppRoute.MODAL}/${AppRoute.PROFIT_MODAL}`,
    },
  ]

  return (
    <div className={styles.wrapper}>
      {tabs.map((item) => (
        <div
          key={item.label}
          className={styles.itemWrapper}
          onClick={() =>
            checkUserAndExecute(() => navigate({ isDashboard: true, path: item.navigate }))
          }
        >
          <div className={styles.iconWrapper}>{item.icon}</div>
          <Typography className={styles.label}>{item.label}</Typography>
        </div>
      ))}
    </div>
  )
}

export { BottomTabs }
