import { ReactNode } from 'react'

import cn from 'classnames'
import { TFunction } from 'i18next'

import { Typography } from '@/libs/common'
import { AppMode, ETokenInfo } from '@/libs/enums'
import {
  checkSimulationValue,
  formatNumber,
  formatPairSymbol,
  getClogColor,
  getPercentage,
  getTaxesColor,
} from '@/libs/helper'
import { TChainConfig } from '@/libs/types/chain.type'
import { TTokenInfo } from '@/libs/types/token-info-response.type'

import styles from './styles.module.scss'

enum EMaxWindowSize {
  LARGE = 1800,
  BIG = 1600,
  DESKTOP = 1400,
  MEDIUM = 1250,
}

type TFunctionParams = {
  currentChain: TChainConfig
  simulation: any
  currentToken: TTokenInfo
  t: TFunction<'translation', undefined>
}

type TokenInfoItem = {
  title: (params: TFunctionParams) => string
  getValue: (params: TFunctionParams) => ReactNode
  tooltipInfo?: (params: TFunctionParams) => string
  isCentered?: boolean
  className?: string | ((params: TFunctionParams) => string)
  minWindowWidth?: {
    [AppMode.PRO]?: number
    [AppMode.LITE]?: number
  }
  forLite?: boolean
  forPro?: boolean
}

const tokenInfoConfig: Record<ETokenInfo, TokenInfoItem> = {
  [ETokenInfo.PRICE]: {
    title: ({ t }) => t('token_list.price'),
    isCentered: true,
    forPro: true,
    getValue: ({ simulation }) => (
      <div className={styles.primaryText}>${formatNumber(simulation.price.usd).formatted}</div>
    ),
  },
  [ETokenInfo.MCAP]: {
    title: ({ t }) => t('token_info.marketcap'),
    isCentered: true,
    forPro: true,
    getValue: ({ simulation }) => `$${formatNumber(simulation.market_cap).raw}`,
    className: styles.greenText,
  },
  [ETokenInfo.GAIN]: {
    title: ({ t }) => t('token_info.gain'),
    tooltipInfo: ({ t }) => t('token_info.gain_tooltip'),
    isCentered: true,
    forPro: true,
    getValue: ({ simulation }) => `${simulation?.gain || 0}x`,
    className: styles.greenText,
  },
  [ETokenInfo.LIQUIDITY]: {
    title: ({ t }) => t('token_info.liquidity'),
    tooltipInfo: ({ currentChain, t }) =>
      t('token_info.liquidity_tooltip', { symbol: currentChain.chainSymbol }),
    isCentered: true,
    forPro: true,
    getValue: ({ simulation, currentToken }) => (
      <>
        <div>${formatNumber(simulation.liquidity.pair.value_usd).formatted}</div>

        {checkSimulationValue(simulation.liquidity.pair.reserve) && (
          <div className={styles.additionalInfo}>
            <>
              {formatNumber(simulation.liquidity.pair.reserve || '0').formatted}{' '}
              {!!simulation.liquidity.pair.address &&
                formatPairSymbol(simulation.liquidity.pair.address, currentToken.pair)}
            </>
          </div>
        )}
      </>
    ),
    className: styles.defaultColor,
  },
  [ETokenInfo.TRADE_OPEN]: {
    title: ({ t }) => t('token_info.trade_open'),
    tooltipInfo: ({ t }) => t('token_info.trade_open_tooltip'),
    isCentered: true,
    forPro: true,
    getValue: ({ simulation, t }) =>
      simulation.tradeable ? t('token_info.yes') : t('token_info.no'),
    className: ({ simulation }) => (simulation?.tradeable ? styles.greenText : styles.redText),
  },
  [ETokenInfo.MAX_BUY_PER_WALLET]: {
    title: ({ t }) => t('token_info.max_buy_wallet'),
    tooltipInfo: ({ t }) => t('token_info.max_buy_tooltip'),
    isCentered: true,
    minWindowWidth: { [AppMode.PRO]: EMaxWindowSize.LARGE },
    forPro: true,
    getValue: ({ simulation }) => (
      <>
        <div>
          {`${getPercentage(simulation.limits.buy.percentage)} /
            ${getPercentage(simulation.limits.wallet.percentage || '0')}`}
        </div>
        <div className={styles.additionalInfo}>
          {checkSimulationValue(simulation.limits.buy.cost_chain_currency)
            ? formatNumber(simulation.limits.buy.cost_chain_currency).formatted
            : '-'}{' '}
          /{' '}
          {checkSimulationValue(simulation.limits.wallet.cost_chain_currency)
            ? formatNumber(simulation.limits.wallet.cost_chain_currency || '0').formatted
            : '-'}
        </div>
      </>
    ),
    className: styles.defaultColor,
  },
  [ETokenInfo.TAXES]: {
    title: ({ t }) => t('token_info.tax_b_s_t'),
    tooltipInfo: ({ t }) => t('token_info.tax_b_s_t_tooltip'),
    isCentered: true,
    minWindowWidth: { [AppMode.PRO]: EMaxWindowSize.LARGE, [AppMode.LITE]: EMaxWindowSize.BIG },
    forLite: true,
    forPro: true,
    getValue: ({ simulation }) => {
      if (!simulation?.taxes) return null
      return (
        <>
          <span className={cn(styles[getTaxesColor(simulation.taxes.buy)])}>
            {getPercentage(simulation.taxes.buy)}
          </span>
          {' / '}
          <span className={cn(styles[getTaxesColor(simulation.taxes.sell)])}>
            {getPercentage(simulation.taxes.sell)}
          </span>
          {' / '}
          <span className={cn(styles[getTaxesColor(simulation.taxes.transfer)])}>
            {getPercentage(simulation.taxes.transfer)}
          </span>
        </>
      )
    },
  },
  [ETokenInfo.HONEYPOT]: {
    title: ({ t }) => t('token_info.honeypot'),
    tooltipInfo: ({ t }) => t('token_info.honeypot_tooltip'),
    isCentered: true,
    minWindowWidth: { [AppMode.PRO]: EMaxWindowSize.MEDIUM },
    forLite: true,
    forPro: true,
    getValue: ({ simulation, t }) =>
      simulation?.honeypot ? t('token_info.yes') : t('token_info.no'),
    className: ({ simulation }) => (!simulation?.honeypot ? styles.greenText : styles.redText),
  },
  [ETokenInfo.RENOUNCED]: {
    title: ({ t }) => t('token_info.renounced'),
    tooltipInfo: ({ t }) => t('token_info.renounced_tooltip'),
    isCentered: true,
    minWindowWidth: { [AppMode.PRO]: EMaxWindowSize.MEDIUM },
    forLite: true,
    forPro: true,
    getValue: ({ simulation, t }) =>
      simulation?.renounced ? t('token_info.yes') : t('token_info.no'),
    className: ({ simulation }) => (simulation?.renounced ? styles.greenText : styles.redText),
  },
  [ETokenInfo.BURN_LP]: {
    title: ({ t }) => t('token_info.burn_lp'),
    tooltipInfo: ({ t }) => t('token_info.burn_lp_tooltip'),
    isCentered: true,
    minWindowWidth: { [AppMode.PRO]: EMaxWindowSize.DESKTOP },
    forLite: true,
    forPro: true,
    getValue: ({ simulation }) =>
      simulation?.liquidity.burned === '-' ? '-' : `${simulation?.liquidity.burned || 0}%`,
    className: ({ simulation }) =>
      Number(simulation?.liquidity?.burned || '0') > 50 ? styles.greenText : styles.redText,
  },
  [ETokenInfo.CLOG]: {
    title: ({ t }) => t('token_info.clog'),
    tooltipInfo: ({ t }) => t('token_info.clog_tooltip'),
    isCentered: true,
    minWindowWidth: { [AppMode.PRO]: EMaxWindowSize.BIG },
    forLite: true,
    forPro: true,
    getValue: ({ simulation }) => (simulation?.clog ? `${simulation.clog}%` : '-'),
    className: ({ simulation }) => styles[getClogColor(+(simulation?.clog || '0'))],
  },
  [ETokenInfo.MAX_BUY]: {
    title: ({ t }) => t('token_info.max_buy'),
    tooltipInfo: ({ t }) => t('token_info.max_buy_wallet_tooltip'),
    isCentered: true,
    minWindowWidth: { [AppMode.PRO]: EMaxWindowSize.BIG },
    forLite: true,
    getValue: ({ simulation }) =>
      simulation && (
        <Typography variant="body2" className={styles.defaultColor}>
          <div>{getPercentage(simulation.limits.buy.percentage)}</div>
          <div className={styles.additionalInfo}>
            {checkSimulationValue(simulation.limits.buy.cost_chain_currency) ? (
              <>${formatNumber(simulation.limits.buy.cost_chain_currency).formatted}</>
            ) : (
              '-'
            )}
          </div>
        </Typography>
      ),
  },
  [ETokenInfo.MAX_SELL]: {
    title: ({ t }) => t('token_info.max_sell'),
    isCentered: true,
    minWindowWidth: { [AppMode.PRO]: EMaxWindowSize.BIG, [AppMode.LITE]: EMaxWindowSize.BIG },
    forLite: true,
    getValue: ({ simulation }) =>
      simulation && (
        <Typography variant="body2" className={styles.defaultColor}>
          <div>{getPercentage(simulation.limits.sell.percentage)}</div>
          <div className={styles.additionalInfo}>
            {checkSimulationValue(simulation.limits.sell.cost_chain_currency) ? (
              <>${formatNumber(simulation.limits.sell.cost_chain_currency).formatted}</>
            ) : (
              '-'
            )}
          </div>
        </Typography>
      ),
  },
  [ETokenInfo.MAX_HOLD]: {
    title: ({ t }) => t('token_info.max_hold'),
    isCentered: true,
    minWindowWidth: { [AppMode.PRO]: EMaxWindowSize.BIG, [AppMode.LITE]: EMaxWindowSize.BIG },
    forLite: true,
    getValue: ({ simulation }) =>
      simulation && (
        <Typography variant="body2" className={styles.defaultColor}>
          <div>{getPercentage(simulation.limits.wallet.percentage)}</div>
          <div className={styles.additionalInfo}>
            {checkSimulationValue(simulation.limits.wallet.cost_chain_currency) ? (
              <>${formatNumber(simulation.limits.wallet.cost_chain_currency).formatted}</>
            ) : (
              '-'
            )}
          </div>
        </Typography>
      ),
  },
  [ETokenInfo.MINT_AUTH]: {
    title: ({ t }) => t('token_info.mint_auth'),
    isCentered: true,
    minWindowWidth: { [AppMode.PRO]: EMaxWindowSize.MEDIUM },
    forLite: true,
    forPro: true,
    getValue: ({ simulation, t }) =>
      simulation?.mint_auth ? t('token_info.enabled') : t('token_info.disabled'),
    className: ({ simulation }) => (simulation?.mint_auth ? styles.redText : styles.greenText),
  },
  [ETokenInfo.FREEZE_AUTH]: {
    title: ({ t }) => t('token_info.freeze_auth'),
    isCentered: true,
    minWindowWidth: { [AppMode.PRO]: EMaxWindowSize.MEDIUM },
    forLite: true,
    forPro: true,
    getValue: ({ simulation, t }) =>
      simulation?.freeze_auth ? t('token_info.enabled') : t('token_info.disabled'),
    className: ({ simulation }) => (simulation?.freeze_auth ? styles.redText : styles.greenText),
  },
  [ETokenInfo.TOKEN_DATA_MUTABLE]: {
    title: ({ t }) => t('token_info.token_data_mutable'),
    isCentered: true,
    minWindowWidth: { [AppMode.PRO]: EMaxWindowSize.BIG },
    forLite: true,
    forPro: true,
    getValue: ({ simulation, t }) =>
      simulation?.data_mutable ? t('token_info.yes') : t('token_info.no'),
    className: ({ simulation }) => (simulation?.data_mutable ? styles.redText : styles.greenText),
  },
  [ETokenInfo.UPDATE_AUTH]: {
    title: ({ t }) => t('token_info.update_auth'),
    isCentered: true,
    minWindowWidth: { [AppMode.PRO]: EMaxWindowSize.DESKTOP },
    forLite: true,
    forPro: true,
    getValue: ({ simulation, t }) =>
      simulation.update_auth ? t('token_info.yes') : t('token_info.no'),
    // className: ({simulation}) => (simulation.update_auth ? styles.redText : styles.greenText),
  },
  [ETokenInfo.DEV_SOLD]: {
    title: ({ t }) => t('token_info.dev_sold'),
    isCentered: true,
    minWindowWidth: { [AppMode.PRO]: EMaxWindowSize.BIG },
    forLite: true,
    forPro: true,
    getValue: ({ simulation, t }) =>
      simulation?.dev_sold ? t('token_info.yes') : t('token_info.no'),
    className: ({ simulation }) => (simulation?.dev_sold ? styles.redText : styles.greenText),
  },
  [ETokenInfo.HOLDERS]: {
    title: ({ t }) => t('token_info.holders'),
    isCentered: true,
    minWindowWidth: { [AppMode.PRO]: EMaxWindowSize.LARGE, [AppMode.LITE]: EMaxWindowSize.DESKTOP },
    forLite: true,
    forPro: true,
    getValue: ({ simulation }) => simulation?.holders_count?.toString() || '-',
  },
  [ETokenInfo.TOP_10_HOLDERS]: {
    title: ({ t }) => t('token_info.top_10_holders'),
    minWindowWidth: { [AppMode.PRO]: EMaxWindowSize.LARGE, [AppMode.LITE]: EMaxWindowSize.LARGE },
    forLite: true,
    forPro: true,
    getValue: ({ simulation }) => simulation?.top10Holders || '-',
  },
}

export { tokenInfoConfig }
