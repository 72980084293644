import { forwardRef, useImperativeHandle, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@mui/material'
import * as yup from 'yup'

import { updateMasterPassword } from '@/api/user'
import { CustomToast } from '@/components/custom-toast'
import { Accordion, Button, Input } from '@/libs/common'
import { extractErrorDescription } from '@/libs/helper/extractErrorDescription'
import { passwordValidation } from '@/libs/validations/user'

import styles from './styles.module.scss'

type TForm = {
  new_master: string
  repeat_new_master: string
  current_master: string
}

type TProps = {
  value?: string
}

const defaultValues = {
  current_master: '',
  new_master: '',
  repeat_new_master: '',
}

// eslint-disable-next-line react/display-name
const ChangeMasterPassword = forwardRef(({ value = '' }: TProps, ref) => {
  const [expanded, setExpanded] = useState(false)
  const [innerLoading, setInnerLoading] = useState(false)
  const { t } = useTranslation()

  const validationSchema = yup.object({
    current_master: passwordValidation,
    new_master: passwordValidation,
    repeat_new_master: passwordValidation.oneOf(
      [yup.ref('new_master')],
      t('settings.passwords_validation'),
    ),
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues, resolver: yupResolver(validationSchema) })

  const checkPassword = (passwords: TForm) => {
    return passwords?.new_master === passwords?.repeat_new_master
  }

  const changeMaster = async (data: TForm) => {
    if (!checkPassword(data)) {
      CustomToast('error', 'settings.passwords_different')
    }
    try {
      const payload = {
        current_master: data?.current_master,
        new_master: data?.new_master,
      }
      setInnerLoading(true)
      await updateMasterPassword(payload)
      CustomToast('success', 'success')
    } catch (err) {
      extractErrorDescription(err)
    } finally {
      setInnerLoading(false)
      setExpanded(false)
    }
  }

  useImperativeHandle(ref, () => ({
    setExpanded,
  }))

  return (
    <Accordion
      className={styles.accordion}
      value={value}
      titleClassName={styles.title}
      title={t('settings.change_master_password')}
      mainColorArrow
      isHorizontal
      expanded={expanded}
      onChange={setExpanded}
    >
      <Controller
        name={'current_master'}
        control={control}
        render={({ field: { ref, ...field } }) => (
          <Input
            isTransparent
            placeholder={t('settings.enter_current_master_password')}
            label={t('settings.current_master_password')}
            error={!!errors.current_master?.message}
            {...field}
          />
        )}
      />
      <Controller
        name={'new_master'}
        control={control}
        render={({ field: { ref, ...field } }) => (
          <Input
            isTransparent
            placeholder={t('settings.enter_new_master_password')}
            label={t('settings.new_master_password')}
            error={!!errors.new_master?.message}
            errorMessage={errors.new_master?.message && t(errors.new_master.message)}
            {...field}
          />
        )}
      />
      <Controller
        name={'repeat_new_master'}
        control={control}
        render={({ field: { ref, ...field } }) => (
          <Input
            isTransparent
            placeholder={t('settings.repeat_master_password')}
            label={t('settings.repeat_new_master_password')}
            error={!!errors.repeat_new_master?.message}
            errorMessage={errors.repeat_new_master?.message && t(errors.repeat_new_master.message)}
            {...field}
          />
        )}
      />
      <Grid container flexWrap="nowrap" gap={2}>
        <Button
          styleVariant="black"
          disabled={innerLoading}
          onClick={() => {
            setExpanded(false)
          }}
        >
          {t('cancel')}
        </Button>
        <Button
          type="button"
          isLoading={innerLoading}
          onClick={handleSubmit((data) => changeMaster(data as TForm))}
          checkOnAccountLock
        >
          {t('save')}
        </Button>
      </Grid>
    </Accordion>
  )
})

export { ChangeMasterPassword }
