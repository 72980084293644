import { createProfitPanel } from '@/api/profit-loss'
import { CustomToast } from '@/components/custom-toast'
import { TOrderHistory } from '@/libs/types'
import { store } from '@/store'

export const handleOrdersStatusesChanging = (updatedOrders: TOrderHistory[]) => {
  const profitSell = store.getState().profitSell.profitSell
  for (const order of updatedOrders) {
    switch (order.hash[0].status?.status) {
      case 'successful': {
        if (order.type === 'buy') {
          const isExistPanel = profitSell?.ap?.some(
            (el: any) => el.panel.token_address === order.token && !el.panel.isDeleted,
          )
          if (!isExistPanel) {
            createProfitPanel({
              token_address: order.token,
              userId: order.userId,
              isActive: true,
              isDeleted: false,
            })
          }
        }
        CustomToast('success', 'toast.success.order_status_changed', {
          interpolation: {
            type: order.type === 'buy' ? 'buying' : 'selling',
            tokenName: order.tokenName,
          },
        })
        break
      }
      case 'failed': {
        CustomToast('error', 'toast.error.transaction_failed', {
          interpolation: {
            type: order.type === 'buy' ? 'buying' : 'selling',
            tokenName: order.tokenName,
          },
        })
      }
    }
  }
}
