const isBase64 = (str: string) => {
  const base64Regex = /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/

  if (str.length % 4 !== 0) {
    return false
  }

  return base64Regex.test(str)
}

export { isBase64 }
