import { ComponentProps, FC, useState } from 'react'

import { Grid } from '@mui/material'
import cn from 'classnames'

import { attachChainToAccount } from '@/api/user'
import { Icon } from '@/libs/common/icon'
import { Typography } from '@/libs/common/typography'
import { IconName } from '@/libs/enums'
import { ValueOf } from '@/libs/types'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

type TProperty = {
  title: string
  chainId: number
  description: string
  iconName: ValueOf<typeof IconName>
  onStatusChange: (data: object) => void
  status: boolean
}

const ListChain: FC<ComponentProps<'div'> & TProperty> = ({
  iconName,
  title,
  description,
  chainId,
  onStatusChange,
  status,
}) => {
  const [attached, attach] = useState<boolean>(false)
  const userData = useAppSelector((state) => state.user.userData)

  async function attachChain() {
    if (userData && !attached) {
      const data = JSON.parse(localStorage.account)
      await attachChainToAccount(userData.user_id as string, {
        blockchain: chainId,
        ...data,
      })
      attach(true)
      onStatusChange({
        chainId: chainId,
        status: true,
      })
    }
  }

  return (
    <div className={cn(styles.container)} onClick={() => attachChain()}>
      <Grid container className={styles.icon}>
        <Icon name={iconName as any} />
      </Grid>

      <Grid
        container
        direction="column"
        justifyContent="space-between"
        wrap="nowrap"
        className={styles.textContent}
        flexGrow={2}
      >
        {
          <>
            <Typography
              variant="body2"
              className={cn(styles.textContentItem, styles.textContentItemTitle)}
            >
              {title}
            </Typography>
            {description && (
              <Typography variant="body2" className={styles.textContentItem}>
                {description}
              </Typography>
            )}
          </>
        }
      </Grid>
      <Grid flexGrow={1}></Grid>
      <>
        <Grid flexGrow={1}></Grid>
        <Grid container className={cn(styles.icon, styles.iconLast)}>
          {attached || status ? (
            <Icon name={IconName.CHECK} />
          ) : (
            <Icon name={IconName.ADD_CIRCLE} />
          )}
        </Grid>
      </>
    </div>
  )
}

export { ListChain }
