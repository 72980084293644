import { TypeOptions, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { WithTranslation } from '@/libs/common'
import { getToastIcon, getToastStyles } from '@/libs/helper'

import styles from './styles.module.scss'

type TOptions = {
  interpolation?: Record<string, string | number>
  noTitle?: boolean
  noIcon?: boolean
}

const CustomToast = (type: TypeOptions, message: string, options?: TOptions) => {
  const tostStyles = getToastStyles(type)
  const { interpolation } = options || {}

  toast(
    () => (
      <div className={styles.customToast}>
        {!options?.noTitle && (
          <p className={styles.title}>
            <WithTranslation>{tostStyles.title}</WithTranslation>
          </p>
        )}
        <p className={styles.message}>
          <WithTranslation interpolation={interpolation}>{message}</WithTranslation>{' '}
        </p>
      </div>
    ),
    {
      closeOnClick: false,
      draggable: false,
      type,
      progressStyle: { backgroundColor: tostStyles.color },
      icon: options?.noIcon ? false : getToastIcon(type),
    },
  )
}

export { CustomToast }
