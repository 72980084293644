import { LocalStorageItem } from '../enums'

interface LastVisitedToken {
  token: string
  chainId: number
}

const setLastVisitedToken = (chainId: number, tokenAddress: string) => {
  const existingData = localStorage.getItem(LocalStorageItem.TOKEN_ADDRESS)
  const tokens: Record<number, LastVisitedToken> = existingData ? JSON.parse(existingData) : {}

  tokens[chainId] = {
    token: tokenAddress,
    chainId,
  }

  localStorage.setItem(LocalStorageItem.TOKEN_ADDRESS, JSON.stringify(tokens))
}

const getLastVisitedToken = (chainId: number): string | null => {
  const existingData = localStorage.getItem(LocalStorageItem.TOKEN_ADDRESS)
  if (!existingData) return null

  const tokens: Record<number, LastVisitedToken> = JSON.parse(existingData)
  return tokens[chainId]?.token ?? null
}

export { getLastVisitedToken, setLastVisitedToken }
