import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { HoldingsList } from '@/components/holdings-list'
import { Tabs } from '@/libs/common'
import { useModal } from '@/pages/modal-page/modal-page'

const HoldingsModal = () => {
  const { setModalProps } = useModal()
  const { t } = useTranslation()

  useEffect(() => {
    setModalProps({ title: t('menu.holdings') })
  }, [])

  return (
    <Tabs
      tabs={[
        { label: t('menu.holdings'), content: <HoldingsList isValid={true} withSorting /> },
        { label: t('token_info.honeypot'), content: <HoldingsList isValid={false} /> },
      ]}
    />
  )
}

export { HoldingsModal }
