import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppMode } from '@/hooks/useAppMode'
// import { Bubblemap } from '@/components/coming-soon-overlay'
import { ListButton } from '@/libs/common'
import { ButtonLink } from '@/libs/common/button-link'
import { AppRoute, IconName } from '@/libs/enums'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

const AddWalletTab: FC = () => {
  const { mode } = useAppMode()
  const { description } = useAppSelector((state) => state.chain.currentChain)
  const { t } = useTranslation()

  return (
    <div className={styles.container}>
      <ButtonLink
        to={`${AppRoute.DASHBOARD}/${mode}/${description.toLowerCase()}/${AppRoute.MODAL}/${AppRoute.SELECT_WALLET_COUNT}`}
      >
        <ListButton
          title={t('setup_wallets.generate_new_wallets')}
          description={t('setup_wallets.setup_wallet_description')}
          iconName={IconName.MANUFACTURING}
        />
      </ButtonLink>
      <div className={styles.buttonLink}>
        <ButtonLink
          to={`${AppRoute.DASHBOARD}/${mode}/${description.toLowerCase()}/${AppRoute.MODAL}/${AppRoute.IMPORT_PRIVATE_KEY}`}
        >
          <ListButton
            title={t('setup_wallets.import_wallets')}
            description={t('setup_wallets.connect_existing_wallet')}
            iconName={IconName.KEY}
          />
        </ButtonLink>
      </div>
      {/* <div className={styles.buttonLink}>
        <Bubblemap />
        <ButtonLink to={`${AppRoute.DASHBOARD}/${AppRoute.MODAL}/${AppRoute.WALLET_FILE}`}>
          <ListButton
            title="Import wallet file"
            description="Explanation..."
            iconName={IconName.LIST_ALT_ADD}
          />
        </ButtonLink>
      </div> */}
    </div>
  )
}

export { AddWalletTab }
