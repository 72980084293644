import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Box } from '@mui/material'
import cn from 'classnames'

import { Spinner } from '@/components/spinner'
import { ButtonCopy, TokenPlaceholder, Typography } from '@/libs/common'
import { DividerInline } from '@/libs/common/divider-inline'
import { TokenCreated } from '@/libs/common/token-created'
import { TokenDataItem } from '@/libs/common/token-data-item'
import { TokenSocials } from '@/libs/common/token-socials'
import { chainsConfig } from '@/libs/configs/chains.config'
import { SpinnerSize } from '@/libs/enums'
import { hideWalletAddress } from '@/libs/helper/hideWalletAddress'
import { useModal } from '@/pages/modal-page/modal-page'
import { useAppSelector } from '@/store'

import { getModalFieldsByChain } from './config/config'
import styles from './styles.module.scss'

const TokenInfo = () => {
  const { t } = useTranslation()
  const selectedToken = useAppSelector((state) => state.chain.currentToken)
  const simulation = useAppSelector(
    (state) => state.chain.adaptedCurrentTokenSimulationWebsocket?.data,
  )
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const { setModalProps } = useModal()

  const { isSolana } = useMemo(() => {
    const solanaId = chainsConfig.find((chain) => chain.label === 'SOLANA')?.id
    return {
      isSolana: currentChain.id === solanaId,
    }
  }, [currentChain.id])

  useEffect(() => {
    setModalProps({
      title: !selectedToken || !simulation ? '' : `${simulation?.name} ${t('modal.info')}`,
    })
  }, [selectedToken, simulation])

  if (!simulation && !!selectedToken) {
    return (
      <div className={styles.spinnerWrapper}>
        <Spinner size={SpinnerSize.MEDIUM} centered />
      </div>
    )
  }

  if (!simulation || !selectedToken) {
    return (
      <div>
        <Typography variant="body1" align="center">
          Current token simulation is not available
        </Typography>
        <Typography variant="body1" align="center">
          Please, try it later
        </Typography>
      </div>
    )
  }

  const { generalFields, tradingFields, auditFields } = getModalFieldsByChain(
    isSolana ? 'solana' : 'evm',
  )

  return (
    <div className={styles.wrapper}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="24px"
        className={styles.tokenInfoWrapper}
      >
        <Box display="flex" alignItems="center" gap={1.5}>
          {selectedToken?.token.images.small ? (
            <img
              src={selectedToken.token.images.small}
              className={styles.tokenLogo}
              loading="lazy"
              alt={selectedToken.pair.name}
            />
          ) : (
            <TokenPlaceholder size="44px" />
          )}
          <div>
            <Box display="flex" gap={1.5} alignItems="center">
              <Typography variant="body1" className={cn(styles.symbol)}>
                {selectedToken?.token.symbol}
              </Typography>
              <TokenSocials gap={1.5} selectedToken={selectedToken} isActive={true} />
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              <ButtonCopy value={selectedToken.token.address} />
              <Typography variant="body1" className={styles.address}>
                {hideWalletAddress(selectedToken.token.address)}
              </Typography>
            </Box>
          </div>
        </Box>
        <TokenCreated
          className={styles.tokenCreated}
          status={'TRADING'}
          createdAt={selectedToken.pair.created_at}
        />
      </Box>

      <DividerInline>
        <Typography textColor="color-grey-3" variant="body1" style={{ fontSize: '15px' }}>
          {t('settings.general')}
        </Typography>
      </DividerInline>
      <div className={styles.tokenDataContainer}>
        {generalFields.map((field) => {
          let value = field.getValue(simulation, selectedToken)
          if (field.translateValue && typeof value === 'string') {
            value = t(value)
          }
          return (
            <TokenDataItem
              key={field.id}
              title={t(field.title)}
              tooltipInfo={field.tooltipInfo && t(field.tooltipInfo)}
              titleClassName={field.titleClassName}
              value={value}
              className={
                typeof field.className === 'function'
                  ? field.className(simulation)
                  : field.className
              }
            />
          )
        })}
      </div>

      {tradingFields.length > 0 && (
        <>
          <DividerInline>
            <Typography textColor="color-grey-3" variant="body1" style={{ fontSize: '15px' }}>
              Trading
            </Typography>
          </DividerInline>
          <div className={styles.tokenDataContainer}>
            {tradingFields.map((field) => {
              let value = field.getValue(simulation, selectedToken)
              if (field.translateValue && typeof value === 'string') {
                value = t(value)
              }
              return (
                <TokenDataItem
                  key={field.id}
                  title={t(field.title)}
                  tooltipInfo={field.tooltipInfo && t(field.tooltipInfo)}
                  titleClassName={field.titleClassName}
                  value={value}
                  className={
                    typeof field.className === 'function'
                      ? field.className(simulation)
                      : field.className
                  }
                />
              )
            })}
          </div>
        </>
      )}

      <DividerInline>
        <Typography textColor="color-grey-3" variant="body1" style={{ fontSize: '15px' }}>
          {t('token_info.audit')}
        </Typography>
      </DividerInline>
      <div className={cn(styles.tokenDataContainer, isSolana && styles.auditContainer)}>
        {auditFields.map((field) => {
          let value = field.getValue(simulation, selectedToken)
          if (field.translateValue && typeof value === 'string') {
            value = t(value)
          }
          return (
            <TokenDataItem
              key={field.id}
              title={t(field.title)}
              tooltipInfo={field.tooltipInfo && t(field.tooltipInfo)}
              titleClassName={field.titleClassName}
              value={value}
              className={
                typeof field.className === 'function'
                  ? field.className(simulation)
                  : field.className
              }
            />
          )
        })}
      </div>
    </div>
  )
}

export { TokenInfo }
