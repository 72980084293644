import { updateUser } from '@/api/user'
import { extractErrorDescription } from '@/libs/helper/extractErrorDescription'

export const updateUsername = async (value: string, onSuccess?: () => void) => {
  try {
    const trimmedValue = value.trim()
    if (!trimmedValue) return

    await updateUser({ username: trimmedValue })
    onSuccess?.()
  } catch (err) {
    extractErrorDescription(err)
  }
}
