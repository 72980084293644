import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import cls from 'classnames'

import { TransactionsTableV2 } from '@/components/transactions-table-v2'
import useCheckUser from '@/hooks/useCheckUser.ts'
import { ButtonGroupRadio, ButtonGroupRadioButton } from '@/libs/common'
import { AppMode } from '@/libs/enums'

import styles from './styles.module.scss'

const TransactionsContainer: FC = () => {
  const { t } = useTranslation()

  const { mode } = useParams()

  const types = [
    {
      value: 'all',
      label: t('token_list.all_transactions'),
    },
    {
      value: 'own',
      label: t('token_list.my_transactions'),
    },
  ]

  const [type, setType] = useState(types[0].value)
  const checkUserAndExecute = useCheckUser()

  return (
    <div>
      <div>
        <ButtonGroupRadio
          className={styles.group}
          value={type}
          onChange={(_: React.BaseSyntheticEvent, newValue: string) => {
            checkUserAndExecute(() => {
              setType(newValue)
            })
          }}
          exclusive
        >
          {types.map(({ value, label }) => (
            <ButtonGroupRadioButton
              key={value}
              value={value}
              className={cls(styles.button, styles.bigSize, {
                [styles.active]: type === value,
              })}
            >
              {label}
            </ButtonGroupRadioButton>
          ))}
        </ButtonGroupRadio>
      </div>

      {type === types[1].value ? (
        <TransactionsTableV2
          styleValiant={mode === AppMode.LITE ? 'lite' : 'basic'}
          ownTransactions
          key={1}
        />
      ) : (
        <TransactionsTableV2
          styleValiant={mode === AppMode.LITE ? 'lite' : 'basic'}
          ownTransactions={false}
          key={2}
        />
      )}
    </div>
  )
}

export { TransactionsContainer }
