import { memo, useEffect, useState } from 'react'

const Time = memo(() => {
  const [time, setTime] = useState('')

  useEffect(() => {
    const updateTime = () => {
      const now = new Date()
      const offset = -now.getTimezoneOffset() / 60
      const hours = now.getHours().toString().padStart(2, '0')
      const minutes = now.getMinutes().toString().padStart(2, '0')
      const seconds = now.getSeconds().toString().padStart(2, '0')
      const timezone = `(UTC${offset >= 0 ? '+' : ''}${offset})`
      const formattedTime = `${hours}:${minutes}:${seconds} ${timezone}`
      setTime(formattedTime)
    }

    updateTime()
    const interval = setInterval(updateTime, 1000)
    return () => clearInterval(interval)
  }, [])

  return time
})

Time.displayName = 'Time'

export { Time }
