import { FC, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'
import cn from 'classnames'

import { ProfitSellTabCard } from '@/components/buy-sell-snipe-profit-sell'
import { sortingOptions } from '@/components/profit-sell-tab/libs/pages/libs/constants'
import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { ESortingOption, useSortedData } from '@/hooks/useSortedData'
import { useSorting } from '@/hooks/useSorting'
import { Button, ButtonGroupRadio, ButtonGroupRadioButton, Icon, Typography } from '@/libs/common'
import { SearchTextfield } from '@/libs/common/input-search/libs'
import { AppRoute, IconName } from '@/libs/enums'
import { TActivePanelItem } from '@/libs/types/panels-data-socket-response.type.ts'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

type TProps = {
  usedInModal?: boolean
}

const PositionsSummariesPage: FC<TProps> = ({ usedInModal }) => {
  const positions = useAppSelector((state) => state.profitSell.profitSell?.ap)
  const { t } = useTranslation()

  const [search, setSearch] = useState('')
  const { SortIcon, sorting: sortingOrder } = useSorting()

  const availablePositions = useMemo(() => {
    return positions?.filter((el: any) => {
      let filterConstraint = el.v && !!el.tb
      if (search) {
        const keyword = search.replace(/(^\s+|\s+$)/g, '').toLowerCase()

        filterConstraint =
          filterConstraint &&
          (el.tn.toLowerCase().includes(keyword) || el.ta.toLowerCase().includes(keyword))
      }
      return filterConstraint
    })
  }, [positions, search, sortingOrder])

  const {
    activeSortingName,
    sortedData,
    handleChange: handleChangeSorting,
  } = useSortedData<TActivePanelItem>({
    data: availablePositions,
    search,
    sortingOrder,
  })

  const navigate = useCustomNavigate()

  const addNew = useCallback(() => {
    navigate({
      isDashboard: true,
      path: `${AppRoute.MODAL}/${AppRoute.PROFIT_SELL_ADD_TOKEN}`,
    })
  }, [navigate])

  return (
    <>
      <Grid container wrap="nowrap" alignItems="center" justifyContent="space-between">
        <Grid display="flex" alignItems="center" gap={1}>
          <Typography variant="body2" textColor="light-grey">
            {t('sell_tab.select_position')}
          </Typography>
          <SortIcon />
          <ButtonGroupRadio
            className={styles.filters}
            value={activeSortingName}
            onChange={(_: React.BaseSyntheticEvent, newValue: ESortingOption) =>
              handleChangeSorting(newValue)
            }
            exclusive
          >
            {sortingOptions.map((option) => (
              <ButtonGroupRadioButton
                key={option.value}
                value={option.value}
                className={cn(styles.button, {
                  [styles.withIcon]: !!option.image,
                  [styles.active]: activeSortingName == option.value,
                })}
              >
                {option.image && <Icon name={option.image} />}
                {t(option.label)}
              </ButtonGroupRadioButton>
            ))}
          </ButtonGroupRadio>
        </Grid>
        <Button
          className={styles.button}
          styleVariant="borderless-gold"
          iconStartName={IconName.ADD_CIRCLE}
          onClick={addNew}
          transparent
        >
          {t('add_new')}
        </Button>
      </Grid>
      <SearchTextfield
        value={search}
        onChange={(event) => {
          setSearch(event.currentTarget.value)
        }}
        variant="standard"
        className={styles.search}
        placeholder={t('menu.enter_token')}
      />

      <Grid
        container
        direction="column"
        wrap="nowrap"
        gap={1.5}
        className={cn(styles.cardsContainer, { [styles.usedInModal]: usedInModal })}
      >
        {sortedData?.map((position: TActivePanelItem) => (
          <ProfitSellTabCard key={position.id} position={position} />
        ))}
        {!sortedData?.length && (
          <Typography variant="body1" textColor="grey" align="center">
            {t('sell_tab.no_positions_available')}
          </Typography>
        )}
      </Grid>
    </>
  )
}

export { PositionsSummariesPage }
