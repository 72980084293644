import { forwardRef, useImperativeHandle, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Grid } from '@mui/material'

import { Accordion, Button, Input, Typography } from '@/libs/common'
import { SwitchInline } from '@/libs/common/switch-inline'

import styles from './styles.module.scss'

type TProps = {
  title: string
  name: 'userName' | 'email' | 'password' | 'twoFA' | 'master_password'
  disabled?: boolean
  btnText?: string
  placeholder?: string
  currentValue?: string
  subRule?: string
  loading?: boolean
  onSubmit?: (name: string) => any
}

const UserFormItem = forwardRef(
  (
    {
      title,
      currentValue = '',
      name,
      loading,
      onSubmit,
      btnText,
      placeholder,
      subRule,
      disabled,
    }: TProps,
    ref,
  ) => {
    const [expanded, setExpanded] = useState(false)
    const [innerLoading, setInnerLoading] = useState(false)
    const { t } = useTranslation()

    useImperativeHandle(ref, () => ({
      setExpanded,
    }))

    const {
      control,
      formState: { errors },
    } = useFormContext()

    return (
      <Accordion
        className={styles.accordion}
        value={currentValue}
        titleClassName={styles.title}
        title={title}
        mainColorArrow
        isHorizontal
        expanded={expanded}
        onChange={setExpanded}
      >
        {name !== 'twoFA' ? (
          <Controller
            name={name}
            control={control}
            render={({ field: { ref, ...field } }) => (
              <>
                <Input
                  isTransparent
                  placeholder={placeholder}
                  error={!!errors[name]?.message}
                  disabled={disabled}
                  {...field}
                />
                {subRule && <Typography variant="body2">{subRule}</Typography>}
              </>
            )}
          />
        ) : (
          <Controller
            name={name}
            control={control}
            render={({ field: { ref, ...field } }) => (
              <SwitchInline {...field} label={t('settings.activate_2fa')} />
            )}
          />
        )}

        <Grid container flexWrap="nowrap" gap={2}>
          <Button
            styleVariant="black"
            disabled={loading || innerLoading}
            onClick={() => {
              setExpanded(false)
            }}
          >
            {t('cancel')}
          </Button>

          <Button
            type="button"
            isLoading={loading || innerLoading}
            checkOnAccountLock
            disabled={disabled || !!errors[name]?.message}
            onClick={async () => {
              if (!onSubmit) return
              try {
                setInnerLoading(true)
                await onSubmit(name)
              } catch (err) {
                console.error(err)
              } finally {
                setInnerLoading(false)
                setExpanded(false)
              }
            }}
          >
            {btnText || t('save')}
          </Button>
        </Grid>
      </Accordion>
    )
  },
)

UserFormItem.displayName = 'UserFormItem'

export { UserFormItem }
