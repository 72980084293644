import bs58 from 'bs58'

type StorageData = {
  address: string | null
  shared_secret_dapp: Uint8Array | null
  session: string | null
  dapp_key_pair_public_key: Uint8Array | null
  dapp_key_pair_secret_key: Uint8Array | null
  signature: string | null
  message_nonce: string | null
}

type Values = StorageData[keyof StorageData]

export class PhantomStorage {
  private storage: Storage
  private storageKey: string

  constructor(storage = window.localStorage, storageKey = 'phantom-storage') {
    this.storage = storage
    this.storageKey = storageKey

    if (!this.storage.getItem(this.storageKey)) {
      this.storage.setItem(this.storageKey, JSON.stringify({}))
    }
  }

  private encodeValue(value: Values) {
    return value instanceof Uint8Array ? bs58.encode(value) : value
  }

  private decodeValue(value: string, key: keyof StorageData): Uint8Array | string {
    const uint8ArrayKeys: (keyof StorageData)[] = [
      'shared_secret_dapp',
      'dapp_key_pair_public_key',
      'dapp_key_pair_secret_key',
    ]
    return uint8ArrayKeys.includes(key) && typeof value === 'string' ? bs58.decode(value) : value
  }

  public getAll(): StorageData {
    const storage = JSON.parse(this.storage.getItem(this.storageKey) || '{}')

    for (const key in storage) {
      storage[key] = this.decodeValue(storage[key], key as keyof StorageData)
    }

    return storage
  }

  public getItem<K extends keyof StorageData>(key: K): StorageData[K] {
    try {
      const value = JSON.parse(this.storage.getItem(this.storageKey) || '{}')[key]
      return this.decodeValue(value, key) as StorageData[K]
    } catch (error) {
      return null
    }
  }

  public setItem(key: keyof StorageData, value: StorageData[keyof StorageData]) {
    try {
      const storage = JSON.parse(this.storage.getItem(this.storageKey) || '{}')
      storage[key] = this.encodeValue(value)
      this.storage.setItem(this.storageKey, JSON.stringify(storage))
    } catch (error) {
      console.error(error)
    }
  }

  public removeItem(key: keyof StorageData) {
    try {
      const storage = JSON.parse(this.storage.getItem(this.storageKey) || '{}')
      delete storage[key]
      this.storage.setItem(this.storageKey, JSON.stringify(storage))
    } catch (error) {
      console.error(error)
    }
  }

  public clear() {
    this.storage.setItem(this.storageKey, JSON.stringify({}))
  }
}

export const phantomStorage = new PhantomStorage()
