import { api } from '@/api'
import { getCurrentUserId } from '@/libs/helper/getCurrentUserId'
import { TBackendResponse } from '@/libs/types/backend-response.type'
import { TUserWallet } from '@/libs/types/user.type'
import { store } from '@/store'

import {
  TExportWallets,
  TGenerateWalletsPayload,
  TGenerateWalletsResponse,
  TImportWalletsrResponse,
  TTokenBalanceByWalletResponse,
  TUpdateWalletBasicInfoPayload,
  TUpdateWalletPayload,
} from './types'

const BASE_URL = 'api/v1/wallet'

const updateWallet = (payload: TUpdateWalletPayload) => {
  const userId = getCurrentUserId()
  let { blockchain } = payload
  if (!blockchain) {
    blockchain = store.getState().chain.currentChain.indexerChainId
  }
  return api.post<TBackendResponse<TUserWallet[]>>(`${BASE_URL}/base/${userId}/update?l=en`, {
    ...payload,
    blockchain,
  })
}

const setWalletAsDefault = async (payload: TUpdateWalletBasicInfoPayload) =>
  updateWallet({ ...payload, default: true })

const renameWallet = async (payload: TUpdateWalletBasicInfoPayload & { name: string }) =>
  updateWallet(payload)

const changeWalletOrder = async (address: string, order: string) => updateWallet({ address, order })

const deleteWallets = async (wallets: string[], blockchain?: number) => {
  const userId = getCurrentUserId()
  if (!blockchain) {
    blockchain = store.getState().chain.currentChain.indexerChainId
  }
  return api.post(`${BASE_URL}/base/${userId}/delete?l=en`, { wallets, blockchain })
}

const getUserWallets = (userId: string) => {
  const blockchain = store.getState().chain.currentChain.id
  return api.get<TBackendResponse<TUserWallet[]>>(
    `/api/v1/wallet/base/${userId}/get?b=${blockchain}`,
  )
}

const getUserWalletsFormatted = async (userId: string) => {
  const { data } = await getUserWallets(userId)
  const walletsInfo = data.data

  return walletsInfo
}

const importWallets = (payload: string[]) => {
  const userId = getCurrentUserId()
  return api.post<TImportWalletsrResponse>(`${BASE_URL}/base/${userId}/import`, {
    blockchain: store.getState().chain.currentChain.indexerChainId,
    wallets: payload,
  })
}

// Old endpoints

const changeUserWalletsOrder = async (
  updatedWalletsOrder: { walletId: string; newPriority: number }[],
) => api.patch(`/api/v1/wallet/base/updatePriorities`, updatedWalletsOrder)

const generateWallets = (userId: string, payload: TGenerateWalletsPayload) =>
  api.post<TBackendResponse<TGenerateWalletsResponse[]>>(
    `/api/v1/wallet/base/${userId}/generate`,
    payload,
  )

const getTokenBalanceByWallet = async (tokenAddress: string, networkId: number) =>
  api.get<TTokenBalanceByWalletResponse[]>(
    `${BASE_URL}/get-token-balances-for-wallets/${tokenAddress}/${networkId}`,
  )

const exportWallets = async (data: object) => {
  const userId = getCurrentUserId()
  return api.post<TBackendResponse<TExportWallets[]>>(`${BASE_URL}/base/${userId}/export`, data)
}

export {
  getUserWallets,
  getUserWalletsFormatted,
  changeUserWalletsOrder,
  setWalletAsDefault,
  renameWallet,
  deleteWallets,
  changeWalletOrder,
  generateWallets,
  getTokenBalanceByWallet,
  importWallets,
  exportWallets,
}
