import { useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Grid, InputAdornment, Stack } from '@mui/material'

import {
  BackButton,
  Button,
  ButtonCopy,
  ButtonGroupRadio,
  ButtonGroupRadioButton,
  Divider,
  Icon,
  Input,
  Typography,
} from '@/libs/common'
import { RadioButtonContent } from '@/libs/common/radio-button-content'
import { IconName } from '@/libs/enums'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

const defaultValues = {
  paymentPlan: '',
  currency: '',
  amount: '',
}

const PayForPlan = () => {
  const [paymentPlan, setPaymentPlan] = useState(defaultValues.paymentPlan)
  const [currency, setCurrency] = useState(defaultValues.currency)
  const { control, handleSubmit } = useForm({ defaultValues })
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const { t } = useTranslation()

  const onSubmit: SubmitHandler<typeof defaultValues> = (data) => {
    return data
  }
  return (
    <div>
      <BackButton isAbsolute />
      <div className={styles.header}>
        <Typography variant="h1" textColor="light-grey" textAlign="center">
          Pay for the Pro plan
        </Typography>
        <Typography textAlign="center">
          Payment for the plan will be deducted from your main wallet and automatically be renewed
          after the selected period.
        </Typography>
      </div>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="paymentPlan"
          control={control}
          render={({ field: { ref, onChange, ...field } }) => (
            <ButtonGroupRadio
              label={
                <Typography variant="body2" textColor="light-grey" className={styles.label}>
                  Select payment plan
                </Typography>
              }
              onChange={(_, value: string) => {
                setPaymentPlan(value)
                onChange(value)
              }}
              exclusive
              className={styles.buttonGroupRadio}
              {...field}
            >
              <ButtonGroupRadioButton value="3" name="plan">
                <RadioButtonContent title="3 Months - $50" description="$15.50 / Monthly" />
              </ButtonGroupRadioButton>
              <ButtonGroupRadioButton value="6" name="plan">
                <RadioButtonContent title="6 Months - $90" description="$12.50 / Monthly" />
              </ButtonGroupRadioButton>
              <ButtonGroupRadioButton value="12" name="plan">
                <RadioButtonContent title="12 Months - $200" description="$9.55 / Monthly" />
              </ButtonGroupRadioButton>
            </ButtonGroupRadio>
          )}
        />
        {paymentPlan && (
          <>
            <Controller
              name="currency"
              control={control}
              render={({ field: { ref, onChange, ...field } }) => (
                <ButtonGroupRadio
                  label={
                    <Typography variant="body2" textColor="light-grey" className={styles.label}>
                      Select currency
                    </Typography>
                  }
                  exclusive
                  onChange={(_, value: string) => {
                    setCurrency(value)
                    onChange(value)
                  }}
                  {...field}
                  className={styles.buttonGroupRadio}
                >
                  <ButtonGroupRadioButton value="1" name="currency">
                    <RadioButtonContent
                      title="Ethereum"
                      description="ETH"
                      iconName={IconName.COIN_ETH}
                    />
                  </ButtonGroupRadioButton>
                  <ButtonGroupRadioButton value="2" name="currency">
                    <RadioButtonContent
                      title="Ethereum"
                      description="ETH"
                      iconName={IconName.COIN_ETH}
                    />
                  </ButtonGroupRadioButton>
                  <ButtonGroupRadioButton value="3" name="currency">
                    <RadioButtonContent
                      title="Ethereum"
                      description="ETH"
                      iconName={IconName.COIN_ETH}
                    />
                  </ButtonGroupRadioButton>
                  <ButtonGroupRadioButton value="4" name="currency">
                    <RadioButtonContent
                      title="Ethereum"
                      description="ETH"
                      iconName={IconName.COIN_ETH}
                    />
                  </ButtonGroupRadioButton>
                </ButtonGroupRadio>
              )}
            />
            {currency && (
              <Stack spacing={2} useFlexGap>
                <Typography variant="body1" textColor="light-grey">
                  Deposit payment to main wallet
                </Typography>
                <Controller
                  name="amount"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <Input
                      className={styles.input}
                      label="Amount to deposit"
                      placeholder="0.123844"
                      isCopy
                      {...field}
                      endAdornment={
                        <InputAdornment position="end" className={styles.InputAdornment}>
                          <Grid></Grid>
                          <Grid container className={styles.InputAdornmentIcon}>
                            <Icon name={IconName.COIN_ETH} />
                          </Grid>
                          <Typography variant="caption" className={styles.InputAdornmentTypo}>
                            {currentChain.chainSymbol}
                          </Typography>
                        </InputAdornment>
                      }
                    />
                  )}
                />
                <Grid container wrap="nowrap">
                  <Grid container direction="column">
                    <Typography variant="caption">Main wallet address</Typography>
                    <Grid container gap={0.5}>
                      <Typography variant="body2" textColor="light-grey">
                        0x69825....d4ec3d2311933
                      </Typography>
                      <ButtonCopy value={'0x69825....d4ec3d2311933'} />
                    </Grid>
                  </Grid>
                  <Grid container direction="column">
                    <Typography variant="caption">Main wallet balance</Typography>
                    <Typography variant="body2" textColor="light-grey">
                      0.000000 {currentChain.chainSymbol}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider className={styles.divider}>OR</Divider>
                <Button type="submit">
                  <Icon name={IconName.METAMASK_LOGO} />
                  <span className={styles.buttonText}>{t('menu.deposit')}</span>
                </Button>
              </Stack>
            )}
          </>
        )}
      </form>
    </div>
  )
}

export { PayForPlan }
