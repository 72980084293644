import { ESortingOption } from '@/hooks/useSortedData'

const sortingOptions = [
  {
    label: 'sorting.age',
    value: ESortingOption.NEWEST,
    image: null,
  },
  {
    label: 'sorting.worth',
    value: ESortingOption.WORTH,
    image: null,
  },
]

export { sortingOptions }
