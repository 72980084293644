import { FC, ReactNode } from 'react'

import Grid, { GridProps } from '@mui/material/Grid'
import cn from 'classnames'

import { Typography } from '@/libs/common'

import styles from './styles.module.scss'

type TVerticalGroupProps = GridProps & {
  title?: string
  description?: string | ReactNode
  subValue?: string | ReactNode
  isProfitIndicator?: string
  descClassName?: string
}

const VerticalGroup: FC<TVerticalGroupProps> = ({
  className,
  title,
  description,
  descClassName,
  subValue,
  ...props
}) => {
  return (
    <Grid container direction="column" className={cn(styles.verticalGroup, className)} {...props}>
      <Typography variant="caption" resetLineHeight>
        {title}
      </Typography>
      <Typography
        variant="caption"
        textColor="light-grey"
        resetLineHeight
        className={cn(styles.description, descClassName ? styles[descClassName] : '')}
      >
        {description}
      </Typography>
      {!!subValue && (
        <Typography resetLineHeight className={cn(styles.subValue)}>
          {subValue}
        </Typography>
      )}
    </Grid>
  )
}

export { VerticalGroup }
