import { type FC } from 'react'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CardActionArea } from '@mui/material'
import cls from 'classnames'

import { ButtonGroupRadioButton, Icon } from '@/libs/common'
import { IconName } from '@/libs/enums'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

type TProps = {
  onChange: (newValue: number | string) => void
}

const CustomInput: FC<TProps> = ({ onChange }) => {
  const { t } = useTranslation()

  const [isCustom, setIsCustom] = useState(false)
  const [inputValue, setInputValue] = useState<number | string>('')
  const [value, setValue] = useState<number>(0)
  const radioButton = useRef<HTMLDivElement>(null)

  const userData = useAppSelector((state) => state.user.userData)
  const userWallets = useAppSelector((state) => state.user.userWallets)

  const planMaxWallets = userData?.plan.max_wallets ?? 20
  const userWalletsCount = userWallets?.length ?? 0

  const maxWallets = Math.max(0, planMaxWallets - userWalletsCount)

  useEffect(() => {
    if (value && radioButton && radioButton.current) {
      radioButton.current.click()
    }
  }, [value])

  const handleCustom = () => {
    if (!value) setIsCustom(true)
  }

  const handleClose = (event: React.MouseEvent) => {
    event.stopPropagation()
    setIsCustom(false)
    onChange(0)
  }

  const handleEdit = (event: React.MouseEvent) => {
    event.stopPropagation()
    setIsCustom(true)
  }

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = +event.target.value
    if (!Number.isNaN(newValue)) {
      const validValue = newValue > maxWallets ? maxWallets : newValue
      setInputValue(validValue)
    }
  }

  const handleSubmit = (event: React.MouseEvent) => {
    event.stopPropagation()
    if (+inputValue <= 5) {
      return
    }
    onChange(+inputValue)
    setValue(+inputValue)
    setIsCustom(false)
  }

  return (
    <ButtonGroupRadioButton
      value={isCustom ? 0 : value}
      className={styles.container}
      onClick={handleCustom}
      disableRipple
    >
      <div className={styles.content} ref={radioButton}>
        {isCustom ? (
          <>
            <input className={styles.input} onChange={onInputChange} value={inputValue} />
            <div className={styles.icons}>
              <CardActionArea component="div" className={styles.iconButton} onClick={handleClose}>
                <Icon name={IconName.CLOSE_MIDDLE} />
              </CardActionArea>
              <CardActionArea
                component="div"
                className={cls(styles.iconButton, {
                  [styles.disabled]: +inputValue <= 5,
                })}
                onClick={handleSubmit}
              >
                <Icon name={IconName.CHECK_SMALL} />
              </CardActionArea>
            </div>
          </>
        ) : (
          <>
            <div className={cls(styles.pen, { [styles.fulfilled]: value })}>
              <CardActionArea component="div" onClick={handleEdit}>
                <Icon name={IconName.EDIT_SMALL} />
              </CardActionArea>
            </div>

            {value || t('wallet.custom')}
          </>
        )}
      </div>
    </ButtonGroupRadioButton>
  )
}

export { CustomInput }
