import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import cls from 'classnames'

import { NewTokensTable } from '@/components/token-list/libs/new-tokens-table'
import styles from '@/components/token-list/styles.module.scss'
import { ButtonGroupRadio, ButtonGroupRadioButton, Icon } from '@/libs/common'
import { IconName } from '@/libs/enums'
import { TSelectOption } from '@/libs/types/select-option.type'
import { useAppSelector } from '@/store'

const NewTokens = () => {
  const { t } = useTranslation()

  const resolutionFormats: TSelectOption<string>[] = [
    {
      value: 'ALL',
      label: t('token_info.all'),
    },
    {
      value: 'DEPLOYED',
      label: t('token_info.deployed'),
    },
    {
      value: 'TRADING',
      label: t('token_info.trading'),
    },
    {
      value: 'RUGGED',
      label: t('token_info.rugged'),
    },
  ]

  const [resolutionFormat, setResolutionFormat] = useState(resolutionFormats[0].value)

  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const chains = [
    // {
    //   value: 'all',
    //   label: 'All',
    // },
    // {
    //   value: 'eth',
    //   label: 'ETH',
    //   image: IconName.ETHEREUM,
    // },
    {
      value: currentChain.chainName,
      label: currentChain.description,
      image: (IconName as any)[currentChain.networkIconName],
    },
  ]
  const [chain, setChain] = useState<string>(chains[0].value)

  return (
    <div>
      <div className={styles.controls}>
        <div className={styles.chains}>
          <ButtonGroupRadio
            className={styles.group}
            value={chain}
            onChange={(_: React.BaseSyntheticEvent, newValue: string) => setChain(newValue)}
            separated
            exclusive
          >
            {chains.map((option) => (
              <ButtonGroupRadioButton
                key={option.value}
                value={option.value}
                className={cls(styles.button, {
                  [styles.withIcon]: !!option.image,
                })}
              >
                {option.image && <Icon name={option.image} />}
                {option.label}
              </ButtonGroupRadioButton>
            ))}
          </ButtonGroupRadio>
          {/* <Button styleVariant="black" className={cls(styles.button, styles.greyBorder)}>
            <Icon name={IconName.MORE} />
          </Button> */}
        </div>

        <div className={styles.formats}>
          <ButtonGroupRadio
            className={styles.group}
            value={resolutionFormat}
            onChange={(_: React.BaseSyntheticEvent, newValue: string) =>
              setResolutionFormat(newValue)
            }
            exclusive
          >
            {resolutionFormats.map(({ value, label }) => (
              <ButtonGroupRadioButton
                key={value}
                value={value}
                className={cls(styles.button, styles.bigSize, {
                  [styles.active]: resolutionFormat === value,
                })}
              >
                {label}
              </ButtonGroupRadioButton>
            ))}
          </ButtonGroupRadio>
        </div>
      </div>
      <NewTokensTable resolution={resolutionFormat} />
    </div>
  )
}

export { NewTokens }
