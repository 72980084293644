import { type PropsWithChildren, useState } from 'react'

import { useWindowDimensions } from '@/hooks/useWindowDimensions'
import { RefreshButton, Typography } from '@/libs/common'
import { ModalComponent } from '@/libs/common/modal-component'

import { Filter } from '../filter'
import styles from './styles.module.scss'

type Props = {
  title: string
  isLoading: boolean
  refreshList: () => void
}

const Section = ({ title, children, isLoading, refreshList }: PropsWithChildren<Props>) => {
  const [isOpen, setIsOpen] = useState(false)
  const { width: windowWidth } = useWindowDimensions()

  return (
    <>
      <section className={styles.root}>
        <header className={styles.header}>
          {windowWidth > 1200 && <Typography variant="h2">{title}</Typography>}
          <div className={styles.filter}>
            {/* <Button
              className={styles.filterButton}
              styleVariant="borderless-gold"
              iconStart={<Icon name={IconName.SETTINGS} width={16} height={16} color="#ffc800" />}
              transparent
              onClick={() => setIsOpen(true)}

            >
              Filter
            </Button> */}
            {/* <div className={styles.filterCount}>4</div> */}
            <RefreshButton onClick={refreshList} isLoading={isLoading} />
          </div>
        </header>
        <div className={styles.content}>{children}</div>
      </section>
      <ModalComponent name="filter" title={title} open={isOpen} setOpen={setIsOpen} hasCloseButton>
        <Filter />
      </ModalComponent>
    </>
  )
}

export { Section }
