import { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { Grid, Stack } from '@mui/material'
import cls from 'classnames'
import { t } from 'i18next'

import { getDefaultTemplate } from '@/components/buy-tab/libs/utils/get-default-template'
import { BuyTemplateSettingsList } from '@/components/buy-template-settings-list'
import { SelectWallet } from '@/components/select-wallet'
import { TemplatesControl } from '@/components/templates-control'
import { useWindowDimensions } from '@/hooks/useWindowDimensions'
import {
  Button,
  ButtonGroupRadio,
  ButtonGroupRadioButton,
  ButtonLink,
  HiddenInputs,
  Icon,
  Input,
  Tabs,
  Typography,
} from '@/libs/common'
import { EndAdornment } from '@/libs/common/input/components/end-adornment'
import { OptionalInput } from '@/libs/common/optional-input'
import { PercentsInput } from '@/libs/common/percents-input'
import { SwitchInline } from '@/libs/common/switch-inline'
import {
  AppMode,
  AppRoute,
  ChainName,
  EManualSettingsSection,
  IconName,
  ScrapeTemplateAction,
} from '@/libs/enums'
import { createBuyFormStateFromTemplate } from '@/libs/helper/buy/createBuyFormStateFromTemplate'
import { TSelectOption } from '@/libs/types/select-option.type'
import { TBuyTemplate } from '@/libs/types/template'
import { useModal } from '@/pages/modal-page/modal-page'
import { useAppSelector } from '@/store'

import { templateDefaultValues } from '../../constants'
import { getTemplateActionTitle } from '../../helpers'
import { SCRAPE_CHAIN_SETTINGS } from './libs/constants'
import styles from './styles.module.scss'

const ProfitSettingsTabs: TSelectOption<string>[] = [
  { value: 'sellInSteps', label: t('sell_tab.sell_in_steps') },
  { value: 'sellOnProfit', label: 'Sell on profit' },
  { value: 'trailingSell', label: 'Trailing sell' },
]

const manualTemplate = {
  id: 'Manual',
  name: 'Manual',
  order: 1,
}
const AddNewScrapeTemplate = () => {
  const [mainTab, setMainTab] = useState(0)
  const [isAntiRugSettings, setIsAntiRugSettings] = useState(templateDefaultValues.shields.antiRug)
  const [isAutoSellSettings, setIsAutoSellSetting] = useState(false)
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const userWallets = useAppSelector((state) => state.user.userWallets)
  const userTemplates = useAppSelector((state) => state.user.userTemplates.buyTemplates)
  const defaultPriorities = useAppSelector((state) => state.user.defaultPriorities)
  const [customizableTemplateId, setCustomizableTemplateId] = useState<string | null>(null)

  const templates = useMemo(() => {
    const defaultTemplates: any = [manualTemplate]
    const mainWallet = userWallets?.find((wallet) => wallet.is_default) || userWallets?.[0]
    const defaultCustomTemplate = getDefaultTemplate(defaultPriorities ?? undefined, mainWallet)

    if (defaultCustomTemplate) {
      defaultTemplates.push(defaultCustomTemplate)
    }

    return [
      ...defaultTemplates,
      ...(userTemplates?.filter(
        (item) => item.setup.network.blockchain === currentChain.indexerChainId,
      ) || []),
    ]
  }, [userTemplates, currentChain, userWallets])

  const [currentTemplate, setCurrentTemplate] = useState<TBuyTemplate>(
    (templates as any)[1]?.isPredefined ? (templates[1] as any) : manualTemplate,
  )

  const defaultValues = useMemo(() => {
    const defaultWallet = userWallets?.length
      ? userWallets.find((wallet) => wallet.is_default)
      : null
    const initialWallets = defaultWallet ? [defaultWallet.address] : []

    const advancedBuyDefaultValues = {
      buyPriority: defaultPriorities?.buy_priority ?? '',
      approvePriority: defaultPriorities?.approve_priority ?? '',
    }

    const ordinaryBuyDefaultValues = {
      ...templateDefaultValues.ordinaryBuy,
      selectedWallets: initialWallets,
      receive: currentTemplate?.setup?.operation.setup.buy_amount ?? '',
    }

    const sellDefaultValues = {
      ...templateDefaultValues.sell,
      walletsToSellOnProfit: initialWallets,
      walletsToSellOnLoss: initialWallets,
      selectedWalletsForProfit: initialWallets,
      selectedWalletsForLoss: initialWallets,
      walletsToTrailingSell: initialWallets,
    }

    return {
      templateName: '',
      ...templateDefaultValues,
      ordinaryBuy: ordinaryBuyDefaultValues,
      advancedBuy: advancedBuyDefaultValues,
      sell: sellDefaultValues,
    }
  }, [userWallets?.length, defaultPriorities])

  const { control, setValue } = useForm({ defaultValues })

  const customizeTemplate = () => {
    if (!currentTemplate) return

    const newValues = createBuyFormStateFromTemplate(currentTemplate).template

    Object.keys(newValues).forEach((key: any) => {
      setValue(key, (newValues as any)[key])
    })

    setCustomizableTemplateId(currentTemplate.id)
    handleTemplateChange(manualTemplate.id)
  }

  const handleTemplateChange = (newId?: string) => {
    const foundTemplate = templates.find((item) => item.id === newId) || null
    // const newTemplate =
    //   foundTemplate && foundTemplate.id !== defaultTemplates[0].id
    //     ? (foundTemplate as TBuyTemplate)
    //     : null

    setCurrentTemplate(foundTemplate as TBuyTemplate)
  }

  const [profitTab, setProfitTab] = useState<string>(ProfitSettingsTabs[0].value)
  const isLastMainTab = mainTab === Object.values(EManualSettingsSection).length - 1

  const handleProfitTabChange = (_: React.BaseSyntheticEvent, newValue: string) => {
    setProfitTab(newValue)
  }

  const handleChangeMainTab = () => {
    setMainTab((value) =>
      value < Object.values(EManualSettingsSection).length ? value + 1 : value,
    )
  }
  const { templateId } = useParams()
  const { setModalProps } = useModal()
  const windowDimension = useWindowDimensions()

  useEffect(() => {
    setModalProps({
      title: getTemplateActionTitle(ScrapeTemplateAction.ADD_SCRAPE),
      titleProps: { className: styles.title },
      withBackButton: true,
    })
  }, [templateId])

  const isResponsive = windowDimension.width <= 630
  const chainSettings = SCRAPE_CHAIN_SETTINGS[ChainName.BNB]

  return (
    <Stack spacing={2} useFlexGap>
      <div className={styles.groupWrapper}>
        <div className={styles.groupTitleWrapper}>
          <Icon name={IconName.ETHEREUM} />

          <Typography variant="body1" textColor="light-grey" className={styles.groupName}>
            BlazingBot General
          </Typography>
        </div>

        <ButtonLink
          to={`${AppRoute.DASHBOARD}/${AppMode.PRO}/${currentChain.description.toLowerCase()}/${AppRoute.MODAL}/${AppRoute.ADD_NEW_SCRAPE_GROUP}`}
        >
          <Icon name={IconName.CLOSE} />
        </ButtonLink>
      </div>
      <form className={styles.form}>
        <Grid container rowGap={3}>
          <div style={{ width: '100%' }}>
            <TemplatesControl
              templates={templates}
              currentTemplateId={currentTemplate?.id || manualTemplate.id}
              onSelectTemplate={handleTemplateChange}
              onCustomize={customizeTemplate}
              customizeDisabled={!currentTemplate}
              editDisabled={!currentTemplate || currentTemplate.isPredefined}
              deleteDisabled={!currentTemplate || currentTemplate.isPredefined}
              isResponsive={isResponsive}
            />
          </div>

          <Controller
            name="templateName"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <Input
                isTransparent
                label={t('name')}
                placeholder={t('template.enter_template_name')}
                maxLength={15}
                {...field}
              />
            )}
          />
        </Grid>
        {currentTemplate?.id == manualTemplate.id && (
          <Tabs
            tabControl
            value={mainTab}
            tabs={[
              {
                label: EManualSettingsSection.BUY,
                content: (
                  <Grid container rowGap={2}>
                    {chainSettings[EManualSettingsSection.BUY]?.buyAmount ?? (
                      <Controller
                        name="ordinaryBuy.amount"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <OptionalInput
                            isNumeric
                            className={cls(styles.input, styles.inputSize)}
                            label={t('token_info.amount')}
                            placeholder={t('template.enter_gwei_amount', {
                              currency: currentChain.priorityCurrency.toLowerCase(),
                            })}
                            endAdornment={
                              <EndAdornment
                                label={currentChain.chainSymbol}
                                icon={(IconName as any)[currentChain.iconName]}
                              />
                            }
                            {...field}
                          />
                        )}
                      />
                    )}

                    <Grid container columnGap={3} flexWrap="nowrap">
                      {chainSettings[EManualSettingsSection.BUY]?.antiMev ?? (
                        <Controller
                          name="ordinaryBuy.privateTransaction"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <SwitchInline
                              label={t('template.anti_mev')}
                              tooltipInfo={t('template.anti_mev_tooltip')}
                              {...field}
                            />
                          )}
                        />
                      )}
                      {chainSettings[EManualSettingsSection.BUY]?.degenChadMode ?? (
                        <Controller
                          name="ordinaryBuy.degenChadMode"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <SwitchInline
                              label={t('template.degen_chad_mode')}
                              tooltipInfo={t('template.degen_chad_mode_tooltip')}
                              {...field}
                            />
                          )}
                        />
                      )}
                    </Grid>

                    <Controller
                      name="ordinaryBuy.slippage"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <PercentsInput
                          label={t('template.slippage')}
                          onOptionSelect={(value: any) => field.onChange(value)}
                          tooltipInfo={t('template.slippage_tooltip')}
                          placeholder="X"
                          {...field}
                        />
                      )}
                    />

                    <Controller
                      name="ordinaryBuy.selectedWallets"
                      control={control}
                      render={({ field: { ref, value, ...field } }) => (
                        <SelectWallet
                          isMulti
                          wallet={value}
                          wallets={userWallets}
                          tooltipInfo="tooltipInfo"
                          isSelectDefaultWallet={!customizableTemplateId}
                          {...field}
                        />
                      )}
                    />

                    <Grid container display="flex" flexWrap="nowrap" gap={3}>
                      <Controller
                        name="advancedBuy.buyPriority"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <OptionalInput
                            className={styles.input}
                            label={t('template.buy_priority')}
                            placeholder={t('template.enter_gwei_amount', {
                              currency: currentChain.priorityCurrency.toLowerCase(),
                            })}
                            isNumeric
                            tooltipInfo={`${t('template.buy_priority_tooltip')} ${currentChain.chainSymbol}.`}
                            onAutoChange={() => {
                              //TO DO: added auto switch
                            }}
                            endAdornment={
                              <EndAdornment
                                label="GWEI"
                                icon={(IconName as any)[currentChain.iconName]}
                              />
                            }
                            {...field}
                          />
                        )}
                      />

                      <Controller
                        name="advancedBuy.approvePriority"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <OptionalInput
                            className={cls(styles.input, styles.inputSize)}
                            label={t('template.approve_priority')}
                            placeholder={t('template.enter_gwei_amount', {
                              currency: currentChain.priorityCurrency.toLowerCase(),
                            })}
                            isNumeric
                            tooltipInfo={`${t('template.approve_priority_tooltip')} ${currentChain.chainSymbol}.`}
                            endAdornment={
                              <EndAdornment
                                label="GWEI"
                                icon={(IconName as any)[currentChain.iconName]}
                              />
                            }
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Controller
                      name="shields.duplicateBuy"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <SwitchInline
                          label="Duplicate Buy"
                          tooltipInfo="If enabled, it will buy even if you already own that token, disable it and the bot will not buy if you own that token."
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                ),
              },
              {
                label: EManualSettingsSection.SAFETY,
                content: (
                  <Grid container rowGap={2} flexDirection="column">
                    <Grid container columnGap={3} flexWrap="nowrap">
                      <Controller
                        name="shields.transferOnBlackList"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <SwitchInline
                            label="Transfer On Blacklist"
                            tooltipInfo="Will transfer tokens to the backup wallet when the developer tries to blacklist your wallet."
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        name="shields.antiBlackList"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <SwitchInline
                            label={t('template.anti_blacklist')}
                            tooltipInfo={t('template.anti_blacklist_tooltip')}
                            {...field}
                          />
                        )}
                      />
                    </Grid>

                    <HiddenInputs
                      expanded={isAntiRugSettings}
                      trigger={
                        <Controller
                          name="shields.antiRug"
                          control={control}
                          render={({ field: { ref, onChange, ...field } }) => (
                            <SwitchInline
                              label={t('template.anti_rug')}
                              tooltipInfo={t('template.anti_rug_tooltip')}
                              onChange={(
                                _: React.ChangeEvent<HTMLInputElement>,
                                checked: boolean,
                              ) => {
                                setIsAntiRugSettings(checked)
                                onChange(checked)
                              }}
                              {...field}
                            />
                          )}
                        />
                      }
                    >
                      <Grid container rowGap={2}>
                        <Controller
                          name="shields.antiRugThreshold"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <PercentsInput
                              label={t('template.anti_rug_threshold')}
                              onOptionSelect={(value: any) => field.onChange(value)}
                              tooltipInfo={t('template.anti_rug_tooltip')}
                              {...field}
                            />
                          )}
                        />

                        <Controller
                          name="shields.sellRugExtraTip"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <OptionalInput
                              placeholder={t('token_info.amount')}
                              isNumeric
                              label={t('template.sell_extra_rug')}
                              tooltipInfo={t('template.sell_extra_rug_tooltip')}
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                    </HiddenInputs>

                    <Grid container flexWrap="nowrap" columnGap={3}>
                      <Controller
                        name="shields.buy_tax"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <OptionalInput
                            placeholder={t('template.enter_percentage')}
                            isNumeric
                            label={t('template.max_buy_template')}
                            adornmentText="%"
                            tooltipInfo={t('template.max_buy_tax_tooltip')}
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        name="shields.sell_tax"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <OptionalInput
                            placeholder={t('template.enter_percentage')}
                            isNumeric
                            label={t('template.max_sell_tax')}
                            adornmentText="%"
                            tooltipInfo={t('template.max_sell_tax_tooltip')}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid container flexWrap="nowrap" columnGap={3}>
                      <Controller
                        name="shields.minimum_liquidity"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <OptionalInput
                            placeholder={t('token_info.enter_min_liquidity')}
                            isNumeric
                            label={t('token_info.min_liquidity')}
                            adornmentText="$"
                            tooltipInfo={t('token_info.min_liquidity_tooltip')}
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        name="shields.maximum_liquidity"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <OptionalInput
                            placeholder={t('token_info.enter_max_liquidity')}
                            isNumeric
                            label={t('token_info.max_liquidity')}
                            adornmentText="$"
                            tooltipInfo={t('token_info.max_liquidity_tooltip')}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Controller
                      name="shields.maximum_market_cap"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <OptionalInput
                          placeholder={t('token_info.enter_max_marketcap')}
                          isNumeric
                          label={t('token_info.max_marketcap')}
                          adornmentText="$"
                          tooltipInfo={t('token_info.max_marketcap_tooltip')}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                ),
              },
              {
                label: EManualSettingsSection.SELL,
                content: (
                  <Grid gap={3} container flexDirection="column">
                    <Grid gap={2} container flexDirection="column">
                      <Controller
                        name="sell.copySells"
                        control={control}
                        render={({ field: { ref, onChange, ...field } }) => (
                          <SwitchInline
                            label="Copy sells"
                            tooltipInfo="Enable to copy sales transactions of a tracked wallet."
                            {...field}
                          />
                        )}
                      />
                    </Grid>

                    <HiddenInputs
                      expanded={isAutoSellSettings}
                      trigger={
                        <Controller
                          name="sell.autoSell"
                          control={control}
                          render={({ field: { ref, onChange, ...field } }) => (
                            <SwitchInline
                              label={t('template.auto_sell')}
                              tooltipInfo={t('template.auto_sell_tooltip')}
                              {...field}
                              onChange={(
                                _: React.ChangeEvent<HTMLInputElement>,
                                checked: boolean,
                              ) => {
                                setIsAutoSellSetting(checked)
                                onChange(checked)
                              }}
                            />
                          )}
                        />
                      }
                    >
                      <Grid container rowGap={2} flexDirection="column">
                        <div className={styles.dividerContainer}>
                          <Typography variant="body2" textColor="grey">
                            Profit
                          </Typography>

                          <div className={styles.divider}></div>
                        </div>
                        {/* <Controller
                                name="sell.walletsToSellOnProfit"
                                control={control}
                                render={({ field: { ref, value, ...field } }) => {
                                  setSelectedWallets(value)

                                  return (
                                    <SelectWallet
                                      isMulti
                                      tooltipInfo="Select from which wallets tokens will be sold when they reach a specified amount of profit."
                                      wallet={value}
                                      wallets={userWallets}
                                      isSelectDefaultWallet={!customizableTemplateId}
                                      {...field}
                                      label="Wallets to sell on Profit"
                                    />
                                  )
                                }}
                              />*/}

                        <ButtonGroupRadio
                          onChange={handleProfitTabChange}
                          value={profitTab}
                          exclusive
                          className={styles.group}
                        >
                          {ProfitSettingsTabs.map((tab) => (
                            <ButtonGroupRadioButton
                              value={tab.value}
                              key={tab.value}
                              className={cls({ [styles.bgGrey]: tab.value === profitTab })}
                            >
                              {tab.label}
                            </ButtonGroupRadioButton>
                          ))}
                        </ButtonGroupRadio>

                        {/* {profitTab === ProfitSettingsTabs[0].value &&
                                selectedWallets.length > 0 && (
                                  <div>
                                    <div className={styles.labelContent}>
                                      <InputLabel className={styles.label}>
                                        <Typography variant="body2" textColor="light-grey">
                                          Sell in steps
                                        </Typography>
                                      </InputLabel>
                                      <TooltipIcon
                                        info="This setting allows you to decide at what profits how many wallets will sell. For example, 2 wallets will sell at 1000% profit, next 2 wallets will sell at 2000%."
                                        marginLeft={5}
                                      />
                                    </div>
                                    <div className={styles.stepWrapper}>
                                      <Controller
                                        name="sell.firstStep"
                                        control={control}
                                        render={({ field: { ref, ...field } }) => (
                                          <StepInput
                                            wallet="#1"
                                            balance="12.58 ETH"
                                            className={styles.firstInput}
                                            {...field}
                                          />
                                        )}
                                      />
                                      <Controller
                                        name="sell.secondStep"
                                        control={control}
                                        render={({ field: { ref, ...field } }) => (
                                          <StepInput wallet="#2" balance="12.58 ETH" {...field} />
                                        )}
                                      />
                                      <Controller
                                        name="sell.thirdStep"
                                        control={control}
                                        render={({ field: { ref, ...field } }) => (
                                          <StepInput
                                            wallet="#3"
                                            balance="12.58 ETH"
                                            className={styles.lastInput}
                                            {...field}
                                          />
                                        )}
                                      />
                                    </div>
                                  </div>
                                )}

                              {profitTab === ProfitSettingsTabs[0].value &&
                                !selectedWallets.length && (
                                  <Typography variant="body1" className={styles.walletsDescr}>
                                    No wallets selected
                                  </Typography>
                                )} */}

                        {profitTab === ProfitSettingsTabs[1].value && (
                          <Grid container flexWrap="nowrap" columnGap={3}>
                            <Controller
                              name="sell.sellOnProfit"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <OptionalInput
                                  placeholder={t('sell_tab.enter_sell_profit')}
                                  isNumeric
                                  label={t('sell_tab.sell_on_profit')}
                                  adornmentText="%"
                                  tooltipInfo={t('sell_tab.sell_on_profit_tooltip')}
                                  {...field}
                                />
                              )}
                            />

                            <Controller
                              name="sell.sellOnProfitAmount"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <OptionalInput
                                  placeholder={t('sell_tab.sell_profit_amount')}
                                  isNumeric
                                  label={t('sell_tab.sell_profit_amount_title')}
                                  adornmentText="%"
                                  tooltipInfo={t('sell_tab.sell_profit_amount_tooltip')}
                                  {...field}
                                />
                              )}
                            />
                          </Grid>
                        )}

                        {profitTab === ProfitSettingsTabs[2].value && (
                          <>
                            <Controller
                              name="sell.walletsToTrailingSell"
                              control={control}
                              render={({ field: { ref, value, ...field } }) => (
                                <SelectWallet
                                  isMulti
                                  wallets={userWallets}
                                  wallet={value}
                                  label={t('sell_tab.wallets_to_sell')}
                                  tooltipInfo="tooltipInfo"
                                  isSelectDefaultWallet={!customizableTemplateId}
                                  {...field}
                                />
                              )}
                            />
                            <Controller
                              name="sell.trailingSell"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <OptionalInput
                                  placeholder={t('sell_tab.enter_sell_trailing')}
                                  isNumeric
                                  label={t('sell_tab.sell_profit')}
                                  adornmentText="%"
                                  tooltipInfo="tooltipInfo"
                                  {...field}
                                />
                              )}
                            />
                          </>
                        )}

                        {profitTab !== ProfitSettingsTabs[2].value && (
                          <>
                            <div className={styles.dividerContainer}>
                              <Typography variant="body2" textColor="grey">
                                {t('loss')}
                              </Typography>

                              <div className={styles.divider}></div>
                            </div>
                            <Controller
                              name="sell.walletsToSellOnLoss"
                              control={control}
                              render={({ field: { ref, value, ...field } }) => (
                                <SelectWallet
                                  label="Wallets to sell on Loss"
                                  isMulti
                                  wallets={userWallets}
                                  tooltipInfo="tooltip"
                                  wallet={value}
                                  isSelectDefaultWallet={!customizableTemplateId}
                                  {...field}
                                />
                              )}
                            />

                            <Grid container flexWrap="nowrap" columnGap={3}>
                              <Controller
                                name="sell.stopLoss"
                                control={control}
                                render={({ field: { ref, ...field } }) => (
                                  <OptionalInput
                                    placeholder={t('amount_values.enter_amount')}
                                    label={t('template.stop_loss')}
                                    tooltipInfo={t('template.stop_loss_tooltip')}
                                    adornmentText="%"
                                    {...field}
                                  />
                                )}
                              />
                              <Controller
                                name="sell.stopLossAmount"
                                control={control}
                                render={({ field: { ref, ...field } }) => (
                                  <OptionalInput
                                    placeholder={t('amount_values.enter_amount')}
                                    label={t('template.stop_loss_amount')}
                                    tooltipInfo={t('amount_values.amount_sell_on_loss_tooltip')}
                                    adornmentText="%"
                                    {...field}
                                  />
                                )}
                              />
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </HiddenInputs>
                  </Grid>
                ),
              },
            ]}
          />
        )}

        {currentTemplate?.id !== manualTemplate.id && (
          <BuyTemplateSettingsList template={currentTemplate as TBuyTemplate} expanded={true} />
        )}

        <Grid container columnGap={3} flexWrap="nowrap" marginTop={2}>
          <Button styleVariant="black" onClick={handleChangeMainTab} disabled={isLastMainTab}>
            {t('continue')}
          </Button>
          <Button type="submit" disabled={!isLastMainTab}>
            {t('add')}
          </Button>
        </Grid>
      </form>
    </Stack>
  )
}

export { AddNewScrapeTemplate }
