import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Stack } from '@mui/material'

import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { BackButton, Button, Typography } from '@/libs/common'
import { AppRoute } from '@/libs/enums'

import styles from './styles.module.scss'

const WalletFile: FC = () => {
  const navigate = useCustomNavigate()
  const { t } = useTranslation()

  const handleImportWallet = () => {
    navigate({
      isDashboard: true,
      path: `${AppRoute.MODAL}/${AppRoute.IMPORT_WALLETS}`,
      searchParams: {
        type: 'wallet-file',
      },
    })
  }
  return (
    <Stack className={styles.container} useFlexGap>
      <BackButton isAbsolute />

      <Typography variant="h1" align="center" className={styles.title}>
        {t('setup_wallets.import_wallet_file')}
      </Typography>

      <div className={styles.dropBlock}>
        <Typography className={styles.dropDescription}>
          Drag and drop file here
          <span className={styles.span}>or</span>
          <Link to="/" className={styles.link}>
            Browser files
          </Link>
        </Typography>
      </div>

      <Button onClick={handleImportWallet}>{t('continue')}</Button>
    </Stack>
  )
}

export { WalletFile }
