import { EndAdornment } from '@/libs/common/input/components/end-adornment'
import { IconName } from '@/libs/enums'
import { useAppSelector } from '@/store'

const PriorityCurrency = () => {
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  return (
    <EndAdornment
      label={currentChain.priorityCurrency}
      icon={(IconName as any)[currentChain.iconName]}
    />
  )
}

export { PriorityCurrency }
