import { CSSProperties, FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Box } from '@mui/material'
import { Variant } from '@mui/material/styles/createTypography'
import cn from 'classnames'

import { TStatusType } from '@/api/tokens/types'
import { Icon, Typography } from '@/libs/common'
import { IconName } from '@/libs/enums'
import { TokenStatus } from '@/libs/enums/token-status.enums'
import { calculateTimeFromNow } from '@/libs/helper/calculateTimeDifference'

import { capitalizeWord } from './libs/helpers/capitalize-word'
import styles from './styles.module.scss'

type TProps = {
  status?: TStatusType
  createdAt?: number
  isShort?: boolean
  isShowTitles?: boolean
  scheduleVariant?: Variant
  rowGap?: CSSProperties['rowGap']
  scheduleClassName?: string
  className?: string
}

const TokenCreated: FC<TProps> = ({
  status,
  createdAt,
  isShort = false,
  isShowTitles = true,
  scheduleVariant = 'body2',
  rowGap = '4px',
  scheduleClassName,
  className = '',
}) => {
  const { t } = useTranslation()

  return (
    <Box className={className} display="flex" flexDirection="row" columnGap={1}>
      {isShowTitles && (
        <Box display="flex" flexDirection="column" rowGap={rowGap} alignItems="flex-end">
          {createdAt && (
            <Typography textColor="color-grey-2" variant="body2">
              {t('token_info.created')}:
            </Typography>
          )}
          {status && (
            <Typography textColor="color-grey-2" variant="body2">
              {t('token_info.status')}:
            </Typography>
          )}
        </Box>
      )}

      <Box display="flex" flexDirection="column" rowGap={rowGap}>
        {createdAt && (
          <Box display="flex" alignItems="center" gap="4px" width={75}>
            <Icon name={IconName.SCHEDULE} />
            <Typography
              className={cn(scheduleClassName, { [styles.hasAgoPostfix]: !isShort })}
              textColor="color-lightgrey-3"
              variant={scheduleVariant}
            >
              {calculateTimeFromNow(createdAt)}
            </Typography>
          </Box>
        )}

        {status && (
          <Box display="flex" alignItems="center" gap="4px" width={75}>
            {status == TokenStatus.TRADING && <Icon name={IconName.TRADING_STATUS} />}
            <Typography
              className={cn(styles.cell, styles.status, {
                [styles.redText]: status === 'RUGGED',
                [styles.greenText]: status === 'TRADING',
                [styles.orangeText]: status === 'DEPLOYED',
              })}
              variant="body2"
            >
              {capitalizeWord(status)}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export { TokenCreated }
