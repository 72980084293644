import { FC, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useParams } from 'react-router-dom'

import Grid from '@mui/material/Grid'
import cls from 'classnames'

import { CopyTrade } from '@/components/copy-trade'
import { DashboardComponent } from '@/components/dashboard-component'
import { DashboardComponentLite } from '@/components/dashboard-component-lite'
import { Header } from '@/components/header'
// import { Licenses } from '@/components/licenses'
import { SidebarMinimised } from '@/components/sidebar-minimised'
import { TokenList } from '@/components/token-list'
import { UserSettings } from '@/components/user-settings'
import useComponentWidth from '@/hooks/useComponentWidth'
import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { useWindowDimensions } from '@/hooks/useWindowDimensions'
import { ButtonIcon, Tabs } from '@/libs/common'
import { AppMode, AppRoute, IconName, TabName } from '@/libs/enums'

import { BottomTabs } from './libs/components/bottom-tabs'
import styles from './styles.module.scss'

const Dashboard: FC = () => {
  const { mode } = useParams()
  const navigate = useCustomNavigate()
  const ref = useRef<HTMLInputElement>(null)
  const [collapsed, setCollapsed] = useState(true)
  const [componentWidth] = useComponentWidth(ref)
  const { t } = useTranslation()
  const excludeTabs = [t('dashboard.copy_trade'), t('dashboard.scrape')]

  const handleOpenBuyModal = () => {
    navigate({ isDashboard: true, path: `${AppRoute.MODAL}/${AppRoute.LITE_BUY}` })
  }

  const handleOpenSellModal = () => {
    navigate({ isDashboard: true, path: `${AppRoute.MODAL}/${AppRoute.LITE_SELL}` })
  }

  const windowDimension = useWindowDimensions()
  const showTabs = windowDimension.isBottomTabsVisible && mode === AppMode.PRO

  const tabs = useMemo(
    () =>
      [
        {
          label: t('dashboard.dashboard'),
          content:
            mode === AppMode.PRO ? (
              <DashboardComponent width={componentWidth} />
            ) : (
              <DashboardComponentLite />
            ),
        },
        {
          label: t('dashboard.token_list'),
          content: <TokenList />,
        },
        { label: t('dashboard.copy_trade'), content: <CopyTrade /> },
        { label: t('dashboard.scrape'), content: <CopyTrade isScrape /> },
        {
          label: t('dashboard.settings'),
          content: <UserSettings />,
        },
      ].filter((item) => !excludeTabs.includes(item.label)),
    [t, mode, componentWidth, excludeTabs],
  )

  return (
    <div className={styles.wrapper}>
      <Header setCollapsed={setCollapsed} />

      <Grid
        container
        className={cls(styles.body, mode === 'lite' && styles.bodyLite)}
        justifyContent="center"
      >
        <Grid container wrap="nowrap">
          <Grid container wrap="nowrap" className={styles.bodyContent}>
            {mode === AppMode.PRO && (
              <SidebarMinimised collapsed={collapsed} setCollapsed={setCollapsed} />
            )}

            <div className={styles.content} ref={ref}>
              <Tabs
                className={styles.tabs}
                tabContentClasses={styles.tabContent}
                name={TabName.DASHBOARD_NAV}
                // variant="standard"
                buttonsMargin={false}
                isMain
                tabs={tabs}
              />
            </div>
          </Grid>
          <Outlet />
        </Grid>

        {mode === AppMode.LITE && (
          <div className={styles.footer}>
            <ButtonIcon icon={IconName.BUY} onClick={handleOpenBuyModal}>
              {t('buy')}
            </ButtonIcon>

            <ButtonIcon icon={IconName.SELL} onClick={handleOpenSellModal}>
              {t('sell')}
            </ButtonIcon>
          </div>
        )}
      </Grid>

      {showTabs && <BottomTabs />}
    </div>
  )
}

export { Dashboard }
