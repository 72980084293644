import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import cls from 'classnames'

import { TDetailedPairStats } from '@/api/tokens/types'
import { ValuesCollation } from '@/components/values-collation'
import { ButtonGroupRadio, ButtonGroupRadioButton, Typography } from '@/libs/common'
import { TokenDataItem } from '@/libs/common/token-data-item'
import { SpinnerSize } from '@/libs/enums'
import { formatNumber } from '@/libs/helper'
import { formatPercentageNumber } from '@/libs/helper/formatPercentageNumber'
import { TSelectOption } from '@/libs/types/select-option.type'
import { useAppSelector } from '@/store'

import { Spinner } from '../spinner'
import styles from './styles.module.scss'

const options: TSelectOption<string>[] = [
  {
    value: 'five_minutes',
    label: '5M',
  },
  {
    value: 'one_hour',
    label: '1H',
  },
  {
    value: 'four_hours',
    label: '4H',
  },
  {
    value: 'one_day',
    label: '24H',
  },
]

type TProperty = {
  variant?: 'pro' | 'lite'
}

const PairStats: FC<TProperty> = ({ variant = 'lite' }) => {
  const { t } = useTranslation()

  const currentToken = useAppSelector((state) => state.chain.currentToken)
  const chain = useAppSelector((state) => state.chain.currentChain)
  const [currentOption, setCurrentOption] = useState(options[0].value)
  const [pairStats, setPairStats] = useState<TDetailedPairStats | null>(null)
  const selectedStat = useMemo(
    () => (pairStats ? pairStats[currentOption as keyof TDetailedPairStats] : null),
    [pairStats, currentOption],
  )

  const setup = async () => {
    if (!currentToken) return
    setPairStats(currentToken.statistics)
  }

  useEffect(() => {
    setup()
  }, [currentToken, chain])

  if (!selectedStat) {
    return (
      <div>
        <Spinner centered size={SpinnerSize.SMALL} />
      </div>
    )
  }

  return (
    <div className={cls(styles.container, variant === 'lite' && styles.lite)}>
      <ButtonGroupRadio
        exclusive
        className={styles.radioGroup}
        groupClassName={styles.radioGroupContent}
        value={currentOption}
        onChange={(_, value) => {
          setCurrentOption(value)
        }}
      >
        {selectedStat &&
          options?.map((el, index) => {
            const change = +(
              pairStats?.[el.value as keyof TDetailedPairStats]?.currency.percentage_change || '0'
            )
            return (
              <ButtonGroupRadioButton
                value={el.value}
                name="wallet"
                className={cls(styles.customButton, styles.blockButton)}
                key={index}
              >
                <Typography variant="body2" textColor="light-grey">
                  {el.label}
                </Typography>
                <Typography
                  variant="body2"
                  className={cls(change > 0 ? styles.greenText : change < 0 ? styles.redText : '')}
                >
                  {formatPercentageNumber(change)}%
                </Typography>
              </ButtonGroupRadioButton>
            )
          })}
      </ButtonGroupRadio>
      <div className={styles.content}>
        <div className={styles.row}>
          <TokenDataItem
            title="TXNS"
            value={selectedStat?.non_currency?.transactions || 0}
            className={styles.summary}
          />
          <div className={styles.details}>
            <ValuesCollation
              firstElement={{
                title: t('orders.buys'),
                value: selectedStat?.non_currency?.buys,
              }}
              secondElement={{
                title: t('orders.sells'),
                value: selectedStat?.non_currency?.sells,
              }}
            />
          </div>
        </div>
        <div className={styles.row}>
          <TokenDataItem
            title={t('token_info.volume')}
            value={`$${formatNumber(selectedStat?.currency?.total_volume || 0).raw}`}
            className={styles.summary}
          />
          <div className={styles.details}>
            <ValuesCollation
              firstElement={{
                title: t('token_info.buy_vol'),
                value: selectedStat?.currency?.buy_volume,
                currencySymbol: '$',
              }}
              secondElement={{
                title: t('token_info.sell_vol'),
                value: selectedStat?.currency?.sell_volume,
                currencySymbol: '$',
              }}
            />
          </div>
        </div>
        <div className={styles.row}>
          <TokenDataItem
            title={t('token_info.makers')}
            value={selectedStat?.non_currency?.traders || 0}
            className={styles.summary}
          />
          <div className={styles.details}>
            <ValuesCollation
              firstElement={{
                title: t('orders.buyers'),
                value: selectedStat?.non_currency?.buyers,
              }}
              secondElement={{
                title: t('orders.sellers'),
                value: selectedStat?.non_currency?.sellers,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export { PairStats }
