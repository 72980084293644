import {
  DEFAULT_TOKEN,
  DEFAULT_TOKEN_BASE,
  DEFAULT_TOKEN_BSC,
  DEFAULT_TOKEN_SOLANA,
} from '@/libs/configs/default-token.config.ts'
import { ChainName, ETokenInfo } from '@/libs/enums'
import { TChainConfig } from '@/libs/types/chain.type'

const evmTokenInfo = [
  ETokenInfo.PRICE,
  ETokenInfo.MCAP,
  ETokenInfo.GAIN,
  ETokenInfo.LIQUIDITY,
  ETokenInfo.TRADE_OPEN,
  ETokenInfo.TAXES,
  ETokenInfo.HONEYPOT,
  ETokenInfo.RENOUNCED,
  ETokenInfo.BURN_LP,
  ETokenInfo.CLOG,
  ETokenInfo.MAX_BUY,
  ETokenInfo.MAX_SELL,
  ETokenInfo.MAX_HOLD,
  ETokenInfo.MAX_BUY_PER_WALLET,
  ETokenInfo.HOLDERS,
  ETokenInfo.TOP_10_HOLDERS,
]

const chainsConfig: TChainConfig[] = [
  {
    id: 1,
    chainName: ChainName.ETHEREUM,
    label: 'Ethereum Chain',
    networkLabel: 'Ethereum',
    description: 'ETH',
    defaultToken: DEFAULT_TOKEN,
    chainSymbol: 'ETH',
    iconName: 'ETHEREUM',
    networkIconName: 'ETHEREUM',
    scanLogo: 'ETHERSCAN',
    nativeTokenAddress: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    indexerChainId: 1,
    chainId: 1,
    bubblemapsChainId: 1,
    priorityCurrency: 'GWEI',
    defaultTxPriorities: {
      buy_priority: '7',
      sell_priority: '7',
      approve_priority: '7',
      bribe_amount: '7',
    },
    chainDexes: [
      {
        id: 0,
        label: 'Uniswap V2',
      },
      {
        id: 1,
        label: 'Uniswap V3',
      },
      {
        id: 10,
        label: 'SushiSwap V2',
      },
      {
        id: 11,
        label: 'SushiSwap V3',
      },
      {
        id: 20,
        label: 'PancakeSwap V2',
      },
      {
        id: 21,
        label: 'PancakeSwap V3',
      },
      {
        id: 30,
        label: 'ShibaSwap V2',
      },
    ],
    explorer: 'https://etherscan.io',
    defaultValues: {
      slippage: 100,
      txType: 1,
    },
    features: {
      noAntiDuplicate: true,
      maxBuyTaxForTemplates: true,
      maxSellTaxForTemplates: true,
      afterTax: true,
      maxTxOrFail: true,
      degenMode: true,
      approvePriority: true,
      buyPriority: true,
      sellPriority: true,
      useGasInTx: true,
      bridge: true,
      transfers: true,
    },
    isEVM: true,
    tokenInfo: evmTokenInfo,
  },
  {
    id: 2,
    chainName: ChainName.BNB,
    label: 'Binance Smart Chain',
    networkLabel: 'BNB',
    description: 'BSC',
    defaultToken: DEFAULT_TOKEN_BSC,
    chainSymbol: 'BNB',
    scanLogo: 'BSCSCAN',
    iconName: 'BNB_V2',
    networkIconName: 'BNB_V2',
    nativeTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    indexerChainId: 2,
    chainId: 56,
    bubblemapsChainId: 56,
    priorityCurrency: 'GWEI',
    defaultTxPriorities: {
      buy_priority: '7',
      sell_priority: '7',
      approve_priority: '7',
      bribe_amount: '7',
    },
    chainDexes: [
      {
        id: 0,
        label: 'PancakeSwap V2',
      },
      {
        id: 1,
        label: 'PancakeSwap V3',
      },
      {
        id: 11,
        label: 'Uniswap V3',
      },
      {
        id: 20,
        label: 'ApeSwap V2',
      },
      {
        id: 30,
        label: 'BakerySwap V2',
      },
    ],
    explorer: 'https://bscscan.com',
    features: {
      noPriorityGas: true,
      noPrivateTx: true,
      buyPriority: true,
      sellPriority: true,
      approvePriority: true,
      noAntiDuplicate: true,
      maxBuyTaxForTemplates: true,
      maxSellTaxForTemplates: true,
      afterTax: true,
      maxTxOrFail: true,
      degenMode: true,
      useGasInTx: true,
      bridge: true,
      transfers: true,
    },
    defaultValues: {
      slippage: 25,
      txType: 0,
    },
    isEVM: true,
    tokenInfo: evmTokenInfo,
  },
  {
    id: 5,
    chainName: ChainName.BASE,
    label: 'BASE',
    networkLabel: 'BASE',
    description: 'BASE',
    defaultToken: DEFAULT_TOKEN_BASE,
    chainSymbol: 'ETH',
    scanLogo: 'ETHERSCAN',
    iconName: 'BASE_CHAIN',
    networkIconName: 'BASE_NETWORK',
    nativeTokenAddress: '0x4200000000000000000000000000000000000006',
    indexerChainId: 5,
    chainId: 8453,
    bubblemapsChainId: 8453,
    priorityCurrency: 'GWEI',
    defaultTxPriorities: {
      buy_priority: '7',
      sell_priority: '7',
      approve_priority: '7',
      bribe_amount: '7',
    },
    chainDexes: [
      {
        id: 0,
        label: 'BaseSwap V2',
      },
      {
        id: 1,
        label: 'BaseSwap V3',
      },
      {
        id: 10,
        label: 'SushiSwap V2',
      },
      {
        id: 11,
        label: 'SushiSwap V3',
      },
      {
        id: 20,
        label: 'Uniswap V2',
      },
      {
        id: 21,
        label: 'Uniswap V3',
      },
    ],
    explorer: 'https://basescan.org',
    defaultValues: {
      slippage: 25,
      txType: 1,
    },
    features: {
      noPrivateTx: true,
      buyPriority: true,
      sellPriority: true,
      approvePriority: true,
      noAntiDuplicate: true,
      maxBuyTaxForTemplates: true,
      maxSellTaxForTemplates: true,
      afterTax: true,
      maxTxOrFail: true,
      degenMode: true,
      useGasInTx: true,
      bridge: true,
      transfers: true,
    },
    isEVM: true,
    tokenInfo: evmTokenInfo,
  },
  {
    id: 8,
    chainName: ChainName.SOLANA,
    chainId: 900,
    bubblemapsChainId: 'solana',
    label: 'SOLANA',
    networkLabel: 'SOL',
    description: 'SOL',
    defaultToken: DEFAULT_TOKEN_SOLANA,
    chainSymbol: 'SOL',
    scanLogo: 'SOLSCAN',
    iconName: 'SOLANA',
    networkIconName: 'SOLANA',
    nativeTokenAddress: 'So11111111111111111111111111111111111111112',
    indexerChainId: 8,
    explorer: 'https://solscan.io',
    priorityCurrency: 'SOL',
    defaultTxPriorities: {
      buy_priority: '0.005',
      sell_priority: '0.005',
      approve_priority: '0.005',
      bribe_amount: '0.005',
    },
    defaultValues: {
      slippage: 25,
      txType: 1,
    },
    features: {
      noPrivateTx: false,
      buyPriority: true,
      sellPriority: true,
      approvePriority: false,
      noAntiDuplicate: true,
      bribeAmount: true,
      afterTax: false,
      maxTxOrFail: false,
      degenMode: true,
      useGasInTx: false,
      usePriorityAmount: true,
      bridge: true,
      noPriorityGas: true,
      transfers: false,
    },
    isEVM: false,
    tokenInfo: [
      ETokenInfo.PRICE,
      ETokenInfo.MCAP,
      ETokenInfo.GAIN,
      ETokenInfo.LIQUIDITY,
      ETokenInfo.MINT_AUTH,
      ETokenInfo.FREEZE_AUTH,
      ETokenInfo.BURN_LP,
      ETokenInfo.TOKEN_DATA_MUTABLE,
      ETokenInfo.UPDATE_AUTH,
      ETokenInfo.HOLDERS,
      ETokenInfo.TOP_10_HOLDERS,
      ETokenInfo.DEV_SOLD,
    ],
  },

  // {
  //   id: 42161,
  //   chainName: ChainName.ARBITRUM,
  //   label: 'Arbitrum',
  //   description: 'ARB',
  //   iconName: 'ARBITRUM',
  //   nativeTokenAddress: '0x912CE59144191C1204E64559FE8253a0e49E6548',
  //   indexerChainId: 3,
  //   chainDexes: [
  //     {
  //       id: 1,
  //       label: 'Uniswap V3',
  //     },
  //     {
  //       id: 10,
  //       label: 'SushiSwap V2',
  //     },
  //     {
  //       id: 11,
  //       label: 'SushiSwap V3',
  //     },
  //     {
  //       id: 20,
  //       label: 'Camelot V2',
  //     },
  //     {
  //       id: 21,
  //       label: 'Camelot V3',
  //     },
  //     {
  //       id: 30,
  //       label: 'ArbSwap V2',
  //     },
  //   ],
  // },
  // @TODO change IDs to base chain IDs (these were copied from ethereum)
  // @TODO change IDs to avax chain IDs (these were copied from ethereum)
  // {
  //   id: 1,
  //   chainName: ChainName.AVAX,
  //   label: 'AVAX',
  //   description: 'AVAX',
  //   iconName: 'AVAX_CHAIN',
  //   nativeTokenAddress: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  //   indexerChainId: 0,
  //   chainDexes: [
  //     {
  //       id: 0,
  //       label: 'Uniswap V2',
  //     },
  //     {
  //       id: 1,
  //       label: 'Uniswap V3',
  //     },
  //     {
  //       id: 10,
  //       label: 'SushiSwap V2',
  //     },
  //     {
  //       id: 11,
  //       label: 'SushiSwap V3',
  //     },
  //     {
  //       id: 20,
  //       label: 'PancakeSwap V2',
  //     },
  //     {
  //       id: 21,
  //       label: 'PancakeSwap V3',
  //     },
  //     {
  //       id: 30,
  //       label: 'ShibaSwap V2',
  //     },
  //   ],
  // },
]

export { chainsConfig }
