import { FC, useEffect } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { yupResolver } from '@hookform/resolvers/yup'
import { Box } from '@mui/material'
import * as yup from 'yup'

import { Button, ButtonIcon, Icon, InputPassword, Typography } from '@/libs/common'
import { IconName } from '@/libs/enums'

import styles from '../../styles.module.scss'
import { TAuthenticator } from '../types'

type TProps = {
  goBack: () => void
  authenticator: TAuthenticator
  onSubmit: () => void
}

const defaultValues = {
  masterPassword: '',
}

const ConfirmMasterPassword: FC<TProps> = ({ onSubmit, goBack, authenticator }) => {
  const { t } = useTranslation()

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    formState: { isValid, errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(
      yup.object({
        masterPassword: yup
          .string()
          .required(t('login.master_password_required'))
          .max(32, t('login.master_password_validation_msg')),
      }),
    ),
  })

  const masterPassword = watch('masterPassword')
  useEffect(() => {
    clearErrors()
  }, [masterPassword])

  const onFormSubmit: SubmitHandler<typeof defaultValues> = (values: typeof defaultValues) => {
    if (authenticator.master !== values.masterPassword) {
      setError('masterPassword', {
        message: t('login.master_password_error'),
      })
      return
    }

    clearErrors()
    onSubmit()
  }

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} className={styles.content}>
      <div className={styles.header}>
        <ButtonIcon
          icon={IconName.ARROW_RIGHT}
          onClick={goBack}
          containerClassName={styles.backButton}
        />

        <Typography variant="h1" align="center" className={styles.title}>
          {t('login.confirm_password')}
        </Typography>
      </div>
      <div className={styles.confirmPasswordBody}>
        <Controller
          name="masterPassword"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <InputPassword
              label={t('login.master_password')}
              maxLength={32}
              isHideInputCaption
              className={styles.confirmInput}
              labelClassName={styles.confirmInputLabel}
              placeholder={t('login.master_password_placeholder')}
              {...field}
            />
          )}
        />

        {errors.masterPassword && (
          <Box className={styles.spendError} display="flex" gap={1}>
            <Icon name={IconName.INFO_ERROR} />
            <Typography variant="body1" textColor="red">
              {errors.masterPassword?.message}
            </Typography>
          </Box>
        )}
      </div>

      <Button disabled={!isValid} type="submit">
        {t('login.confirm')}
      </Button>
    </form>
  )
}

export { ConfirmMasterPassword }
