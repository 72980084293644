import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { ButtonGroupRadio, ButtonGroupRadioButton, Typography } from '@/libs/common'
import { WalletsQuantityButton } from '@/libs/enums'

import { CustomInput } from './libs/components/custom-input'
import styles from './styles.module.scss'

type TProperty = {
  counter?: number
  handleChange: (newValue: number | string) => void
}

const SelectWalletCount: FC<TProperty> = ({ counter, handleChange }) => {
  const { t } = useTranslation()

  return (
    <ButtonGroupRadio
      label={
        <Typography variant="body2" textColor="light-grey">
          {t('wallet.select_wallet_count')}
        </Typography>
      }
      value={counter}
      onChange={(_, value) => handleChange(value)}
      exclusive
    >
      {Object.values(WalletsQuantityButton).map((content) =>
        content === WalletsQuantityButton.CUSTOM ? (
          <CustomInput key={content} onChange={handleChange} />
        ) : (
          <ButtonGroupRadioButton value={content} className={styles.button} key={content}>
            {content}
          </ButtonGroupRadioButton>
        ),
      )}
    </ButtonGroupRadio>
  )
}

export { SelectWalletCount }
