import { FC, useState } from 'react'

import { Grid, InputAdornment, InputLabel } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import { InputProps as MUIInputProps } from '@mui/material/Input'

import { Icon, Input, Switch, Typography } from '@/libs/common'
import { SwitchInline } from '@/libs/common/switch-inline'
import { TooltipIcon } from '@/libs/common/tooltip-icon'
import { IconName } from '@/libs/enums'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

type TProps = MUIInputProps & {
  type?: string
  label: string
  value?: string
  isOptional?: boolean
  adornmentText?: string
  tooltipInfo?: string
  isAuto?: boolean
  onAutoChange?: () => void
  onExpand?: (expanded: boolean) => void
  isNumeric?: boolean
  maxLength?: number
  isHideInputCaption?: boolean
}

const OptionalInput: FC<TProps> = ({
  label,
  value = '',
  isOptional,
  adornmentText,
  tooltipInfo,
  isAuto = true,
  type = 'text',
  onAutoChange,
  onExpand,
  isNumeric,
  maxLength,
  isHideInputCaption = true,
  ...rest
}) => {
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const [expanded, setExpanded] = useState(!!value || !isOptional)
  const [auto, setAuto] = useState(false)
  return (
    <Accordion expanded={expanded} className={styles.accordion}>
      <AccordionSummary className={styles.accordionHeader}>
        {isOptional ? (
          <SwitchInline
            label={label}
            checked={expanded}
            value={expanded}
            tooltipInfo={tooltipInfo}
            onChange={(_, value) => {
              setExpanded(value)
              onExpand?.(value)
            }}
          />
        ) : (
          <div className={styles.labelWrapper}>
            <div className={styles.labelContent}>
              <InputLabel className={styles.label}>
                <Typography variant="body2" textColor="light-grey">
                  {label}
                </Typography>
              </InputLabel>
              {tooltipInfo && <TooltipIcon info={tooltipInfo} marginLeft={5} />}
            </div>
            {onAutoChange && (
              <>
                <div className={styles.divider}></div>
                <div className={styles.switch}>
                  {isAuto && (
                    <Typography variant="body2" className={styles.auto}>
                      Auto
                    </Typography>
                  )}
                  <Switch
                    value={auto}
                    onChange={(_, checked: boolean) => {
                      setAuto(checked)
                    }}
                  />
                </div>
              </>
            )}
          </div>
        )}
      </AccordionSummary>
      {!auto && (
        <AccordionDetails className={styles.accordionDetails}>
          <Input
            type={type}
            isNumeric={isNumeric}
            className={styles.input}
            value={value}
            maxLength={maxLength}
            isHideInputCaption={isHideInputCaption}
            endAdornment={
              <InputAdornment position="end" className={styles.inputAdornment}>
                <Grid></Grid>
                {adornmentText ? (
                  <Typography variant="caption" className={styles.adornmentText}>
                    {adornmentText}
                  </Typography>
                ) : (
                  <>
                    <Grid container className={styles.inputAdornmentIcon}>
                      <Icon name={(IconName as any)[currentChain.iconName]} />
                    </Grid>
                    <Typography isUpperCase variant="caption">
                      {currentChain.chainName}
                    </Typography>
                  </>
                )}
              </InputAdornment>
            }
            {...rest}
          />
        </AccordionDetails>
      )}
    </Accordion>
  )
}

export { OptionalInput }
