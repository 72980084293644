import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'

import { createProfitPanel } from '@/api/profit-loss'
import { CustomToast } from '@/components/custom-toast'
import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { Button, ListButton } from '@/libs/common'
import { handleError } from '@/libs/helper/handleError'
import { AddTokenButton } from '@/pages/modal-page/libs/components/add-token/index'
import { useModal } from '@/pages/modal-page/modal-page'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

// const ProfitSettingsTabs: TSelectOption<string>[] = [
//   { value: 'sellInSteps', label: 'Sell in steps' },
//   { value: 'sellOnProfit', label: 'Sell on profit' },
//   { value: 'trailingSell', label: 'Trailing sell' },
// ]

// const mockTemplates: TSnipeTemplate[] = [
//   {
//     _id: '1',
//     name: 'Manual',
//   },
//   {
//     _id: '2',
//     name: 'Fast Buy',
//   },
//   {
//     _id: '3',
//     name: 'Max profit',
//   },
//   {
//     _id: '4',
//     name: 'Quick trade',
//   },
// ]

// const defaultValues = {
//   transferOnBlacklist: false,
//   antiBlacklist: false,
//   templateType: null,
//   autoSell: false,
//   sellPriority: '',
//   walletsToSellOnProfit: [],
//   walletsToSellOnLoss: [],
//   stopLoss: '',
//   stopLossAmount: '',
//   w1: '25',
//   w2: '32',
//   w3: '40',
//   w4: '25',
//   w5: '32',
//   w6: '40',
//   sellOnProfit: '',
//   sellOnProfitAmount: '',
//   walletsToTrailingSell: [],
//   trailingSell: '',
// }

const AddTokenSetup: FC = () => {
  const { t } = useTranslation()
  const userData = useAppSelector((state) => state.user.userData)
  const userHoldings = useAppSelector((state) => state.user.userHoldingsWebsocket)

  const { address } = useParams()

  const navigate = useCustomNavigate()

  const holding = useMemo(
    () => userHoldings?.v.t.find((item) => item.a === address),
    [address, userHoldings],
  )

  const [isLoading, setIsLoading] = useState(false)

  const { setModalProps } = useModal()

  useEffect(() => {
    setModalProps({ title: t('token_info.add_token_to_pnl'), withBackButton: true })
  }, [])

  // const [templates] = useState<TSnipeTemplate[]>(mockTemplates)
  // const [currentTemplate] = useState<TSnipeTemplate | null>(mockTemplates[0])
  // const [isAutoSellSettings, setIsAutoSellSetting] = useState(false)
  // const [walletsOnProfit, setWalletsOnProfit] = useState<string[]>(
  //   defaultValues.walletsToSellOnProfit,
  // )
  // const [profitTab, setProfitTab] = useState<string>(ProfitSettingsTabs[0].value)
  // const [isDisable, setIsDisable] = useState(true)
  // const { control, handleSubmit } = useForm({ defaultValues })

  // useEffect(() => {
  //   //TO DO: replace mock of disabling footer

  //   setIsDisable(currentTemplate === mockTemplates[0])
  // }, [currentTemplate])

  // const customizeTemplate = () => {
  //   // Implement template customization here
  // }

  // const handleProfitTabChange = (_: React.BaseSyntheticEvent, newValue: string) => {
  //   setProfitTab(newValue)
  // }

  // const onSubmit: SubmitHandler<typeof defaultValues> = (data) => {
  //   // Send data to backend
  //   return data
  // }

  const onSubmit = async () => {
    if (!holding || !userData) {
      return
    }

    try {
      setIsLoading(true)
      await createProfitPanel({
        token_address: holding.a,
        userId: userData.user_id,
        isActive: true,
        isDeleted: false,
      })
      CustomToast('success', 'token_info.added_token_to_pnl')
      navigate({
        isDashboard: true,
        searchParams: {
          buySell: 'profit/loss',
        },
      })
    } catch (err) {
      handleError(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Stack className={styles.stack}>
      <Grid className={styles.content}>
        <Grid>
          <ListButton withArrowIcon={false} disabled className={styles.tokenWrapper}>
            {holding && <AddTokenButton holding={holding} />}
          </ListButton>
        </Grid>

        {/* <Grid>
          <TemplatesControl
            templates={templates}
            currentTemplateId={currentTemplate?._id || mockTemplates[0]._id}
            onSelectTemplate={(id) => id}
            onCustomize={customizeTemplate}
            customizeDisabled={currentTemplate?._id === '1'}
            editDisabled={currentTemplate?._id === '1'}
            deleteDisabled={!!currentTemplate && ['1', '2'].includes(currentTemplate._id)}
          />
        </Grid> */}

        {/* <form className={styles.form} onSubmit={handleSubmit(onSubmit)}> */}
        {/* <Stack spacing={2} useFlexGap>
            <Typography variant="body2" component="h2" textColor="white">
              Safety Settings
            </Typography>
            <Grid
              container
              className={styles.row}
              justifyContent="space-between"
              alignItems="center"
              wrap="nowrap"
              gap={2}
            >
              <Controller
                name="transferOnBlacklist"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <SwitchInline
                    label="Transfer On Blacklist"
                    tooltipInfo="Will transfer tokens to the backup wallet when the developer tries to blacklist your wallet."
                    {...field}
                  />
                )}
              />

              <Controller
                name="antiBlacklist"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <SwitchInline label="Anti-Blacklist" tooltipInfo="Sells or transfers (if blacklist transfer is enabled) tokens to your wallet upon a blacklist event." {...field} />
                )}
              />
            </Grid>
          </Stack> */}
        {/* <Stack spacing={2} useFlexGap>
            <Typography variant="body2" component="h2" textColor="white">
              Sell Settings
            </Typography>
            <Grid
              container
              className={styles.row}
              justifyContent="space-between"
              alignItems="center"
              wrap="nowrap"
              gap={2}
            >
              <HiddenInputs
                expanded={isAutoSellSettings}
                trigger={
                  <Controller
                    name="autoSell"
                    control={control}
                    render={({ field: { ref, onChange, ...field } }) => (
                      <SwitchInline
                        label="Auto Sell"
                        tooltipInfo="The bot will automatically sell tokens when the settings you specified are triggered."
                        {...field}
                        onChange={(_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                          setIsAutoSellSetting(checked)
                          onChange(checked)
                        }}
                      />
                    )}
                  />
                }
              >
                <Grid container rowGap={2} flexDirection="column">
                  <Controller
                    name="sellPriority"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <OptionalInput
                        placeholder="Enter gwei amount"
                        isNumeric
                        label="Sell Priority"
                        tooltipInfo="Enter the extra Gwei amount that will be used to prioritize your transaction in the network. 1 Gwei is equal to 0.000000001 ETH."
                        endAdornment={<EndAdornment label="GWEI" icon={IconName.ETHEREUM} />}
                        onAutoChange={() => {
                          //TO DO: added auto switch
                        }}
                        {...field}
                      />
                    )}
                  />
                  <div className={styles.dividerContainer}>
                    <Typography variant="body2" textColor="grey">
                      Profit
                    </Typography>
                    <div className={styles.divider}></div>
                  </div>

                  <Controller
                    name="walletsToSellOnProfit"
                    control={control}
                    render={({ field: { ref, value, onChange, ...field } }) => (
                      <SelectWallet
                        tooltipInfo="Select from which wallets tokens will be sold when they reach a specified amount of profit."
                        label="Wallets to sell on Profit"
                        isMulti
                        wallet={value}
                        onChange={(value) => {
                          setWalletsOnProfit(value)
                          onChange(value)
                        }}
                        {...field}
                      />
                    )}
                  />

                  <ButtonGroupRadio
                    onChange={handleProfitTabChange}
                    value={profitTab}
                    exclusive
                    className={styles.group}
                  >
                    {ProfitSettingsTabs.map((tab) => (
                      <ButtonGroupRadioButton
                        value={tab.value}
                        key={tab.value}
                        className={cls({ [styles.bgGrey]: tab.value === profitTab })}
                      >
                        {tab.label}
                      </ButtonGroupRadioButton>
                    ))}
                  </ButtonGroupRadio>

                  {profitTab === ProfitSettingsTabs[0].value && (
                    <div>
                      <div className={styles.labelContent}>
                        <InputLabel className={styles.label}>
                          <Typography variant="body2" textColor="light-grey">
                            Sell in steps
                          </Typography>
                        </InputLabel>
                        <TooltipIcon info="This setting allows you to decide at what profits how many wallets will sell. For example, 2 wallets will sell at 1000% profit, next 2 wallets will sell at 2000%." marginLeft={5} />
                      </div>
                      {walletsOnProfit.length !== 0 ? (
                        <div className={styles.stepWrapper}>
                          {walletsOnProfit.map((wallet, index) => (
                            <Controller
                              key={wallet}
                              name={`w${wallet}` as keyof typeof defaultValues}
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <StepInput
                                  wallet="#1"
                                  balance="12.58 ETH"
                                  className={cls({
                                    [styles.firstInput]: index === 0,
                                    [styles.lastInput]: index === walletsOnProfit.length - 1,
                                  })}
                                  {...field}
                                />
                              )}
                            />
                          ))}
                        </div>
                      ) : (
                        <Grid marginTop={1}>
                          <Typography variant="body1">No wallets selected</Typography>
                        </Grid>
                      )}
                    </div>
                  )}
                  {profitTab === ProfitSettingsTabs[1].value && (
                    <Grid container flexWrap="nowrap" columnGap={3}>
                      <Controller
                        name="sellOnProfit"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <OptionalInput
                            placeholder="Enter sell on profit"
                            isNumeric
                            label="Sell on Profit"
                            adornmentText="%"
                            tooltipInfo="Threshold of profit on token position. Sells when the position reaches the specified profit amount."
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        name="sellOnProfitAmount"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <OptionalInput
                            placeholder="Enter sell profit amount"
                            isNumeric
                            label="Sell Profit Amount"
                            adornmentText="%"
                            tooltipInfo="The percentage of tokens that will be sold when the Take Profit is triggered."
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                  )}
                  {profitTab === ProfitSettingsTabs[2].value ? (
                    <>
                      <Controller
                        name="walletsToTrailingSell"
                        control={control}
                        render={({ field: { ref, value, ...field } }) => (
                          <SelectWallet
                            isMulti
                            wallet={value}
                            label="Wallets to trailing sell"
                            tooltipInfo="tooltipInfo"
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        name="trailingSell"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <OptionalInput
                            placeholder="Enter trailing sell"
                            isNumeric
                            label="Trailing Sell"
                            adornmentText="%"
                            tooltipInfo="tooltipInfo"
                            {...field}
                          />
                        )}
                      />
                    </>
                  ) : (
                    <>
                      <div className={styles.dividerContainer}>
                        <Typography variant="body2" textColor="grey">
                          Loss
                        </Typography>
                        <div className={styles.divider}></div>
                      </div>

                      <Controller
                        name="walletsToSellOnLoss"
                        control={control}
                        render={({ field: { ref, value, ...field } }) => (
                          <SelectWallet
                            label="Wallets to sell on Loss"
                            tooltipInfo="tooltipInfo"
                            isMulti
                            wallet={value}
                            {...field}
                          />
                        )}
                      />

                      <Grid container flexWrap="nowrap" columnGap={3}>
                        <Controller
                          name="stopLoss"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <OptionalInput
                              placeholder="Enter amount"
                              isNumeric
                              label="Stop Loss"
                              tooltipInfo="Threshold of loss on token position, exceeding which tokens will be sold."
                              adornmentText="%"
                              {...field}
                            />
                          )}
                        />

                        <Controller
                          name="stopLossAmount"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <OptionalInput
                              placeholder="Enter amount"
                              isNumeric
                              label="Stop Loss Amount"
                              tooltipInfo="The percentage of tokens that will be sold when the stop loss is triggered."
                              adornmentText="%"
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </HiddenInputs>
            </Grid>
          </Stack> */}
        <Button
          type="submit"
          // disabled={isDisable}
          disabled={!holding}
          onClick={onSubmit}
          isLoading={isLoading}
        >
          {t('add')}
        </Button>
        {/* </form> */}
      </Grid>
    </Stack>
  )
}

// TODO: remove mock data
// const mockPassedTokenData: TokenDTO = {
//   id: '001',
//   name: 'Ethereum',
//   nameShort: 'ETH',
//   amount: 0.12434,
//   worth: 315.37,
//   address: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
// }

export { AddTokenSetup }
