import { useTranslation } from 'react-i18next'

import { Typography } from '@/libs/common'

const NoTokenSelected = () => {
  const { t } = useTranslation()
  return (
    <Typography
      variant="body2"
      textColor="color-grey-2"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      {t('token_list.no_token_selected')}
    </Typography>
  )
}
export { NoTokenSelected }
