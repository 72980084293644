import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {
  Box,
  Collapse,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import cn from 'classnames'

import { SellPopup } from '@/components/sell-popup'
import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { ButtonIcon, Icon, type TOnChangeVisible } from '@/libs/common'
import { AppRoute, IconName } from '@/libs/enums'
import { splitAndTrimString } from '@/libs/helper'
import { TPositionsTableRowData } from '@/libs/types/positions-table-row-data'

import styles from './styles.module.scss'

type TProps = {
  row: TPositionsTableRowData
  index: number
}

const Row: FC<TProps> = ({ row, index }) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [sellPopup, setSellPopup] = useState<TOnChangeVisible>({
    visible: false,
    anchorE: undefined,
  })
  const navigate = useCustomNavigate()

  const handleOpenProfitSellPanel = () => {
    navigate({
      isDashboard: true,
      path: `${AppRoute.MODAL}/${AppRoute.PROFIT_SELL_ADD_TOKEN_SETUP}/123`,
    })
  }

  return (
    <>
      <TableRow
        className={cn(styles.tableRow, {
          [styles.backgroundEven]: index % 2 !== 0,
          [styles.backgroundOdd]: index % 2 === 0,
        })}
      >
        <TableCell className={cn(styles.cell, styles.rowCell)}>
          <IconButton onClick={() => setOpen(!open)}>
            {open ? (
              <KeyboardArrowUpIcon className={styles.icon} />
            ) : (
              <KeyboardArrowDownIcon className={styles.icon} />
            )}
          </IconButton>
        </TableCell>

        <TableCell
          className={cn(styles.cell, styles.tableRow, styles.paddingZero)}
          component="th"
          scope="row"
        >
          {row.position}
        </TableCell>

        <TableCell
          className={cn(
            styles.cell,
            styles.tableRow,
            row.status ? styles.statusOpen : styles.statusClosed,
          )}
          align="right"
        >
          {row.status ? 'Open' : 'Closed'}
        </TableCell>

        <TableCell className={cn(styles.cell, styles.tableRow)} align="right">
          {row.trader}
        </TableCell>

        <TableCell className={cn(styles.cell, styles.tableRow)} align="right">
          {row.totalSpent}
        </TableCell>

        <TableCell className={cn(styles.cell, styles.tableRow)} align="right">
          {row.totalRevenue}
        </TableCell>

        <TableCell
          className={cn(styles.cell, styles.tableRow, styles.colorGreen, styles.rowPadding)}
          align="right"
        >
          <Box display="flex" flexDirection="column">
            <div>{splitAndTrimString(row.realizedPL, 'ROI')[0]}</div>
            <div>{splitAndTrimString(row.realizedPL, 'ROI')[1]}</div>
          </Box>
        </TableCell>

        <TableCell className={cn(styles.cell, styles.tableRow, styles.colorGreen)} align="right">
          <Box display="flex" flexDirection="column">
            <div>{splitAndTrimString(row.potentialPL, 'ROI')[0]}</div>
            <div>{splitAndTrimString(row.potentialPL, 'ROI')[1]}</div>
          </Box>
        </TableCell>

        <TableCell className={cn(styles.cell, styles.tableRow, styles.tableButtonsRow)}>
          <Grid className={styles.popupContainer}>
            <ButtonIcon
              tooltipTitle={t('sell')}
              icon={IconName.SELL}
              onClick={(e) => {
                setSellPopup({ visible: true, anchorE: e.currentTarget })
              }}
            />

            <SellPopup onChangeVisible={setSellPopup} popoverState={sellPopup} />
          </Grid>

          <ButtonIcon tooltipTitle="Profit sell" onClick={handleOpenProfitSellPanel}>
            <Icon name={IconName.PROFIT_SELL} />
          </ButtonIcon>
        </TableCell>
      </TableRow>

      <TableRow className={styles.backgroundEven}>
        <TableCell
          className={cn(styles.cell, styles.cellWrapper)}
          sx={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={8}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1} width={'100%'}>
              <Table classes={styles} size="small" aria-label="purchases">
                <TableHead className={styles.tableHead}>
                  <TableRow>
                    <TableCell className={cn(styles.cell, styles.paddingZero)}></TableCell>

                    <TableCell className={cn(styles.cell, styles.paddingZero)}></TableCell>

                    <TableCell className={cn(styles.cell, styles.paddingZero)}></TableCell>

                    <TableCell className={cn(styles.cell, styles.paddingZero)}></TableCell>

                    <TableCell className={cn(styles.cell, styles.paddingZero)}></TableCell>

                    <TableCell className={cn(styles.cell, styles.paddingZero)}></TableCell>

                    <TableCell className={cn(styles.cell, styles.paddingZero)}></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {row.history.map((historyRow, index) => (
                    <TableRow key={historyRow.name + index}>
                      <TableCell
                        className={cn(styles.cell, styles.timeCeil)}
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {historyRow.name}
                      </TableCell>

                      <TableCell
                        className={cn(styles.cell, styles.tokenCeil)}
                        align="right"
                      ></TableCell>

                      <TableCell
                        className={cn(styles.cell, styles.tokenCeil)}
                        align="right"
                      ></TableCell>

                      <TableCell className={cn(styles.cell, styles.spentCeil)} align="center">
                        {historyRow.spent}
                      </TableCell>

                      <TableCell className={cn(styles.cell, styles.revenueCeil)} align="center">
                        {historyRow.revenue}
                      </TableCell>

                      <TableCell
                        className={cn(styles.cell, styles.colorGreen, styles.plCeil)}
                        align="right"
                      >
                        <Box display="flex" flexDirection="column">
                          <div>{splitAndTrimString(historyRow.realizedPL, 'ROI')[0]}</div>
                          <div>{splitAndTrimString(historyRow.realizedPL, 'ROI')[1]}</div>
                        </Box>
                      </TableCell>

                      <TableCell
                        className={cn(styles.cell, styles.colorGreen, styles.plCeil)}
                        align="right"
                      >
                        <Box display="flex" flexDirection="column">
                          <div>{splitAndTrimString(historyRow.potential, 'ROI')[0]}</div>
                          <div>{splitAndTrimString(historyRow.potential, 'ROI')[1]}</div>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export { Row }
