import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Grid } from '@mui/material'

import { useWindowDimensions } from '@/hooks/useWindowDimensions'
import { EndAdornment } from '@/libs/common/input/components/end-adornment'
import { OptionalInput } from '@/libs/common/optional-input'
import { SwitchInline } from '@/libs/common/switch-inline'
import { ChainName, IconName } from '@/libs/enums'
import { TManualBuyValidationSchema } from '@/libs/validations/types'
import { useAppSelector } from '@/store'

const ManualTemplateSafetyTab = ({
  iconName,
  chainSymbol,
  chainName,
}: {
  chainName: ChainName
  iconName: any
  chainSymbol: string
  templateId: string | undefined
}) => {
  const { t } = useTranslation()
  //const [isAntiRugSettings, setIsAntiRugSettings] = useState(false)
  const windowDimension = useWindowDimensions()
  const {
    control,
    formState: { errors },
    getValues,
  } = useFormContext<TManualBuyValidationSchema>()
  const degenChadMode = getValues().template.ordinaryBuy.degenChadMode
  const isResponsive = windowDimension.width <= 630
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  return (
    <Grid container rowGap={2} flexDirection="column" className={chainName}>
      {/* {!chainSettings[EManualSettingsSection.SAFETY]!.blackListSettings && (
        <Grid container columnGap={3} flexWrap="nowrap">
          <Controller
            name="template.transferOnBlacklist"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <SwitchInline
                label="Transfer On Blacklist"
                tooltipInfo="Will transfer tokens to the backup wallet when the developer tries to blacklist your wallet."
                {...field}
              />
            )}
          />
          <Controller
            name="template.antiBlacklist"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <SwitchInline
                label="Anti-Blacklist"
                tooltipInfo="Sells or transfers (if blacklist transfer is enabled) tokens to your wallet upon a blacklist event."
                {...field}
              />
            )}
          />
        </Grid>
      )}

      {!chainSettings[EManualSettingsSection.SAFETY]!.blackListSettings && (
        <HiddenInputs
          expanded={isAntiRugSettings}
          trigger={
            <Controller
              name="template.antiRug"
              control={control}
              render={({ field: { ref, onChange, ...field } }) => (
                <SwitchInline
                  label="Anti-Rug"
                  tooltipInfo="Bot will sell your position upon a liquidity removal event or any honeypot attempt on token smart contract."
                  onChange={(_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                    setIsAntiRugSettings(checked)
                    onChange(checked)
                  }}
                  {...field}
                />
              )}
            />
          }
        >
          <Grid container rowGap={2}>
            <Controller
              name="template.antiRugThreshold"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <PercentsInput
                  label="Anti-Rug Threshold"
                  onOptionSelect={(value: any) => field.onChange(value)}
                  tooltipInfo="Bot will sell your position upon a liquidity removal event or any honeypot attempt on token smart contract."
                  {...field}
                />
              )}
            />
            <Controller
              name="template.sellRugExtraTip"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <OptionalInput
                  placeholder="Amount"
                  isNumeric
                  label="Sell Rug Extra Tip"
                  tooltipInfo="The amount of Gwei that will be added over developer transaction to prioritize your transaction."
                  {...field}
                />
              )}
            />
          </Grid>
        </HiddenInputs>
      )} */}
      {!currentChain.features?.noAntiDuplicate && (
        <Grid container flexWrap="nowrap" gap={3} flexDirection={isResponsive ? 'column' : 'row'}>
          <Controller
            disabled={degenChadMode}
            name="template.shields.anti_duplicate"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <SwitchInline
                label="Anti Duplicate"
                tooltipInfo="Does not purchase the token if the user already owns it."
                {...field}
              />
            )}
          />
        </Grid>
      )}

      {(currentChain.features?.maxBuyTaxForTemplates ||
        currentChain.features?.maxSellTaxForTemplates) && (
        <Grid container flexWrap="nowrap" gap={3} flexDirection={isResponsive ? 'column' : 'row'}>
          {!!currentChain.features?.maxBuyTaxForTemplates && (
            <Controller
              name="template.shields.buy_tax"
              control={control}
              disabled={degenChadMode}
              render={({ field: { ref, ...field } }) => (
                <OptionalInput
                  placeholder={t('template.enter_percentage')}
                  isNumeric
                  label={t('template.max_buy_tax')}
                  adornmentText="%"
                  tooltipInfo={t('template.max_buy_tax_tooltip')}
                  error={!!errors.template?.shields?.buy_tax?.message}
                  {...field}
                />
              )}
            />
          )}
          {!!currentChain.features?.maxSellTaxForTemplates && (
            <Controller
              name="template.shields.sell_tax"
              control={control}
              disabled={degenChadMode}
              render={({ field: { ref, ...field } }) => (
                <OptionalInput
                  placeholder={t('template.enter_percentage')}
                  isNumeric
                  label={t('template.max_sell_tax')}
                  adornmentText="%"
                  tooltipInfo={t('template.max_sell_tax_tooltip')}
                  error={!!errors.template?.shields?.sell_tax?.message}
                  {...field}
                />
              )}
            />
          )}
        </Grid>
      )}
      <Grid container flexWrap="nowrap" gap={3} flexDirection={isResponsive ? 'column' : 'row'}>
        <Controller
          name="template.shields.minimum_liquidity"
          control={control}
          disabled={degenChadMode}
          render={({ field: { ref, ...field } }) => (
            <OptionalInput
              placeholder={t('token_info.enter_min_liquidity')}
              isNumeric
              label={t('token_info.min_liquidity')}
              endAdornment={<EndAdornment label={chainSymbol} icon={(IconName as any)[iconName]} />}
              tooltipInfo={t('token_info.min_liquidity_tooltip')}
              error={!!errors.template?.shields?.minimum_liquidity?.message}
              {...field}
            />
          )}
        />

        <Controller
          name="template.shields.maximum_liquidity"
          control={control}
          disabled={degenChadMode}
          render={({ field: { ref, ...field } }) => (
            <OptionalInput
              placeholder={t('token_info.enter_max_liquidity')}
              isNumeric
              label={t('token_info.max_liquidity')}
              endAdornment={<EndAdornment label={chainSymbol} icon={(IconName as any)[iconName]} />}
              tooltipInfo={t('token_info.max_liquidity_tooltip')}
              error={!!errors.template?.shields?.maximum_liquidity?.message}
              {...field}
            />
          )}
        />
      </Grid>

      <Controller
        name="template.shields.maximum_market_cap"
        control={control}
        disabled={degenChadMode}
        render={({ field: { ref, ...field } }) => (
          <OptionalInput
            placeholder={t('token_info.enter_max_marketcap')}
            isNumeric
            label={t('token_info.max_marketcap')}
            adornmentText="$"
            tooltipInfo={t('token_info.max_marketcap_tooltip')}
            error={!!errors.template?.shields?.maximum_market_cap?.message}
            {...field}
          />
        )}
      />
    </Grid>
  )
}

export { ManualTemplateSafetyTab }
