import { useEffect } from 'react'

import { CustomToast } from '@/components/custom-toast'
import { TokenWarnings } from '@/libs/enums/token-warnings.enum'

type Params = {
  isHoneypot: boolean
  isDisabled: boolean
  isLowLiquidity: boolean
  isHighBuyTax: boolean
  isHighSellTax: boolean
}

function useTokenWarnings({
  isHoneypot,
  isDisabled,
  isLowLiquidity,
  isHighBuyTax,
  isHighSellTax,
}: Params) {
  useEffect(() => {
    if (isHoneypot) {
      CustomToast('error', TokenWarnings.HONEYPOT, { noIcon: true, noTitle: true })
    }
  }, [isHoneypot])

  useEffect(() => {
    if (isDisabled) {
      CustomToast('error', TokenWarnings.DISABLED, { noIcon: true, noTitle: true })
    }
  }, [isDisabled])

  useEffect(() => {
    if (isLowLiquidity) {
      CustomToast('error', TokenWarnings.LIQUIDITY, { noIcon: true, noTitle: true })
    }
  }, [isLowLiquidity])

  useEffect(() => {
    if (isHighBuyTax) {
      CustomToast('error', TokenWarnings.BUY_TAX, { noIcon: true, noTitle: true })
    }
  }, [isHighBuyTax])

  useEffect(() => {
    if (isHighSellTax) {
      CustomToast('error', TokenWarnings.SELL_TAX, { noIcon: true, noTitle: true })
    }
  }, [isHighSellTax])
}

export { useTokenWarnings }
