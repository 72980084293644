import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { updateBuyTemplate } from '@/api/templates'
import useCheckUser from '@/hooks/useCheckUser.ts'
import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { Accordion, AccordionListElement, Icon, Typography } from '@/libs/common'
import { DragAndDropList } from '@/libs/common/drag-and-drop-list'
import { AppRoute, IconName, TemplateGroup } from '@/libs/enums'
import { TBuyTemplate } from '@/libs/types/template'
import { useAppDispatch, useAppSelector } from '@/store'
import { setCurrentSelectedTemplate, setUserBuyTemplates } from '@/store/slices/user.slice'

import styles from './styles.module.scss'

type TProps = {
  isOpen?: boolean
}

const TemplatesAccordion: FC<TProps> = ({ isOpen }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const userTemplates = useAppSelector((state) => state.user.userTemplates.buyTemplates)
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  const buyTemplates = useMemo(
    () =>
      userTemplates?.filter(
        (item) => item.setup.network.blockchain === currentChain.indexerChainId,
      ),
    [userTemplates, currentChain],
  )

  const [isSomeElementDragging, setIsSomeElementDragging] = useState(false)
  const [expanded, setExpanded] = useState(false)

  const navigate = useCustomNavigate()
  const checkUserAndExecute = useCheckUser()

  useEffect(() => {
    if (!isOpen && expanded) {
      setExpanded(false)
    }
  }, [isOpen])

  const handleOpenModal = () => {
    checkUserAndExecute(() => {
      navigate({ isDashboard: true, path: `${AppRoute.MODAL}/${AppRoute.TEMPLATE_MANAGEMENT}` })
    })
  }

  const openTemplateSettings = (templateId: string) => {
    dispatch(
      setCurrentSelectedTemplate({
        group: TemplateGroup.BUY,
        id: templateId,
      }),
    )
    navigate({
      isDashboard: true,
      path: `${AppRoute.MODAL}/${AppRoute.MANUAL_BUY_TEMPLATE}/${templateId}`,
    })
  }

  const handleChangeBuyTemplatesOrder = (_: number, newIndex: number) => {
    const changedTemplate = buyTemplates?.[newIndex]
    if (!changedTemplate) return
    updateBuyTemplate(changedTemplate.id, { order: (newIndex + 1).toString() })
  }

  const renderItem = useCallback(
    (template: TBuyTemplate, isDragging: boolean) => (
      <AccordionListElement
        disableHover={isSomeElementDragging && !isDragging}
        onClick={() => {
          openTemplateSettings(template.id)
        }}
        className={styles.templateItem}
        key={template.id}
        enableBurger
      >
        <Typography variant="body2" className={styles.content}>
          {template.name}
        </Typography>
      </AccordionListElement>
    ),
    [isSomeElementDragging],
  )

  return (
    <Accordion
      title={t('template.templates')}
      headerIcon={<Icon name={IconName.TEMPLATES_GREY} />}
      titleClassName={styles.title}
      enableSettings
      onSettingClick={handleOpenModal}
      contentWithScroll="templates"
      expanded={expanded}
      onChange={setExpanded}
    >
      <div className={styles.group}>
        <Typography variant="body2" textColor="light-grey">
          {t('template.manual_buy')}
        </Typography>
        {buyTemplates?.length ? (
          <DragAndDropList
            data={buyTemplates}
            propOfItemForKey="id"
            setData={(newOrder) => {
              dispatch(setUserBuyTemplates(newOrder))
            }}
            renderItem={renderItem}
            onDragStateChange={(state) => {
              setIsSomeElementDragging(state)
            }}
            onOrderChanged={handleChangeBuyTemplatesOrder}
          />
        ) : (
          <Typography variant="body1" component="span" textColor="grey" align="center">
            {t('template.no_templates')}
          </Typography>
        )}
      </div>
    </Accordion>
  )
}

export { TemplatesAccordion }
