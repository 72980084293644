import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import cn from 'classnames'

import { AuthCard, TAuthCardProps } from '@/components/auth-card'
import { Typography } from '@/libs/common/typography'
import { AppRoute, IconName, VerificationStatus } from '@/libs/enums'

import { SetupWalletsButtonLink as ButtonLink } from './libs/components'
import styles from './styles.module.scss'

const SetupWallets: FC<TAuthCardProps> = ({ className, ...props }) => {
  const { t } = useTranslation()

  return (
    <AuthCard
      className={cn(styles.setupWalletsAuthCard, className)}
      verificationStatus={VerificationStatus.SETUP_WALLETS}
      isHeaderButtons
      {...props}
    >
      <Grid
        container
        alignItems="center"
        direction="column"
        gap={1}
        className={styles.titleContainer}
      >
        <Typography variant="h1" align="center" className={styles.title}>
          {t('login.setup_wallets')}
        </Typography>
        <Typography variant="body1">{t('login.choose_method_for_wallets')}</Typography>
      </Grid>

      <Stack spacing={2} useFlexGap>
        <ButtonLink
          to={AppRoute.GENERATE_WALLETS}
          iconName={IconName.MANUFACTURING}
          title={t('setup_wallets.generate_new_wallets')}
          description={t('setup_wallets.setup_wallet_description')}
        />

        <ButtonLink
          to={AppRoute.IMPORT_KEYS}
          iconName={IconName.KEY}
          title={t('setup_wallets.import_wallets')}
          description={t('setup_wallets.connect_existing_wallet')}
        />

        {/* TODO: Uncomment when those features are ready */}
        {/* <ButtonLink
          to={AppRoute.IMPORT_WALLET_FILE}
          iconName={IconName.LIST_ALT_ADD}
          title="Import wallet file"
          description="If you have installed wallets on your computer, you can import them by file."
        /> */}
      </Stack>
    </AuthCard>
  )
}

export { SetupWallets }
