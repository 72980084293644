import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppMode } from '@/hooks/useAppMode'
import { ListButton } from '@/libs/common'
import { ButtonLink } from '@/libs/common/button-link'
import { AppRoute, IconName } from '@/libs/enums'
import { ESidebarBtnType } from '@/libs/enums/sidebar-btn-type'
import { TUserWallet } from '@/libs/types/user.type'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

type TProperty = {
  wallet: TUserWallet | undefined
  type?: ESidebarBtnType
}
const TransferTab: FC<TProperty> = ({ wallet, type }) => {
  const { mode } = useAppMode()
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const { t } = useTranslation()

  return (
    <div className={styles.container}>
      {(type === ESidebarBtnType.TRANSFER || !type) && (
        <ButtonLink
          to={`${AppRoute.DASHBOARD}/${mode}/${currentChain.description.toLowerCase()}/${AppRoute.MODAL}/${AppRoute.TRANSFER}/${wallet?.address}`}
        >
          <ListButton
            title={t('modal.transfer.transfer')}
            description={t('modal.transfer.transfer_description')}
            iconName={IconName.WITHDRAW}
          />
        </ButtonLink>
      )}
      {(type === ESidebarBtnType.DISPERSE_COLLECT || !type) && (
        <>
          <ButtonLink
            to={`${AppRoute.DASHBOARD}/${mode}/${currentChain.description.toLowerCase()}/${AppRoute.MODAL}/${AppRoute.DISPERSE_MULTI}/${wallet?.address}`}
          >
            <ListButton
              title={t('modal.transfer.disperse_multi')}
              description={t('modal.transfer.disperse_description')}
              iconName={IconName.CROSS}
            />
          </ButtonLink>
          <ButtonLink
            to={`${AppRoute.DASHBOARD}/${mode}/${currentChain.description.toLowerCase()}/${AppRoute.MODAL}/${AppRoute.COLLECT_MULTI}/${wallet?.address}`}
          >
            <ListButton
              title={t('modal.transfer.collect_multi')}
              description={t('modal.transfer.collect_description')}
              iconName={IconName.CROSS}
            />
          </ButtonLink>
        </>
      )}
    </div>
  )
}

export { TransferTab }
