import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import MUIButton, { ButtonProps as MUIButtonProps } from '@mui/material/Button'
import cn from 'classnames'

import { Spinner } from '@/components/spinner/spinner'
import { Icon } from '@/libs/common/icon'
import { Tooltip } from '@/libs/common/tooltip/index'
import { IconName, SpinnerSize } from '@/libs/enums'
import { ValueOf } from '@/libs/types'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

type TStyleVariant =
  | 'yellow'
  | 'purple'
  | 'black'
  | 'red'
  | 'basic'
  | 'borderless-text'
  | 'borderless-gold'
  | 'borderless-red'

type TButtonProps = MUIButtonProps & {
  styleVariant?: TStyleVariant
  outlined?: boolean
  borderless?: boolean
  iconButton?: boolean
  transparent?: boolean
  contentMaxWidth?: boolean
  iconStart?: React.ReactNode
  iconStartName?: ValueOf<typeof IconName>
  iconEndName?: ValueOf<typeof IconName>
  isLoading?: boolean
  tooltipTitle?: string
  checkOnAccountLock?: boolean
}

const Button: FC<TButtonProps> = ({
  children,
  className,
  variant = 'contained',
  styleVariant = 'yellow',
  outlined = false,
  borderless = false,
  iconButton = false,
  transparent = false,
  contentMaxWidth = false,
  iconStart,
  iconStartName,
  iconEndName,
  disabled,
  isLoading,
  tooltipTitle,
  checkOnAccountLock = false,
  ...props
}) => {
  const userData = useAppSelector((state) => state.user.userData)
  const accountLockState = checkOnAccountLock && !!userData?.locked
  const { t } = useTranslation()

  const button = (
    <MUIButton
      variant={variant}
      className={cn(
        styles.root,
        styles[styleVariant],
        {
          [styles.outlined]: outlined,
          [styles.borderless]: borderless,
          [styles.iconButton]: iconButton,
          [styles.transparent]: transparent,
        },
        className,
      )}
      disabled={disabled || isLoading || accountLockState}
      {...props}
    >
      {iconStart}
      {iconStartName && <Icon name={iconStartName as any} />}
      <span className={cn(styles.textContent, { [styles.contentMaxWidth]: contentMaxWidth })}>
        {isLoading ? <Spinner size={SpinnerSize.SMALL} /> : children}
      </span>
      {iconEndName && <Icon name={iconEndName as any} />}
    </MUIButton>
  )

  return tooltipTitle || accountLockState ? (
    <Tooltip
      title={accountLockState ? t('menu.unlock_access') : tooltipTitle}
      withIcon={false}
      placement="top"
      className={styles.tooltip}
    >
      {button}
    </Tooltip>
  ) : (
    button
  )
}

export { Button, type TButtonProps }
