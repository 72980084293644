import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Grid } from '@mui/material'
import cn from 'classnames'

import { PriorityCurrency } from '@/components/priority-currency'
import { SelectWallet } from '@/components/select-wallet'
import { useWindowDimensions } from '@/hooks/useWindowDimensions'
import { Button, Typography } from '@/libs/common'
import { EndAdornment } from '@/libs/common/input/components/end-adornment'
import { OptionalInput } from '@/libs/common/optional-input'
import { PercentsInput } from '@/libs/common/percents-input'
import { SwitchInline } from '@/libs/common/switch-inline'
import { ChainName, EManualSettingsSection, IconName } from '@/libs/enums'
import { formatNumber } from '@/libs/helper'
import { getChainSensitiveLabel } from '@/libs/helper/getChainSensitiveLabel'
import { TManualBuyValidationSchema } from '@/libs/validations/types'
import { useAppSelector } from '@/store'

import { MANUAL_BUY_CHAIN_SETTINGS } from '../../libs/constants'
import styles from '../../styles.module.scss'

type TProps = {
  chainName: ChainName
  templateId: string | undefined
  isInit: boolean
  isPercentageUnit: boolean
  setIsPercentageUnit: (value: boolean) => void
}

const ManualTemplateBuyTab: FC<TProps> = ({
  chainName,
  templateId,
  isInit,
  isPercentageUnit,
  setIsPercentageUnit,
}) => {
  const { t } = useTranslation()
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const windowDimension = useWindowDimensions()
  const userWallets = useAppSelector((state) => state.user.userWallets)

  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext<TManualBuyValidationSchema>()

  const chainSettings = MANUAL_BUY_CHAIN_SETTINGS[chainName]
  const isEditAction = !!templateId || !isInit
  const isResponsive = windowDimension.width <= 630

  return (
    <Grid container rowGap={2}>
      <Grid container gap={3} flexWrap="nowrap" flexDirection={isResponsive ? 'column' : 'row'}>
        {!currentChain.features?.noPrivateTx && (
          <Controller
            name="template.ordinaryBuy.privateTransaction"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <SwitchInline
                label={t('template.anti_mev')}
                tooltipInfo={t('template.anti_mev_tooltip')}
                {...field}
              />
            )}
          />
        )}

        {!!currentChain.features?.degenMode && (
          <Controller
            name="template.ordinaryBuy.degenChadMode"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <SwitchInline
                label={t('template.degen_chad_mode')}
                tooltipInfo={t('template.degen_chad_mode_tooltip')}
                {...field}
              />
            )}
          />
        )}
      </Grid>

      <Controller
        name="template.ordinaryBuy.slippage"
        control={control}
        render={({ field: { ref, ...field } }) => (
          <PercentsInput
            label={t('template.slippage')}
            onOptionSelect={(value: any) => field.onChange(value)}
            tooltipInfo={t('template.slippage_tooltip')}
            placeholder="X"
            error={!!errors.template?.ordinaryBuy?.slippage?.message}
            withAuto={chainSettings[EManualSettingsSection.BUY]!.autoSlippage ?? true}
            {...field}
          />
        )}
      />

      <Controller
        name="template.ordinaryBuy.selectedWallets"
        control={control}
        render={({ field: { ref, value, onChange, ...field } }) => (
          <SelectWallet
            tooltipInfo={t('wallet.select_wallets_tooltip')}
            isMulti
            wallet={value as string[]}
            wallets={userWallets}
            isSelectDefaultWallet={!isEditAction}
            onChange={(value: any) => {
              onChange(value)
            }}
            {...field}
          />
        )}
      />

      {!currentChain.features?.noMinTokens && (
        <Controller
          name="template.advancedBuy.minPercentTokenOrFail"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <OptionalInput
              className={styles.input}
              label={t('amount_values.exact_percentage_tokens')}
              placeholder={t('amount_values.enter_token_amount_or_percentage')}
              isNumeric
              tooltipInfo={t('amount_values.enter_token_amount_or_percentage_tooltip')}
              endAdornment={
                <Button
                  className={cn(styles.toggleOption, isPercentageUnit && styles.activeToggleOption)}
                  transparent
                  onClick={() => {
                    setIsPercentageUnit(!isPercentageUnit)
                  }}
                >
                  %
                </Button>
              }
              disabled={watch('template.advancedBuy.maxTxOrFail')}
              error={!!errors.template?.advancedBuy?.minPercentTokenOrFail?.message}
              {...field}
            />
          )}
        />
      )}

      {currentChain.features?.buyPriority && (
        <Controller
          name="template.advancedBuy.buyPriority"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <OptionalInput
              isNumeric
              className={styles.input}
              label={getChainSensitiveLabel('buyPriority')}
              placeholder={t('template.enter_gwei_amount', {
                currency: currentChain.priorityCurrency.toLowerCase(),
              })}
              tooltipInfo={`An extra fee that you can add to to speed up the transaction execution. 1 GWEI =0.000000001 ${currentChain.chainSymbol}.`}
              endAdornment={<PriorityCurrency />}
              error={!!errors.template?.advancedBuy?.buyPriority?.message}
              {...field}
            />
          )}
        />
      )}

      {currentChain.features?.approvePriority && (
        <Controller
          name="template.advancedBuy.approvePriority"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <OptionalInput
              className={styles.input}
              label={getChainSensitiveLabel('approvePriority')}
              placeholder={t('template.enter_gwei_amount', {
                currency: currentChain.priorityCurrency.toLowerCase(),
              })}
              isNumeric
              tooltipInfo={`${t('template.approve_priority_tooltip')} ${currentChain.chainSymbol}.`}
              endAdornment={<PriorityCurrency />}
              error={!!errors.template?.advancedBuy?.approvePriority?.message}
              {...field}
            />
          )}
        />
      )}

      {currentChain.features?.bribeAmount && (
        <Controller
          name="template.advancedBuy.bribeAmount"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <OptionalInput
              className={styles.input}
              label={getChainSensitiveLabel('bribeAmount')}
              tooltipInfo={t('token_info.bribe_amount_tooltip')}
              placeholder={t('token_info.bribe_amount_placeholder')}
              isNumeric
              endAdornment={<PriorityCurrency />}
              error={!!errors.template?.advancedBuy?.bribeAmount?.message}
              {...field}
            />
          )}
        />
      )}

      {!currentChain.features?.noTemplateSpendAmount && (
        <Controller
          name="template.ordinaryBuy.buy_amount"
          control={control}
          render={({ field: { ref, value, ...field } }) => (
            <OptionalInput
              {...field}
              className={styles.input}
              label={t('amount_values.spend_amount')}
              placeholder={t('amount_values.amount_to_spend')}
              isNumeric
              endAdornment={
                <Grid display="flex" alignItems="center" columnGap={2}>
                  <Typography fontWeight={400} whiteSpace="nowrap">
                    ($
                    {formatNumber(+value * (currentChain.nativeTokenPriceInUsd || 0), 2).formatted})
                  </Typography>
                  <EndAdornment
                    label={currentChain.chainSymbol}
                    icon={(IconName as any)[currentChain.iconName]}
                  />
                </Grid>
              }
              error={!!errors.template?.ordinaryBuy?.buy_amount?.message}
              value={value ? `${value}` : ''}
            />
          )}
        />
      )}

      {!!currentChain.features?.maxTxOrFail && (
        <Controller
          name="template.advancedBuy.maxTxOrFail"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <SwitchInline
              label={t('template.max_tx_or_fail')}
              tooltipInfo={t('template.max_tx_or_fail_tooltip')}
              {...field}
            />
          )}
        />
      )}
    </Grid>
  )
}

export { ManualTemplateBuyTab }
