import { useTranslation } from 'react-i18next'

import { Grid } from '@mui/material'

import { Typography } from '@/libs/common'

const BrowserNotSupportedMessage = () => {
  const { t } = useTranslation()
  return (
    <Grid
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      padding={2}
    >
      <Typography variant="h1" textAlign="center">
        {t('browser.unsupported_title')}
        <br />

        {t('browser.unsupported_title_2')}
      </Typography>
    </Grid>
  )
}

export { BrowserNotSupportedMessage }
