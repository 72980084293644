import { TypeOptions } from 'react-toastify'

import { EToastProgressBarColor } from '@/libs/enums'

const getToastStyles = (type: TypeOptions) => {
  const colors = {
    info: EToastProgressBarColor.INFO,
    error: EToastProgressBarColor.ERROR,
    success: EToastProgressBarColor.SUCCESS,
    warning: EToastProgressBarColor.WARNING,
    default: '#000',
  }

  const titles = {
    info: 'toast.title.attention',
    error: 'toast.title.error',
    success: 'toast.title.success',
    warning: 'toast.title.warning',
    default: '',
  }

  return {
    color: colors[type] || colors.default,
    title: titles[type] || titles.default,
  }
}

export { getToastStyles }
