import { type ForwardedRef, forwardRef } from 'react'

import { OptionRootSlotProps } from '@mui/base/Option'
import cn from 'classnames'

import styles from './styles.module.scss'

const PlainSelectListItem = forwardRef(
  <TValue extends Record<string, unknown>>(
    props: OptionRootSlotProps<TValue>,
    ref: ForwardedRef<HTMLLIElement>,
  ) => {
    const { ownerState, className, ...other } = props

    return <li {...other} ref={ref} className={cn(className, styles.item)} />
  },
)

PlainSelectListItem.displayName = 'PlainSelectListItem'

export { PlainSelectListItem }
