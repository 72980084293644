import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import { AnyAction, Dispatch, ThunkDispatch, combineSlices, configureStore } from '@reduxjs/toolkit'

import { appSlice } from './slices/app.slice'
import { authSlice } from './slices/auth.slice'
import { chainSlice } from './slices/chain.slice'
import { favoritesTokensSlice } from './slices/favorites-tokens.slice'
import { orderSlice } from './slices/order.slice'
import { profitSellSlice } from './slices/profit-sell.slice'
import { userSlice } from './slices/user.slice'

const rootReducer = combineSlices(
  userSlice,
  chainSlice,
  favoritesTokensSlice,
  orderSlice,
  profitSellSlice,
  appSlice,
  authSlice,
)

const store = configureStore({
  reducer: rootReducer,
})

type TRootState = ReturnType<typeof store.getState>

type AppDispatch = ThunkDispatch<any, undefined, AnyAction> & Dispatch<any>

const useAppDispatch: () => AppDispatch = useDispatch
const useAppSelector: TypedUseSelectorHook<TRootState> = useSelector

export type { TRootState }

export { store, useAppDispatch, useAppSelector }
