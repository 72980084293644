import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { DepositTab } from '@/components/deposit-tab'
import { useWalletFromParams } from '@/hooks/useWalletFromParams'
import { Tabs, Typography } from '@/libs/common'
import { TabName } from '@/libs/enums'
import { ESidebarBtnType } from '@/libs/enums/sidebar-btn-type'
import { formatTokenPrice } from '@/libs/helper/formatTokenPrice'
import { useModal } from '@/pages/modal-page/modal-page'
import { useAppSelector } from '@/store'

import { HoldingsTab } from './libs/components/holdings-tab'
import { TransferTab } from './libs/components/transfer-tab'
import styles from './styles.module.scss'

const WalletDetails: FC = () => {
  const { t } = useTranslation()
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  const wallet = useWalletFromParams()

  const { setModalProps } = useModal()

  const [searchParams] = useSearchParams()
  const typeFromQuery = searchParams.get('type')

  const [defaultType, setDefaultType] = useState(typeFromQuery)

  const defaultActiveTab = useMemo(() => {
    switch (typeFromQuery) {
      case ESidebarBtnType.TRANSFER:
        return 2
      case ESidebarBtnType.DISPERSE_COLLECT:
        return 2
      default:
        setDefaultType(null)
        return 0
    }
  }, [typeFromQuery])

  useEffect(() => {
    setModalProps({
      title: wallet?.name || '',
      titleProps: { className: styles.title },
      withBackButton: true,
    })
  }, [wallet])

  const tabs = useMemo(() => {
    const currentTabs = [
      { label: t('menu.holdings'), content: <HoldingsTab wallet={wallet} /> },
      { label: t('menu.deposit'), content: <DepositTab wallet={wallet} /> },
    ]
    if (currentChain.features?.transfers) {
      currentTabs.push({
        label: t('menu.transfer'),
        content: <TransferTab wallet={wallet} type={defaultType as ESidebarBtnType} />,
      })
    }
    return currentTabs
  }, [currentChain, defaultType, wallet, t])

  return (
    <div>
      <Typography variant="body2" align="center" marginBottom={1}>
        {formatTokenPrice(`${wallet?.balanceFormatted || 0}`).formatted} {currentChain.chainSymbol}{' '}
        (~$
        {(
          +(wallet?.balanceFormatted || 0) * (currentChain.nativeTokenPriceInUsd || 0)
        ).toLocaleString('en-US', { maximumFractionDigits: 2 })}
        )
      </Typography>
      <Tabs name={TabName.Wallet_DETAILS} tabs={tabs} defaultValue={defaultActiveTab || 0} />
    </div>
  )
}

export { WalletDetails }
