import { t } from 'i18next'

type VerificationStatusType = {
  CREATE_MASTER_PASSWORD: string
  ENABLE_2FA?: string
  SETUP_WALLETS: string
  ENJOY_BLAZING_BOT: string
  VERIFY_EMAIL?: string
  SELECT_PLAN?: string
}

let VerificationStatus: VerificationStatusType = {
  CREATE_MASTER_PASSWORD: t('login.master_password'),
  ENABLE_2FA: t('login.setup_2fa'),
  SETUP_WALLETS: t('login.setup_wallets'),
  ENJOY_BLAZING_BOT: t('login.enjoy_bbot'),
}

function initStatus() {
  VerificationStatus = {
    CREATE_MASTER_PASSWORD: t('login.master_password'),
    SETUP_WALLETS: t('login.setup_wallets'),
    ENJOY_BLAZING_BOT: t('login.enjoy_bbot'),
  }
}

function add2FA() {
  VerificationStatus = {
    CREATE_MASTER_PASSWORD: t('login.master_password'),
    ENABLE_2FA: t('login.setup_2fa'),
    SETUP_WALLETS: t('login.setup_wallets'),
    ENJOY_BLAZING_BOT: t('login.enjoy_bbot'),
  }
}

export { VerificationStatus, add2FA, initStatus }
