import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid } from '@mui/material'

import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { Button, Typography } from '@/libs/common'
import { AppRoute, IconName } from '@/libs/enums'
import { TemplateItem } from '@/pages/modal-page/libs/components/template-management/libs/components/template-item'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

type TProps = {
  selectedChainId: number
}

const Templates: FC<TProps> = ({ selectedChainId }) => {
  const { t } = useTranslation()
  const userTemplates = useAppSelector((state) => state.user.userTemplates)

  const buyTemplates = useMemo(
    () =>
      userTemplates.buyTemplates?.filter(
        (item) => item.setup.network.blockchain === selectedChainId,
      ),
    [userTemplates, selectedChainId],
  )
  const navigate = useCustomNavigate()

  const openWebhook = () => {
    navigate({ isDashboard: true, path: `${AppRoute.MODAL}/${AppRoute.CREATE_WEBHOOK}` })
  }

  return (
    <Grid gap={3} container flexDirection="column" className={styles.container}>
      <Grid gap={2} container flexDirection="column">
        <Grid gap={2} container className={styles.header}>
          <Typography variant="body2" className={styles.title}>
            {t('template.manual_buy')}
          </Typography>
          <div>
            <Button
              className={styles.addButton}
              styleVariant="borderless-gold"
              iconStartName={IconName.ADD_CIRCLE}
              transparent
              onClick={openWebhook}
            >
              {t('template.create_webhook')}
            </Button>
          </div>
        </Grid>
        {buyTemplates?.length ? (
          buyTemplates.map((item) => (
            <TemplateItem key={item.id} title={item.name} isDefault={false} templateId={item.id} />
          ))
        ) : (
          <Typography variant="body2" textAlign="center" marginBottom={2}>
            {t('template.no_templates')}
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}

export { Templates }
