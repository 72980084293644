import { store } from '@/store'

export const getPrioritiesDefault = () => {
  const userPriorities = store.getState().user.defaultPriorities
  const defaultChainValues = store.getState().chain.currentChain.defaultTxPriorities
  return {
    sellPriority: userPriorities?.sell_priority || defaultChainValues.sell_priority,
    buyPriority: userPriorities?.buy_priority || defaultChainValues.buy_priority,
    approvePriority: userPriorities?.approve_priority || defaultChainValues.approve_priority,
    bribeAmount: userPriorities?.bribe_amount || defaultChainValues.bribe_amount,
  }
}
