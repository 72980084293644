import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid } from '@mui/material'
import cn from 'classnames'

import { deleteBuyTemplate } from '@/api/templates'
import { Spinner } from '@/components/spinner/spinner'
import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { Button, ButtonIcon, Icon, Popover, type TOnChangeVisible, Typography } from '@/libs/common'
import { AppRoute, IconName, SpinnerSize } from '@/libs/enums'
import { handleError } from '@/libs/helper/handleError'
import { useAppDispatch } from '@/store'
import { setUserBuyTemplates } from '@/store/slices/user.slice'

import styles from './styles.module.scss'

type TProps = {
  title: string
  isDefault: boolean
  templateId: string
}

const TemplateItem: FC<TProps> = ({ title, isDefault, templateId }) => {
  const [deletePopup, setDeletePopup] = useState<TOnChangeVisible>({
    visible: false,
    anchorE: undefined,
  })
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)

  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const navigate = useCustomNavigate()

  const handleEdit = () => {
    navigate({
      isDashboard: true,
      path: `${AppRoute.MODAL}/${AppRoute.MANUAL_BUY_TEMPLATE}/${templateId}`,
    })
  }

  const handleDelete = async () => {
    try {
      setIsDeleteLoading(true)
      const { data } = await deleteBuyTemplate(templateId)
      dispatch(setUserBuyTemplates(data.data))
    } catch (err) {
      const axiosError = typeof err == 'object' ? { ...err, isAxiosError: true } : err
      handleError(axiosError)
    } finally {
      setIsDeleteLoading(false)
    }
  }

  const open = Boolean(deletePopup.anchorE)
  const id = open ? 'delete-popover' : undefined

  return (
    <Grid className={styles.templateItemContainer}>
      <Grid container gap={1} alignItems="center">
        <Typography
          variant="body2"
          className={cn(styles.title, {
            [styles.default]: isDefault,
          })}
        >
          {title}
        </Typography>

        {/* TODO: Add default templates */}
        {/* <Button
          styleVariant="basic"
          className={cn(isDefault ? styles.defaultDescription : styles.description)}
          onClick={!isDefault ? setAsDefault : undefined}
        >
          {isDefault ? 'Default' : 'Set as default'}
        </Button> */}
      </Grid>

      <Grid display="flex" gap={1} className={styles.buttons}>
        <Button
          tooltipTitle={t('edit')}
          styleVariant="basic"
          borderless
          className={styles.button}
          onClick={handleEdit}
        >
          <Icon name={IconName.EDIT_SMALL} />
        </Button>

        <Grid className={styles.popupContainer}>
          <Button
            id={id}
            tooltipTitle={t('delete')}
            styleVariant="basic"
            borderless
            className={styles.button}
            disabled={isDeleteLoading}
            type="button"
            onClick={(e) => setDeletePopup({ visible: true, anchorE: e.currentTarget })}
          >
            {isDeleteLoading ? (
              <Spinner size={SpinnerSize.SMALL} />
            ) : (
              <Icon name={IconName.DELETE_RED} />
            )}
          </Button>

          <Popover
            id={id}
            className={styles.popover}
            popoverState={deletePopup}
            onChangeVisible={setDeletePopup}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <Typography variant="body2" className={styles.title}>
              {t('confirm_delete')}
            </Typography>
            <Typography variant="body2">
              {t('modal.template_management.confirm_delete_message', { template: title })}
            </Typography>
            <div className={styles.popoverButtons}>
              <ButtonIcon
                onClick={() => {
                  setDeletePopup({ visible: false, anchorE: deletePopup.anchorE })
                }}
              >
                <Typography variant="body2" className={styles.cancel}>
                  {t('cancel')}
                </Typography>
              </ButtonIcon>
              <Button
                className={styles.confirm}
                styleVariant="red"
                onClick={() => {
                  handleDelete()
                  setDeletePopup({ visible: false, anchorE: deletePopup.anchorE })
                }}
              >
                {t('delete')}
              </Button>
            </div>
          </Popover>
        </Grid>
      </Grid>
    </Grid>
  )
}

export { TemplateItem }
