import BigNumber from 'bignumber.js'
import moment from 'moment'

import { Bar } from '@/charting_library/charting_library'
import { store } from '@/store'

import { SUBSCRIPT_NUMBER_MAP } from '../configs'

const calcPricePrecision = (num: number) => {
  if (!num) return 8

  switch (true) {
    case Math.abs(+num) < 0.000000000000000000000000001:
      return 32

    case Math.abs(+num) < 0.0000000000000000000000001:
      return 30

    case Math.abs(+num) < 0.00000000000000000000001:
      return 28

    case Math.abs(+num) < 0.000000000000000000001:
      return 26

    case Math.abs(+num) < 0.0000000000000000001:
      return 24

    case Math.abs(+num) < 0.00000000000000001:
      return 22

    case Math.abs(+num) < 0.000000000000001:
      return 20

    case Math.abs(+num) < 0.0000000000001:
      return 18

    case Math.abs(+num) < 0.00000000001:
      return 16

    case Math.abs(+num) < 0.000000001:
      return 14

    case Math.abs(+num) < 0.0000001:
      return 12

    case Math.abs(+num) < 0.00001:
      return 10

    case Math.abs(+num) < 0.05:
      return 6

    case Math.abs(+num) < 1:
      return 4

    case Math.abs(+num) < 20:
      return 3

    default:
      return 2
  }
}

const formatPrice = (num: number, precision?: number, gr0 = true): string => {
  if (!num) {
    return num.toString()
  }

  if (!precision) {
    precision = calcPricePrecision(+num)
  }

  let formatted = new BigNumber(num).toFormat(precision)

  if (formatted.match(/^0\.[0]+$/g)) {
    formatted = formatted.replace(/\.[0]+$/g, '')
  }

  if (gr0 && formatted.match(/\.0{4,42}[1-9]+/g)) {
    const match = formatted.match(/\.0{4,42}/g)

    if (match) {
      const matchString = match[0].slice(1)
      formatted = formatted.replace(/\.0{4,42}/g, `.0${SUBSCRIPT_NUMBER_MAP[matchString.length]}`)
    }
  }

  return formatted
}

const formatMCap = (price: number) => {
  const simulation = store.getState().chain.adaptedCurrentTokenSimulationWebsocket?.data
  const totalSupply = simulation ? +simulation.total_supply - +simulation.burned.t : 0
  const marketCap = (price * totalSupply) / 1000000000

  if (marketCap >= 1) {
    return marketCap.toFixed(3).toString() + ' B'
  } else if (marketCap >= 0.001) {
    return (marketCap * 1000).toFixed(1).toString() + ' M'
  } else {
    const smallCap = (marketCap * 1000000).toFixed(4)
    const parts = smallCap.split('.')
    parts[1] = +parts[1] > 0 ? (+parts[1] / 10).toString() : '000'

    return parts.join(',') + ' K'
  }
}

const subtractDateInTimestamp = (
  date: moment.MomentInput,
  amount: number,
  units: moment.DurationInputArg2,
) => {
  return moment(date).subtract(amount, units).valueOf()
}

const convertTo3DayBars = (bars: Bar[]) => {
  const modIndex = 3
  const modResult = moment(bars[0].time).dayOfYear() % modIndex
  let shiftDays = !modIndex ? 0 : modResult >= modIndex - 1 ? 1 : -1

  const firstBar = { ...bars[0], time: subtractDateInTimestamp(bars[0].time, shiftDays, 'day') }
  const aggregatedBars = [firstBar]

  let i = 3 - shiftDays
  while (i < bars.length) {
    const currentBar = bars[i]
    const date = new Date(currentBar.time)
    const nextDateDayOfYear = moment(date).add(3, 'days').dayOfYear()

    if ([1, 2, 3].includes(nextDateDayOfYear)) {
      shiftDays = nextDateDayOfYear - 1
    }

    const slice = bars.slice(i, i + 3)

    if (slice.length === 3) {
      const open = slice[0].open
      const close = slice[2].close
      const high = Math.max(slice[0].high, slice[1].high, slice[2].high)
      const low = Math.min(slice[0].low, slice[1].low, slice[2].low)
      const volume = (slice[0].volume || 0) + (slice[1].volume || 0) + (slice[2].volume || 0)
      const time = slice[0].time

      aggregatedBars.push({ time, open, high, low, close, volume })
    }

    i += 3 - shiftDays
  }

  return aggregatedBars
}

const convertToCustomInterval = (bars: Bar[], interval: string) => {
  switch (interval) {
    case '3D':
      return convertTo3DayBars(bars)
    default:
      return convertTo3DayBars(bars)
  }
}

export { formatPrice, formatMCap, convertToCustomInterval }
