import { CSSProperties, useMemo, useState } from 'react'

import cn from 'classnames'
import moment from 'moment'

import { makeManualBuy } from '@/api/orders'
import { TVirtualToken } from '@/api/tokens/types'
import { CustomToast } from '@/components/custom-toast'
import { useSelectNewToken } from '@/hooks/useSelectNewToken.hook'
import { ButtonCopy, Icon, TokenPlaceholder } from '@/libs/common'
import { MAX_TRX_DECIMALS } from '@/libs/configs/transactions.config'
import { IconName, TransactionResponseType } from '@/libs/enums'
import { formatNumber } from '@/libs/helper'
import { createManualBuyPayload } from '@/libs/helper/buy/createManualBuyPayload'
import { convertScientificNotationNumber } from '@/libs/helper/convertScientificNotationNumber'
import { handleError } from '@/libs/helper/handleError'
import { hideWalletAddress } from '@/libs/helper/hideWalletAddress'
import { processTransactionResponse } from '@/libs/helper/processTransactionResponse'
import { TBuyTransaction } from '@/libs/types/buy-transaction'
import { useAppSelector } from '@/store'

import { QuickBuyButton } from '../../quick-buy-button'
import { SectionType } from '../../types'
import styles from './styles.module.scss'

interface TProps {
  token: TVirtualToken
  quickBuyAmount: string
  section: SectionType
}

const TokenCard = ({ token, quickBuyAmount, section }: TProps) => {
  const handleNewTokenSelection = useSelectNewToken()
  const [isLoading, setIsLoading] = useState(false)
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const mainWallet = useAppSelector((state) => state.user.mainWallet)
  const iconName = currentChain.iconName

  const handleQuickBuy = async () => {
    if (!mainWallet) return

    try {
      setIsLoading(true)

      const coeff =
        currentChain.nativeTokenPriceInUsd && token.price_usd
          ? currentChain.nativeTokenPriceInUsd / +token.price_usd
          : 0
      const receive = convertScientificNotationNumber(+quickBuyAmount * coeff, MAX_TRX_DECIMALS)

      const payload = createManualBuyPayload({
        data: {
          advancedBuy: {
            approvePriority: '',
            buyPriority: '',
            maxTxOrFail: false,
            minPercentTokenOrFail: '',
          },
          shields: {
            buy_tax: '',
            maximum_liquidity: '',
            maximum_market_cap: '',
            minimum_liquidity: '',
            sell_tax: '',
          },
          ordinaryBuy: {
            degenChadMode: false,
            privateTransaction: !currentChain.features?.noPrivateTx,
            receive,
            selectedWallets: [mainWallet.address],
            slippage: currentChain.defaultValues.slippage,
            spend: quickBuyAmount,
          },
        },
        wallets: [
          {
            name: mainWallet.name,
            public_key: mainWallet.address,
          },
        ],
        quickBuy: true,
        quickBuyAddress: token.token,
      }) as TBuyTransaction

      if (!payload) {
        CustomToast('error', 'Something went wrong')
        return
      }
      const response = await makeManualBuy(payload)

      processTransactionResponse(response.data, TransactionResponseType.BUY)
    } catch (err) {
      handleError(err)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSelectToken = (e: React.MouseEvent) => {
    const target = e.target as HTMLElement
    if (target.closest('#quick-buy-area')) {
      return
    }

    handleNewTokenSelection(token.token)
  }

  const getProgressStyle = (
    progress: string | undefined,
    section: SectionType,
  ): CSSProperties | undefined => {
    if (section === 'bonded') {
      return {
        '--progress-color': '#3DAF41',
        '--progress': '100%',
      } as CSSProperties
    }

    if (!progress) return undefined
    const progressNumber = parseInt(progress)

    return {
      '--progress-color': progressNumber < 50 ? '#FFC800' : '#7B80CD',
      '--progress': `${progress}%`,
    } as CSSProperties
  }

  const progressStyle = useMemo(
    () => getProgressStyle(token.red_pill_progress, section),
    [token.red_pill_progress, section],
  )

  return (
    <div className={cn(styles.root, styles.clickable)} onClick={handleSelectToken}>
      <div className={styles.top}>
        <div className={styles.info}>
          <div className={styles.logoWrapper} style={progressStyle}>
            {token.image ? (
              <img src={token.image} className={styles.logo} alt="" />
            ) : (
              <TokenPlaceholder size="35px" />
            )}
          </div>
          <div className={styles.infoText}>
            <div className={styles.tokenFullName}>
              <span className={styles.tokenSymbol}>{token.symbol}</span>
              <span className={styles.tokenName}>{token.name}</span>
            </div>
            <div className={styles.tokenAddress}>
              <div className={styles.tokenAddressText} title={token.token}>
                {hideWalletAddress(token.token)}
              </div>
              <ButtonCopy value={token.token} className={styles.tokenAddressButton} />
              {/* <button type="button" className={styles.tokenAddressButton}>
                <Icon width={16} height={16} name={IconName.PUMP_FUN} />
              </button> */}
            </div>
          </div>
        </div>
        <div id="quick-buy-area" className={styles.quickBuy}>
          <QuickBuyButton
            borderColor="#ffc800"
            onClick={handleQuickBuy}
            isLoading={isLoading}
            iconName={iconName as any}
            amount={quickBuyAmount}
          />
        </div>
      </div>
      <div className={styles.bottom}>
        <div className={styles.line}>
          <span className={styles.time}>{moment(token.created_at).utc().fromNow()}</span>
          {/* <span className={styles.label}>
            T10: <span className={cn(styles.value, styles.green)}>16%</span>
          </span>
          <span className={styles.label}>
            DH: <span className={cn(styles.value, styles.red)}>7%</span>
          </span> */}
        </div>
        <div className={cn(styles.line, styles.lineRight)}>
          <div className={styles.users}>
            <Icon name={IconName.PERSON_} width={16} height={16} />
            <span>{token.holders_count}</span>
          </div>
          <span className={styles.label}>
            Liq:{' '}
            <span className={cn(styles.value, styles.white)}>
              {token.liquidity_usd === '-' ? '-' : formatNumber(token.liquidity_usd).formatted}
            </span>
          </span>
          <span className={styles.label}>
            MC:{' '}
            <span className={cn(styles.value, styles.purple)}>
              {token.market_cap === '-' ? '-' : formatNumber(token.market_cap).formatted}
            </span>
          </span>
        </div>
      </div>
    </div>
  )
}

export { TokenCard }
