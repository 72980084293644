import { FC, useCallback, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Grid, InputLabel } from '@mui/material'
import cls from 'classnames'

import { PriorityCurrency } from '@/components/priority-currency'
import { SelectWallet } from '@/components/select-wallet'
import {
  ButtonGroupRadio,
  ButtonGroupRadioButton,
  HiddenInputs,
  StepInput,
  Typography,
} from '@/libs/common'
import { OptionalInput } from '@/libs/common/optional-input'
import { SwitchInline } from '@/libs/common/switch-inline'
import { TooltipIcon } from '@/libs/common/tooltip-icon'
import { TSelectOption } from '@/libs/types/select-option.type'
import { TUserWallet } from '@/libs/types/user.type'
import { TManualBuyValidationSchema } from '@/libs/validations/types'
import { useAppSelector } from '@/store'

import styles from '../../styles.module.scss'

type TProps = {
  selectedWalletAddresses: string[]
}

const ManualTemplateSellTab: FC<TProps> = ({ selectedWalletAddresses }) => {
  const { t } = useTranslation()

  const ProfitSettingsTabs: TSelectOption<string>[] = [
    { value: 'sellInSteps', label: t('sell_tab.sell_in_steps') },
    { value: 'sellOnProfit', label: 'Sell on profit' },
    { value: 'trailingSell', label: 'Trailing sell' },
  ]

  const userWallets = useAppSelector((state) => state.user.userWallets)
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  const [walletsOnProfit, setWalletsOnProfit] = useState<number[]>([])
  const [profitTab, setProfitTab] = useState<string>(ProfitSettingsTabs[0].value)

  const { control } = useFormContext<TManualBuyValidationSchema>()

  const handleProfitTabChange = useCallback((_: React.BaseSyntheticEvent, newValue: string) => {
    setProfitTab(newValue)
  }, [])
  const selectedWallets = useMemo(() => {
    if (selectedWalletAddresses?.length && userWallets) {
      return userWallets.filter((wallet: TUserWallet) =>
        selectedWalletAddresses.includes(wallet.address),
      )
    }
    return []
  }, [selectedWalletAddresses, userWallets])

  return (
    <Controller
      name="template.autoSell"
      control={control}
      render={({ field: { ref, onChange, value, ...field } }) => (
        <HiddenInputs
          expanded={value}
          trigger={
            <SwitchInline
              label={t('template.auto_sell')}
              tooltipInfo={t('template.auto_sell_tooltip')}
              {...field}
              onChange={(_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                onChange(checked)
              }}
              value={value}
            />
          }
        >
          <Grid container rowGap={2} flexDirection="column">
            <Controller
              name="template.sellPriority"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <OptionalInput
                  placeholder={t('template.enter_gwei_amount', {
                    currency: currentChain.priorityCurrency.toLowerCase(),
                  })}
                  isNumeric
                  label={t('template.sell_priority')}
                  tooltipInfo={`${t('template.enter_gwei_amount_tooltip')} ${currentChain.chainSymbol}.`}
                  endAdornment={<PriorityCurrency />}
                  onAutoChange={() => {
                    //TO DO: added auto switch
                  }}
                  {...field}
                />
              )}
            />
            <div className={styles.dividerContainer}>
              <Typography variant="body2" textColor="grey">
                Profit
              </Typography>
              <div className={styles.divider}></div>
            </div>
            <Controller
              name="template.walletsToSellOnProfit"
              control={control}
              render={({ field: { ref, value, onChange, ...field } }) => (
                <SelectWallet
                  tooltipInfo={t('wallet.wallets_to_sell_on_profit_tooltip')}
                  label={t('wallet.wallets_to_sell_on_profit')}
                  isMulti
                  onChange={(value: any) => {
                    setWalletsOnProfit(value)
                    onChange(value)
                  }}
                  isSelectDefaultWallet={false}
                  wallet={value}
                  wallets={userWallets}
                  {...field}
                />
              )}
            />

            <ButtonGroupRadio
              onChange={handleProfitTabChange}
              value={profitTab}
              exclusive
              className={styles.group}
            >
              {ProfitSettingsTabs.map((tab) => (
                <ButtonGroupRadioButton
                  value={tab.value}
                  key={tab.value}
                  className={cls({ [styles.bgGrey]: tab.value === profitTab })}
                >
                  {tab.label}
                </ButtonGroupRadioButton>
              ))}
            </ButtonGroupRadio>

            {profitTab === ProfitSettingsTabs[0].value && (
              <div>
                <div className={styles.labelContent}>
                  <InputLabel className={styles.label}>
                    <Typography variant="body2" textColor="light-grey">
                      {t('sell_tab.sell_in_steps')}
                    </Typography>
                  </InputLabel>
                  <TooltipIcon info={t('sell_tab.sell_in_steps_tooltip')} marginLeft={5} />
                </div>
                {walletsOnProfit.length !== 0 ? (
                  <div className={styles.stepWrapper}>
                    {walletsOnProfit.map((wallet, index) => (
                      <Controller
                        key={wallet}
                        name={`w${wallet}` as keyof TManualBuyValidationSchema}
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <StepInput
                            wallet="#1"
                            balance="12.58 ETH"
                            className={cls({
                              [styles.firstInput]: index === 0,
                              [styles.lastInput]: index === walletsOnProfit.length - 1,
                            })}
                            {...field}
                          />
                        )}
                      />
                    ))}
                  </div>
                ) : (
                  <Grid marginTop={1}>
                    <Typography variant="body1">{t('wallet.no_wallets_selected')}</Typography>
                  </Grid>
                )}
              </div>
            )}
            {profitTab === ProfitSettingsTabs[1].value && (
              <Grid container flexWrap="nowrap" columnGap={3}>
                <Controller
                  name="template.sellOnProfit"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <OptionalInput
                      placeholder={t('sell_tab.enter_sell_profit')}
                      isNumeric
                      label={t('sell_tab.sell_on_profit')}
                      adornmentText="%"
                      tooltipInfo={t('sell_tab.sell_on_profit_tooltip')}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="template.sellOnProfitAmount"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <OptionalInput
                      placeholder={t('sell_tab.sell_profit_amount')}
                      isNumeric
                      label={t('sell_tab.sell_profit_amount_title')}
                      adornmentText="%"
                      tooltipInfo={t('sell_tab.sell_profit_amount_tooltip')}
                      {...field}
                    />
                  )}
                />
              </Grid>
            )}
            {profitTab === ProfitSettingsTabs[2].value ? (
              <>
                <Controller
                  name="template.walletsToTrailingSell"
                  control={control}
                  render={({ field: { ref, value, ...field } }) => (
                    <SelectWallet
                      isMulti
                      wallet={value}
                      wallets={selectedWallets}
                      label={t('sell_tab.wallets_to_sell')}
                      tooltipInfo="tooltipInfo"
                      isSelectDefaultWallet={false}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="template.trailingSell"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <OptionalInput
                      placeholder={t('sell_tab.enter_sell_trailing')}
                      isNumeric
                      label={t('sell_tab.sell_profit')}
                      adornmentText="%"
                      tooltipInfo="tooltipInfo"
                      {...field}
                    />
                  )}
                />
              </>
            ) : (
              <>
                <div className={styles.dividerContainer}>
                  <Typography variant="body2" textColor="grey">
                    {t('loss')}
                  </Typography>
                  <div className={styles.divider}></div>
                </div>
                <Controller
                  name="template.walletsToSellOnStopLoss"
                  control={control}
                  render={({ field: { ref, value, ...field } }) => (
                    <SelectWallet
                      tooltipInfo={t('wallet.wallets_to_sell_on_stop_tooltip')}
                      label={t('wallet.wallets_to_sell_on_stop')}
                      isMulti
                      wallet={value}
                      wallets={userWallets}
                      isSelectDefaultWallet={false}
                      {...field}
                    />
                  )}
                />
                <Grid container flexWrap="nowrap" columnGap={3}>
                  <Controller
                    name="template.stopLoss"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <OptionalInput
                        placeholder={t('amount_values.percentage_sell_on_loss')}
                        isNumeric
                        label={t('template.stop_loss')}
                        tooltipInfo={t('template.stop_loss_tooltip')}
                        adornmentText="%"
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="template.stopLossAmount"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <OptionalInput
                        placeholder={t('amount_values.amount_sell_on_loss')}
                        isNumeric
                        label={t('template.stop_loss_amount')}
                        tooltipInfo={t('amount_values.amount_sell_on_loss_tooltip')}
                        adornmentText="%"
                        {...field}
                      />
                    )}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </HiddenInputs>
      )}
    />
  )
}

export { ManualTemplateSellTab }
