import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Stack } from '@mui/material'

import { exportWallets } from '@/api/wallet'
import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { Button, Input } from '@/libs/common'
import { AppRoute } from '@/libs/enums'
import { extractErrorDescription } from '@/libs/helper/extractErrorDescription'
import { useModal } from '@/pages/modal-page/modal-page'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

const MasterPassword: FC = () => {
  const indexerChainId = useAppSelector((state) => state.chain.currentChain.indexerChainId)
  const { t: translate } = useTranslation()

  const [inputValue, setValue] = useState<string>('')
  const [loading, setLoading] = useState(false)

  const navigate = useCustomNavigate()

  const { setModalProps } = useModal()

  useEffect(() => {
    setModalProps({ title: translate('login.master_password_placeholder') })
  }, [translate, setModalProps])

  const submit = async () => {
    try {
      const wallets = JSON.parse(localStorage.exporWallets) as string[] | undefined
      if (!wallets) return
      setLoading(true)
      const { data } = await exportWallets({
        blockchain: indexerChainId,
        master: inputValue,
        wallets: wallets,
      })
      localStorage.removeItem('exporWallets')
      navigate({
        isDashboard: true,
        path: `${AppRoute.MODAL}/${AppRoute.EXPORT_WALLETS}`,
        searchParams: {
          wallets: JSON.stringify(
            data.data.map((item) => ({ ...item, name: encodeURIComponent(item.name) })),
          ),
        },
      })
    } catch (err) {
      extractErrorDescription(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Stack spacing={3} useFlexGap>
      <Input
        value={inputValue}
        onChange={(e) => {
          setValue(e.target.value)
        }}
        isTransparent
        label={translate('login.master_password')}
        placeholder={translate('login.master_password_placeholder')}
        className={styles.input}
      />
      <Button disabled={!inputValue} onClick={submit} isLoading={loading}>
        Submit
      </Button>
    </Stack>
  )
}

export { MasterPassword }
