import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Grid } from '@mui/material'

import { Button, Input, Typography } from '@/libs/common'

import styles from './styles.module.scss'

const LicenseNewServer = () => {
  const defaultValues = {
    name: '',
    ipAddress: '',
  }

  const { t } = useTranslation()

  const { handleSubmit, control } = useForm({ defaultValues })
  const onSubmit: SubmitHandler<typeof defaultValues> = (data) => {
    // Send data to backend
    return data
  }
  return (
    <Grid container gap={3} flexDirection="column">
      <Typography variant="h1" align="center">
        License new server
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <div>
          <Controller
            name="name"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <Input
                isTransparent
                label={t('name')}
                placeholder="Enter name"
                maxLength={15}
                {...field}
              />
            )}
          />

          <Controller
            name="ipAddress"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <Input isTransparent label="IP address" placeholder="Enter IP address" {...field} />
            )}
          />
        </div>

        <Button type="submit">License</Button>
      </form>
    </Grid>
  )
}

export { LicenseNewServer }
