import { type ForwardedRef, forwardRef } from 'react'

import { type SelectRootSlotProps } from '@mui/base/Select'
import cn from 'classnames'

import { Icon } from '@/libs/common'
import { IconName } from '@/libs/enums'

import { ESelectRootVariant } from '../../../enums'
import { TDefaultSelectRootProps } from './libs/types'
import styles from './styles.module.scss'

const DefaultSelectRoot = forwardRef(
  <TValue extends Record<string, unknown>, Multiple extends boolean>(
    props: SelectRootSlotProps<TValue, Multiple> & TDefaultSelectRootProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const {
      ownerState,
      className,
      prefix,
      variant,
      children: label,
      formatValueDisplay,
      value,
      ...other
    } = props

    return (
      <button
        {...other}
        className={cn(className, styles.root, styles[variant || ESelectRootVariant.SOLID], {
          [styles.open]: ownerState.open,
        })}
        ref={ref}
      >
        {prefix}
        {formatValueDisplay ? formatValueDisplay(value as any) : label}
        <div className={styles.icon}>
          <Icon name={IconName.ARROW_DOWN_MINIMALISTIC} />
        </div>
      </button>
    )
  },
)

DefaultSelectRoot.displayName = 'DefaultSelectRoot'

export { DefaultSelectRoot }
