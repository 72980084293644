import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid } from '@mui/material'

import { SellTab } from '@/components/sell-tab'
import { useModal } from '@/pages/modal-page/modal-page'

const SellModal = () => {
  const { setModalProps } = useModal()
  const { t } = useTranslation()
  useEffect(() => {
    setModalProps({ title: t('sell'), titleProps: { marginBottom: 3 } })
  }, [])

  return (
    <Grid>
      <SellTab usedInModal />
    </Grid>
  )
}

export { SellModal }
