import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { Spinner } from '@/components/spinner'
import { useAppMode } from '@/hooks/useAppMode'
import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { useSelectNewToken } from '@/hooks/useSelectNewToken.hook'
import { useWindowDimensions } from '@/hooks/useWindowDimensions'
import { Button, ButtonIcon, Icon, Popover, type TPopoverProps, Typography } from '@/libs/common'
import { AppMode, AppRoute, IconName, SpinnerSize, TabName } from '@/libs/enums'
import { useAppDispatch, useAppSelector } from '@/store'
import { fetchOrdersHistory } from '@/store/slices/order.slice'

import styles from './styles.module.scss'

type TSellPopupProps = Omit<TPopoverProps, 'children'> & {
  light?: boolean
  tokenAddress?: string
  onMarketSellAll?: () => Promise<void>
}

const SellPopup: FC<TSellPopupProps> = ({
  popoverState,
  light,
  onChangeVisible,
  className,
  tokenAddress,
  onMarketSellAll,
}) => {
  const { t } = useTranslation()
  const selectedTokenAddress = useAppSelector((state) => state.chain.selectedTokenAddress)

  const [isLoading, setIsLoading] = useState(false)

  const { mode } = useAppMode()
  const navigate = useCustomNavigate()
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()

  const handleNewTokenSelection = useSelectNewToken()
  const windowDimension = useWindowDimensions()
  const isSellInModal = windowDimension.isBottomTabsVisible

  const sellRoute = mode === AppMode.PRO ? AppRoute.SELL_MODAL : AppRoute.LITE_SELL

  const handleOpenSellPanel = async () => {
    if (!tokenAddress) return
    if (selectedTokenAddress === tokenAddress) {
      if (isSellInModal) {
        navigate({
          isDashboard: true,
          path: `${AppRoute.MODAL}/${sellRoute}`,
          searchParams: {
            token: tokenAddress,
          },
        })
      } else {
        searchParams.set(mode === AppMode.PRO ? TabName.BUY_SELL : TabName.BUY_SELL_LITE, 'sell')
        setSearchParams(searchParams)
      }
    }

    handleNewTokenSelection(tokenAddress, {
      customRedirectPath: isSellInModal
        ? `${AppRoute.DASHBOARD}/${mode}/${AppRoute.MODAL}/${sellRoute}`
        : null,
      customQueryParams: {
        [mode === AppMode.PRO ? TabName.BUY_SELL : TabName.BUY_SELL_LITE]: isSellInModal
          ? null
          : 'sell',
      },
    })

    onChangeVisible({ visible: false, anchorE: popoverState.anchorE })
  }

  const handleSellAll = async () => {
    setIsLoading(true)
    if (onMarketSellAll) {
      await onMarketSellAll()
      await dispatch(fetchOrdersHistory()).unwrap()
    }
    setIsLoading(false)
    onChangeVisible({ visible: false, anchorE: popoverState.anchorE })
  }

  return (
    <Popover
      className={className}
      popoverState={popoverState}
      onChangeVisible={onChangeVisible}
      light={light}
    >
      <div>
        <Typography variant="body2" className={styles.title}>
          {t('sell')}
        </Typography>

        <ButtonIcon
          onClick={() => onChangeVisible({ visible: false, anchorE: popoverState.anchorE })}
          className={styles.close}
        >
          <Icon name={IconName.CLOSE_SMALL} />
        </ButtonIcon>

        <div className={styles.buttons}>
          {/* <ButtonIcon
          onClick={() => {
            onChangeVisible(false)
          }}
          className={styles.cancel}
        >
          <Typography variant="body2" textColor="gold">
            Cancel
          </Typography>
        </ButtonIcon> */}

          <ButtonIcon
            onClick={() => {
              onChangeVisible({ visible: false, anchorE: popoverState.anchorE })
              handleOpenSellPanel()
            }}
            className={styles.sellPanel}
          >
            <Typography variant="body2" textColor="color-grey-3">
              {t('sell_tab.open_sell_panel')}
            </Typography>
          </ButtonIcon>

          <Button
            className={styles.confirm}
            onClick={(e) => {
              e.stopPropagation()
              handleSellAll()
            }}
            disabled={isLoading}
          >
            {isLoading ? <Spinner size={SpinnerSize.SMALL} /> : t('sell_tab.market_sell_all')}
          </Button>
        </div>
      </div>
    </Popover>
  )
}

export { SellPopup, type TSellPopupProps }
