import { EBuyMode } from '@/libs/enums/buy-mode.enum'
import { convertScientificNotationNumber } from '@/libs/helper/convertScientificNotationNumber'
import { TBuyTemplate } from '@/libs/types/template'
import { store } from '@/store'

import { getPrioritiesDefault } from '../getPrioritiesDefault'

const createBuyFormStateFromTemplate = (template: TBuyTemplate) => {
  const defaultPriorities = getPrioritiesDefault()
  const userWallets = store.getState().user.userWallets
  const currentChain = store.getState().chain.currentChain

  const buyMode = template.setup.operation.setup.buy_mode

  const walletsPositions: string[] = []
  template.setup.wallets.forEach((wallet) => {
    const userWalletToUse = userWallets?.find((item) => item.address === wallet.public_key)
    if (userWalletToUse) {
      walletsPositions.push(userWalletToUse.address)
    }
  })

  const ordinaryBuyDefaultValues = {
    privateTransaction: !currentChain.features?.noPrivateTx && template.setup.operation.private_tx,
    degenChadMode: !template.setup.operation.shields.anti_honeypot,
    slippage: template.setup.operation.setup.slippage,
    selectedWallets: walletsPositions,
    spend: '',
    receive: '',
    buy_amount: convertScientificNotationNumber(template.setup.operation.setup.buy_amount || ''),
  }

  let buyPriority = 0
  if (currentChain.features?.usePriorityAmount) {
    buyPriority = template.setup.operation.priority_amount || +defaultPriorities.buyPriority
  } else {
    buyPriority = currentChain.features?.noPriorityGas
      ? template.setup.operation.gas!.buy.gas
      : template.setup.operation.gas!.buy.miner_tip
  }

  let approvePriority = +defaultPriorities.approvePriority
  if (template.setup.operation.gas) {
    if (currentChain.features?.noPriorityGas) {
      approvePriority = template.setup.operation.gas.approve.gas
    } else {
      approvePriority = template.setup.operation.gas.approve.miner_tip
    }
  }

  const advancedBuyDefaultValues = {
    buyPriority: `${buyPriority}`,
    approvePriority: `${approvePriority}`,
    bribeAmount: `${template.setup.operation.bribe_amount || defaultPriorities.bribeAmount}`,
    minPercentTokenOrFail: `${
      buyMode === EBuyMode.MIN_TOKENS
        ? template.setup.operation.setup.buy_tokens
        : buyMode === EBuyMode.MIN_PERCENT
          ? `${template.setup.operation.setup.supply_percentage}`
          : ''
    }`,
    maxTxOrFail: buyMode === EBuyMode.MAX_TRANSACTION,
  }

  const shieldsDefaultValues = {
    buy_tax: `${template.setup.operation.shields.buy_tax || 0}`,
    sell_tax: `${template.setup.operation.shields.sell_tax || 0}`,
    buy_amount: '',
    minimum_liquidity: `${template.setup.operation.shields.minimum_liquidity}`,
    maximum_liquidity: `${template.setup.operation.shields.maximum_liquidity}`,
    maximum_market_cap: `${template.setup.operation.shields.maximum_market_cap}`,
  }

  return {
    name: template.name,
    template: {
      ordinaryBuy: ordinaryBuyDefaultValues,
      advancedBuy: advancedBuyDefaultValues,
      shields: shieldsDefaultValues,
      transferOnBlacklist: false,
      antiBlacklist: false,
      antiRug: false,
      antiRugThreshold: 0,
      sellRugExtraTip: '0',
      autoSell: false,
      sellPriority: '',
      walletsToSellOnProfit: walletsPositions,
      walletsToSellOnLoss: walletsPositions,
      walletsToSellOnStopLoss: walletsPositions,
      stopLoss: '',
      stopLossAmount: '',
      sellOnProfit: '',
      sellOnProfitAmount: '',
      walletsToTrailingSell: [],
      trailingSell: '',
    },
  }
}

export { createBuyFormStateFromTemplate }
