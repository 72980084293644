import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import QRCode from 'react-qr-code'

import { updateUser2FA, validateCode } from '@/api/user'
import { AuthCard } from '@/components/auth-card'
import { CustomToast } from '@/components/custom-toast'
import { SixDigitForm } from '@/components/six-digit-form'
import { Spinner } from '@/components/spinner'
import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { Button, ButtonCopy } from '@/libs/common'
import { ButtonIcon } from '@/libs/common/button-icon'
import { Icon } from '@/libs/common/icon'
import { Typography } from '@/libs/common/typography'
import { AppRoute, IconName, SpinnerSize, VerificationStatus } from '@/libs/enums'
import { initStatus } from '@/libs/enums/verification-status.enum.ts'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

type TProps = {
  settings?: boolean
}

const Enable2fa: FC<TProps> = ({ settings }) => {
  const [code, setCode] = useState<string[]>(new Array(6).fill(''))
  const navigate = useCustomNavigate()
  const resetAuthenticatorData = useAppSelector((state) => state.user.userAuthenticatorData)
  const signUpData = localStorage.signUpData
  const [authenticator] = useState<any>(JSON.parse(signUpData || [{}]))
  const [loading, setLoading] = useState<boolean>(false)
  const [isEnable, setIsEnable] = useState(false)
  const { t } = useTranslation()

  const handleCodeChange = (newCode: string[]) => {
    setCode(newCode)
  }

  if (resetAuthenticatorData.s2fa) {
    authenticator.s2fa = resetAuthenticatorData.s2fa
  }

  useEffect(() => {
    const codeString = code.join('')
    if (codeString.length === 6) {
      setLoading(true)
      validateCode(authenticator.id, { code: codeString as string })
        .then(({ data }) => {
          updateUser2FA({
            s2fa: true,
          }).then(() => {
            if (data.data.valid) {
              if (settings) {
                CustomToast('success', 'success')
                navigate({ delta: -1 })
              } else {
                navigate({ path: `${AppRoute.SETUP_WALLETS}` })
              }
            } else {
              CustomToast('error', 'login.error_2fa')
              setLoading(false)
            }
          })
        })
        .catch(() => setLoading(false))
    }
  }, [code])

  const handleEnable = () => {
    setIsEnable(true)
  }

  const getSecretFromUrl = (url: string) => {
    const regex = /secret=([A-Z0-9]+)/
    const match = url.match(regex)
    return match ? match[1] : null
  }

  const formatSecret = (secret: string | null): string => {
    if (!secret) {
      throw new Error(t('login.secret_error'))
    }
    return secret.match(/.{1,4}/g)?.join(' ') ?? ''
  }

  const qrCode: string = authenticator.s2fa
  const secret: string | null = getSecretFromUrl(qrCode as string)
  const formattedSecret = formatSecret(secret as string)

  const renderEnable2FAContent = () => (
    <div className={styles.contentWrapper}>
      <div className={styles.titleWrapper}>
        {!settings && (
          <ButtonIcon
            icon={IconName.ARROW_RIGHT}
            onClick={() => {
              initStatus()
              navigate({ delta: -1 })
            }}
          />
        )}
        <Typography variant="h1" className={styles.title}>
          {isEnable ? t('login.enter_code') : t('login.enable_2fa')}
        </Typography>
      </div>
      {!isEnable ? (
        <>
          <div className={styles.step}>
            <Icon name={IconName.DOWNLOAD} />
            <div className={styles.stepDesc}>
              <div className={styles.stepTitle}>{t('login.step')} 1</div>
              <div className={styles.stepText}>
                {t('login.download_2fa')}
                <span className={styles.gold}> Google Authenticator</span>.
              </div>
            </div>
          </div>
          <div className={styles.stepWrapper}>
            <div className={styles.step}>
              <Icon name={IconName.FLIP} />
              <div className={styles.stepDesc}>
                <div className={styles.stepTitle}>{t('login.step')} 2</div>
                <div className={styles.stepText}>{t('login.scan_2fa')}</div>
              </div>
            </div>
            <div className={styles.code}>
              <div className={styles.qr}>
                <QRCode size={120} value={authenticator.s2fa} />
              </div>
              <div className={styles.codeDesc}>
                <div className={styles.codeLabel}>{t('login.code')}</div>
                <div className={styles.copy}>
                  <div className={styles.codeContent}>{formattedSecret}</div>
                  <ButtonCopy value={formattedSecret.split(' ').join('')} />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.step}>
            <Icon name={IconName.PHONE} />
            <div className={styles.stepDesc}>
              <div className={styles.stepTitle}>Step 3</div>
              <div className={styles.stepText}>{t('login.enter_2fa')}</div>
            </div>
          </div>
          <Button onClick={handleEnable}>{t('login.enable_2fa_short')}</Button>
        </>
      ) : (
        <>
          <Typography variant="h2" textColor="color-grey-2" align="center">
            {t('login.description_2fa')}
          </Typography>
          {loading && <Spinner size={SpinnerSize.BIG} centered />}
          {!loading && <SixDigitForm onCodeChange={handleCodeChange} />}
        </>
      )}
    </div>
  )

  return settings ? (
    renderEnable2FAContent()
  ) : (
    <AuthCard verificationStatus={VerificationStatus.ENABLE_2FA} className={styles.card}>
      {renderEnable2FAContent()}
    </AuthCard>
  )
}

export { Enable2fa }
