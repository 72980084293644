import { Grid } from '@mui/material'

import AffiliateChart from '@/assets/img/affiliate/affiliate-chart.png'
import { Typography } from '@/libs/common'

import styles from './styles.module.scss'

const AffiliateImage = () => {
  return (
    <Grid flexDirection="column" className={styles.imageContainer}>
      <Typography variant="h1" textColor="light-grey">
        How It Works
      </Typography>
      <img className={styles.affiliateChart} src={AffiliateChart} alt="affiliate-chart" />
    </Grid>
  )
}

export { AffiliateImage }
