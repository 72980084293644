import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import Stack, { StackProps } from '@mui/material/Stack'
import cn from 'classnames'

import { Tabs } from '@/libs/common/index'
import { TTab } from '@/libs/types'

import { PairStats } from '../pair-stats'
import { OrderHistoryTab } from './libs/components'
import styles from './styles.module.scss'

const PendingOrders: FC<StackProps> = ({ className, ...props }) => {
  const { t } = useTranslation()

  const tabs: TTab[] = [
    { label: t('menu.volume'), content: <PairStats variant="pro" /> },
    {
      label: t('menu.order_history'),
      content: <OrderHistoryTab />,
    },
  ]

  return (
    <Stack className={cn(styles.container, className)} spacing={2} useFlexGap {...props}>
      <Tabs tabs={tabs} defaultValue={0} />
    </Stack>
  )
}

export { PendingOrders }
