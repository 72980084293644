import { useMemo } from 'react'

import { useAppSelector } from '@/store'

const useCurrentTokenInfoLoading = () => {
  const currentToken = useAppSelector((state) => state.chain.currentToken)
  const selectedTokenAddress = useAppSelector(
    (state) => state.chain.selectedTokenAddress,
  )?.toLowerCase()
  const simulation = useAppSelector(
    (state) => state.chain.adaptedCurrentTokenSimulationWebsocket?.data,
  )

  return useMemo(() => {
    return (
      selectedTokenAddress !== currentToken?.token.address.toLowerCase() ||
      selectedTokenAddress !== simulation?.address.toLowerCase()
    )
  }, [selectedTokenAddress, currentToken, simulation])
}

export { useCurrentTokenInfoLoading }
