import { type ForwardedRef, forwardRef } from 'react'

import { SelectListboxSlotProps } from '@mui/base/Select'
import cn from 'classnames'

import styles from './styles.module.scss'

const RoudedSelectListBox = forwardRef(
  <TValue extends Record<string, unknown>, Multiple extends boolean>(
    props: SelectListboxSlotProps<TValue, Multiple>,
    ref: ForwardedRef<HTMLUListElement>,
  ) => {
    const { ownerState, className, ...other } = props

    return <ul {...other} className={cn(className, styles.container)} ref={ref} />
  },
)

RoudedSelectListBox.displayName = 'RoudedSelectListBox'

export { RoudedSelectListBox }
