import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { FormControlLabel, Grid, RadioGroup } from '@mui/material'

import { Spinner } from '@/components/spinner'
import { useHoldingsWithSettings } from '@/hooks/useHoldingsWithSettings.hook'
import { Checkbox, Typography } from '@/libs/common'
import { ButtonSort } from '@/libs/common/button-sort'
import { Radio } from '@/libs/common/radio'
import { SpinnerSize, THoldingsBalancesType } from '@/libs/enums'
import { formatTokenPrice } from '@/libs/helper/formatTokenPrice'
import { useAppSelector } from '@/store'

import { HoldingsElement } from './libs/holdings-element'
import styles from './styles.module.scss'

type TProps = {
  isValid?: boolean
  withSorting?: boolean
}

const HoldingsList: FC<TProps> = ({ isValid, withSorting }) => {
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const honeypotHoldings = useAppSelector((state) => state.user.userHoldingsWebsocket?.s)
  const holdingsLoading = useAppSelector((state) => state.user.userHoldingsLoading)
  const userData = useAppSelector((state) => state.user.userData)

  const { t } = useTranslation()

  const {
    filteredHoldings,
    totalHoldingByFilter,
    holdingsSettings,
    handleHideSmallBalances,
    toggleSort,
    handleBalancesType,
  } = useHoldingsWithSettings()

  return (
    <Grid gap={2} display="flex" flexDirection="column">
      {holdingsLoading && userData ? (
        <Grid marginTop={2}>
          <Spinner size={SpinnerSize.SMALL} centered />
        </Grid>
      ) : (
        <>
          {isValid && !!filteredHoldings && (
            <>
              <RadioGroup
                // defaultValue={holdingsSettings.selectedBalancesType}
                value={holdingsSettings.selectedBalancesType}
                name="balance-filters"
                onChange={(_, value) => handleBalancesType(value as THoldingsBalancesType)}
                className={styles.balanceFilters}
              >
                <FormControlLabel
                  value={THoldingsBalancesType.ALL}
                  control={<Radio label={t('token_info.all')} />}
                  label=""
                  className={styles.radioWrapper}
                />
                <FormControlLabel
                  value={THoldingsBalancesType.WALLETS}
                  control={<Radio label={t('token_info.balances')} />}
                  label=""
                  className={styles.radioWrapper}
                />
                <FormControlLabel
                  value={THoldingsBalancesType.TOKENS}
                  control={<Radio label={t('token_info.tokens')} />}
                  label=""
                  className={styles.radioWrapper}
                />
              </RadioGroup>
              <Grid display="flex" alignItems="center" gap={1}>
                {withSorting && (
                  <ButtonSort sorting={holdingsSettings.sorting} handleSorting={toggleSort} />
                )}
                <Checkbox
                  label={t('token_info.hide_balances')}
                  value={holdingsSettings.hideSmallBalances}
                  checked={holdingsSettings.hideSmallBalances}
                  onChange={(_, value) => handleHideSmallBalances(value)}
                />
              </Grid>
            </>
          )}
          {!!filteredHoldings && isValid && (
            <Grid container className={styles.chartWrapper}>
              {/* <Checkbox label="Hide Honeypot Tokens" /> */}

              <div>
                <Grid container alignItems="center" gap={0.6}>
                  <Typography className={styles.totalHoldings}>
                    ${formatTokenPrice(totalHoldingByFilter.usd || '0').formatted}
                  </Typography>

                  {/* <Typography
            variant="body2"
            className={cn(values.percents > 0 ? styles.profit : styles.loss)}
          >
            {Math.abs(values.percents)}% (All)
          </Typography> */}
                </Grid>

                <Typography variant="body2" className={styles.totalNative}>
                  {formatTokenPrice(totalHoldingByFilter.native || '0').formatted}
                  {currentChain.chainSymbol}
                </Typography>
              </div>

              {/* <Chart isMiniChart />

      <TimeFrameToggle options={options} /> */}
            </Grid>
          )}
          {isValid && !filteredHoldings?.t.length && (
            <Typography
              variant="body1"
              component="span"
              textColor="grey"
              align="center"
              marginTop={2}
              marginBottom={2}
            >
              {t('wallet.no_holdings')}
            </Typography>
          )}
          {!isValid && !honeypotHoldings?.length && (
            <Typography
              variant="body1"
              component="span"
              textColor="grey"
              width="100%"
              marginTop={2}
              marginBottom={2}
            >
              {t('wallet.no_honeypots')}
            </Typography>
          )}

          {(isValid ? filteredHoldings?.t : honeypotHoldings)?.map((holding) => (
            <HoldingsElement key={holding.a} holding={holding} isHoneypot={!isValid} />
          ))}
        </>
      )}
    </Grid>
  )
}

export { HoldingsList }
