import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { FavoriteHistoryList } from '@/components/favorite-history-list'
import { Tabs } from '@/libs/common'
import { useModal } from '@/pages/modal-page/modal-page'

const FavoritesHistory = () => {
  const { setModalProps } = useModal()
  const { t } = useTranslation()

  useEffect(() => {
    setModalProps({ title: t('menu.favorites_history') })
  }, [])

  return (
    <Tabs
      tabs={[
        { label: t('menu.favorites'), content: <FavoriteHistoryList isFavorites={true} /> },
        { label: t('menu.history'), content: <FavoriteHistoryList isFavorites={false} /> },
      ]}
    />
  )
}

export { FavoritesHistory }
