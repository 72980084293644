import { t } from 'i18next'

import { store } from '@/store'

import { chainSensitiveLabels } from '../configs/chain-sensitive-labels'
import { ChainName } from '../enums'
import { ValueOf } from '../types'

const getChainSensitiveLabel = (label: keyof typeof chainSensitiveLabels) => {
  const chainName = store.getState().chain.currentChain.chainName

  const config = chainSensitiveLabels[label] as ValueOf<typeof chainSensitiveLabels> &
    Record<ChainName, string>

  return t(config[chainName] || config.default)
}

export { getChainSensitiveLabel }
