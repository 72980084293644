import {
  CopyTradeTemplateAction,
  ManualBuyTemplateAction,
  ScrapeTemplateAction,
  SnipeTemplateAction,
} from '@/libs/enums'

const getTemplateActionTitle = (query: string | null) => {
  switch (query) {
    case ManualBuyTemplateAction.ADD:
      return 'modal.manualBuyTemplate.createTitle'
    case ManualBuyTemplateAction.EDIT:
      return 'modal.manualBuyTemplate.editTitle'
    case SnipeTemplateAction.ADD_SNIPE:
      return 'Add new Snipe Template'
    case SnipeTemplateAction.EDIT_SNIPE:
      return 'Edit Snipe Template'
    case CopyTradeTemplateAction.ADD_COPYTRADE:
      return 'Add new copytrade wallet'
    case CopyTradeTemplateAction.EDIT_COPYTRADE:
      return 'Edit copytrade settings'
    case ScrapeTemplateAction.ADD_SCRAPE:
      return 'Add new scrape'
    default:
      return 'Unknown type'
  }
}

export { getTemplateActionTitle }
