import { FC } from 'react'

import { Grid, TableCell, TableRow } from '@mui/material'
import cn from 'classnames'
import moment from 'moment'

import { TTopToken } from '@/api/tokens/types'
import styles from '@/components/token-list/libs/trending-table/styles.module.scss'
import { useSelectNewToken } from '@/hooks/useSelectNewToken.hook'
import { Icon, TokenPlaceholder, Typography } from '@/libs/common'
import { IconName } from '@/libs/enums'
import { formatNumber } from '@/libs/helper'
import { useAppSelector } from '@/store'

type TProperty = {
  row: TTopToken
  dateFormat: string
}

const Row: FC<TProperty> = ({ row, dateFormat }) => {
  const handleNewTokenSelection = useSelectNewToken()
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  const getExchangesCount = () => {
    if (row.exchanges.length <= 4) return null
    const exchangesWithImg = row.exchanges.filter((el) => el.iconUrl)

    return exchangesWithImg.length <= 4
      ? `+${row.exchanges.length - exchangesWithImg.length}`
      : `+${row.exchanges.length - 4}`
  }
  const handleChangeCurrentToken = async () => {
    handleNewTokenSelection(row.address)
  }

  return (
    <TableRow className={styles.row} onClick={handleChangeCurrentToken}>
      <TableCell className={cn(styles.cell, styles.token)}>
        <div className={styles.controls}>
          <div className={styles.iconWrapper}>
            {row.images.small ? (
              <img src={row.images.small} className={styles.icon} loading="lazy" alt={row.name} />
            ) : (
              <TokenPlaceholder size="35px" />
            )}

            {/*<img src={eth} loading="lazy" alt="network logo" className={styles.network} />*/}
            <div className={styles.network}>
              <Icon name={(IconName as any)[currentChain.networkIconName]} />
            </div>
          </div>

          <Grid container flexDirection="column">
            <Typography variant="body2" className={styles.symbol}>
              {row.symbol}
            </Typography>

            <Typography variant="body2" className={styles.name}>
              {row.name}
            </Typography>
          </Grid>
        </div>
      </TableCell>
      <TableCell className={cn(styles.cell, styles.exchanges)}>
        <div className={styles.exchangeWrapper}>
          {row.exchanges
            .filter((el) => el.iconUrl)
            .slice(0, 4)
            .map((el, index) => (
              <img
                key={el.id}
                src={el.iconUrl}
                alt={el.name}
                className={styles.image}
                style={{ zIndex: 4 - index }}
              ></img>
            ))}
          <Typography>{getExchangesCount()}</Typography>
        </div>
      </TableCell>
      <TableCell className={cn(styles.cell, styles.price)}>
        ${formatNumber(row.price).formatted}
      </TableCell>
      <TableCell
        className={cn(
          styles.cell,
          styles.time,
          (row.price_change_one_hour || 0) >= 0 ? styles.greenText : styles.redText,
        )}
      >
        {formatNumber((row.price_change_one_hour || 0) * 100).formatted}%
      </TableCell>
      <TableCell
        className={cn(
          styles.cell,
          styles.time,
          styles.time,
          (row.price_change_four_hours || 0) >= 0 ? styles.greenText : styles.redText,
        )}
      >
        {formatNumber((row.price_change_four_hours || 0) * 100).formatted}%
      </TableCell>
      <TableCell
        className={cn(
          styles.cell,
          styles.time,
          styles.time,
          (row.price_change_twelve_hours || 0) >= 0 ? styles.greenText : styles.redText,
        )}
      >
        {formatNumber((row.price_change_twelve_hours || 0) * 100).formatted}%
      </TableCell>
      <TableCell
        className={cn(
          styles.cell,
          styles.time,
          styles.time,
          (row.price_change_one_day || 0) >= 0 ? styles.greenText : styles.redText,
        )}
      >
        {formatNumber((row.price_change_one_day || 0) * 100).formatted}%
      </TableCell>
      <TableCell className={cn(styles.cell, styles.vol)}>
        <div>V {formatNumber(row.volume).formatted}</div>
        <div>L {formatNumber(row.liquidity).formatted}</div>
      </TableCell>
      <TableCell className={cn(styles.cell, styles.vol)}>
        <div>T {formatNumber(row.txn_count_one_day || 0).formatted}</div>
        <div>M {formatNumber(row.market_cap || 0).formatted}</div>
      </TableCell>
      <TableCell className={cn(styles.cell, styles.vol)}>
        <div>
          <span className={styles.greenText}>B</span>{' '}
          {formatNumber(row.buys_count_one_day || 0).formatted}
        </div>
        <div>
          <span className={styles.redText}>S</span>{' '}
          {formatNumber(row.sells_count_one_day || 0).formatted}
        </div>
      </TableCell>
      <TableCell className={cn(styles.cell, styles.vol)}>
        <div>
          C{' '}
          {dateFormat === 'date'
            ? moment.utc(row.created_at * 1000).format('MMM D, HH:mm')
            : moment
                .utc(row.created_at * 1000)
                .startOf('second')
                .fromNow()}
        </div>
        <div>
          L{' '}
          {row.last_transaction
            ? dateFormat === 'date'
              ? moment.utc(row.last_transaction * 1000).format('MMM D, HH:mm')
              : moment
                  .utc(row.last_transaction * 1000)
                  .startOf('second')
                  .fromNow()
            : '-'}
        </div>
      </TableCell>
    </TableRow>
  )
}

export { Row }
