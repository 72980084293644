import { useTranslation } from 'react-i18next'

import { Grid } from '@mui/material'

import { ActiveDevicesTable } from '@/components/user-settings/libs/components'
import { Typography } from '@/libs/common'

import styles from './styles.module.scss'

const Servers = () => {
  // TODO: for License Modal feature
  //const navigate = useNavigate()
  // const handleOpenLicenseModal = () => {
  //   navigate(`${AppRoute.MODAL}/${AppRoute.LICENSE_NEW_SERVER}`)
  // }
  const { t } = useTranslation()

  return (
    // TODO: for servers table feature
    // <Grid container flexDirection="column" gap={1.5}>
    //   <Typography variant="body2" textColor="white" className={styles.titleWrapper}>
    //     Active servers <span className={styles.span}>(3 / 5)</span>
    //     <Button
    //       className={styles.button}
    //       styleVariant="borderless-gold"
    //       iconStartName={IconName.ADD_CIRCLE}
    //       onClick={handleOpenLicenseModal}
    //       transparent
    //     >
    //       License new
    //     </Button>
    //   </Typography>
    //
    //   <ActiveServersTable />
    // </Grid>
    <Grid container flexDirection="column" gap={1.5}>
      <Typography variant="body2" textColor="white" className={styles.titleWrapper}>
        {t('settings.linked_devices')}
      </Typography>

      <ActiveDevicesTable />
    </Grid>
  )
}

export { Servers }
