import { TSniperSimulationData } from '@/libs/types/sniper-simulation-socket-response.type'

export function adaptSimulation(data: TSniperSimulationData) {
  const liquidityInfo = typeof data.l === 'string' ? null : data.l
  return {
    data: {
      name: data.t.n,
      symbol: data.t.s,
      address: data.t.a,
      tradeable: data.t.t,
      honeypot: data.t.h,
      market_cap: data.t.mc,
      renounced: data.t.r,
      burned: data.t.b,
      total_supply: data.t.ts,
      holders_count: data.t.ho,
      top10Holders: data.t.h10,
      max_tx: data.lt?.b.ccc || null,
      max_wallet: data.lt?.w.ccc || null,
      clog: data.t.c,
      gain: data.t.g,
      owner_supply: data.t.ow,
      data_mutable: data.t.dm || null,
      freeze_auth: data.t.fa || null,
      mint_auth: data.t.ma || null,
      dev_sold: data.t.ds || null,
      update_auth: data.t.ua || null,
      price: {
        tokens: data.p.op,
        usd: data.p.ou,
      },
      liquidity: {
        pair: {
          reserve: liquidityInfo?.p.r,
          value_usd: liquidityInfo ? liquidityInfo.p.vu : (data.l as string),
          address: liquidityInfo?.p.a,
        },
        burned: liquidityInfo?.b,
        dex_name: liquidityInfo?.dn,
        total_supply: liquidityInfo?.ts,
        suggested_dex: Number(liquidityInfo?.sd),
      },
      taxes: data.tx
        ? {
            buy: data.tx.b,
            sell: data.tx.s,
            transfer: data.tx.t,
          }
        : null,
      limits: data.lt
        ? {
            buy: {
              percentage: data.lt.b.p,
              cost_chain_currency: data.lt.b.ccc,
            },
            sell: {
              percentage: data.lt.s.p,
              cost_chain_currency: data.lt.s.ccc,
            },
            wallet: {
              percentage: data.lt.w.p,
              cost_chain_currency: data.lt.w.ccc,
            },
          }
        : null,
    },
  } as const
}
