import { FC } from 'react'

import { Grid } from '@mui/material'

import { Icon, Typography } from '@/libs/common'
import { IconName } from '@/libs/enums'
import { ValueOf } from '@/libs/types'

import styles from './styles.module.scss'

type TProps = {
  iconName: ValueOf<typeof IconName>
  title: string
  isLast?: boolean
  index: number
}

const AffiliateStep: FC<TProps> = ({ iconName, title, isLast, index }) => {
  return (
    <div className={styles.container}>
      <Grid display="flex" flexDirection="column" alignItems="center" className={styles.content}>
        <span className={styles.stepNumber}>{index}</span>
        <Typography className={styles.stepTitle} variant="body1">
          {title}
        </Typography>
      </Grid>
      {!isLast && (
        <span className={styles.stepIcon}>
          <Icon name={iconName as any} />
        </span>
      )}
    </div>
  )
}

export { AffiliateStep }
