import { useRef, useState } from 'react'

import cn from 'classnames'

import { useAppSelector } from '@/store'

import { RESOLUTION_MAP } from '../configs'
import { useChartData } from './libs/hooks/use-chart-data.hook'
import { Time } from './libs/time'
import styles from './styles.module.scss'

const setNewResolution = (newRes: keyof typeof RESOLUTION_MAP) => {
  localStorage.lastChartResolution = newRes
}

const getResolution = (): keyof typeof RESOLUTION_MAP => {
  let res = localStorage.lastChartResolution
  if (!res) {
    res = '1' satisfies keyof typeof RESOLUTION_MAP // Default resolution
    setNewResolution(res)
  }
  return res
}

const TelegramTradingView = () => {
  const containerRef = useRef<HTMLDivElement>(null)
  const currentToken = useAppSelector((state) => state.chain.currentToken)

  const [intervals, setIntervals] = useState<{
    resolution: keyof typeof RESOLUTION_MAP
    from: number
    to: number
  }>({
    resolution: getResolution(),
    from: Math.floor(Date.now() / 1000) - 3 * 60 * 60,
    to: Math.floor(Date.now() / 1000),
  })
  const [displayType, setDisplayType] = useState<'price' | 'mcap'>('price')

  useChartData(containerRef, currentToken?.pair.address || '', intervals, displayType)

  return (
    <div>
      <div className={styles.topControls}>
        <div className={styles.displayTypeButtons}>
          <button
            className={cn(styles.displayTypeButton, {
              [styles.selectedDisplayType]: displayType === 'price',
            })}
            onClick={() => setDisplayType('price')}
          >
            Price
          </button>
          <span className={styles.divider}>/</span>
          <button
            className={cn(styles.displayTypeButton, {
              [styles.selectedDisplayType]: displayType === 'mcap',
            })}
            onClick={() => setDisplayType('mcap')}
          >
            MCap
          </button>
        </div>
      </div>
      <div ref={containerRef} />
      <div className={styles.bottomControls}>
        <div className={styles.resolutionButtons}>
          <button
            className={cn(styles.resolutionButton, {
              [styles.selectedResolution]: intervals.resolution === '60',
            })}
            onClick={() =>
              setIntervals({
                resolution: '60',
                from: Math.floor(Date.now() / 1000) - 3 * 30 * 24 * 60 * 60,
                to: Math.floor(Date.now() / 1000),
              })
            }
          >
            3m
          </button>
          <button
            className={cn(styles.resolutionButton, {
              [styles.selectedResolution]: intervals.resolution === '5',
            })}
            onClick={() =>
              setIntervals({
                resolution: '5',
                from: Math.floor(Date.now() / 1000) - 5 * 24 * 60 * 60,
                to: Math.floor(Date.now() / 1000),
              })
            }
          >
            5d
          </button>
          <button
            className={cn(styles.resolutionButton, {
              [styles.selectedResolution]: intervals.resolution === '1',
            })}
            onClick={() =>
              setIntervals({
                resolution: '1',
                from: Math.floor(Date.now() / 1000) - 24 * 60 * 60,
                to: Math.floor(Date.now() / 1000),
              })
            }
          >
            1d
          </button>
        </div>
        <div className={styles.time}>
          <Time />
        </div>
      </div>
    </div>
  )
}

export { TelegramTradingView }
