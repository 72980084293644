import * as yup from 'yup'

const passwordValidation = yup
  .string()
  .required('settings.field_required')
  .min(12, 'settings.password_min')
  .max(32, 'settings.password_max')
  .trim()

const userNameValidation = yup
  .string()
  .trim()
  .matches(/^[^?& ]+$/, 'Username cannot contain spaces or special characters')
  .min(3)

export { passwordValidation, userNameValidation }
