import { ChainName, EManualSettingsSection } from '@/libs/enums'

import { TOptionalCopyTradeFields } from './types'

// Template has all fields with empty params
const SCRAPE_CHAIN_SETTINGS: Record<ChainName, TOptionalCopyTradeFields> = {
  [ChainName.ETHEREUM]: {},
  [ChainName.BNB]: {
    [EManualSettingsSection.BUY]: {
      antiMev: false,
    },
    [EManualSettingsSection.SAFETY]: {},
    [EManualSettingsSection.SELL]: {},
  },
  [ChainName.AVAX]: {},
  [ChainName.ARBITRUM]: {},
  [ChainName.BASE]: {},
  [ChainName.SOLANA]: {
    [EManualSettingsSection.BUY]: {
      antiMev: false,
    },
  },
}

export { SCRAPE_CHAIN_SETTINGS }
