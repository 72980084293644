import { useEffect, useMemo, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useParams, useSearchParams } from 'react-router-dom'

// import { useSearchParams } from 'react-router-dom'
import { Grid, InputLabel } from '@mui/material'
import cn from 'classnames'
import { t } from 'i18next'

import { chainPriorityCurrencies } from '@/components/buy-tab/libs/constants'
import { getNewDefaultTemplate } from '@/components/buy-tab/libs/utils/get-default-template'
import { SelectWallet } from '@/components/select-wallet'
import { TemplatesControl } from '@/components/templates-control'
import { useWindowDimensions } from '@/hooks/useWindowDimensions'
import {
  Button,
  ButtonGroupRadio,
  ButtonGroupRadioButton,
  HiddenInputs,
  Input,
  StepInput,
  Tabs,
  Typography,
} from '@/libs/common'
import { EndAdornment } from '@/libs/common/input/components/end-adornment'
import { OptionalInput } from '@/libs/common/optional-input'
import { PercentsInput } from '@/libs/common/percents-input'
import { SwitchInline } from '@/libs/common/switch-inline'
import { TooltipIcon } from '@/libs/common/tooltip-icon'
import { CopyTradeTemplateAction, EManualSettingsSection, IconName } from '@/libs/enums'
import { createBuyFormStateFromTemplate } from '@/libs/helper/buy/createBuyFormStateFromTemplate'
import { TCopyTradeTransaction } from '@/libs/types/copy-trade-transaction'
import { TSelectOption } from '@/libs/types/select-option.type'
import { TEntityTemplate } from '@/libs/types/template'
import { getTemplateActionTitle } from '@/pages/modal-page/libs/helpers'
import { useModal } from '@/pages/modal-page/modal-page'
import { useAppSelector } from '@/store'

import { templateDefaultValues } from '../../constants'
import { CopyTradeTemplateSettings } from './libs/components/copy-trade-template-settings'
import { COPYTRADE_CHAIN_SETTINGS } from './libs/constants'
import styles from './styles.module.scss'

const ProfitSettingsTabs: TSelectOption<string>[] = [
  { value: 'sellInSteps', label: t('sell_tab.sell_in_steps') },
  { value: 'sellOnProfit', label: 'Sell on profit' },
  { value: 'trailingSell', label: 'Trailing sell' },
]

const manualTemplate = {
  id: 'Manual',
  name: 'Manual',
  isPredefined: true,
}

type TCopyTradeTemplate = TEntityTemplate<TCopyTradeTransaction>

const CopyTradeTemplate = () => {
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const userWallets = useAppSelector((state) => state.user.userWallets)
  const userTemplates = useAppSelector((state) => state.user.userTemplates.buyTemplates)
  const defaultPriorities = useAppSelector((state) => state.user.defaultPriorities)

  const [mainTab, setMainTab] = useState(0)
  const [profitTab, setProfitTab] = useState<string>(ProfitSettingsTabs[0].value)
  const [isAntiRugSettings, setIsAntiRugSettings] = useState(false)
  const [isAutoSellSettings, setIsAutoSellSetting] = useState(true)
  const [customizableTemplateId, setCustomizableTemplateId] = useState<string | null>(null)
  const [selectedWallets, setSelectedWallets] = useState<string[]>([])
  const [frontrunBuys, setFrontrunBuys] = useState(false)
  const [frontrunSells, setFrontrunSells] = useState(false)
  const [backrunBuys, setBackrunBuys] = useState(false)
  const [searchParams] = useSearchParams()
  const { setModalProps } = useModal()
  const { templateId } = useParams()
  const windowDimension = useWindowDimensions()

  const isLastMainTab = mainTab === Object.values(EManualSettingsSection).length - 1
  const action = searchParams.get('action')
  const isResponsive = windowDimension.width <= 630

  const templates = useMemo(() => {
    const defaultTemplates = [manualTemplate]
    const mainWallet = userWallets?.find((wallet) => wallet.is_default) || userWallets?.[0]
    const defaultCustomTemplate = getNewDefaultTemplate({
      mainWallet,
      chainName: currentChain.chainName,
    })

    if (defaultCustomTemplate) {
      defaultTemplates.push(defaultCustomTemplate as any)
    }

    return [
      ...defaultTemplates,
      ...(userTemplates?.filter(
        (item) => item.setup?.network.blockchain === currentChain.indexerChainId,
      ) || []),
    ]
  }, [userTemplates, currentChain, userWallets])

  const [currentTemplate, setCurrentTemplate] = useState<
    TCopyTradeTemplate | typeof manualTemplate
  >(manualTemplate)

  const defaultValues = useMemo(() => {
    const defaultWallet = userWallets?.length
      ? userWallets.find((wallet) => wallet.is_default)
      : null
    const initialWallets = defaultWallet ? [defaultWallet.address] : []

    const advancedBuyDefaultValues = {
      buyPriority: defaultPriorities?.buy_priority ?? '',
      approvePriority: defaultPriorities?.approve_priority ?? '',
    }

    const ordinaryBuyDefaultValues = {
      ...templateDefaultValues.ordinaryBuy,
      selectedWallets: initialWallets,
      receive: 'setup' in currentTemplate ? currentTemplate?.setup?.operation.setup.buy_amount : '',
    }

    const sellDefaultValues = {
      ...templateDefaultValues.sell,
      walletsToSellOnProfit: initialWallets,
      walletsToSellOnLoss: initialWallets,
      selectedWalletsForProfit: initialWallets,
      selectedWalletsForLoss: initialWallets,
      walletsToTrailingSell: initialWallets,
    }

    return {
      ...templateDefaultValues,
      ordinaryBuy: ordinaryBuyDefaultValues,
      advancedBuy: advancedBuyDefaultValues,
      sell: sellDefaultValues,
    }
  }, [userWallets?.length, defaultPriorities])

  const { handleSubmit, control, setValue } = useForm({ defaultValues })

  useEffect(() => {
    setModalProps({
      title: getTemplateActionTitle(CopyTradeTemplateAction.ADD_COPYTRADE),
      titleProps: { className: styles.title },
      withBackButton: true,
    })
  }, [templateId])

  const customizeTemplate = () => {
    if (!currentTemplate) return

    const newValues = createBuyFormStateFromTemplate(currentTemplate as TCopyTradeTemplate).template

    Object.keys(newValues).forEach((key: any) => {
      setValue(key, (newValues as any)[key])
    })

    setCustomizableTemplateId(currentTemplate.id)
    handleTemplateChange(manualTemplate.id)
  }

  const handleTemplateChange = (newId?: string) => {
    const foundTemplate = templates.find((item) => item.id === newId) || null
    // const newTemplate =
    //   foundTemplate && foundTemplate.id !== manualTemplate.id
    //     ? (foundTemplate as TBuyTemplate)
    //     : null

    setCurrentTemplate(foundTemplate as TCopyTradeTemplate)
  }

  const handleProfitTabChange = (_: React.BaseSyntheticEvent, newValue: string) => {
    setProfitTab(newValue)
  }

  const handleChangeMainTab = () => {
    setMainTab((value) =>
      value < Object.values(EManualSettingsSection).length ? value + 1 : value,
    )
  }

  const onSubmit: SubmitHandler<typeof defaultValues> = (data) => {
    // Send data to backend
    return data
  }

  const chainSettings = COPYTRADE_CHAIN_SETTINGS[currentChain.chainName]
  return (
    <div>
      <Grid className={styles.content}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container rowGap={3}>
            <Grid container rowGap={3}>
              <Grid width="100%">
                <Input
                  label={t('name')}
                  placeholder="Enter name"
                  className={styles.input}
                  maxLength={15}
                />

                <Input
                  label={t('wallet.wallet_address')}
                  placeholder={t('wallet.enter_wallet_address')}
                  className={styles.input}
                />
              </Grid>

              <Grid width="100%">
                <TemplatesControl
                  templates={templates}
                  currentTemplateId={currentTemplate?.id || manualTemplate.id}
                  onSelectTemplate={handleTemplateChange}
                  onCustomize={customizeTemplate}
                  customizeDisabled={!currentTemplate}
                  editDisabled={!currentTemplate || currentTemplate.isPredefined}
                  deleteDisabled={!currentTemplate || currentTemplate.isPredefined}
                  isResponsive={isResponsive}
                />
              </Grid>

              {currentTemplate?.id == manualTemplate.id && (
                <Tabs
                  tabControl
                  value={mainTab}
                  tabs={[
                    {
                      label: EManualSettingsSection.BUY,
                      content: (
                        <Grid container rowGap={2}>
                          {chainSettings[EManualSettingsSection.BUY]?.buyAmount ?? (
                            <Controller
                              name="ordinaryBuy.amount"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <OptionalInput
                                  isNumeric
                                  className={cn(styles.input, styles.inputSize)}
                                  label={t('token_info.amount')}
                                  placeholder={t('template.enter_gwei_amount', {
                                    currency: currentChain.priorityCurrency.toLowerCase(),
                                  })}
                                  endAdornment={
                                    <EndAdornment
                                      label={chainPriorityCurrencies[currentChain.chainName]}
                                      icon={(IconName as any)[currentChain.iconName]}
                                    />
                                  }
                                  {...field}
                                />
                              )}
                            />
                          )}

                          <Grid container columnGap={3} flexWrap="nowrap">
                            {chainSettings[EManualSettingsSection.BUY]?.antiMev ?? (
                              <Controller
                                name="ordinaryBuy.privateTransaction"
                                control={control}
                                render={({ field: { ref, ...field } }) => (
                                  <SwitchInline
                                    label={t('template.anti_mev')}
                                    tooltipInfo={t('template.anti_mev_tooltip')}
                                    {...field}
                                  />
                                )}
                              />
                            )}
                            {chainSettings[EManualSettingsSection.BUY]?.degenChadMode ?? (
                              <Controller
                                name="ordinaryBuy.degenChadMode"
                                control={control}
                                render={({ field: { ref, ...field } }) => (
                                  <SwitchInline
                                    label={t('template.degen_chad_mode')}
                                    tooltipInfo={t('template.degen_chad_mode_tooltip')}
                                    {...field}
                                  />
                                )}
                              />
                            )}
                          </Grid>

                          <Grid container columnGap={3} flexWrap="nowrap">
                            <Controller
                              name="ordinaryBuy.frontrunBuys"
                              control={control}
                              render={({ field: { ref, onChange, ...field } }) => (
                                <SwitchInline
                                  label="Frontrun Buys"
                                  tooltipInfo="Will try to buy faster than other wallet. If it detects transaction initiated by other wallet, it will spend more gas fee (gwei) to prioritize your transaction in network."
                                  onChange={(
                                    _: React.ChangeEvent<HTMLInputElement>,
                                    checked: boolean,
                                  ) => {
                                    if (checked && backrunBuys) {
                                      setValue('ordinaryBuy.backrunBuys', false)
                                      setBackrunBuys(false)
                                    }
                                    setFrontrunBuys(checked)
                                    onChange(checked)
                                  }}
                                  {...field}
                                />
                              )}
                            />

                            <Controller
                              name="ordinaryBuy.backrunBuys"
                              control={control}
                              render={({ field: { ref, onChange, ...field } }) => (
                                <SwitchInline
                                  label="Backrun Buys"
                                  tooltipInfo="This will make sure that the tracked transaction is prioritized over the your backrun transaction."
                                  onChange={(
                                    _: React.ChangeEvent<HTMLInputElement>,
                                    checked: boolean,
                                  ) => {
                                    if (checked && frontrunBuys) {
                                      setValue('ordinaryBuy.frontrunBuys', false)
                                      setFrontrunBuys(false)
                                    }
                                    setBackrunBuys(checked)
                                    onChange(checked)
                                  }}
                                  {...field}
                                />
                              )}
                            />
                          </Grid>

                          {frontrunBuys && (
                            <Controller
                              name="ordinaryBuy.frontrunBuyPriority"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <OptionalInput
                                  isNumeric
                                  className={styles.input}
                                  label="Frontrun Priority"
                                  placeholder={t('template.enter_gwei_amount', {
                                    currency: currentChain.priorityCurrency.toLowerCase(),
                                  })}
                                  tooltipInfo="It will add this much priority gwei to the dev transaction gwei, if developer does 80 and you enter 5 here it will do 85."
                                  endAdornment={
                                    <EndAdornment
                                      label={chainPriorityCurrencies[currentChain.chainName]}
                                      icon={(IconName as any)[currentChain.iconName]}
                                    />
                                  }
                                  {...field}
                                />
                              )}
                            />
                          )}

                          <Controller
                            name="ordinaryBuy.slippage"
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <PercentsInput
                                label={t('template.slippage')}
                                onOptionSelect={(value: any) => field.onChange(value)}
                                tooltipInfo={t('template.slippage_tooltip')}
                                placeholder="X"
                                {...field}
                              />
                            )}
                          />

                          <Controller
                            name="ordinaryBuy.selectedWallets"
                            control={control}
                            render={({ field: { ref, value, ...field } }) => (
                              <SelectWallet
                                isMulti
                                wallet={value}
                                wallets={userWallets}
                                tooltipInfo="tooltipInfo"
                                isSelectDefaultWallet={!customizableTemplateId}
                                {...field}
                              />
                            )}
                          />

                          <Grid container display="flex" flexWrap="nowrap" gap={3}>
                            <Controller
                              name="advancedBuy.buyPriority"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <OptionalInput
                                  className={styles.input}
                                  label={t('template.buy_priority')}
                                  placeholder={t('template.enter_gwei_amount', {
                                    currency: currentChain.priorityCurrency.toLowerCase(),
                                  })}
                                  isNumeric
                                  tooltipInfo={`${t('template.buy_priority_tooltip')} ${currentChain.chainSymbol}.`}
                                  onAutoChange={() => {
                                    //TO DO: added auto switch
                                  }}
                                  endAdornment={
                                    <EndAdornment
                                      label={chainPriorityCurrencies[currentChain.chainName]}
                                      icon={(IconName as any)[currentChain.iconName]}
                                    />
                                  }
                                  {...field}
                                />
                              )}
                            />

                            <Controller
                              name="advancedBuy.approvePriority"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <OptionalInput
                                  className={cn(styles.input, styles.inputSize)}
                                  label={t('template.approve_priority')}
                                  placeholder={t('template.enter_gwei_amount', {
                                    currency: currentChain.priorityCurrency.toLowerCase(),
                                  })}
                                  isNumeric
                                  tooltipInfo={`${t('template.approve_priority_tooltip')} ${currentChain.chainSymbol}.`}
                                  endAdornment={
                                    <EndAdornment
                                      label={chainPriorityCurrencies[currentChain.chainName]}
                                      icon={(IconName as any)[currentChain.iconName]}
                                    />
                                  }
                                  {...field}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      ),
                    },
                    {
                      label: EManualSettingsSection.SAFETY,
                      content: (
                        <Grid container rowGap={2} flexDirection="column">
                          <Grid container columnGap={3} flexWrap="nowrap">
                            <Controller
                              name="shields.transferOnBlackList"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <SwitchInline
                                  label="Transfer On Blacklist"
                                  tooltipInfo="Will transfer tokens to the backup wallet when the developer tries to blacklist your wallet."
                                  {...field}
                                />
                              )}
                            />
                            <Controller
                              name="shields.antiBlackList"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <SwitchInline
                                  label={t('template.anti_blacklist')}
                                  tooltipInfo={t('template.anti_blacklist_tooltip')}
                                  {...field}
                                />
                              )}
                            />
                          </Grid>

                          <HiddenInputs
                            expanded={isAntiRugSettings}
                            trigger={
                              <Controller
                                name="shields.antiRug"
                                control={control}
                                render={({ field: { ref, onChange, ...field } }) => (
                                  <SwitchInline
                                    label={t('template.anti_rug')}
                                    tooltipInfo={t('template.anti_rug_tooltip')}
                                    onChange={(
                                      _: React.ChangeEvent<HTMLInputElement>,
                                      checked: boolean,
                                    ) => {
                                      setIsAntiRugSettings(checked)
                                      onChange(checked)
                                    }}
                                    {...field}
                                  />
                                )}
                              />
                            }
                          >
                            <Grid container rowGap={2}>
                              <Controller
                                name="shields.antiRugThreshold"
                                control={control}
                                render={({ field: { ref, ...field } }) => (
                                  <PercentsInput
                                    label={t('template.anti_rug_threshold')}
                                    onOptionSelect={(value: any) => field.onChange(value)}
                                    tooltipInfo={t('template.anti_rug_tooltip')}
                                    {...field}
                                  />
                                )}
                              />

                              <Controller
                                name="shields.sellRugExtraTip"
                                control={control}
                                render={({ field: { ref, ...field } }) => (
                                  <OptionalInput
                                    placeholder={t('token_info.amount')}
                                    isNumeric
                                    label={t('template.sell_extra_rug')}
                                    tooltipInfo={t('template.sell_extra_rug_tooltip')}
                                    {...field}
                                  />
                                )}
                              />
                            </Grid>
                          </HiddenInputs>

                          <Grid container flexWrap="nowrap" columnGap={3}>
                            <Controller
                              name="shields.buy_tax"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <OptionalInput
                                  placeholder={t('template.enter_percentage')}
                                  isNumeric
                                  label={t('template.max_buy_template')}
                                  adornmentText="%"
                                  tooltipInfo={t('template.max_buy_tax_tooltip')}
                                  {...field}
                                />
                              )}
                            />
                            <Controller
                              name="shields.sell_tax"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <OptionalInput
                                  placeholder={t('template.enter_percentage')}
                                  isNumeric
                                  label={t('template.max_sell_tax')}
                                  adornmentText="%"
                                  tooltipInfo={t('template.max_sell_tax_tooltip')}
                                  {...field}
                                />
                              )}
                            />
                          </Grid>
                          <Grid container flexWrap="nowrap" columnGap={3}>
                            <Controller
                              name="shields.minimum_liquidity"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <OptionalInput
                                  placeholder={t('token_info.enter_min_liquidity')}
                                  isNumeric
                                  label={t('token_info.min_liquidity')}
                                  adornmentText="$"
                                  tooltipInfo={t('token_info.min_liquidity_tooltip')}
                                  {...field}
                                />
                              )}
                            />
                            <Controller
                              name="shields.maximum_liquidity"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <OptionalInput
                                  placeholder={t('token_info.enter_max_liquidity')}
                                  isNumeric
                                  label={t('token_info.max_liquidity')}
                                  adornmentText="$"
                                  tooltipInfo={t('token_info.max_liquidity_tooltip')}
                                  {...field}
                                />
                              )}
                            />
                          </Grid>
                          <Controller
                            name="shields.maximum_market_cap"
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <OptionalInput
                                placeholder={t('token_info.enter_max_marketcap')}
                                isNumeric
                                label={t('token_info.max_marketcap')}
                                adornmentText="$"
                                tooltipInfo={t('token_info.max_marketcap_tooltip')}
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                      ),
                    },
                    {
                      label: EManualSettingsSection.SELL,
                      content: (
                        <Grid gap={3} container flexDirection="column">
                          <Grid gap={2} container flexDirection="column">
                            <Controller
                              name="sell.copySells"
                              control={control}
                              render={({ field: { ref, onChange, ...field } }) => (
                                <SwitchInline
                                  label="Copy sells"
                                  tooltipInfo="Enable to copy sales transactions of a tracked wallet."
                                  {...field}
                                />
                              )}
                            />

                            <Controller
                              name="sell.frontrunSells"
                              control={control}
                              render={({ field: { ref, onChange, ...field } }) => (
                                <SwitchInline
                                  label="Frontrun Sells"
                                  tooltipInfo="Used to sell faster than the tracked wallet. When the bot notices that the tracked wallet is trying to sell a token, it will launch a transaction with a higher priority to beat it."
                                  onChange={(
                                    _: React.ChangeEvent<HTMLInputElement>,
                                    checked: boolean,
                                  ) => {
                                    setFrontrunSells(checked)
                                    onChange(checked)
                                  }}
                                  {...field}
                                />
                              )}
                            />
                            {frontrunSells && (
                              <Controller
                                name="sell.frontrunSellPriority"
                                control={control}
                                render={({ field: { ref, ...field } }) => (
                                  <OptionalInput
                                    className={styles.input}
                                    label="Frontrun Priority"
                                    placeholder={t('template.enter_gwei_amount', {
                                      currency: currentChain.priorityCurrency.toLowerCase(),
                                    })}
                                    isNumeric
                                    tooltipInfo="It will add this much priority gwei to the dev transaction gwei, if developer does 80 and you enter 5 here it will do 85."
                                    endAdornment={
                                      <EndAdornment
                                        label={chainPriorityCurrencies[currentChain.chainName]}
                                        icon={(IconName as any)[currentChain.iconName]}
                                      />
                                    }
                                    {...field}
                                  />
                                )}
                              />
                            )}
                          </Grid>

                          <HiddenInputs
                            expanded={isAutoSellSettings}
                            trigger={
                              <Controller
                                name="sell.autoSell"
                                control={control}
                                render={({ field: { ref, onChange, ...field } }) => (
                                  <SwitchInline
                                    label={t('template.auto_sell')}
                                    tooltipInfo={t('template.auto_sell_tooltip')}
                                    {...field}
                                    onChange={(
                                      _: React.ChangeEvent<HTMLInputElement>,
                                      checked: boolean,
                                    ) => {
                                      setIsAutoSellSetting(checked)
                                      onChange(checked)
                                    }}
                                  />
                                )}
                              />
                            }
                          >
                            <Grid container rowGap={2} flexDirection="column">
                              <div className={styles.dividerContainer}>
                                <Typography variant="body2" textColor="grey">
                                  Profit
                                </Typography>

                                <div className={styles.divider}></div>
                              </div>
                              <Controller
                                name="sell.walletsToSellOnProfit"
                                control={control}
                                render={({ field: { ref, value, ...field } }) => {
                                  setSelectedWallets(value)

                                  return (
                                    <SelectWallet
                                      isMulti
                                      tooltipInfo={t('wallet.wallets_to_sell_on_profit_tooltip')}
                                      wallet={value}
                                      wallets={userWallets}
                                      isSelectDefaultWallet={!customizableTemplateId}
                                      {...field}
                                      label={t('wallet.wallets_to_sell_on_profit')}
                                    />
                                  )
                                }}
                              />

                              <ButtonGroupRadio
                                onChange={handleProfitTabChange}
                                value={profitTab}
                                exclusive
                                className={styles.group}
                              >
                                {ProfitSettingsTabs.map((tab) => (
                                  <ButtonGroupRadioButton
                                    value={tab.value}
                                    key={tab.value}
                                    className={cn({ [styles.bgGrey]: tab.value === profitTab })}
                                  >
                                    {tab.label}
                                  </ButtonGroupRadioButton>
                                ))}
                              </ButtonGroupRadio>

                              {profitTab === ProfitSettingsTabs[0].value &&
                                selectedWallets.length > 0 && (
                                  <div>
                                    <div className={styles.labelContent}>
                                      <InputLabel className={styles.label}>
                                        <Typography variant="body2" textColor="light-grey">
                                          {t('sell_tab.sell_in_steps')}
                                        </Typography>
                                      </InputLabel>
                                      <TooltipIcon
                                        info={t('sell_tab.sell_in_steps_tooltip')}
                                        marginLeft={5}
                                      />
                                    </div>
                                    <div className={styles.stepWrapper}>
                                      <Controller
                                        name="sell.firstStep"
                                        control={control}
                                        render={({ field: { ref, ...field } }) => (
                                          <StepInput
                                            wallet="#1"
                                            balance="12.58 ETH"
                                            className={styles.firstInput}
                                            {...field}
                                          />
                                        )}
                                      />
                                      <Controller
                                        name="sell.secondStep"
                                        control={control}
                                        render={({ field: { ref, ...field } }) => (
                                          <StepInput wallet="#2" balance="12.58 ETH" {...field} />
                                        )}
                                      />
                                      <Controller
                                        name="sell.thirdStep"
                                        control={control}
                                        render={({ field: { ref, ...field } }) => (
                                          <StepInput
                                            wallet="#3"
                                            balance="12.58 ETH"
                                            className={styles.lastInput}
                                            {...field}
                                          />
                                        )}
                                      />
                                    </div>
                                  </div>
                                )}

                              {profitTab === ProfitSettingsTabs[0].value &&
                                !selectedWallets.length && (
                                  <Typography variant="body1" className={styles.walletsDescr}>
                                    {t('wallet.no_wallets_selected')}
                                  </Typography>
                                )}

                              {profitTab === ProfitSettingsTabs[1].value && (
                                <Grid container flexWrap="nowrap" columnGap={3}>
                                  <Controller
                                    name="sell.sellOnProfit"
                                    control={control}
                                    render={({ field: { ref, ...field } }) => (
                                      <OptionalInput
                                        placeholder={t('sell_tab.enter_sell_profit')}
                                        isNumeric
                                        label={t('sell_tab.sell_on_profit')}
                                        adornmentText="%"
                                        tooltipInfo={t('sell_tab.sell_on_profit_tooltip')}
                                        {...field}
                                      />
                                    )}
                                  />

                                  <Controller
                                    name="sell.sellOnProfitAmount"
                                    control={control}
                                    render={({ field: { ref, ...field } }) => (
                                      <OptionalInput
                                        placeholder={t('sell_tab.sell_profit_amount')}
                                        isNumeric
                                        label={t('sell_tab.sell_profit_amount_title')}
                                        adornmentText="%"
                                        tooltipInfo={t('sell_tab.sell_profit_amount_tooltip')}
                                        {...field}
                                      />
                                    )}
                                  />
                                </Grid>
                              )}

                              {profitTab === ProfitSettingsTabs[2].value && (
                                <>
                                  <Controller
                                    name="sell.walletsToTrailingSell"
                                    control={control}
                                    render={({ field: { ref, value, ...field } }) => (
                                      <SelectWallet
                                        isMulti
                                        wallets={userWallets}
                                        wallet={value}
                                        label={t('sell_tab.wallets_to_sell')}
                                        tooltipInfo="tooltipInfo"
                                        isSelectDefaultWallet={!customizableTemplateId}
                                        {...field}
                                      />
                                    )}
                                  />
                                  <Controller
                                    name="sell.trailingSell"
                                    control={control}
                                    render={({ field: { ref, ...field } }) => (
                                      <OptionalInput
                                        placeholder={t('sell_tab.enter_sell_trailing')}
                                        isNumeric
                                        label={t('sell_tab.sell_profit')}
                                        adornmentText="%"
                                        tooltipInfo="tooltipInfo"
                                        {...field}
                                      />
                                    )}
                                  />
                                </>
                              )}

                              {profitTab !== ProfitSettingsTabs[2].value && (
                                <>
                                  <div className={styles.dividerContainer}>
                                    <Typography variant="body2" textColor="grey">
                                      {t('loss')}
                                    </Typography>

                                    <div className={styles.divider}></div>
                                  </div>
                                  <Controller
                                    name="sell.walletsToSellOnLoss"
                                    control={control}
                                    render={({ field: { ref, value, ...field } }) => (
                                      <SelectWallet
                                        label="Wallets to sell on Loss"
                                        isMulti
                                        wallets={userWallets}
                                        tooltipInfo="tooltip"
                                        wallet={value}
                                        isSelectDefaultWallet={!customizableTemplateId}
                                        {...field}
                                      />
                                    )}
                                  />

                                  <Grid container flexWrap="nowrap" columnGap={3}>
                                    <Controller
                                      name="sell.stopLoss"
                                      control={control}
                                      render={({ field: { ref, ...field } }) => (
                                        <OptionalInput
                                          placeholder={t('amount_values.enter_amount')}
                                          label={t('template.stop_loss')}
                                          tooltipInfo={t('template.stop_loss_tooltip')}
                                          adornmentText="%"
                                          {...field}
                                        />
                                      )}
                                    />
                                    <Controller
                                      name="sell.stopLossAmount"
                                      control={control}
                                      render={({ field: { ref, ...field } }) => (
                                        <OptionalInput
                                          placeholder={t('amount_values.enter_amount')}
                                          label={t('template.stop_loss_amount')}
                                          tooltipInfo={t(
                                            'amount_values.amount_sell_on_loss_tooltip',
                                          )}
                                          adornmentText="%"
                                          {...field}
                                        />
                                      )}
                                    />
                                  </Grid>
                                </>
                              )}
                            </Grid>
                          </HiddenInputs>
                        </Grid>
                      ),
                    },
                  ]}
                />
              )}

              {currentTemplate?.id !== manualTemplate.id && (
                <CopyTradeTemplateSettings
                  template={currentTemplate as TCopyTradeTemplate}
                  expanded={true}
                />
              )}

              {/* {currentTemplate?.id == fastBuyTemplateId && (
                <Grid width="100%" gap={2} container>
                  <Typography variant="body2" textColor="white">
                    Template Settings
                  </Typography>

                  <Grid container flexDirection="column" gap={1.5}>
                    <Typography variant="body2" textColor="white">
                      Buy
                    </Typography>

                    <Grid container gap={3}>
                      <Grid width="47.9%" container flexDirection="column" gap={1}>
                        <SettingField
                          title="Anti-Mev"
                          value={defaultValues.ordinaryBuy.privateTransaction}
                        />

                        <SettingField
                          title="Wallets Selected"
                          value={mockFastTemplateSettings.buy.walletsSelected}
                        />

                        <SettingField
                          title="Approve Priority"
                          value={
                            defaultValues.advancedBuy.approvePriority ||
                            mockFastTemplateSettings.buy.approvePriority
                          }
                        />
                      </Grid>

                      <Grid width="47.9%" container flexDirection="column" gap={1}>
                        <SettingField
                          title="Degen Chad Mode"
                          value={defaultValues.ordinaryBuy.degenChadMode}
                        />

                        <SettingField
                          title="Buy Priority"
                          value={
                            defaultValues.advancedBuy.buyPriority ||
                            mockFastTemplateSettings.buy.buyPriority
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container flexDirection="column" gap={1.5}>
                    <Typography variant="body2" textColor="white">
                      Safety
                    </Typography>

                    <Grid container gap={3}>
                      <Grid width="47.9%" container flexDirection="column" gap={1}>
                        <SettingField
                          title="Anti-Rug"
                          value={
                            defaultValues.shields.antiRug || mockFastTemplateSettings.safety.antiRug
                          }
                        />

                        <SettingField
                          title="Anti-Rug Threshold"
                          value={
                            defaultValues.shields.antiRugThreshold ||
                            mockFastTemplateSettings.safety.antiRugThreshold
                          }
                        />

                        <SettingField
                          title="Sell Rug Extra Tip"
                          value={
                            defaultValues.shields.sellRugExtraTip ||
                            mockFastTemplateSettings.safety.sellRugExtraTip
                          }
                        />

                        <SettingField
                          title="Max Buy Tax"
                          value={
                            defaultValues.shields.buy_tax ||
                            mockFastTemplateSettings.safety.maxBuyTax
                          }
                        />

                        <SettingField
                          title="Min Liquidity"
                          value={
                            defaultValues.shields.minimum_liquidity ||
                            mockFastTemplateSettings.safety.minLiquidity
                          }
                        />
                      </Grid>

                      <Grid width="47.9%" container flexDirection="column" gap={1}>
                         <SettingField
                          title="Anti-Blacklist"
                          value={defaultValues.shields.antiBlacklist}
                        />

                        <SettingField
                          title="Transfer On Blacklist"
                          value={defaultValues.shields.transferOnBlackList}
                        />

                        <SettingField
                          title="Max Marketcap"
                          value={
                            defaultValues.shields.maximum_market_cap ||
                            mockFastTemplateSettings.safety.maxMcap
                          }
                        />

                        <SettingField
                          title="Max Sell Tax"
                          value={
                            defaultValues.shields.sell_tax ||
                            mockFastTemplateSettings.safety.maxSellTax
                          }
                        />

                        <SettingField
                          title="Max Liquidity"
                          value={
                            defaultValues.shields.maximum_liquidity ||
                            mockFastTemplateSettings.safety.maxLiquidity
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container flexDirection="column" gap={1.5}>
                    <Typography variant="body2" textColor="white">
                      Sell
                    </Typography>

                    <Grid container gap={3}>
                      <Grid width="47.9%" container flexDirection="column" gap={1}>
                        <SettingField
                          title="Sell Priority"
                          value={
                            defaultValues.sell.sellPriority ||
                            mockFastTemplateSettings.sell.sellPriority
                          }
                        />

                        <SettingField
                          title="Sell on Profit"
                          value={mockFastTemplateSettings.sell.sellOnProfit}
                        />

                        <SettingField
                          title="Wallets to sell on Profit"
                          value={
                            defaultValues.sell.walletsToSellOnProfit && userWallets
                              ? `${defaultValues.sell.walletsToSellOnProfit.length}/${userWallets?.length}`
                              : mockFastTemplateSettings.sell.walletsToSellOnProfit
                          }
                        />

                        <SettingField
                          title="Sell in steps"
                          value={mockFastTemplateSettings.sell.sellInSteps}
                        />
                      </Grid>

                      <Grid width="47.9%" container flexDirection="column" gap={1}>
                        <SettingField
                          title="Auto Sell"
                          value={
                            defaultValues.sell.autoSell || mockFastTemplateSettings.sell.autoSell
                          }
                        />

                        <SettingField
                          title="Sell Profit Amount"
                          value={mockFastTemplateSettings.sell.sellProfitAmount}
                        />

                        <SettingField
                          title="Sell Loss Amount"
                          value={mockFastTemplateSettings.sell.sellLossAmount}
                        />

                        <SettingField
                          title="Trailing Sell"
                          value={mockFastTemplateSettings.sell.trailingSell}
                        />

                        <SettingField title="Sell in steps:" value={''} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )} */}
            </Grid>
            <Grid container columnGap={3} flexWrap="nowrap">
              <Button styleVariant="black" onClick={handleChangeMainTab} disabled={isLastMainTab}>
                {t('continue')}
              </Button>

              <Button disabled={!isLastMainTab}>
                {action === 'add-copytrade' ? t('add') : t('save')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </div>
  )
}

export { CopyTradeTemplate }
