import {
  FC,
  HTMLAttributes,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete'

import { getFilterTokens, getTokenExchangesAndSocial } from '@/api/tokens'
import { TDetailedPairStats } from '@/api/tokens/types'
import { useSelectNewToken } from '@/hooks/useSelectNewToken.hook'
import { Button, Icon, InputSearchDashboardResultCard } from '@/libs/common'
import { IconName } from '@/libs/enums'
import { debounce } from '@/libs/helper'
import { handleError } from '@/libs/helper/handleError'
import { setLastVisitedToken } from '@/libs/helper/lastVisitedToken'
import { TTokenInfo } from '@/libs/types/token-info-response.type'
import { useAppDispatch, useAppSelector } from '@/store'
import { setCurrentToken } from '@/store/slices/chain.slice'

import { SearchTextfield } from './libs'
import styles from './styles.module.scss'

type TInputSearchProps = Omit<
  AutocompleteProps<unknown, false, false, false, 'div'>,
  'renderInput' | 'options'
> & {
  placeholder?: string
}

const InputSearch: FC<TInputSearchProps> = ({ placeholder }) => {
  const { t } = useTranslation()
  const currentTokenGlobal = useAppSelector((state) => state.chain.currentToken)
  const selectedTokenAddress = useAppSelector((state) => state.chain.selectedTokenAddress)
  const dispatch = useAppDispatch()

  const handleNewTokenSelection = useSelectNewToken()
  const { mode } = useParams()
  const [inputValue, setInputValue] = useState('')
  const [isShowMoreResults, setIsShowMoreResults] = useState(false)
  const [loading, setLoading] = useState(false)
  const [option, setOption] = useState<TTokenInfo[]>([])
  // const [selectedAddress, setSelectedAddress] = useState('')
  const chain = useAppSelector((state) => state.chain.currentChain)

  const handleChangeInput = (
    event: SyntheticEvent<Element, Event> | null,
    value: unknown,
    reason?: string,
  ) => {
    setInputValue((value as string) || '')
    if (!value) {
      setIsShowMoreResults(false)
      setOption([])
      // setSelectedAddress('')
      return
    }

    if (!event || reason === 'reset') return

    let searchPhrase = (value as string) || ''

    if (searchPhrase.length > 42) {
      const regex = /0x[a-fA-F0-9]{40}/
      const tokenAddress = searchPhrase.match(regex)
      searchPhrase = tokenAddress ? tokenAddress[0] : searchPhrase
    }

    getTokensList(searchPhrase)
  }

  // Fetch token data from the address and set it to Redux
  const getDataFromAddress = async (address: string, cutomOptions?: TTokenInfo[]) => {
    const currentToken =
      (cutomOptions || option).find((tkn) => tkn.token.address === address) ?? currentTokenGlobal

    if (currentToken) {
      const { data } = await getTokenExchangesAndSocial(address, chain.id)
      if (data.data) {
        setLastVisitedToken(chain.id, address)
        const socialLinks = { ...data?.data.token.social }
        const statistics = { ...data?.data.statistics }
        const info = { ...data?.data.token }
        dispatch(
          setCurrentToken({
            ...currentToken,
            socialLinks,
            statistics: statistics as TDetailedPairStats,
            info: info as any,
          }),
        )
      }
    }
  }

  // Fetch tokens list based on phrase
  const getTokensList = useCallback(
    debounce(async (phrase: string) => {
      if (!phrase || phrase === currentTokenGlobal?.token.address) {
        return
      }
      setLoading(true)
      try {
        const { data } = await getFilterTokens(phrase, chain.id)
        if (Array.isArray(data?.data) && data?.data.length) {
          const searchedToken = data.data.find((item) => item.token.address === phrase)
          const newOptions = searchedToken ? [searchedToken] : data.data

          setOption(newOptions)
          if (newOptions.length === 1) {
            handleTokenSelect(newOptions[0].token.address, newOptions)
          }
        } else {
          setOption([])
        }
      } catch (error) {
        handleError(error, 'Error fetching tokens: ')
      } finally {
        setLoading(false)
      }
    }, 500),
    [currentTokenGlobal, chain, mode],
  )

  // Clear search input
  const handleClear = () => {
    setInputValue('')
    setOption([])
    // setSelectedAddress('')
    setIsShowMoreResults(false)
  }

  // Handle token selection from the list
  const handleTokenSelect = (tokenAddress: string, cutomOptions?: TTokenInfo[]) => {
    // setSelectedAddress(tokenAddress)
    handleNewTokenSelection(tokenAddress, { skipTokenFetch: true })
    getDataFromAddress(tokenAddress, cutomOptions)
  }

  useEffect(() => {
    if (selectedTokenAddress && selectedTokenAddress !== currentTokenGlobal?.token.address) {
      handleChangeInput(null, selectedTokenAddress)
      // setSelectedAddress(selectedTokenAddress)
      setOption([])

      setInputValue('')
    }
  }, [selectedTokenAddress, currentTokenGlobal?.token.symbol])

  // Generate options for Autocomplete
  const inputOptions = option.map(
    (tokenInfo) => `${tokenInfo.token.name} | ${tokenInfo.token.address}`,
  )

  const tokensWith24Volume = useMemo(() => {
    return option
      .filter((item) => {
        return Number(item.token?.volume_24h) > 0
      })
      .map((item) => `${item.token.name} | ${item.token.address}`)
  }, [option])

  // Render options for Autocomplete
  const renderOptions = (
    props: HTMLAttributes<HTMLLIElement>,
    optionSelected: string | { showMoreResults: boolean },
  ) => {
    if (optionSelected === 'showMoreResults') {
      return (
        <li {...props} key="show_no_volume_results">
          <Button
            borderless
            styleVariant="black"
            onClick={(e: any) => {
              e.stopPropagation()
              setIsShowMoreResults((prev) => !prev)
            }}
          >
            {t('token_info.show_low_volume_results')}
          </Button>
        </li>
      )
    }

    if (!option || option.length === 1) {
      return null
    }

    const entry = option.find(
      (tokenInfo) => `${tokenInfo.token.name} | ${tokenInfo.token.address}` === optionSelected,
    )

    return (
      <li {...props} key={entry?.token.address}>
        <InputSearchDashboardResultCard
          option={entry as TTokenInfo}
          setSelectedAddress={(address) => handleTokenSelect(address)}
        />
      </li>
    )
  }

  const filterOptions = (options: string[]) => {
    const isShowAllTokens =
      isShowMoreResults || options.length == tokensWith24Volume.length || !option.length
    return isShowAllTokens ? options : [...tokensWith24Volume, 'showMoreResults']
  }

  // Get label for Autocomplete option
  const getOptionLabel = (option: string | { showMoreResults: boolean }) =>
    typeof option === 'string' ? option.split(' | ')[0] : ''

  return (
    <Autocomplete
      classes={styles}
      className={styles.input}
      freeSolo
      loading={loading}
      loadingText={`${t('loading')}...`}
      filterOptions={filterOptions}
      aria-expanded={true}
      renderInput={(params) => (
        <SearchTextfield
          {...params}
          placeholder={placeholder}
          variant="standard"
          className="test"
          InputProps={params.InputProps}
        />
      )}
      options={inputOptions}
      renderOption={renderOptions}
      getOptionLabel={getOptionLabel}
      clearIcon={<Icon name={IconName.CLOSE_SMALL} />}
      componentsProps={{
        clearIndicator: {
          onClick: handleClear,
        },
      }}
      value={inputValue}
      onInputChange={handleChangeInput}
    />
  )
}

export { InputSearch, type TInputSearchProps }
