import { ChainName, EManualSettingsSection } from '@/libs/enums'

import { TOptionalManualBuyFields } from './types'

// Template has all fields with empty params
const MANUAL_BUY_CHAIN_SETTINGS: Record<ChainName, TOptionalManualBuyFields> = {
  [ChainName.ETHEREUM]: {
    [EManualSettingsSection.BUY]: {
      autoSlippage: false,
      autoPriorities: false,
      buyPriority: false,
      approvePriority: false,
    },
    [EManualSettingsSection.SAFETY]: {
      blackListSettings: false,
    },
    [EManualSettingsSection.SELL]: {
      blackListSell: false,
    },
  },
  [ChainName.BNB]: {
    [EManualSettingsSection.BUY]: {
      antiMev: false,
      minTokens: false,
      spendAmount: false,
      autoSlippage: false,
    },
    [EManualSettingsSection.SAFETY]: {
      blackListSettings: true,
    },
    [EManualSettingsSection.SELL]: {
      blackListSell: true,
    },
  },
  [ChainName.BASE]: {
    [EManualSettingsSection.BUY]: {
      antiMev: false,
      minTokens: false,
      spendAmount: false,
      autoSlippage: false,
    },
    [EManualSettingsSection.SAFETY]: {
      blackListSettings: true,
    },
    [EManualSettingsSection.SELL]: {
      blackListSell: true,
    },
  },
  [ChainName.AVAX]: {},
  [ChainName.ARBITRUM]: {},
  [ChainName.SOLANA]: {
    [EManualSettingsSection.BUY]: {
      autoSlippage: false,
    },
  },
}

export { MANUAL_BUY_CHAIN_SETTINGS }
