import { ListButton, Typography } from '@/libs/common'
import { usePhantomDeeplinks } from '@/libs/common/solana/use-phantom-deeplinks'

import styles from './styles.module.scss'

function PhantomDeeplinks() {
  const { signMessage } = usePhantomDeeplinks()

  return (
    <div className={styles.container}>
      <div className={styles.connectWalletContainer}>
        <div className={styles.titleWrapper}>
          <Typography variant="h1" className={styles.title}>
            Please sign the message to continue registration
          </Typography>
        </div>
        <ListButton title="Please verify your wallet signature." onClick={signMessage} />
      </div>
    </div>
  )
}

export { PhantomDeeplinks }
