import bs58 from 'bs58'

/* eslint-disable import/no-extraneous-dependencies */
import nacl from 'tweetnacl'

type ConnectParams = {
  app_url: string
  dapp_encryption_public_key: string
  redirect_link: string
  cluster?: 'mainnet-beta' | 'devnet' | 'testnet'
}

type OnConnectApprovedParams = {
  phantom_encryption_public_key: string
  nonce: string
  data: string
}

type SignMessageParams = {
  dapp_encryption_public_key: string
  redirect_link: string
  message: string
  session: string
}

type OnSignMessageApprovedParams = {
  nonce: string
  data: string
}

type DisconnectParams = {
  dapp_encryption_public_key: string
  redirect_link: string
  session: string
}

type RejectedParams = {
  errorCode: string
  errorMessage: string
}

class PhantomDeeplinksApi {
  private baseUrl = 'https://phantom.app/ul/v1'

  private encryptPayload<T>(payload: T, sharedSecret?: Uint8Array) {
    if (!sharedSecret) throw new Error('missing shared secret')

    const nonce = nacl.randomBytes(24)

    const encryptedPayload = nacl.box.after(
      Buffer.from(JSON.stringify(payload)),
      nonce,
      sharedSecret,
    )

    return [nonce, encryptedPayload]
  }

  public decryptPayload<T>(data: string, nonce: string, sharedSecret?: Uint8Array): T {
    if (!sharedSecret) throw new Error('missing shared secret')

    const decryptedData = nacl.box.open.after(bs58.decode(data), bs58.decode(nonce), sharedSecret)

    if (!decryptedData) {
      throw new Error('Unable to decrypt data')
    }

    return JSON.parse(Buffer.from(decryptedData).toString('utf8'))
  }

  public connect(params: ConnectParams) {
    const url = new URL(`${this.baseUrl}/connect`)

    for (const [key, value] of Object.entries(params)) {
      url.searchParams.set(key, value)
    }

    window.location.href = url.toString()
  }

  public onConnect({
    searchParams,
    onApproved,
    onRejected,
    onError,
  }: {
    searchParams: URLSearchParams
    onApproved: (data: OnConnectApprovedParams) => void
    onRejected: (error: RejectedParams) => void
    onError: (searchParams: Record<string, string>) => void
  }) {
    const phantom_encryption_public_key = searchParams.get('phantom_encryption_public_key')
    const nonce = searchParams.get('nonce')
    const data = searchParams.get('data')

    const errorCode = searchParams.get('errorCode')
    const errorMessage = searchParams.get('errorMessage')

    if (phantom_encryption_public_key && nonce && data) {
      onApproved({
        phantom_encryption_public_key,
        nonce,
        data,
      })
    } else if (errorCode && errorMessage) {
      onRejected({ errorCode, errorMessage })
    } else {
      onError(Object.fromEntries(searchParams.entries()))
    }
  }

  public signMessage(params: SignMessageParams, sharedSecret?: Uint8Array) {
    const url = new URL(`${this.baseUrl}/signMessage`)

    const payload = {
      message: bs58.encode(Buffer.from(params.message)),
      session: params.session,
    }
    const [nonce, encryptedPayload] = this.encryptPayload(payload, sharedSecret)

    url.searchParams.set('dapp_encryption_public_key', params.dapp_encryption_public_key)
    url.searchParams.set('nonce', bs58.encode(nonce))
    url.searchParams.set('redirect_link', params.redirect_link)
    url.searchParams.set('payload', bs58.encode(encryptedPayload))

    window.location.href = url.toString()
  }

  public onSignMessage({
    searchParams,
    onApproved,
    onRejected,
    onError,
  }: {
    searchParams: URLSearchParams
    onApproved: (data: OnSignMessageApprovedParams) => void
    onRejected: (error: RejectedParams) => void
    onError: (searchParams: Record<string, string>) => void
  }) {
    const nonce = searchParams.get('nonce')
    const data = searchParams.get('data')

    const errorCode = searchParams.get('errorCode')
    const errorMessage = searchParams.get('errorMessage')

    if (nonce && data) {
      onApproved({ nonce, data })
    } else if (errorCode && errorMessage) {
      onRejected({ errorCode, errorMessage })
    } else {
      onError(Object.fromEntries(searchParams.entries()))
    }
  }

  public disconnect(params: DisconnectParams, sharedSecret?: Uint8Array) {
    const url = new URL(`${this.baseUrl}/disconnect`)

    const payload = {
      session: params.session,
    }
    const [nonce, encryptedPayload] = this.encryptPayload(payload, sharedSecret)

    url.searchParams.set('dapp_encryption_public_key', params.dapp_encryption_public_key)
    url.searchParams.set('nonce', bs58.encode(nonce))
    url.searchParams.set('redirect_link', params.redirect_link)
    url.searchParams.set('payload', bs58.encode(encryptedPayload))

    window.location.href = url.toString()
  }

  public onDisconnect({
    searchParams,
    onApproved,
    onRejected,
  }: {
    searchParams: URLSearchParams
    onApproved: () => void
    onRejected: (error: RejectedParams) => void
  }) {
    const errorCode = searchParams.get('errorCode')
    const errorMessage = searchParams.get('errorMessage')

    if (errorCode && errorMessage) {
      onRejected({ errorCode, errorMessage })
    } else {
      onApproved()
    }
  }
}

export const phantomDeeplinksApi = new PhantomDeeplinksApi()
