import { useTranslation } from 'react-i18next'

import { Button } from '@/libs/common'
import { ModalComponent } from '@/libs/common/modal-component'
import { useAppSelector } from '@/store'

const IdleWarningModal = () => {
  const isAppIdle = useAppSelector((state) => state.app.isAppIdle)
  const { t } = useTranslation()

  return (
    <ModalComponent
      open={isAppIdle}
      name="idle-modal"
      title={t('alert_modal.you_disconnected')}
      message={t('alert_modal.you_disconnected_description')}
    >
      <Button onClick={() => location.reload()}>{t('alert_modal.reload')}</Button>
    </ModalComponent>
  )
}

export { IdleWarningModal }
