import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { AuthCard } from '@/components/auth-card'
import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { Button } from '@/libs/common'
import { Typography } from '@/libs/common/typography'
import { AppRoute, VerificationStatus } from '@/libs/enums'

import styles from './styles.module.scss'

const ImportWalletFile = () => {
  const navigate = useCustomNavigate()
  const { t } = useTranslation()

  const handleImportFile = () => {
    navigate({ path: AppRoute.MASTER_PASSWORD_ADVANTAGES })
  }
  return (
    <AuthCard
      className={styles.section}
      verificationStatus={VerificationStatus.SETUP_WALLETS}
      isHeaderButtons
      isBackButton
    >
      <section>
        <Typography variant="h1" align="center" className={styles.title}>
          {t('setup_wallets.import_wallet_file')}
        </Typography>

        <Typography variant="body1" className={styles.explanationWrapper}>
          {t('setup_wallets.import_wallet_attention')}
        </Typography>

        <div className={styles.dropBlock}>
          <Typography className={styles.dropDescription}>
            {t('drag_and_drop')}
            <span className={styles.span}>or</span>
            <Link to="/" className={styles.link}>
              {t('browser_file')}{' '}
            </Link>
          </Typography>
        </div>

        <Button onClick={handleImportFile}>{t('continue')}</Button>
      </section>
    </AuthCard>
  )
}

export { ImportWalletFile }
