import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import bs58 from 'bs58'

import { signUp } from '@/api/user'
import { TSignupBody } from '@/api/user/types'
import { AuthCard } from '@/components/auth-card'
import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { Button, Typography } from '@/libs/common'
import { AppRoute } from '@/libs/enums'
import { extractErrorDescription } from '@/libs/helper/extractErrorDescription'
import { useAppDispatch } from '@/store'
import { setAuth } from '@/store/slices/auth.slice'
import { fetchUserById } from '@/store/slices/user.slice.ts'

import styles from './styles.module.scss'

export const AlreadySign = () => {
  const navigate = useCustomNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)

  async function handleSignUp(data: TSignupBody) {
    const res = await signUp(data)
    localStorage.signUpData = JSON.stringify(res.data.data)
    localStorage.token = res.data.data.token
    localStorage.refreshToken = res.data.data.refresh_token
    await dispatch(fetchUserById(res.data.data.id)).unwrap()
    navigate({ path: AppRoute.CREATE_MASTER_PASSWORD })
  }

  async function addUser() {
    const data = JSON.parse(localStorage.signData) as TSignupBody
    try {
      setLoading(true)
      const affiliateId = sessionStorage.affiliateId
      if (affiliateId) {
        data.referred_by = affiliateId
        sessionStorage.removeItem('affiliateId')
      }
      await handleSignUp(data)
    } catch (e: any) {
      const errCodeTitle = e?.response?.data?.data?.title
      if (errCodeTitle === 'Invalid Signature') {
        const signature = bs58.encode(Buffer.from(data.signature, 'base64'))
        await handleSignUp({ ...data, signature })
      } else {
        extractErrorDescription(e)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <AuthCard className={styles.card}>
      <div className={styles.content}>
        <div className={styles.titleWrapper}>
          <Typography variant="h1" className={styles.title} align="center">
            {t('login.already_sign')}
          </Typography>
        </div>
        <Button styleVariant="yellow" isLoading={loading} onClick={addUser}>
          <Typography variant="body2" textColor="black" className={styles.message}>
            {t('login.new_user')}
          </Typography>
        </Button>

        <Button
          styleVariant="black"
          onClick={async () => {
            localStorage.setItem('signData', '')
            dispatch(setAuth(null))
            navigate({ path: `/${AppRoute.MODAL}/${AppRoute.WALLET_CONNECT}` })
          }}
        >
          <Typography variant="body2" textColor="white" className={styles.message}>
            {t('login.signed_before')}
          </Typography>
        </Button>
      </div>
    </AuthCard>
  )
}
