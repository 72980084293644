import { FC, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import { TableContainer, TableContainerProps } from '@mui/material'
import cn from 'classnames'

import styles from '@/libs/common/custom-table/styles.module.scss'
import { Typography } from '@/libs/common/typography'

type TProps = PropsWithChildren &
  TableContainerProps & {
    dataExists?: boolean
  }

const CustomTableContainer: FC<TProps> = ({ dataExists = true, children, className, ...props }) => {
  const { t } = useTranslation()
  return (
    <TableContainer {...props} className={cn(styles.container, className)}>
      {children}
      {!dataExists && (
        <Typography textAlign="center" marginTop={1} marginBottom={1}>
          {t('settings.no_data')}
        </Typography>
      )}
    </TableContainer>
  )
}

export { CustomTableContainer }
