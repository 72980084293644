import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { SellTab } from '@/components/dashboard-component-lite/libs/buy-sell-block/libs/sell-tab'
import { useModal } from '@/pages/modal-page/modal-page'

const LiteSell = () => {
  const { setModalProps } = useModal()
  const { t } = useTranslation()

  useEffect(() => {
    setModalProps({ title: t('sell') })
  }, [])

  return (
    <>
      <SellTab />
    </>
  )
}

export { LiteSell }
