import { FC, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Grid, InputLabel } from '@mui/material'
import cls from 'classnames'

import { SelectWallet } from '@/components/select-wallet'
import {
  Accordion,
  Button,
  ButtonGroupRadio,
  ButtonGroupRadioButton,
  HiddenInputs,
  Icon,
  InputWithRadioGroup,
  StepInput,
  Typography,
} from '@/libs/common'
import { ControlledAccordion } from '@/libs/common/controlled-accordion'
import { EndAdornment } from '@/libs/common/input/components/end-adornment'
import { OptionalInput } from '@/libs/common/optional-input'
import { PercentsInput } from '@/libs/common/percents-input'
import { SwitchInline } from '@/libs/common/switch-inline'
import { TooltipIcon } from '@/libs/common/tooltip-icon'
import { IconName } from '@/libs/enums'
import { TSelectOption } from '@/libs/types/select-option.type'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

const spendOptions: TSelectOption<number>[] = [
  {
    value: 0.25,
    label: '25%',
  },
  {
    value: 0.5,
    label: '50%',
  },
  {
    value: 0.75,
    label: '75%',
  },
  {
    value: 1,
    label: '100%',
  },
]

// @TODO remove duplicate data
const ProfitSettingsTabs: TSelectOption<string>[] = [
  { value: 'sellInSteps', label: 'Sell in steps' },
  { value: 'sellOnProfit', label: 'Sell on profit' },
  { value: 'trailingSell', label: 'Trailing sell' },
]

const defaultValues = {
  firstBundleOrFail: '',
  firstBundleBuy: '',
  backupBundle: '',
  backupBundleBuy: '',
  maxTxOrFail: false,
  spend: undefined,
  slippage: 25,
  selectedWallets: [],
  // Snipe settings
  mempoolBackupBuy: '',
  minPercentForFail: '',
  approvePriority: '',
  transferOnBlacklist: false,
  antiBlacklist: false,
  antiRug: false,
  antiRugThreshold: 50,
  sellRugExtraTip: '',
  maxBuyTax: '',
  maxSellTax: '',
  minLiquidity: '',
  maxLiquidity: '',
  maxMarketcap: '',
  autoSell: '',
  walletsToSellOnLoss: [],
  walletsToSellOnProfit: [],
  sellPriority: '',
  selectedWalletsForProfit: [],
  selectedWalletsForLoss: [],
  stopLoss: '',
  stopLossAmount: '',
  firstStep: '25',
  secondStep: '32',
  thirdStep: '40',
  sellOnProfit: '',
  sellOnProfitAmount: '',
  walletsToTrailingSell: [],
  trailingSell: '',
}

type TProperty = {
  buttonName: string
}

const ManualSnipeForm: FC<TProperty> = ({ buttonName }) => {
  const userWallets = useAppSelector((state) => state.user.userWallets)
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  const [availableTokens, setAvailableTokens] = useState(0)
  const [isAntiRugSettings, setIsAntiRugSettings] = useState(defaultValues.antiRug)
  const { t } = useTranslation()

  const [isFirstBundle, setIsFirstBundle] = useState(false)
  const [isBackupBundle, setIsBackupBundle] = useState(false)
  const [isAutoSell, setIsAutoSell] = useState(false)
  const [selectedWallets, setSelectedWallets] = useState<number[]>([])
  const [profitTab, setProfitTab] = useState('sellInSteps')

  const { control, handleSubmit } = useForm({
    defaultValues,
  })

  const onSubmit: SubmitHandler<typeof defaultValues> = (data) => {
    // Send data to backend
    return data
  }

  const handleProfitTabChange = (_: React.BaseSyntheticEvent, newValue: string) => {
    setProfitTab(newValue)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container rowGap={2} marginTop={2}>
        <Accordion
          className={styles.accordion}
          titleClassName={styles.subTitle}
          title={'Advanced Snipe Settings'}
          withDivider
          mainColorArrow
        >
          <Grid container rowGap={2} marginTop={1} marginBottom={3}>
            <Typography variant="body1" className={styles.subTitle}>
              {t('buy')}
            </Typography>
            <Controller
              name="mempoolBackupBuy"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <OptionalInput
                  placeholder={t('token_info.amount')}
                  isNumeric
                  label="Mempool Backup Buy"
                  isOptional
                  tooltipInfo="It will send mempool transaction with your other type of transactions, in case there is a bad block or any other situation that may arise it is very helpful to try with mempool too (This will send in same block along with bribe and backup bribe if selected)."
                  {...field}
                />
              )}
            />
            <Grid container flexWrap="nowrap" columnGap={3}>
              <Controller
                name="minPercentForFail"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <OptionalInput
                    placeholder={`${currentChain.chainSymbol} amount`}
                    isNumeric
                    label="Min % / Tokens or fail"
                    tooltipInfo={`Minimal percentage of total supply or exact amount in ${currentChain.chainSymbol} equivalent of tokens that should be bought or the transaction will fail.`}
                    {...field}
                  />
                )}
              />
              <Controller
                name="approvePriority"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <OptionalInput
                    placeholder={t('template.enter_gwei_amount', {
                      currency: currentChain.priorityCurrency.toLowerCase(),
                    })}
                    isNumeric
                    label={t('template.approve_priority')}
                    tooltipInfo={`${t('template.approve_priority_tooltip')} ${currentChain.chainSymbol}.`}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Typography variant="body1" className={styles.subTitle} marginTop={1}>
              {t('template.safety')}
            </Typography>
            <Grid container flexWrap="nowrap" columnGap={3}>
              <Controller
                name="transferOnBlacklist"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <SwitchInline
                    label="Transfer On Blacklist"
                    tooltipInfo="Will transfer tokens to the backup wallet when the developer tries to blacklist your wallet."
                    {...field}
                  />
                )}
              />
              <Controller
                name="antiBlacklist"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <SwitchInline
                    label={t('template.anti_blacklist')}
                    tooltipInfo={t('template.anti_blacklist_tooltip')}
                    {...field}
                  />
                )}
              />
            </Grid>

            <HiddenInputs
              expanded={isAntiRugSettings}
              trigger={
                <Controller
                  name="antiRug"
                  control={control}
                  render={({ field: { ref, onChange, ...field } }) => (
                    <SwitchInline
                      label={t('template.anti_rug')}
                      tooltipInfo={t('template.anti_rug_tooltip')}
                      onChange={(_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                        setIsAntiRugSettings(checked)
                        onChange(checked)
                      }}
                      {...field}
                    />
                  )}
                />
              }
            >
              <Grid container rowGap={2}>
                <Controller
                  name="antiRugThreshold"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <PercentsInput
                      label={t('template.anti_rug_threshold')}
                      onOptionSelect={(value) => field.onChange(value)}
                      tooltipInfo={t('template.anti_rug_tooltip')}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="sellRugExtraTip"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <OptionalInput
                      placeholder={t('token_info.amount')}
                      isNumeric
                      label={t('template.sell_extra_rug')}
                      tooltipInfo={t('template.sell_extra_rug_tooltip')}
                      {...field}
                    />
                  )}
                />
              </Grid>
            </HiddenInputs>

            <Grid container flexWrap="nowrap" columnGap={3}>
              <Controller
                name="maxBuyTax"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <OptionalInput
                    placeholder={t('template.enter_percentage')}
                    isNumeric
                    label={t('template.max_buy_template')}
                    adornmentText="%"
                    tooltipInfo={t('template.max_buy_tax_tooltip')}
                    {...field}
                  />
                )}
              />
              <Controller
                name="maxSellTax"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <OptionalInput
                    placeholder={t('template.enter_percentage')}
                    isNumeric
                    label={t('template.max_sell_tax')}
                    adornmentText="%"
                    tooltipInfo={t('template.max_sell_tax_tooltip')}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid container flexWrap="nowrap" columnGap={3}>
              <Controller
                name="minLiquidity"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <OptionalInput
                    placeholder={t('token_info.enter_min_liquidity')}
                    isNumeric
                    label={t('token_info.min_liquidity')}
                    adornmentText="$"
                    tooltipInfo={t('token_info.min_liquidity_tooltip')}
                    {...field}
                  />
                )}
              />
              <Controller
                name="maxLiquidity"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <OptionalInput
                    placeholder={t('token_info.enter_max_liquidity')}
                    isNumeric
                    label={t('token_info.max_liquidity')}
                    adornmentText="$"
                    tooltipInfo={t('token_info.max_liquidity_tooltip')}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Controller
              name="maxMarketcap"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <OptionalInput
                  placeholder={t('token_info.enter_max_marketcap')}
                  isNumeric
                  label={t('token_info.max_marketcap')}
                  adornmentText="$"
                  tooltipInfo={t('token_info.max_marketcap_tooltip')}
                  {...field}
                />
              )}
            />
            <Typography variant="body1" className={styles.subTitle} marginTop={1}>
              Sell
            </Typography>

            <Grid container rowGap={2}>
              <Controller
                name="autoSell"
                control={control}
                render={({ field: { ref, onChange, ...field } }) => (
                  <SwitchInline
                    label={t('template.auto_sell')}
                    tooltipInfo={t('template.auto_sell_tooltip')}
                    onChange={(_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                      setIsAutoSell(checked)
                      onChange(checked)
                    }}
                    {...field}
                  />
                )}
              />

              <ControlledAccordion expanded={isAutoSell}>
                <Grid container rowGap={2}>
                  <Controller
                    name="sellPriority"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <OptionalInput
                        className={styles.optionalInput}
                        placeholder={t('template.enter_gwei_amount', {
                          currency: currentChain.priorityCurrency.toLowerCase(),
                        })}
                        isNumeric
                        label={t('template.sell_priority')}
                        onAutoChange={() => {
                          // Hendle auto toggle change
                        }}
                        tooltipInfo={`${t('template.enter_gwei_amount_tooltip')} ${currentChain.chainSymbol}.`}
                        onExpand={setIsFirstBundle}
                        {...field}
                      />
                    )}
                  />
                  <Grid container alignItems="center">
                    <Typography variant="body2" textColor="grey">
                      Profit
                    </Typography>

                    <div className={styles.divider}></div>
                  </Grid>

                  <Controller
                    name="walletsToSellOnProfit"
                    control={control}
                    render={({ field: { ref, value, onChange, ...field } }) => {
                      setSelectedWallets(value)

                      return (
                        <SelectWallet
                          isMulti
                          tooltipInfo={t('wallet.wallets_to_sell_on_profit_tooltip')}
                          wallet={value}
                          wallets={userWallets}
                          onChange={(value) => {
                            onChange(value)
                            let availableBalance = 0
                            value.forEach((walletPosition) => {
                              const currentBalance =
                                userWallets?.[+walletPosition - 1]?.balanceFormatted
                              if (currentBalance) {
                                availableBalance += +currentBalance
                              }
                            })
                            setAvailableTokens(availableBalance)
                          }}
                          {...field}
                          label={t('wallet.wallets_to_sell_on_profit')}
                        />
                      )
                    }}
                  />

                  <ButtonGroupRadio
                    onChange={handleProfitTabChange}
                    value={profitTab}
                    exclusive
                    className={styles.group}
                  >
                    {ProfitSettingsTabs.map(({ value, label }) => (
                      <ButtonGroupRadioButton
                        value={value}
                        key={value}
                        className={cls({ [styles.bgGrey]: value === profitTab })}
                      >
                        {label}
                      </ButtonGroupRadioButton>
                    ))}
                  </ButtonGroupRadio>

                  {profitTab === 'sellInSteps' && selectedWallets.length > 0 && (
                    <div className={styles.stepContainer}>
                      <div className={styles.labelContent}>
                        <InputLabel className={styles.label}>Sell in steps</InputLabel>

                        <TooltipIcon info={t('sell_tab.sell_in_steps_tooltip')} marginLeft={5} />
                      </div>

                      <div className={styles.stepWrapper}>
                        <Controller
                          name="firstStep"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <StepInput
                              wallet="#1"
                              balance="12.58 ETH"
                              className={styles.stepInput}
                              {...field}
                            />
                          )}
                        />

                        <Controller
                          name="secondStep"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <StepInput
                              wallet="#2"
                              balance="12.58 ETH"
                              {...field}
                              className={styles.stepInput}
                            />
                          )}
                        />

                        <Controller
                          name="thirdStep"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <StepInput
                              wallet="#3"
                              balance="12.58 ETH"
                              className={cls(styles.stepInput, styles.lastStepInput)}
                              {...field}
                            />
                          )}
                        />
                      </div>
                    </div>
                  )}

                  {profitTab === 'sellInSteps' && !selectedWallets.length && (
                    <Typography variant="body1" className={styles.walletsDescr}>
                      {t('wallet.no_wallets_selected')}
                    </Typography>
                  )}

                  {profitTab === 'sellOnProfit' && (
                    <Grid container flexWrap="nowrap" columnGap={3}>
                      <Controller
                        name="sellOnProfit"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <OptionalInput
                            placeholder={t('sell_tab.enter_sell_profit')}
                            isNumeric
                            label={t('sell_tab.sell_on_profit')}
                            adornmentText="%"
                            tooltipInfo={t('sell_tab.sell_on_profit_tooltip')}
                            {...field}
                          />
                        )}
                      />

                      <Controller
                        name="sellOnProfitAmount"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <OptionalInput
                            placeholder={t('sell_tab.sell_profit_amount')}
                            isNumeric
                            label={t('sell_tab.sell_profit_amount_title')}
                            adornmentText="%"
                            tooltipInfo={t('sell_tab.sell_profit_amount_tooltip')}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                  )}

                  {profitTab === 'trailingSell' ? (
                    <>
                      <Controller
                        name="walletsToTrailingSell"
                        control={control}
                        render={({ field: { ref, value, ...field } }) => (
                          <SelectWallet
                            isMulti
                            wallet={value}
                            label={t('sell_tab.wallets_to_sell')}
                            tooltipInfo={t('wallet.select_wallets_tooltip')}
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        name="trailingSell"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <OptionalInput
                            placeholder={t('sell_tab.enter_sell_trailing')}
                            isNumeric
                            label={t('sell_tab.sell_profit')}
                            adornmentText="%"
                            tooltipInfo="tooltipInfo"
                            {...field}
                          />
                        )}
                      />
                    </>
                  ) : (
                    <>
                      <Grid container alignItems="center">
                        <Typography variant="body2" textColor="grey">
                          {t('loss')}
                        </Typography>

                        <div className={styles.divider}></div>
                      </Grid>

                      <Controller
                        name="selectedWalletsForLoss"
                        control={control}
                        render={({ field: { ref, value, ...field } }) => (
                          <SelectWallet
                            isMulti
                            wallet={value}
                            label={t('wallet.wallets_to_sell_on_stop')}
                            tooltipInfo={t('wallet.wallets_to_sell_on_stop_tooltip')}
                            {...field}
                          />
                        )}
                      />

                      <Grid container flexWrap="nowrap" columnGap={3}>
                        <Controller
                          name="stopLoss"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <OptionalInput
                              placeholder={t('amount_values.percentage_sell_on_loss')}
                              isNumeric
                              label={t('template.stop_loss')}
                              adornmentText="%"
                              tooltipInfo={t('template.stop_loss_tooltip')}
                              {...field}
                            />
                          )}
                        />

                        <Controller
                          name="stopLossAmount"
                          control={control}
                          render={({ field: { ref, ...field } }) => (
                            <OptionalInput
                              placeholder={t('amount_values.amount_sell_on_loss')}
                              isNumeric
                              label={t('template.stop_loss_amount')}
                              adornmentText="%"
                              tooltipInfo={t('amount_values.amount_sell_on_loss_tooltip')}
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </ControlledAccordion>
            </Grid>
          </Grid>
        </Accordion>
        <Grid container rowGap={isFirstBundle ? 2 : 0}>
          <Controller
            name="firstBundleOrFail"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <OptionalInput
                placeholder={t('token_info.amount')}
                isNumeric
                label="First Bundle or fail"
                isOptional
                tooltipInfo="Be the first buyer or don't buy anything. If enabled, your transaction will only proceed if it was in the first block (bundle)."
                onExpand={setIsFirstBundle}
                {...field}
              />
            )}
          />
          <Controller
            name="firstBundleBuy"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <ControlledAccordion expanded={isFirstBundle}>
                <OptionalInput
                  placeholder={t('token_info.amount')}
                  isNumeric
                  label="First Bundle Buy (Tip)"
                  tooltipInfo="tooltipInfo"
                  {...field}
                />
              </ControlledAccordion>
            )}
          />
        </Grid>
        <Grid container rowGap={isBackupBundle ? 2 : 0}>
          <Controller
            name="backupBundle"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <OptionalInput
                placeholder={t('token_info.amount')}
                isNumeric
                label="Backup Bundle"
                isOptional
                tooltipInfo="Sends a backup bundle together with main bundle or just backup bundle if only selected backup - It will be accepted anywhere in the block, not just 1st positions like first bundle or fail"
                onExpand={setIsBackupBundle}
                {...field}
              />
            )}
          />
          <Controller
            name="backupBundleBuy"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <ControlledAccordion expanded={isBackupBundle}>
                <OptionalInput
                  placeholder={t('token_info.amount')}
                  isNumeric
                  label="Backup Bundle Buy"
                  tooltipInfo="The amount of ETH that will be spent to buy token at backup transaction."
                  {...field}
                />
              </ControlledAccordion>
            )}
          />
        </Grid>
        <Controller
          name="maxTxOrFail"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <SwitchInline
              label={t('template.max_tx_or_fail')}
              tooltipInfo={t('template.max_tx_or_fail_tooltip')}
              {...field}
            />
          )}
        />
        <Controller
          name="slippage"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <PercentsInput
              label={t('template.slippage')}
              onOptionSelect={(value) => field.onChange(value)}
              tooltipInfo={t('template.slippage_tooltip')}
              {...field}
            />
          )}
        />
        <Controller
          name="selectedWallets"
          control={control}
          render={({ field: { ref, value, ...field } }) => (
            <SelectWallet
              isMulti
              wallet={value}
              {...field}
              tooltipInfo={t('wallet.select_wallets_tooltip')}
            />
          )}
        />
        <Grid container className={styles.dividerWithArrow}>
          <div className={styles.dividerLine} />
          <Icon name={IconName.ARROW_DOWN_GREY} />
          <div className={styles.dividerLine} />
        </Grid>
        <Controller
          name="spend"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <InputWithRadioGroup
              label={t('spend')}
              placeholder={t('amount_values.amount_to_spend')}
              isNumeric
              onOptionSelect={(value) => field.onChange(value)}
              endAdornment={
                <EndAdornment
                  label={currentChain.chainSymbol}
                  icon={(IconName as any)[currentChain.iconName]}
                />
              }
              radioGroupName="amount"
              options={spendOptions}
              {...field}
            />
          )}
        />
      </Grid>
      <div className={styles.available}>
        <Typography variant="body2" className={styles.availableParam}>
          {t('available')}:
        </Typography>
        <Typography variant="body2" className={styles.availableValue}>
          {availableTokens} {currentChain.chainSymbol}
        </Typography>
      </div>
      <Button type="submit" className={styles.submit}>
        {buttonName}
      </Button>
    </form>
  )
}

export { ManualSnipeForm }
